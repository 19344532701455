<div class="content-section">
	<div class="routing-area-view-area">
		<div class="global-title-section-name-refresh-button">
			<div style="margin-left: 2%;">
				<button mat-stroked-button matTooltip="Refresh {{EntityName}}" (click)="ngOnInit()" color="primary">
					<mat-icon>refresh</mat-icon> Refresh
				</button>
			</div>

		</div>
		<div class="routing-area-view-new-button">
			<button mat-mini-fab color="accent" (click)="AddNew($event)" matTooltip="Add New {{EntityName}}">
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</div>


	<div style="margin-top:1%;"></div>

	<mat-divider></mat-divider>
	<div *ngIf="gridDataSource">
		<app-data-grid [EntityDataArray]="gridDataSource" [EntityColumns]="Columns"
			(EditEvent)="rowDoubleClicked($event)" [EntityIdentifier]="EntityName"
			[GridKeyExpression]="GridKeyExpression">
		</app-data-grid>
	</div>
</div>