<div style="margin-bottom: 1%" class="portfolio-analytics">
  <div class="investor-specific-section">
    <div class="analytics-container">
      <div class="portfolio-analytics-config-main">
        <!-- main config section -->
        <div class="portfolio-analytics-config-section">
          <div>
            <span
              id="analytics-fund-caption"
              class="analytics-section-selection-title"
              >Fund</span
            >
            <!-- <dx-lookup id="analytics-fund-input" #investorLookupInstance itemTemplate="listItem" [width]=200
              matTooltip="The investor to run analysis on">
              <div *dxTemplate="let item of 'listItem'">
                <span style="font-weight: 500">{{item.Name}}</span>
              </div>
            </dx-lookup> -->
            <dx-drop-down-box
              #funddropdownInstance
              [(value)]="treeBoxValue"
              valueExpr="ID"
              displayExpr="Name"
              placeholder="Select a Fund..."
              [dataSource]="FundsFundManagerDataSource"
              width="300"
              [(value)]="treeBoxValue"
              [(opened)]="isTreeBoxOpened"
            >
              <dx-tree-list
                #treelist
                height="600"
                width="500"
                #fundManagerTreeList
                [dataSource]="FundsFundManagerDataSource"
                keyExpr="ID"
                parentIdExpr="Head_ID"
                [allowColumnResizing]="true"
                [allowColumnReordering]="true"
                [showBorders]="true"
                (onSelectionChanged)="ModelGroupsItemsSelected($event)"
                noDataText="no data to display..."
                class="tree-list"
                [highlightChanges]="true"
                [repaintChangesOnly]="true"
                [focusedRowEnabled]="false"
                [showBorders]="true"
                [autoExpandAll]="true"
                [hoverStateEnabled]="true"
                [focusStateEnabled]="true"
                (onItemSelectionChanged)="treeList_itemSelectionChanged($event)"
              >
                <dxo-selection
                  mode="multiple"
                  [recursive]="true"
                ></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-sorting mode="multiple"> </dxo-sorting>
                <dxi-column
                  dataField="Name"
                  caption="Select Funds under a Fund Manager"
                  sortOrder="asc"
                  style="font-size: 200%; font-weight: 700"
                >
                </dxi-column>
                <dxo-selection mode="single"> </dxo-selection>
              </dx-tree-list>
            </dx-drop-down-box>
          </div>
          <div>
            <span
              id="analytics-asset-class-caption"
              class="analytics-section-selection-title"
              >Asset Class/ Total Fund</span
            >
            <dx-lookup
              id="analytics-asset-classes-input"
              #assetClassLookupInstance
              itemTemplate="listItem"
              [width]="200"
              matTooltip="Select from a list of asset classes or the total fund"
            >
              <div *dxTemplate="let item of 'listItem'">
                <span *ngIf="item.Name == 'Total Fund'; else theAssetClasses">
                  <div
                    style="font-weight: 600; color: red"
                    matTooltip="The total fund is made up of all the above asset classes"
                  >
                    {{ item.Name }}
                  </div>
                </span>
                <ng-template #theAssetClasses>
                  <span style="font-weight: 500">{{ item.Name }}</span>
                </ng-template>
              </div>
            </dx-lookup>
          </div>
          <div>
            <span
              id="analytics-start-date-caption"
              class="analytics-section-selection-title"
              >Start Date</span
            >
            <dx-date-box
              id="analytics-start-date-input"
              displayFormat="dd-MMM-yyyy"
              #startDateBox
              matTooltip="Period start date"
            >
            </dx-date-box>
          </div>
          <div>
            <span
              id="analytics-end-date-caption"
              class="analytics-section-selection-title"
              >End Date</span
            >
            <dx-date-box
              id="analytics-end-date-input"
              displayFormat="dd-MMM-yyyy"
              #endDateBox
              matTooltip="Period end date"
            >
            </dx-date-box>
          </div>
          <div>
            <span
              id="analytics-summary-caption"
              class="analytics-section-selection-title"
              style="margin-left: 20px"
              >Summary</span
            >
            <div style="margin-left: 20px">
              <dx-switch
                #summaryPerformanceAttributionSwitch
                [value]="false"
                matTooltip="Opt to show performance attribution as a summary for the specified period given a specific investor."
              >
              </dx-switch>
            </div>
          </div>
          <div>
            <span
              class="analytics-section-selection-title"
              style="margin-left: 20px"
              >Include Cash In Portfolio Valuation</span
            >
            <div style="margin-left: 20px">
              <dx-switch
                #includeCashSwitch
                [value]="true"
                matTooltip="Opt to show performance attribution as a summary for the specified period given a specific investor."
              >
              </dx-switch>
            </div>
          </div>
        </div>
        <!-- main config section -->

        <!-- refresh button config -->
        <div>
          <button
            mat-flat-button
            color="primary"
            (click)="analyzeAssetClass($event)"
          >
            <mat-icon>update</mat-icon>Refresh
          </button>
        </div>
        <!-- refresh button config -->
      </div>

      <div style="margin-top: 1%"></div>
      <!-- toolbar test -->
      <div *ngIf="showSampleGrid === true">
        <div>
          <dx-data-grid
            id="gridContainer"
            [remoteOperations]="false"
            [allowColumnReordering]="true"
            [rowAlternationEnabled]="true"
            [showBorders]="true"
            noDataText="Change options -> click 'Refresh' -> Results appear here."
          >
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 25, 50, 100]"
            ></dxo-pager>
            <dxo-search-panel
              [visible]="true"
              [highlightCaseSensitive]="true"
              id="product3"
            ></dxo-search-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
          </dx-data-grid>
        </div>
      </div>

      <div>
        <div style="margin: 0.5%; height: initial">
          <app-analytics-display-view
            [assetClassModelObservable]="assetClassModelObservable"
            [investorIdObservable]="investorIdObservable"
            [startDateObservable]="startDateObservable"
            [endDateObservable]="endDateObservable"
          >
          </app-analytics-display-view>
        </div>
      </div>
    </div>
  </div>
</div>
