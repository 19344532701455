import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectedFundItemsConfig } from 'src/app/models/selected-fund-items-config';

@Injectable({
  providedIn: 'root'
})
export class SelectedFundConfigService {

  private selectedFundConfigSubject = new Subject<SelectedFundItemsConfig>();
  constructor() { }

  public updateSelectedFundConfig(selectedFundConfig: SelectedFundItemsConfig) {
    this.selectedFundConfigSubject.next(selectedFundConfig)
  }

  public getlatestObservableValue() {
    return this.selectedFundConfigSubject.asObservable();
  }

  public clearRecentObservables() {
    this.selectedFundConfigSubject = new Subject<SelectedFundItemsConfig>();
  }
}
