import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Column } from "devextreme/ui/data_grid";
import { Observable } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

let fundamentalAnalysisModels: FundamentalModels[] = [
  {
    ModelGroup: "Liquidity Analysis",
    Models: [
      {
        ID: "current ratio",
        Head_ID: 0,
        Name: "Current Ratio",
        ParentName: "liquidity analysis",
      },
      {
        ID: "quick ratio",
        Head_ID: 0,
        Name: "Quick Ratio(ACID Test)",
        ParentName: "liquidity analysis",
      },
      {
        ID: "cash ratio",
        Head_ID: 0,
        Name: "Cash Ratios",
        ParentName: "liquidity analysis",
      },
      {
        ID: "operating cashflow ratio",
        Head_ID: 0,
        Name: "Cash Flow fm Operations Ratio",
        ParentName: "liquidity analysis",
      },
      {
        ID: "net working capital",
        Head_ID: 0,
        Name: "Net Working Capital",
        ParentName: "liquidity analysis",
      },
      {
        ID: "asset turnover",
        Head_ID: 0,
        Name: "Asset Turn Over",
        ParentName: "liquidity analysis",
      },
      {
        ID: "stock turnover",
        Head_ID: 0,
        Name: "Stock Turn Over",
        ParentName: "liquidity analysis",
      },
      {
        ID: "working capital to asset ratio",
        Head_ID: 0,
        Name: "Working Capital to Asset Ratio",
        ParentName: "liquidity analysis",
      },
    ],
  },
  {
    ModelGroup: "Profitability Analysis",
    Models: [
      {
        ID: "gross margin",
        Head_ID: 0,
        Name: "Gross Margin",
        ParentName: "profitability analysis",
      },
      {
        ID: "operating margin",
        Head_ID: 0,
        Name: "Operating Margin",
        ParentName: "profitability analysis",
      },
      {
        ID: "pretax margin",
        Head_ID: 0,
        Name: "Pretax Margin",
        ParentName: "profitability analysis",
      },
      {
        ID: "after tax return",
        Head_ID: 0,
        Name: "After tax Return",
        ParentName: "profitability analysis",
      },
      {
        ID: "net return on assets",
        Head_ID: 0,
        Name: "Net Return on Assets",
        ParentName: "profitability analysis",
      },
      {
        ID: "gross return on assets",
        Head_ID: 0,
        Name: "Gross Return on Assets",
        ParentName: "profitability analysis",
      },
      {
        ID: "net return on equity",
        Head_ID: 0,
        Name: "Net Return on Equity",
        ParentName: "profitability analysis",
      },
      {
        ID: "gross return on equity",
        Head_ID: 0,
        Name: "Gross return on equity",
        ParentName: "profitability analysis",
      },
      {
        ID: "ebitd ratio",
        Head_ID: 0,
        Name: "EBIT Ratio",
        ParentName: "profitability analysis",
      },
      {
        ID: "ebitd margin",
        Head_ID: 0,
        Name: "EBIT margin",
        ParentName: "profitability analysis",
      },
      {
        ID: "return on capital employed",
        Head_ID: 0,
        Name: "Return on Capital Employed",
        ParentName: "profitability analysis",
      },
    ],
  },
  {
    ModelGroup: "Turnover Growth Analysis",
    Models: [
      {
        ID: "turnover growth",
        Head_ID: 0,
        Name: "Turnover Growth",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "quick ratio",
        Head_ID: 0,
        Name: "Quick Ratio(ACID Test)",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "profit after tax",
        Head_ID: 0,
        Name: "Profit after Tax",
        ParentName: "profit after tax",
      },
      {
        ID: "inventory turnover",
        Head_ID: 0,
        Name: "Inventory Turnover",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "ebit to operations profit growth",
        Head_ID: 0,
        Name: "EBIT to Operations Profit Growth",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "net profit margin",
        Head_ID: 0,
        Name: "Net Profit Margin",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "fixed assets turnover ratio",
        Head_ID: 0,
        Name: "Fixed Assets Turnover Ratio",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "total assets turnover ratio",
        Head_ID: 0,
        Name: "Total Assets Turnover Ratio",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "receivables turnover ratio",
        Head_ID: 0,
        Name: "Receivables Turnover Ratio",
        ParentName: "turnover growth analysis",
      },
      {
        ID: "days sales in inventory ratio",
        Head_ID: 0,
        Name: "Days sales in inventory ratio",
        ParentName: "turnover growth analysis",
      },
    ],
  },

  {
    ModelGroup: "Solvency Analysis",
    Models: [
      {
        ID: "debt to capital employed",
        Head_ID: 0,
        Name: "Debt to Capital Employed",
        ParentName: "solvency analysis",
      },
      {
        ID: "debt to equity ratio",
        Head_ID: 0,
        Name: "Debt to Equity Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "debt to asset ratio",
        Head_ID: 0,
        Name: "Debt to Asset Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "debt to ebit ratio",
        Head_ID: 0,
        Name: "Debt to EBIT Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "debt to ebitda ratio",
        Head_ID: 0,
        Name: "Debt to EBITDA Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "operating cash flow to debt",
        Head_ID: 0,
        Name: "Operating Cash Flow to Debt",
        ParentName: "solvency analysis",
      },
      {
        ID: "lt debt to equity ratio",
        Head_ID: 0,
        Name: "lt Debt to Equity Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "equity to assets ratio",
        Head_ID: 0,
        Name: "Equity to Assets Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "interest coverage ratio",
        Head_ID: 0,
        Name: "Interest Coverage Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "ebit to interest coverage ratio",
        Head_ID: 0,
        Name: "EBIT to Interest Coverage Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "ebitda to interest coverage ratio",
        Head_ID: 0,
        Name: "EBITDA to Interest Coverage Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "fixed charge cover",
        Head_ID: 0,
        Name: "Fixed Charge Cover",
        ParentName: "solvency analysis",
      },
      {
        ID: "equity to liabilities ratio",
        Head_ID: 0,
        Name: "Equity to Liabilities Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "operating leverage ratio",
        Head_ID: 0,
        Name: "Operating Leverage Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "operating cashflow to interest coverage ratio",
        Head_ID: 0,
        Name: "Operating Cashflow to Interest Coverage Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "operating cashflow to short-term debt ratio",
        Head_ID: 0,
        Name: "Operating Cashflow to Short-term Debt Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "operating cashflow to long-term debt ratio",
        Head_ID: 0,
        Name: "Operating Cashflow to long-term Debt Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "time interest earned",
        Head_ID: 0,
        Name: "Time Interest Earned",
        ParentName: "solvency analysis",
      },
      {
        ID: "capital expenditure ratio",
        Head_ID: 0,
        Name: "Capital Expenditure Ratio",
        ParentName: "solvency analysis",
      },
      {
        ID: "cash from operations to debt",
        Head_ID: 0,
        Name: "Cash from Operations to Debt",
        ParentName: "solvency analysis",
      },
      {
        ID: "financial leverage",
        Head_ID: 0,
        Name: "Financial Leverage",
        ParentName: "solvency analysis",
      },
    ],
  },
  {
    ModelGroup: "Earnings Ratio",
    Models: [
      {
        ID: "number of shares issued",
        Head_ID: 0,
        Name: "Number of Shares Issued",
        ParentName: "earnings ratio",
      },
      {
        ID: "earnings per share",
        Head_ID: 0,
        Name: "Earnings per Share",
        ParentName: "earnings ratio",
      },
      {
        ID: "earnings growth",
        Head_ID: 0,
        Name: "Earnings Growth",
        ParentName: "earnings ratio",
      },
      {
        ID: "earnings yield",
        Head_ID: 0,
        Name: "Earnings Yield",
        ParentName: "earnings ratio",
      },
      {
        ID: "dividend per share",
        Head_ID: 0,
        Name: "Dividend per Share",
        ParentName: "earnings ratio",
      },
      {
        ID: "net asset value per share",
        Head_ID: 0,
        Name: "Net Asset Value per Share",
        ParentName: "earnings ratio",
      },
      {
        ID: "price to earnings ratio",
        Head_ID: 0,
        Name: "Price to Earnings Ratio",
        ParentName: "earnings ratio",
      },
      {
        ID: "dividend yield",
        Head_ID: 0,
        Name: "Dividend Yield",
        ParentName: "earnings ratio",
      },
      {
        ID: "dividend growth rate",
        Head_ID: 0,
        Name: "Dividend Growth Rate",
        ParentName: "earnings ratio",
      },
      {
        ID: "dividend cover",
        Head_ID: 0,
        Name: "Dividend Cover",
        ParentName: "earnings ratio",
      },
      {
        ID: "price to book",
        Head_ID: 0,
        Name: "Price to Book",
        ParentName: "earnings ratio",
      },
      {
        ID: "cashflow per share",
        Head_ID: 0,
        Name: "Cashflow per Share",
        ParentName: "earnings ratio",
      },
      {
        ID: "dividend payout ratio",
        Head_ID: 0,
        Name: "Dividend Payout Ratio",
        ParentName: "earnings ratio",
      },
      {
        ID: "sustainable growth rate",
        Head_ID: 0,
        Name: "Sustainable Growth Rate",
        ParentName: "earnings ratio",
      },
      {
        ID: "market book ratio",
        Head_ID: 0,
        Name: "Market Book Ratio",
        ParentName: "earnings ratio",
      },
      {
        ID: "price to sales",
        Head_ID: 0,
        Name: "Price to Sales",
        ParentName: "earnings ratio",
      },
      {
        ID: "price to cashflow",
        Head_ID: 0,
        Name: "Price to Cashflow",
        ParentName: "earnings ratio",
      },
      {
        ID: "price to free cashflow",
        Head_ID: 0,
        Name: "Price to Free Cashflow",
        ParentName: "earnings ratio",
      },
      {
        ID: "book value per share ratio",
        Head_ID: 0,
        Name: "Book Value per Share Ratio",
        ParentName: "earnings ratio",
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class FundamentalAnalysisService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  getAllModels(): FundamentalModels[] {
    return fundamentalAnalysisModels;
  }

  getAllListedSecurities(): Observable<any> {
    return this.http.get(`${this.url}/accounts/globallistedcompanies`);
  }

  getAccountData(requestPayload: any): Observable<any> {
    return this.apiService.Post("Accounts/FundamentalAnalysis", requestPayload);
  }
}

export interface FundamentalModels {
  ModelGroup: string;
  Models: ModelData[];
}

export interface ModelData {
  ID: string;
  Name: string;
  Head_ID: number;
  ParentName: string;
}
