import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/app-services/api/api.service';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsModelClassCombination } from 'src/app/app-components/performance-attribution/selected-category';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit, AfterViewInit {
  @ViewChild(DxSelectBoxComponent, { static: false }) AssetLevelLookupInstance: DxSelectBoxComponent;
  @Input() assetClassModelObservable: BehaviorSubject<AnalyticsModelClassCombination>;
  @Input() startDateObservable: BehaviorSubject<Date>;
  @Input() endDateObservable: BehaviorSubject<Date>;
  @Input() investorIdObservable: BehaviorSubject<string>;
  assetLevel: any;

  constructor(private apiService: ApiService) {
    this.assetLevel = {
      AssetCategory: 2,
      Asset: 3,
      TotalFund: 4,
      properties: [
        { id: 1, description: 'AssetClass' },
        { id: 2, description: 'AssetCategory' },
        { id: 3, description: 'Asset' },
        { id: 4, description: 'TotalFund' }
      ]
    };
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.AssetLevelLookupInstance.valueExpr = 'id';
    this.AssetLevelLookupInstance.displayExpr = 'description'
    this.AssetLevelLookupInstance.dataSource = this.assetLevel.properties;

    this.AssetLevelLookupInstance.onValueChanged.subscribe(result => {

      console.log(this.investorIdObservable.value)

      const newValue = result.value;
      switch (newValue) {
        case newValue === 1: { // asset class
          // const assetClassesMix = this.apiService.Get('AnalyticsSetup/FindInstrumentClasses?investorId=' + this.investorIdObservable.value).subscribe(assetClasses => {
          //   console.log(assetClasses)
          // })
          console.log(this.investorIdObservable.value)
          console.log('asset class');
          break;
        }
        case newValue === 2: { //asset category
          console.log('asset category');
          break;
        }
        case newValue === 3: { //asset level
          console.log('asset level');
          break;
        }
        case newValue === 4: { //total fund
          console.log('total fund');
          break;
        }
        default: {
          break;
        }
      }

    });
  }

}
