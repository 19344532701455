import { Injectable } from '@angular/core';


export enum UILanguage {

	english = 'english',
	arabic = 'عربى',
	french = 'Française'
}

export class ApplicationString {
	listedEquities: string;
	returnRiskAnalysis: string;
	fixedIncomeValuation: string;
	predictiveModelling: string;
	performanceAttribution: string;

	/**
	 *
	 */
	constructor(language: UILanguage = UILanguage.english) {
		if (language = UILanguage.english) {
			this.listedEquities = 'Listed Equities';
			this.returnRiskAnalysis = 'Return Risk Analysis';
			this.fixedIncomeValuation = 'Fixed Income Valuation';
			this.performanceAttribution = 'Performance Attribution';
			this.predictiveModelling = 'Predictive Modelling';
		}
		if (language = UILanguage.arabic) {
			this.listedEquities = 'الأسهم المدرجة';
			this.returnRiskAnalysis = 'عودة تحليل المخاطر';
			this.fixedIncomeValuation = 'تقييم الدخل الثابت';
			this.performanceAttribution = 'إسناد الأداء';
			this.predictiveModelling = 'النمذجة التنبؤية';
		}
		if (language = UILanguage.french) {
			this.listedEquities = 'الأسهم المدرجة';
			this.returnRiskAnalysis = 'عودة تحليل المخاطر';
			this.fixedIncomeValuation = 'تقييم الدخل الثابت';
			this.performanceAttribution = 'إسناد الأداء';
			this.predictiveModelling = 'النمذجة التنبؤية';
		}
	}
}

export class ToolBarStringsInterface {
	support: string;
	account: string;
	settings: string;
	selectedLanguage: string;
}
export class ToolbarStrings implements ToolBarStringsInterface {
	support: string;
	account: string;
	settings: string;
	selectedLanguage: string;

	constructor(language: UILanguage = UILanguage.english) {
		if (language === UILanguage.english) {
			this.support = 'Contact support';
			this.account = 'Manage account';
			this.settings = 'settings';

			this.selectedLanguage = 'Eng';
		}

		if (language === UILanguage.arabic) {
			this.settings = 'الإعدادات';
			this.support = 'اتصل بالدعم';
			this.account = 'إدارة الحساب';

			this.selectedLanguage = 'عربي';
		}

		if (language === UILanguage.french) {
			this.settings = 'réglages';
			this.support = 'Contactez le support';
			this.account = 'Gérer son compte';

			this.selectedLanguage = 'française';
		}
	}

}

export class DashBoardStrings {

	dashboard: string;
	fundsClassification: string;

	constructor(language: UILanguage = UILanguage.english) {
		if (language === UILanguage.english) {
			this.dashboard = 'Dashboard';
			this.fundsClassification = 'Funds Classification';
		}
		if (language === UILanguage.french) {
			this.dashboard = 'Tableau de bord';
			this.fundsClassification = 'Classification des fonds';
		}
		if (language === UILanguage.arabic) {
			this.dashboard = 'لوحة القيادة';
			this.fundsClassification = 'تصنيف الأموال';
		}
	}
}

export class SideNavStrings {

	dashboard: string;
	analytics: string;
	settings: string;
	riskReturnAnalysis: string;
	performanceAttribution: string;
	collapseMenu: string;

	constructor(language: UILanguage = UILanguage.english) {
		if (language === UILanguage.english) {
			this.dashboard = 'Dashboard';
			this.analytics = 'Analytics';
			this.settings = 'Settings';
			this.riskReturnAnalysis = 'Risk Return Analysis';
			this.performanceAttribution = 'Performance Attribution';
			this.collapseMenu = 'Collapse';
		}
		if (language === UILanguage.french) {
			this.dashboard = 'Tableau de bord';
			this.analytics = 'Analytique';
			this.settings = 'réglages';
			this.riskReturnAnalysis = 'Analyse de risque';
			this.performanceAttribution = 'Attribution de performance';
			this.collapseMenu = 'Effondrer';
		}
		if (language === UILanguage.arabic) {
			this.dashboard = 'لوحة القيادة';
			this.analytics = 'تحليلات';
			this.settings = 'الإعدادات';
			this.riskReturnAnalysis = 'تحليل المخاطر';
			this.performanceAttribution = 'إسناد الأداء';
			this.collapseMenu = 'انهدام';
		}
	}
}
export class AssetClassesStrings {

	alternative: string;
	composite: string;
	derivatives: string;
	equities: string;
	fixedIncome: string;
	loan: string;
	offshore: string;
	privateEquity: string;
	property: string;

	constructor(language: UILanguage = UILanguage.english) {
		if (language === UILanguage.english) {
			this.alternative = 'Alternative';
			this.composite = 'Composite';
			this.derivatives = 'Derivatives';
			this.equities = 'Equities';
			this.fixedIncome = 'Fixed Income';
			this.loan = 'Loan';
			this.offshore = 'Offshore';
			this.privateEquity = 'Private Equity';
			this.property = 'Property';
		}
		if (language === UILanguage.french) {
			this.alternative = 'Alternative';
			this.composite = 'Composite';
			this.derivatives = 'Dérivés';
			this.equities = 'Équité';
			this.fixedIncome = 'Revenu fixe';
			this.loan = 'Prête';
			this.offshore = 'En mer';
			this.privateEquity = 'Capital-investissement';
			this.property = 'Propriété';
		}
		if (language === UILanguage.arabic) {
			this.alternative = 'لبديل';
			this.composite = 'مركب';
			this.derivatives = 'المشتقات';
			this.equities = 'الأسهم';
			this.fixedIncome = 'دخل ثابت';
			this.loan = 'قرض';
			this.offshore = 'البحرية';
			this.privateEquity = 'حقوق الملكية الخاصة';
			this.property = 'خاصية';
		}
	}
}

/**
 * TODO: Save this settings in browser storage so as to use the same language on subsequent browser visits.
 */
@Injectable()
export class StringsService {
	getToolbarString(language: UILanguage = UILanguage.english) {
		return new ToolbarStrings(language);
	}
	getDashBoardStrings(language: UILanguage = UILanguage.english) {
		return new DashBoardStrings(language);
	}
	getSideNavStrings(language: UILanguage = UILanguage.english) {
		return new SideNavStrings(language);
	}
	getAssetClassesString(language: UILanguage = UILanguage.english) {
		return new AssetClassesStrings(language);
	}
}




