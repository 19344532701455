import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { ReplaySubject, BehaviorSubject, Subscription, forkJoin } from "rxjs";
import {
  DxFormComponent,
  DxLookupComponent,
  DxDateBoxComponent,
  DxTabPanelComponent,
  DxSwitchComponent,
  DxTreeListComponent,
  DxDropDownBoxModule,
} from "devextreme-angular";
import { ApiService } from "../../app-services/api/api.service";

import { MatSnackBar } from "@angular/material/snack-bar";
import {
  SelectedCategory,
  AnalyticsModelClassCombination,
} from "./selected-category";
import { AssetLevel } from "./asset-class-type";
import {
  AnalyticsModel,
  SelectedAnalyticsModelDescription,
} from "./analytics-model";
import { ApplicationSettingsService } from "../../app-services/app-settings/application-settings.service";
import { DocumentationService } from "src/app/app-services/documentation/documentation.service";

import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import {
  AnalyticsRequestObjectService,
  AnalyticsRequestObject,
} from "./analytics-request-object/analytics-request-object.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";

export class List {
  id: number;
  text: string;
}
export class AttributionRequestObject {
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
}

/**
 * Controls all the functionality of the Analytics landing page
 */
@Component({
  selector: "app-performance-attribution",
  templateUrl: "./performance-attribution.component.html",
  styleUrls: ["./performance-attribution.component.scss"],
})
export class PerformanceAttributionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("investorLookupInstance", { static: false })
  investorLookupInstance: DxLookupComponent;
  @ViewChild("assetClassLookupInstance", { static: false })
  assetClassLookupInstance: DxLookupComponent;
  @ViewChild("modelsForm", { static: false })
  modelFormInstance: DxFormComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("modelsTabPanel", { static: false })
  modelsTabPanel: DxTabPanelComponent;
  @ViewChild("summaryPerformanceAttributionSwitch", { static: false })
  summarySwitch: DxSwitchComponent;
  @ViewChild("includeCashSwitch", { static: false })
  includeCashSwitch: DxSwitchComponent;
  @ViewChild("fundManagerTreeList", { static: false })
  fundManagerTreeList: DxTreeListComponent;
  @ViewChild("funddropdownInstance", { static: false })
  funddropdownInstance: DxDropDownBoxModule;
  selectedModel: any;
  models = [
    {
      Name: "Performance Attribution",
      Id: "c42f40af-98f9-47e1-ba56-d68d060c050a",
    },
  ];
  assetClassModelObservable: BehaviorSubject<AnalyticsModelClassCombination> =
    new BehaviorSubject<AnalyticsModelClassCombination>(null);
  endDateObservable: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  startDateObservable: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  investorIdObservable: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  AnalyticsModels: Array<AnalyticsModel> = new Array<AnalyticsModel>();
  SelectedAnalyticsModels: ReplaySubject<
    Array<SelectedAnalyticsModelDescription>
  > = new ReplaySubject<Array<SelectedAnalyticsModelDescription>>(1);
  selectedAssetCategories: Array<string> = new Array<string>();
  fetchedAssetClasses: any;
  summarizedPerformanceDataSource: any = null;
  isTreeBoxOpened = false;
  /**
   * Subscriptions
   */
  private _appSettingsServiceSubscription: Subscription;
  private _startDateBoxInstanceSubscription: Subscription;
  private _endDateBoxInstanceSubscription: Subscription;
  private _investorLookupSubscription: Subscription;
  private _assetClassesMixSubscription: Subscription;
  private _apiServiceSubscription: Subscription;
  private _modelsSubscription: Subscription;
  private _investorsSubscription: Subscription;
  private _summarySwitchSubscription: Subscription;
  private _investorChangeSubscription: Subscription;
  private _startDateChangeSubscription: Subscription;

  /**
   * @param apiService API service used to make requests to the API
   * @param matSnackBar Use the snack bar to show popups for informational purposes
   * @param appSettingsService AppSettings service provides default start and end dates
   * @param documentationService Documentation Service
   */

  showSampleGrid = true;
  FundsFundManagerDataSource: any;
  treeBoxValue: string;
  AttributionRequestObject: AttributionRequestObject =
    new AttributionRequestObject();

  constructor(
    private apiService: ApiService,
    public matSnackBar: MatSnackBar,
    public notificationService: SnackNotificationsService,
    public appSettingsService: ApplicationSettingsService,
    public documentationService: DocumentationService,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService,
    private requestObjService: AnalyticsRequestObjectService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Performance Attribution"
    );
    this.treeBoxValue = "Select a Fund";
  }

  ngOnInit() {
    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
        });

        let investors = [];
        fundManager.MasterFunds.forEach((masterfund) => {
          let funds = masterfund.Investors;
          funds.forEach((investor) => {
            investors.push(investor);
          });
        });

        investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: fundManager.Id,
            TenantId: fundManager.TenantId,
          });
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._startDateBoxInstanceSubscription !== undefined)
      this._startDateBoxInstanceSubscription.unsubscribe();
    if (this._endDateBoxInstanceSubscription !== undefined)
      this._endDateBoxInstanceSubscription.unsubscribe();
    if (this._investorLookupSubscription !== undefined)
      this._investorLookupSubscription.unsubscribe();
    if (this._assetClassesMixSubscription !== undefined)
      this._assetClassesMixSubscription.unsubscribe();
    if (this._apiServiceSubscription !== undefined)
      this._apiServiceSubscription.unsubscribe();
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._summarySwitchSubscription !== undefined)
      this._summarySwitchSubscription.unsubscribe();
    if (this._investorChangeSubscription !== undefined)
      this._investorChangeSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    // this.investorLookupInstance.displayExpr = 'Name';
    // this.investorLookupInstance.valueExpr = 'ID';
    // this.investorLookupInstance.placeholder = 'Select Investor/ Fund';
    // // this.investorLookupInstance.title = 'Show analytics for: ';
    // this.investorLookupInstance.usePopover = true;
    // this.investorLookupInstance.dropDownOptions = {
    //     width: '25%',
    //     title: 'Show analytics for: '
    // }
    // this.investorLookupInstance.closeOnOutsideClick = true

    this.assetClassLookupInstance.displayExpr = "Name";
    this.assetClassLookupInstance.valueExpr = "Id";
    this.assetClassLookupInstance.closeOnOutsideClick = true;

    // 1. Get the dates settings
    this._appSettingsServiceSubscription = this.appSettingsService
      .GetSettings()
      .subscribe((settings) => {
        this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
        this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
    /**
     * Update end date minimum value on start date change
     *
     */
    this._startDateChangeSubscription =
      this.startDateBoxInstance.onValueChanged.subscribe((newDateChange) => {
        this.endDateBoxInstance.min = newDateChange.value;
      });

    // 2. Get all investors
    // this._investorsSubscription = this.apiService.Get('Investors').subscribe(result => {
    //     this.investorLookupInstance.dataSource = result;
    //     this.investorLookupInstance.value = result[0].ID;
    // });

    // 3. The asset classes depend on the selected investor
    // this._investorChangeSubscription = this.investorLookupInstance.onValueChanged.subscribe(investorId => {

    //     let selectedInvestorId = investorId.value

    //     this._assetClassesMixSubscription = this.apiService.Get('AnalyticsSetup/FindInstrumentClasses?investorId=' + selectedInvestorId).subscribe(assetClasses => {
    //         this.assetClassLookupInstance.dataSource = assetClasses;
    //         this.assetClassLookupInstance.value = assetClasses[0].Id;
    //     })
    // })

    this._summarySwitchSubscription =
      this.summarySwitch.onValueChanged.subscribe((event) => {
        const value = event.value;
        if (value === true) {
          this.assetClassLookupInstance.disabled = true;
        } else {
          this.assetClassLookupInstance.disabled = false;
        }
      });
  }

  analyzeAssetClass(event: any) {
    if (this.treeBoxValue == null) {
      this.notificationService.ShowWarningNotification("Please Select a Fund");
      return;
    }

    let modelClassCombination = null;
    const isSummary = this.summarySwitch.value;
    if (isSummary == true) {
      const _performanceSummaryAlias = "summary-performance-attribution";
      let modelToInsert = new SelectedAnalyticsModelDescription(
        _performanceSummaryAlias,
        null
      );
      modelClassCombination = new AnalyticsModelClassCombination(
        new SelectedCategory(
          _performanceSummaryAlias,
          _performanceSummaryAlias,
          AssetLevel.TotalFund
        ),
        modelToInsert
      );
    } else {
      const selectedAssetClass = this.assetClassLookupInstance.selectedItem;
      const performanceAttribution = this.models[0];
      const modelToInsert = new SelectedAnalyticsModelDescription(
        performanceAttribution.Name,
        performanceAttribution.Id
      );
      modelClassCombination = new AnalyticsModelClassCombination(
        new SelectedCategory(
          selectedAssetClass.Name,
          selectedAssetClass.Id,
          AssetLevel.AssetClass
        ),
        modelToInsert
      );
    }

    // const selectedInvestor = this.investorLookupInstance.selectedItem;
    // const selectedInvestorId = selectedInvestor.ID;

    const startDate = <Date>this.startDateBoxInstance.value;

    const endDate = <Date>this.endDateBoxInstance.value;

    const includeCash = this.includeCashSwitch.value;

    let requestObject: AnalyticsRequestObject = {
      startDate: startDate,
      endDate: endDate,
      investorId: this.AttributionRequestObject.Funds[0],
      modelDescption: modelClassCombination,
      includeCash: includeCash,
      TenantId: this.AttributionRequestObject.TenantId,
    };

    this.requestObjService.updateRequestObject(requestObject);

    this.showSampleGrid = false;
  }

  exportDataInExcel() {}

  ModelGroupsItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {
      this.treeBoxValue = event.selectedRowsData[0].Name;

      if (event.selectedRowsData[0].Head_ID !== 0) {
        let selectedInvestorId = event.selectedRowsData[0].ID;
        this._assetClassesMixSubscription = this.apiService
          .Get(
            "AnalyticsSetup/FindInstrumentClasses?investorId=" +
              selectedInvestorId +
              "&tenantId=" +
              event.selectedRowsData[0].TenantId
          )
          .subscribe((assetClasses) => {
            this.assetClassLookupInstance.dataSource = assetClasses;
            this.assetClassLookupInstance.value = assetClasses[0].Id;
          });
      } else {
        this.treeBoxValue = null;
      }

      //when only a fund manager is selected
      if (event.selectedRowsData.length === 1) {
        let selectedRowsData = event.selectedRowsData[0];
        if (selectedRowsData.Head_ID === 0) {
          //then this is a fund manager
          const fundManagerId = selectedRowsData.ID;

          this.AttributionRequestObject.Funds = [];
          this.AttributionRequestObject.FundManagers = [];

          this.AttributionRequestObject.FundManagers.push(fundManagerId);
        } else {
          //this is a single fund belonging to a fund manager
          let selectedRowsData = event.selectedRowsData[0];
          const fundId = selectedRowsData.ID;
          const fundManagerId = selectedRowsData.Head_ID;
          const selectedTenantId = selectedRowsData.TenantId;
          this.AttributionRequestObject.TenantId = selectedTenantId;

          this.AttributionRequestObject.Funds = [];
          this.AttributionRequestObject.FundManagers = [];

          this.AttributionRequestObject.Funds.push(fundId);
          this.AttributionRequestObject.FundManagers.push(fundManagerId);
        }
      } else {
        // multiple selection
        const selectedItems = event.selectedRowsData;
        const fundManagerId = event.selectedRowsData[0].Head_ID;
        const selectedTenantId = event.selectedRowsData[0].TenantId;
        const fundIds = event.selectedRowsData.map((item) => item.ID);
        this.AttributionRequestObject.Funds = [];
        this.AttributionRequestObject.FundManagers = [];
        this.AttributionRequestObject.TenantId = selectedTenantId;

        this.AttributionRequestObject.Funds = fundIds;
        this.AttributionRequestObject.FundManagers.push(fundManagerId);
      }
    }
  }
  treeList_itemSelectionChanged(e) {
    const selectedFund = e.selectedRowsData[0];
    if (selectedFund) {
      this.treeBoxValue = selectedFund.ID;
      this.isTreeBoxOpened = false;
    }
  }
}
