import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class NonInnovaClientsAssetRegistryService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  getInvestorAccountsById(investorId: any): Observable<any> {
    return this.http.get(
      `${this.url}/safekeepingaccounts/findinvestoraccounts?investorid=${investorId}`
    );
  }

  getCashTransactions(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CashTransactions",
      requestPayload
    );
  }

  getStocksHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post("AssetRegistry/StocksHoldings", requestPayload);
  }

  getFixedDepositHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/FixedDepositsHoldings",
      requestPayload
    );
  }

  getCallDepositHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CallDepositsHoldings",
      requestPayload
    );
  }

  getTreasuryBillsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getTreasuryBondsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCorporateBondsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCommercialPaperHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CommercialPapersHoldings",
      requestPayload
    );
  }

  getFloatingCorporateBondsHoldingsData(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCashColumns(): any {
    let cashColumns = [
      {
        caption: "Transaction Date",
        dataField: "TransactionDate",
        cssClass: "grid-focus-column",
        alignment: "left",
        sortOrder: "asc",
        dataType: "date",
      },
      { caption: "Transaction Type", dataField: "TransactionTypes" },
      { caption: "Description", dataField: "Description" },
      { caption: "Debit", dataField: "Debit" },
      { caption: "Credit", dataField: "Credit" },
      { caption: "Balance", dataField: "Balance" },
      { caption: "Approved", dataField: "Approved", dataType: "boolean" },
      { caption: "Active", dataField: "Active", dataType: "boolean" },
      { caption: "Completed", dataField: "Completed", dataType: "boolean" },
      { caption: "Currency", dataField: "Currency" },
    ];
    return cashColumns;
  }

  getEquityHoldingsColumns(): any {
    let stockHoldingColumns = [
      {
        caption: "Stock Name",
        dataField: "Name",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Market Price",
        dataField: "MarketPrice",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Share Volume",
        dataField: "ShareVolume",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
      {
        caption: "Current Value",
        dataField: "CurrentValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return stockHoldingColumns;
  }

  getPurchasesHoldingsColumns(): any {
    let purchasesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      { caption: "Code", dataField: "Code", alignment: "left", width: 120 },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "ShareVoulume" },
      { caption: "Commission", dataField: "Comission" },
      { caption: "SaleValue", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Value", dataField: "Value" },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
      },
      { caption: "Status", dataField: "Status" },
    ];
    return purchasesColumns;
  }

  getSaleHoldingsColumns(): any {
    let salesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      { caption: "Code", dataField: "Code", alignment: "left", width: 120 },
      { caption: "Sale Date", dataField: "SaleDate", dataType: "date" },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "ShareVoulume" },
      { caption: "Commission", dataField: "Comission" },
      { caption: "SaleValue", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Value", dataField: "Value" },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
      },
      { caption: "Status", dataField: "Status" },
    ];

    return salesColumns;
  }

  getFixedDepositHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Deposit Bank",
        dataField: "Bank",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "right",
      },

      {
        caption: "Deposit Date",
        dataField: "DepositDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      { caption: "Currency", dataField: "Currency", alignment: "right" },

      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Deposit",
        dataField: "Deposit",
        dataType: "number",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Interest Rate (%)",
        dataField: "InterestRate",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return fixedDepositColumns;
  }

  getFixedDepositPurchasesHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "right" },
      { caption: "Deposit Date", dataField: "DepositDate", alignment: "right" },
      {
        caption: "Last Redeemed",
        dataField: "LastRedeemed",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        alignment: "right",
      },
      {
        caption: "Deposit Amount",
        dataField: "DepositAmount",
        alignment: "right",
      },
      {
        caption: "Interest Rate",
        dataField: "InterestRate",
        alignment: "right",
      },
      { caption: "Value", dataField: "Value", alignment: "right" },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
    ];
    return fixedDepositColumns;
  }

  getFixedDepositSalesHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      {
        caption: "Withdrawal Date",
        dataField: "WithdrawalDate",
        alignment: "right",
      },
      { caption: "Sales Amount", dataField: "SalesAmount", alignment: "right" },
      { caption: "Penalty", dataField: "Penalty" },
      {
        caption: "Interest Rate",
        dataField: "InterestRate",
        alignment: "right",
      },
      { caption: "Value", dataField: "Value", alignment: "right" },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
      { caption: "Status", dataField: "Status", alignment: "right" },
    ];
    return fixedDepositColumns;
  }

  getCallDepositHoldingColumns(): any {
    let callDepositColumns = [
      {
        caption: "Deposit Bank",
        dataField: "Bank",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "right",
      },

      {
        caption: "Deposit Date",
        dataField: "DepositDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      { caption: "Currency", dataField: "Currency", alignment: "right" },

      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Deposit",
        dataField: "Deposit",
        dataType: "number",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Interest Rate (%)",
        dataField: "InterestRate",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return callDepositColumns;
  }

  getCallDepositPurchasesColumns(): any {
    let callDepositPurchasesColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Deposit Date", dataField: "DepositDate" },
      { caption: "Deposit Amount", dataField: "DepositAmount" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
    ];
    return callDepositPurchasesColumns;
  }

  getCallDepositSalesColumns(): any {
    let callDepositHoldingColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Withdrawal Date", dataField: "WithdrawalDate" },
      { caption: "Sale Amount", dataField: "SaleAmount" },
      { caption: "Penalty", dataField: "Penalty" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
    ];
    return callDepositHoldingColumns;
  }

  //Treasury Bills Columns
  getBillsHoldingsColumns(): any {
    let billsHoldingColumns = [
      {
        caption: "Name",
        dataField: "Bond",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },

      {
        caption: "Yield (%)",
        dataField: "Yield",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return billsHoldingColumns;
  }

  getBillsPurchasesHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  getBillsSaleHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  //Treasury Bonds Columsn
  getTbondsHoldingsColumns(): any {
    let billsHoldingColumns = [
      {
        caption: "Name",
        dataField: "Bond",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
      {
        caption: "Coupon Value (%)",
        dataField: "CouponValue",
        alignment: "right",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return billsHoldingColumns;
  }

  getTBondsPurchasesHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
      },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
      },
    ];
    return billsPurchasesHoldingColumns;
  }

  getTbondsSaleHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  //Corporate Bonds Columsn
  getCorporateBondsHoldingsColumns(): any {
    let corporateBondsHoldingColumns = [
      {
        caption: "Name",
        dataField: "Bond",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
      {
        caption: "Coupon Value (%)",
        dataField: "CouponValue",
        alignment: "right",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return corporateBondsHoldingColumns;
  }

  getCorporateBondsPurchasesColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getcorporateBondsSaleColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  //Corporate Bonds Columsn
  getFloatingCorporateBondsHoldingsColumns(): any {
    let corporateBondsHoldingColumns = [
      {
        caption: "Name",
        dataField: "Bond",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
      {
        caption: "Coupon Value (%)",
        dataField: "CouponValue",
        alignment: "right",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return corporateBondsHoldingColumns;
  }

  getFloatingCorporateBondsPurchasesColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getFloatingCorporateBondsSaleColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getCpHoldingsColumns(): any {
    let cpHoldingColumns = [
      {
        caption: "Name",
        dataField: "Bond",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "ISIN",
        dataField: "ISIN",
        alignment: "right",
      },
      {
        caption: "Face Value",
        dataField: "FaceValue",
        cssClass: "cell-highlighted",
        alignment: "right",
      },
      {
        caption: "Yield (%)",
        dataField: "Yield",
        format: { type: "fixedPoint", precision: 2 },
        alignment: "right",
      },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd-MMM-yyyy",
        alignment: "right",
      },
      {
        caption: "Market Value",
        dataField: "MarketValue",
        format: { type: "fixedPoint", precision: 2 },
        cssClass: "cell-highlighted",
        alignment: "right",
      },
    ];
    return cpHoldingColumns;
  }
  getCPHoldingsPurchasesColumns(): any {
    let cpPurchasesHoldingColumns = [
      {
        caption: "Commercial Paper",
        dataField: "CommercialPaper",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return cpPurchasesHoldingColumns;
  }
  getCPHoldingSalesColumns(): any {
    let CPHoldingSalesColumns = [
      {
        caption: "Commercial Paper",
        dataField: "CommercialPaper",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return CPHoldingSalesColumns;
  }
}
