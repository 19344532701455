<header
  class="flux-header clearfix"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
  matRipple
  matRippleColor="accent"
>
  <div class="flux-header-wrapper clearfix">
    <div class="flux-brand" [attr.left-header-theme]="leftHeaderColorTheme">
      <mat-icon class="flux-brand-logo"> timeline </mat-icon>
      <span class="flux-brand-text">Innova Analytics</span>
    </div>
    <div class="flux-header-navbar nav-header-extra-analytics">
      <div class="flux-left-header clearfix">
        <div class="sidebarToggle" (click)="changeTheToggleStatus()">
          <i class="fas fa-bars"></i>
        </div>

        <div class="left-header-nav">
          <ul>
            <li
              *ngIf="navBarSettingsService.ActiveComponentTitleSubject$ | async"
            >
              <span
                matTooltip="Showing the name of the active component"
                matTooltipPosition="above"
                style="padding-left: 10px; font-weight: 400; font-size: medium"
                >{{
                  navBarSettingsService.ActiveComponentTitleSubject$ | async
                }}</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="flux-right-header clearfix">
        <ul>
          <li
            *ngIf="
              isRegulator == true && tenantName == 'Capital Markets Authority'
            "
          >
            <img
              src="../../../../assets/svg/cmalogo.svg"
              alt="logo"
              style="
                max-width: 100%;
                height: 28px;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
          </li>
          <li *ngIf="isRegulator == true && tenantName == 'SEC Zambia'">
            <img
              src="../../../../assets/icons/ZambiaSEC.jpg"
              alt="logo"
              style="
                max-width: 100%;
                height: 28px;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
          </li>
          <li *ngIf="isDemoMode">
            <div class="demo-mode-flag">DEMO VERSION</div>
          </li>
          <li *ngIf="isStagingMode">
            <dx-button
              (onClick)="showAboutInformation($event)"
              matTooltip="This is the Innova Analytics staging version"
              icon="fas fa-info-circle"
              type="default"
              text="STAGING VERSION"
            >
            </dx-button>
          </li>
          <li>
            <dx-button
              id="about-button"
              (onClick)="showReleaseInfo()"
              matTooltip="What's new in this version?"
              type="default"
              icon="fa fa-envelope"
              stylingMode="outlined"
            >
            </dx-button>
          </li>

          <!-- <li>
            <mat-divider [vertical]="true"></mat-divider>
          </li> -->

          <li>
            <dx-button
              matTooltip="Settings menu"
              icon="fas fa-cogs"
              type="default"
              (onClick)="updateSettings($event)"
              stylingMode="outlined"
            >
            </dx-button>
          </li>
          <li>
            <dx-button
              (onClick)="showAboutInformation($event)"
              matTooltip="Find out more about this software"
              icon="fas fa-info-circle"
              type="default"
              text="about"
              stylingMode="outlined"
            >
            </dx-button>
          </li>
          <li *ngIf="!SkipAuthentication">
            <dx-button
              id="loggedInAsButton"
              text=" {{ tenantName }}"
              type="default"
              icon="fa fa-user"
              stylingMode="outlined"
            >
            </dx-button>
          </li>

          <li *ngIf="!SkipAuthentication">
            <dx-button
              id="logout-button"
              (onClick)="logout()"
              matTooltip="logout"
              type="danger"
              icon="fas fa-power-off"
              stylingMode="outlined"
            >
            </dx-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
