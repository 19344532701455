export class AnalyticsModel {

	constructor(name: string, models: Array<AnalyticsModelDescription>) {
		this.Title = name;
		this.Models = models;
	}
	Title: string;
	Models: Array<AnalyticsModelDescription>;

}

export class AnalyticsModelDescription {
	Name: string;
	Id: string;
	ParentId: string;
}

export class SelectedAnalyticsModelDescription {
	Name: string;
	Id: string;

	constructor(name: string, id: string) {
		this.Name = name;
		this.Id = id;
	}
}



export class GlobalModel {
	Name: string;
	Id: string;
}