import { Component, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { DashboardControl, ResourceManager } from 'devexpress-dashboard';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements AfterViewInit, OnDestroy {
    private dashboardControl!: DashboardControl;
    constructor(private element: ElementRef) {
        ResourceManager.embedBundledResources();
    }
    ngAfterViewInit(): void {
        this.dashboardControl = new DashboardControl(this.element.nativeElement.querySelector('.dashboard-container'), {
            // Specifies a URL of the Web Dashboard's server.
            endpoint: 'https://demos.devexpress.com/services/dashboard/api',
            workingMode: 'Viewer',
            initialDashboardId:'Financial'
        });

        this.dashboardControl.render();
    }
    ngOnDestroy(): void {
        this.dashboardControl && this.dashboardControl.dispose();
    }
}
