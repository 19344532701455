import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/app-services/api/api.service';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  apiVersion: string = null;
  uiVersion: string = null;

  constructor(public apiService: ApiService,@Inject(APP_CONFIG) private config: AppConfig) { }

  ngOnInit() {
    this.uiVersion = this.config.AppVersion
    this.apiService.Get('Application/GetVersion').subscribe(result => {
      this.apiVersion = result.Version;
    })
  }

}
