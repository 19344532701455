<div class="nav-bar-settings-container">
	<div class="title-area">
		<h4>Navigation Bar Settings</h4>
	</div>
	<mat-divider></mat-divider>
	<div class="settings-section">
		<div class="focused-menu-item-section">
			<div class="menu-tems-description">
				Menu Item to focus on initial loading / reloading
			</div>
			<mat-form-field appearance="outline" class="menu-items-options">
				<mat-label>Menu Items</mat-label>
				<mat-select disableRipple [formControl]="defaultSelectedMenuItem">
					<mat-option value="1">Risk Return Analysis</mat-option>
					<mat-option value="2">Fixed Income valuation</mat-option>
					<mat-option value="3">Predictive Modelling</mat-option>
					<mat-option value="4">Setup</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="save-button" (click)="dismissBottomNavBarSettings($event)">
			<button mat-raised-button color="accent">Save</button>
		</div>
	</div>
</div>