import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';


import { Product, Service } from './app.service';
import { ApiService } from 'src/app/app-services/api/api.service';
import { DxListComponent } from 'devextreme-angular';

import { combineLatest, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
  selector: 'app-model-mapping',
  templateUrl: './model-mapping.component.html',
  styleUrls: ['./model-mapping.component.scss']
})
export class ModelMappingComponent implements OnInit, AfterViewInit, OnDestroy {

  EntityName = 'Model Mapping';
  products: Product[];
  currentItem: Product;

  selectedItems: any[] = [];
  allowDeleting = false;
  deleteType = 'toggle';
  tasks: string[];

  public returnsGridColumns: Array<any> = [
    { caption: 'Asset C', dataField: 'Security', dataType: 'string', sortOrder: 'asc' },
    { caption: 'Returns', dataField: 'Value', dataType: 'number' }
  ];

  @ViewChild('list', { static: false }) assetCategoriesList: DxListComponent;
  @ViewChild('modelGroupings', { static: false }) modelGroupingsList: DxListComponent;
  ApiSubscription1: Subscription;
  ApiSubscription2: Subscription;
  ApiSubscription3: Subscription;


  constructor(service: Service, private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName)
    this.products = service.getProducts();
    this.currentItem = this.products[0];
    this.tasks = service.getTasks();

  }
  selectItem(e) {
    this.currentItem = e.itemData;
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe()
    }
    if (this.ApiSubscription2 !== undefined) {
      this.ApiSubscription2.unsubscribe()
    }
    if (this.ApiSubscription3 !== undefined) {
      this.ApiSubscription3.unsubscribe()
    }
  }


  ngAfterViewInit(): void {
    this.ApiSubscription1 = this.apiService.Get('analyticssetup/GetAllInstrumentCategories').subscribe((res: Array<any>) => {
      this.assetCategoriesList.displayExpr = 'Name'
      this.assetCategoriesList.keyExpr = 'Id'
      this.assetCategoriesList.dataSource = res;
    });

    this.ApiSubscription2 = this.apiService.Get('analyticssetup/FindModelcategories').subscribe((res: Array<any>) => {
      this.modelGroupingsList.displayExpr = 'Name'
      this.modelGroupingsList.keyExpr = 'Id'
      this.modelGroupingsList.dataSource = res;
    });

    // this.assetCategoriesList.onItemClick.subscribe(res => {
    //   console.warn(res.itemData);
    // })

    // this.modelGroupingsList.onItemClick.subscribe(res => {
    //   console.warn(res.itemData);
    // })

    combineLatest(this.assetCategoriesList.onItemClick, this.modelGroupingsList.onItemClick).subscribe(([assetCategories, selectedModel]) => {

      // @ts-ignore
      let selectedAssetCategoryId = assetCategories.itemData.Id;
      // @ts-ignore
      let selectedModelGroupId = selectedModel.itemData.Id

      this.ApiSubscription3 = this.apiService.Get(`analyticssetup/findmodels?instrumentCategoryId=${selectedAssetCategoryId}&modelCategoryId=${selectedModelGroupId}`).subscribe(items => {
        console.log(items);
      })
    });
  }

}
