import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import notify from "devextreme/ui/notify";

@Injectable({
  providedIn: "root",
})
export class SnackNotificationsService {
  constructor(public snackbar: MatSnackBar) {}

  public ShowSuccessNotification(message: string) {
    this.snackbar.open(message, "close", {
      panelClass: "snack-notification-success",
      duration: 4000,
      politeness: "polite",
    });
  }

  public ShowErrorNotification(message: string) {
    this.snackbar.open(message, "close", {
      panelClass: "snack-notification-error",
      duration: 4000,
      politeness: "polite",
    });
  }
  public ShowWarningNotification(message: string) {
    this.snackbar.open(message, "close", {
      panelClass: ".snack-notification-warning",
      duration: 4000,
      politeness: "polite",
    });
  }
}
