import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService } from 'src/app/app-services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { HttpParams } from '@angular/common/http';
import { DataFormOptions, DataFormComponent } from 'src/app/app-directives/data-form/data-form.component';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { Subscription } from 'rxjs';

export class LimitTypesBase {
  sampleProperty: string = "information"
  sampleFunction(name: string) {
    return name;
  }
}

@Component({
  selector: 'app-limit-types',
  templateUrl: './limit-types.component.html',
  styleUrls: ['./limit-types.component.scss']
})
export class LimitTypesComponent extends LimitTypesBase implements OnInit, OnDestroy {
  gridDataSource: any;
  EntityName = 'Limit Types';
  GridKeyExpression = 'Name';

  public Columns: any = [
    { caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'acs' },
    { caption: 'Country', dataField: 'Country' },
    { caption: 'Regulatory Limit', dataField: 'RegulatoryLimit', dataType: 'boolean' },
    { caption: 'System', dataField: 'System', dataType: 'boolean' },
    { caption: 'Active', dataField: 'Active', dataType: 'boolean' },
    { caption: 'Approved', dataField: 'Approved', dataType: 'boolean' },
  ];

  CyclesEnum: any;
  formItems: Array<any>;
  ApiSubscription1: Subscription = undefined;

  constructor(private apiService: ApiService, public matDialog: MatDialog, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
    super();
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName)

    this.formItems = [
      {
        label: { text: 'Name' },
        dataField: 'Name',
        validationRules: [{
          type: 'required',
          message: 'Name is required'
        }],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },
      },
      {
        dataField: 'CountryId',
        cssClass: 'required-form-item',
        validationRules: [{
          type: 'required',
          message: 'Country is required'
        }],
        editorType: 'dxLookup',
        editorOptions: {
          dataSource: new CustomStore({
            key: 'ID',
            load: (loadOption) => {
              if (loadOption.searchExpr === 'Name') {
                if (loadOption.searchValue !== null) {
                  return this.apiService.Get('Countries/FindByName?name=' + loadOption.searchValue).toPromise();
                }
              }
              return this.apiService.Get('Countries/Get').toPromise();
            },
            byKey: (key) => {
              return this.apiService.Get('Countries/GetById?id=' + key).toPromise();
            }
          }),
          valueExpr: 'ID',
          displayExpr: 'Name'
        },
        label: {
          text: 'Country'
        }
      },
      {
        dataField: 'RegulatoryLimit',
        editorType: 'dxCheckBox',
      },
      {
        dataField: 'System',
        editorType: 'dxCheckBox',
      },
      {
        dataField: 'Active',
        editorType: 'dxCheckBox',
      },
      {
        dataField: 'Approved',
        editorType: 'dxCheckBox',
      }];
  }

  ngOnInit() {
    this.ApiSubscription1 = this.apiService.Get('LimitTypes').subscribe(result => this.gridDataSource = result);
    this.sampleProperty;
  }

  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe()
    }
  }

  rowDoubleClicked(rowData: any) {

      let dataFormOptions = new DataFormOptions();
      dataFormOptions.formData = rowData;
      dataFormOptions.formID = rowData.ID;
      dataFormOptions.formItems = this.formItems;
      dataFormOptions.formTitle = `Edit Limit Type - ${rowData.Name}`;
      dataFormOptions.postEndPoint = 'LimitTypes';
      let dialogRef = this.matDialog.open(DataFormComponent,
        { width: '800px', data: dataFormOptions });
      /** A cheap but expensive way to refresh */
      dialogRef.afterClosed().subscribe(res => {
        this.ngOnInit();
      })
  }
  
  AddNew(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `New Limit Type`
    dataFormOptions.postEndPoint = 'LimitTypes';
    let dialogRef = this.matDialog.open(DataFormComponent,
      { width: '800px', data: dataFormOptions });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
}
