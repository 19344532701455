<div class="regulator-compliance-main-container">
  <div class="regulator-compliance-main-area">
    <!-- main config section -->
    <div class="portfolio-analytics-config-section">
      <div>
        <span id="analytics-fund-caption" class="component-inputs-label"
          >Fund</span
        >
        <dx-drop-down-box
          #funddropdownInstance
          valueExpr="ID"
          displayExpr="name"
          placeholder="Select a Fund..."
          [dataSource]="FundsFundManagerDataSource"
          width="300"
        >
          <dx-tree-list
            height="800"
            width="500"
            #fundManagerTreeList
            [dataSource]="FundsFundManagerDataSource"
            keyExpr="ID"
            parentIdExpr="Head_ID"
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [showBorders]="true"
            (onSelectionChanged)="ModelGroupsItemsSelected($event)"
            noDataText="no data to display..."
            class="tree-list"
            [highlightChanges]="true"
            [repaintChangesOnly]="true"
            [focusedRowEnabled]="false"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [focusStateEnabled]="true"
            (onItemSelectionChanged)="treeList_itemSelectionChanged($event)"
            [autoExpandAll]="true"
          >
            <!-- <dxo-selection mode="multiple" [recursive]="true"></dxo-selection> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"> </dxo-sorting>
            <dxi-column
              dataField="Name"
              caption="Select Funds under a Fund Manager"
              sortOrder="asc"
              style="font-size: 200%; font-weight: 700"
            >
            </dxi-column>
            <dxo-selection mode="single"> </dxo-selection>
          </dx-tree-list>
        </dx-drop-down-box>
      </div>

      <div>
        <span
          id="analytics-start-date-caption"
          class="analytics-section-selection-title component-inputs-label"
          >Start Date</span
        >
        <dx-date-box
          id="analytics-start-date-input"
          displayFormat="dd MMM yyyy"
          #startDateBox
          matTooltip="Period start date"
        >
        </dx-date-box>
      </div>
      <div>
        <span
          id="analytics-end-date-caption"
          class="analytics-section-selection-title component-inputs-label"
          >End Date</span
        >
        <dx-date-box
          id="analytics-end-date-input"
          displayFormat="dd MMM yyyy"
          #endDateBox
          matTooltip="Period end date"
        >
        </dx-date-box>
      </div>
    </div>
    <div>
      <div
        *ngIf="_reportSubscription === undefined; else fetchingInProgressTemp"
      >
        <button
          #refreshButton
          mat-flat-button
          color="primary"
          (click)="fetchReport()"
        >
          <mat-icon> update </mat-icon>
          Refresh
        </button>
      </div>

      <ng-template #fetchingInProgressTemp>
        <button
          mat-raised-button
          color="warn"
          (click)="closeRequest()"
          matTooltip="Cancel this request"
        >
          <mat-icon>cancel_presentation</mat-icon>
          Cancel
        </button>
      </ng-template>
    </div>
  </div>

  <div style="margin-top: 1%"></div>

  <div class="regulator-compliance-grid-main-container">
    <dx-data-grid
      #complianceGridInstance
      columnResizingMode="widget"
      [allowColumnResizing]="true"
      [showBorders]="true"
      [showRowLines]="true"
      [groupPanel]="{ visible: true }"
      [grouping]="{ autoExpandAll: true }"
      [searchPanel]="{
        visible: true,
        placeholder: 'search for anything',
        width: 500
      }"
      [allowColumnReordering]="false"
      [rowAlternationEnabled]="false"
      [export]="{
        enabled: true,
        fileName: 'Non-Portfolio-Analytics',
        allowExportSelectedData: true
      }"
      [selection]="{
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      }"
      [columnChooser]="{ enabled: true, mode: 'select' }"
      [wordWrapEnabled]="false"
      [columnFixing]="true"
      [twoWayBindingEnabled]="true"
      [repaintChangesOnly]="false"
      [activeStateEnabled]="true"
      (onCellPrepared)="gridInstanceCellPrepared($event)"
      [keyExpr]="gridKeyExpression"
      [focusStateEnabled]="true"
      [focusedRowEnabled]="false"
      [scrolling]="{ mode: 'standard', preloadEnabled: true }"
      height="{{ calculateGridHeight() }}"
      [pager]="{
        allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
        showPageSizeSelector: true,
        showInfo: true,
        visible: true
      }"
      [paging]="{
        pageSize: 20,
        enabled: true
      }"
    >
      <dxo-paging [enabled]="true"></dxo-paging>
      <dxo-sorting mode="single"> </dxo-sorting>
    </dx-data-grid>
  </div>
</div>
