export class TestInstrumentCategory {
  id: string;
  text: string;
  icon: string;
  code?: string;
  content: string;
  disabled?: boolean;
  dataSource: Array<any> = new Array<any>();
  columns: any;
  modelVariables: Array<ModelVariable>;
  benchmarks?: any;
  iconStyle?: any;
  analysisLevel?: string;
  defaultWeighting?: string = "True";
}

export interface AssetCategorySecurities {
  Id: string;
  SecurityName: string;
}

export class ModelGroupItem {
  ModelGroupName: string;
  ModelItems: Array<ModelItem>;
  SelectedModelKeys: Array<any>; //possibly strings eventually
}
export interface SummarItemInfo {
  value: any;
  valueText: any;
}

export enum ModelClassification {
  ParentModel,
  Parameter,
}

export interface ModelItem {
  ID: string;
  Head_ID: any;
  ModelName: string;
  /**
   * Used to differentiate the models, It is unique for each parameter in a model
   */
  ModelValue: string;
  ModelGroupName: string;
  ModelClassification: ModelClassification;
  ParentModelName: string;
  /**
   * @property UniqueModelId
   * @description Represents the Id of the model, It is a guid
   */
  UniqueModelId: string;
  /**
   * Say someone selects Calmar ratio model,
   * Since the solution is parameter driven(Vincent), then read the parameters below it and use that
   * to show the result
   */
  ModelParameters: Array<string>;
}

export interface ModelResponseObject {
  ResponseObject: Array<any>;
  SelectedIntrumentCat: string;
  ModelGroupName: string;
  Model: string;
  ModelLabel: string;
}

export interface ModelVariable {
  Id: string;
  Name: string;
  Code: string;
}

export interface Model {
  Code: string;
  Description: string;
  InstrumentCategory: string;
  InstrumentCategoryId: string;
  IsGlobalModel: boolean;
  MaxInputs: number;
  MinInputs: number;
  ModelCategory: string;
  ModelId: string;
  Name: string;
  Variables: Array<ModelVariable>;
}

export interface ModelGroup {
  Name: string;
  Models: Array<Model>;
}

export interface Parameter {
  Name: string;
  Models: Array<string>;
}

export interface NonPortfolioAnalysisInputModel {
  investorId: string;
  InstrumentCategory: string;
  StartDate: any;
  EndingDate: any;
  ModelParameters: Array<Parameter>;
  Securities: Array<string>;
  ModelDependency: string;
  RiskFreeRateReturn: number;
  Weighted: string;
  RiskFreeRateIsAnnualized: number;
  DaysInYear: number;
}

export interface RenderButtonConfig {
  IsClicked: boolean;
  SelectedAssetCategoryId: string;
  cancelRequest: boolean;
  weightOption: string;
}
