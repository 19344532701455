import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portfolio-regression-analysis',
  templateUrl: './portfolio-regression-analysis.component.html',
  styleUrls: ['./portfolio-regression-analysis.component.scss']
})
export class PortfolioRegressionAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
