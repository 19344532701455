<div>
  <div>
    <app-fund-config-form
      #DatesConfig
      (RefreshReport)="RefreshReport($event)"
    ></app-fund-config-form>
  </div>
  <div>
    <mat-accordion multi>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Select Report </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="funds-asset-cats-container">
          <div class="funds-section">
            <div>
              <div
                style="
                  font-weight: 700;
                  margin-left: 5%;
                  font-stretch: extra-expanded;
                  font-size: 110%;
                "
              >
                <div *ngIf="isCurrentUserRegulator == true">
                  <ng-container
                    *ngTemplateOutlet="regulatorFundManagerCustodianList"
                  ></ng-container>
                </div>
                <div *ngIf="isCurrentUserRegulator == false">
                  <ng-container
                    *ngTemplateOutlet="fundManagerList"
                  ></ng-container>
                </div>
                <!-- to update once tenant type claims are added -->
                <!-- <div *ngIf="activeTenantType == 'Custodian'">
                  <ng-container
                    *ngTemplateOutlet="custodianList"
                  ></ng-container>
                </div> -->
              </div>
            </div>
          </div>
          <div class="asset-cats-section">
            <!-- SEC Zambia Reports when FundManager Tab is selected  -->
            <div
              *ngIf="
                activeTenantName == 'SEC Zambia' ||
                activeTenantRegulator == 'zambia-sec'
              "
            >
              <div>
                <app-zambia-sec-report
                  (ReportItemSelected)="ReportItemSelected($event)"
                  (ReportCategorySelected)="updateFundManagerTreeList($event)"
                  (AssetClassSelected)="UpdateAssetClassSelected($event)"
                  (SummarySwitchValue)="PerfomanceAttrSummaryValue($event)"
                  (IncludeCashPortfolioValutionValue)="
                    PerfomanceAttrCashValue($event)
                  "
                  [SelectedFundId]="selectedFund"
                  [SelectedTenantId]="selectedTenant"
                  [CustodianTabSelected]="custodianTabSelected"
                ></app-zambia-sec-report>
              </div>
              <!-- <div *ngIf="custodianTabSelected == true">
                <zambia-sec-custodian-reports>
                  (ReportItemSelected)="CustodianReportItemSelected($event)"
                </zambia-sec-custodian-reports>
              </div> -->
            </div>

            <!-- CMA and Other Stakeholders Reports  -->
            <!-- to update once activeTenantRegulator and activeTenantName claims are added to cma users -->
            <div
              *ngIf="
                !(
                  activeTenantName === 'SEC Zambia' ||
                  activeTenantRegulator === 'zambia-sec'
                )
              "
            >
              <app-report-groups-view
                (ReportItemSelected)="ReportItemSelected($event)"
                (ReportCategorySelected)="updateFundManagerTreeList($event)"
                (AssetClassSelected)="UpdateAssetClassSelected($event)"
                (SummarySwitchValue)="PerfomanceAttrSummaryValue($event)"
                (IncludeCashPortfolioValutionValue)="
                  PerfomanceAttrCashValue($event)
                "
                [SelectedFundId]="selectedFund"
                [SelectedTenantId]="selectedTenant"
              ></app-report-groups-view>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <div
        *ngIf="
          !(
            activeTenantName === 'SEC Zambia' ||
            activeTenantRegulator === 'zambia-sec'
          )
        "
      >
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> View Generated Reports</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="report-results">
            <!-- to update once activeTenantRegulator and activeTenantName claims are added to cma users -->
            <div>
              <dx-report-viewer #reportViewer>
                <dxrv-request-options
                  invokeAction="DXXRDV"
                  [host]="hostUrl"
                ></dxrv-request-options>
                <dxrv-callbacks
                  (CustomizeExportOptions)="OnCustomizeExportOptions($event)"
                  (OnServerError)="OnServerError($event)"
                  (DocumentReady)="ReportDocumentReady($event)"
                >
                </dxrv-callbacks>
              </dx-report-viewer>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>

  <dx-popup
    #errorNotificationPopup
    [width]="300"
    [height]="250"
    [showTitle]="true"
    title=""
    [elementAttr]="{ class: 'error-popup' }"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
  >
    <div *dxTemplate="let data of 'title'">
      <div
        style="
          color: red;
          text-align: center;
          font-weight: 300;
          font-size: 120%;
        "
      >
        Could not show the report
      </div>
    </div>

    <div *dxTemplate="let data of 'content'">
      <div style="font-size: 200%; text-align: center; color: red">
        Description
      </div>

      <div>
        <dx-text-area
          [(value)]="ReportServerError.errorMessage"
          [height]="150"
          [readOnly]="true"
          [elementAttr]="{ class: 'report-error-text-area' }"
        ></dx-text-area>
      </div>

      <div style="bottom: 2px; position: absolute; right: 2px; color: red">
        Report Server Error
      </div>
    </div>
  </dx-popup>
</div>

<ng-template #regulatorFundManagerCustodianList>
  <dx-tab-panel
    #reportCategoriesPanel
    [showNavButtons]="true"
    style="
      width: 100%;
      height: 460px;
      background-color: red;
      background-color: rgba(255, 255, 255, 0.336);
    "
    [activeStateEnabled]="true"
    (onSelectionChanged)="fundManagerCustodianSelectionChanged($event)"
  >
    <dxi-item
      title="Fund Managers"
      class="report-group-contents"
      icon="aligncenter"
    >
      <div *ngIf="isQuarterlyPerfomanceMeasurementReport == false">
        <dx-tree-list
          height="430"
          #fundManagerTreeList
          [dataSource]="FundsFundManagerDataSource"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          (onSelectionChanged)="ModelGroupsItemsSelected($event)"
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="true"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
          (onCellPrepared)="onCellPrepared($event)"
        >
          <dxo-selection
            mode="{{ fundManagerSelectionMode }}"
            [recursive]="true"
          ></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="Name"
            caption="Select Funds under a Fund Manager"
            sortOrder="asc"
            style="font-size: 200%"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
      <div
        *ngIf="isQuarterlyPerfomanceMeasurementReport == true"
        class="QPerfomanceMeasurement"
      >
        <div class="QPerfomanceMeasurementFundManagers">
          <span class="label">Fund Managers</span>
          <mat-divider style="margin-bottom: 1%"></mat-divider>
          <dx-list
            #fundmanagerList
            [dataSource]="fundmanagerData"
            selectionMode="single"
            selectionEnabled="true"
            showSelectionControls="true"
            valueExpr="Id"
            [width]="200"
            height="50"
            displayExpr="Name"
            sort="Name"
            (onSelectionChanged)="FundManagerSelectionChanged($event)"
            [selectedItemKeys]="selectedFundByDefault"
          >
          </dx-list>
        </div>
        <div class="QPerfomanceMeasurementFunds">
          <span class="label" style="margin-top: 1%">Funds</span>
          <mat-divider></mat-divider>
          <dx-tree-list
            height="300"
            #PerfomanceMeasurementFundTreeList
            [dataSource]="fundsData"
            keyExpr="ID"
            parentIdExpr="Head_ID"
            displayExpr="Name"
            (onSelectionChanged)="FundsSelectionChanged($event)"
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            (onSelectionChanged)="FundsSelectionChanged($event)"
            noDataText="no data to display..."
            class="tree-list"
            [highlightChanges]="true"
            [repaintChangesOnly]="true"
            [autoExpandAll]="true"
            [hoverStateEnabled]="true"
            [focusStateEnabled]="true"
          >
            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxi-column dataField="Name" caption="Select Funds"> </dxi-column>
          </dx-tree-list>
        </div>
      </div>
    </dxi-item>
    <dxi-item
      title="Custodians"
      class="report-group-contents"
      icon="aligncenter"
    >
      <div *ngIf="isQuarterlyPerfomanceMeasurementReport == false">
        <dx-tree-list
          height="430"
          #regulatorcustodianTreeList
          [dataSource]="FundsCustodianDataSource"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          (onSelectionChanged)="CustodianItemsSelected($event)"
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="true"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
          (onCellPrepared)="onCellPrepared($event)"
        >
          <dxo-selection
            mode="{{ fundManagerSelectionMode }}"
            [recursive]="true"
          ></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="Name"
            caption="Select Funds under a Fund Manager"
            sortOrder="asc"
            style="font-size: 200%"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
      <div
        *ngIf="isQuarterlyPerfomanceMeasurementReport == true"
        class="QPerfomanceMeasurement"
      >
        <div class="QPerfomanceMeasurementFundManagers">
          <span class="label">Fund Managers</span>
          <mat-divider style="margin-bottom: 1%"></mat-divider>
          <dx-list
            #fundmanagerList
            [dataSource]="fundmanagerData"
            selectionMode="single"
            selectionEnabled="true"
            showSelectionControls="true"
            valueExpr="Id"
            [width]="200"
            height="50"
            displayExpr="Name"
            sort="Name"
            selecItemsByDefault="true"
            (onSelectionChanged)="FundManagerSelectionChanged($event)"
            [selectedItemKeys]="selectedFundByDefault"
            (onContentReady)="FundManagerListContentReady()"
          >
          </dx-list>
        </div>
        <div class="QPerfomanceMeasurementFunds">
          <span class="label" style="margin-top: 1%">Funds</span>
          <mat-divider></mat-divider>
          <dx-list
            #fundsList
            [dataSource]="fundsData"
            selectionMode="all"
            showSelectionControls="true"
            valueExpr="Id"
            [width]="200"
            height="300"
            displayExpr="Name"
            sort="Name"
            (onSelectionChanged)="FundsSelectionChanged($event)"
          >
          </dx-list>
        </div>
      </div>
    </dxi-item>
  </dx-tab-panel>
</ng-template>
<ng-template #fundManagerList>
  <span class="label">Fund Manager / Funds</span>
  <mat-divider></mat-divider>
  <div *ngIf="isQuarterlyPerfomanceMeasurementReport == false">
    <dx-tree-list
      height="430"
      #fundManagerTreeList
      [dataSource]="FundsFundManagerDataSource"
      keyExpr="ID"
      parentIdExpr="Head_ID"
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [showBorders]="true"
      (onSelectionChanged)="ModelGroupsItemsSelected($event)"
      noDataText="no data to display..."
      class="tree-list"
      [highlightChanges]="true"
      [repaintChangesOnly]="true"
      [focusedRowEnabled]="false"
      [showBorders]="true"
      [autoExpandAll]="true"
      [hoverStateEnabled]="true"
      [focusStateEnabled]="true"
      (onCellPrepared)="onCellPrepared($event)"
    >
      <dxo-selection
        mode="{{ fundManagerSelectionMode }}"
        [recursive]="true"
      ></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"> </dxo-sorting>
      <dxi-column
        dataField="Name"
        caption="Select Funds under a Fund Manager"
        sortOrder="asc"
        style="font-size: 200%"
      >
      </dxi-column>
    </dx-tree-list>
  </div>
  <div
    *ngIf="isQuarterlyPerfomanceMeasurementReport == true"
    class="QPerfomanceMeasurement"
  >
    <div>
      <dx-tree-list
        height="430"
        #fundManagerTreeList
        [dataSource]="FundsFundManagerDataSource"
        keyExpr="ID"
        parentIdExpr="Head_ID"
        [allowColumnResizing]="true"
        [allowColumnReordering]="true"
        [showBorders]="true"
        (onSelectionChanged)="ModelGroupsItemsSelected($event)"
        noDataText="no data to display..."
        class="tree-list"
        [highlightChanges]="true"
        [repaintChangesOnly]="true"
        [focusedRowEnabled]="false"
        [showBorders]="true"
        [autoExpandAll]="true"
        [hoverStateEnabled]="true"
        [focusStateEnabled]="true"
      >
        <dxo-selection
          mode="{{ fundManagerSelectionMode }}"
          [recursive]="true"
        ></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple"> </dxo-sorting>
        <dxi-column
          dataField="Name"
          caption="Select Funds under a Fund Manager"
          sortOrder="asc"
          style="font-size: 200%"
        >
        </dxi-column>
      </dx-tree-list>
    </div>
  </div>
</ng-template>
<ng-template #custodianList>
  <span class="label">Custodians</span>
  <mat-divider></mat-divider>
  <div *ngIf="isQuarterlyPerfomanceMeasurementReport == false">
    <dx-tree-list
      height="430"
      #custodianTreeList
      [dataSource]="FundsCustodianDataSource"
      keyExpr="ID"
      parentIdExpr="Head_ID"
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [showBorders]="true"
      (onSelectionChanged)="ModelGroupsItemsSelected($event)"
      noDataText="no data to display..."
      class="tree-list"
      [highlightChanges]="true"
      [repaintChangesOnly]="true"
      [focusedRowEnabled]="false"
      [showBorders]="true"
      [autoExpandAll]="true"
      [hoverStateEnabled]="true"
      [focusStateEnabled]="true"
      (onCellPrepared)="onCellPrepared($event)"
    >
      <dxo-selection
        mode="{{ fundManagerSelectionMode }}"
        [recursive]="true"
      ></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"> </dxo-sorting>
      <dxi-column
        dataField="Name"
        caption="Select Funds under a Fund Manager"
        sortOrder="asc"
        style="font-size: 200%"
      >
      </dxi-column>
    </dx-tree-list>
  </div>
  <div
    *ngIf="isQuarterlyPerfomanceMeasurementReport == true"
    class="QPerfomanceMeasurement"
  >
    <div class="QPerfomanceMeasurementFundManagers">
      <span class="label">Fund Managers</span>
      <mat-divider style="margin-bottom: 1%"></mat-divider>
      <dx-list
        #fundmanagerList
        [dataSource]="fundmanagerData"
        selectionMode="single"
        selectionEnabled="true"
        showSelectionControls="true"
        valueExpr="Id"
        [width]="200"
        height="50"
        displayExpr="Name"
        sort="Name"
        (onSelectionChanged)="FundManagerSelectionChanged($event)"
        [selectedItemKeys]="selectedFundByDefault"
      >
      </dx-list>
    </div>
    <div class="QPerfomanceMeasurementFunds">
      <span class="label" style="margin-top: 1%">Funds</span>
      <mat-divider></mat-divider>
      <dx-list
        #fundsList
        [dataSource]="fundsData"
        selectionMode="all"
        showSelectionControls="true"
        valueExpr="Id"
        [width]="200"
        height="300"
        displayExpr="Name"
        sort="Name"
        (onSelectionChanged)="FundsSelectionChanged($event)"
      >
      </dx-list>
    </div>
  </div>
</ng-template>
