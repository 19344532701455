import {InjectionToken} from '@angular/core';

/**
 * Application Configuration interface
 */
export interface AppConfig {
    AppVersion: string
    ReleaseNotes: string[]
    ApplicationFeatures: string[]
}

/**
 * The application configuration value object
 */
export const ANALYTICS_CONFIG: AppConfig = {
    AppVersion: '1.0.7.9',
    ReleaseNotes: [
    'Add: Asset Categories panel template on Portfolio Rebalancing page - Not complete',
    'Add: investors list on Rebalancing report - Not complete'
    ],
    ApplicationFeatures: []
};

/**
 * The DI visible application config object
 */
export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');