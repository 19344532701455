import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxListComponent, DxTreeListComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportsSelectedIdsConfig } from '../ReportsSelectedIdsConfig';




@Component({
  selector: 'app-fund-managers-list',
  templateUrl: './fund-managers-list.component.html',
  styleUrls: ['./fund-managers-list.component.scss']
})
export class FundManagersListComponent implements OnInit, AfterViewInit {

  @ViewChild('fundManagerTreeList', { static: false }) fundManagersList: DxTreeListComponent;
  @Input() DataSource: Observable<any>;
  // @Input() DataSource: any;


  ReportsSelectedIdsConfig: BehaviorSubject<ReportsSelectedIdsConfig> = new BehaviorSubject<ReportsSelectedIdsConfig>(null);

  constructor(public http: HttpClient) { }
  ngAfterViewInit(): void {

    // this.fundManagersList.dataSource = this.DataSource;

    /**setup funds tree list options */

    //! reuse this logic
    // this.fundManagersList.selectedItemsChange.subscribe((items: Array<any>) => {

    //   if (items.length !== 0) {
    //     let selectedIds = items.map(item => item.ID);

    //     let IdsSelectionConfig: ReportsSelectedIdsConfig = { parentListName: 'FundManagers', selectedIds: selectedIds }

    //     this.ReportsSelectedIdsConfig.next(IdsSelectionConfig);

    //   }
    // })
  }


  showMoreInfoOnHover(event) {

    //todo find something to do when a model is hovered upon
    // if (event.data !== undefined) {
    //   console.log(event.data);

    // }

  }

  onCellPrepared(e) {

  }


  ModelGroupsItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {

      //when only a fund manager is selected
      if (event.selectedRowsData.length === 1) {
        let selectedRowsData = event.selectedRowsData[0];
        if (selectedRowsData.Head_ID === 0) {//then this is a fund manager
          const fundManagerId = selectedRowsData.ID;
          // const fundsList = this.DataSource.
          this.ReportsSelectedIdsConfig.next({ parentListName: 'FundManager', selectedFundIds: [], selectedFundManagerIds: [fundManagerId] }) //!emit the selectedItems here

        }
        else {
          //this is a single fund belonging to a fund manager
          console.log('hello world');
          let selectedRowsData = event.selectedRowsData[0]
          const fundId = selectedRowsData.ID;
          const fundManagerId = selectedRowsData.Head_ID;
          // this.DataSource.
          // const fundsList = this.DataSource.
          this.ReportsSelectedIdsConfig.next({ parentListName: 'FundManager', selectedFundIds: [fundId], selectedFundManagerIds: [fundManagerId] }) //!emit the selectedItems here

        }
      }
      else {
        // multiple selection
        const selectedItems = event.selectedRowsData;
        const fundManagerId = event.selectedRowsData[0].Head_ID;
        const fundIds = event.selectedRowsData.map(item => item.ID);
        this.ReportsSelectedIdsConfig.next({ parentListName: 'FundManager', selectedFundIds: fundIds, selectedFundManagerIds: [fundManagerId] })
        console.error(selectedItems);
      }

    }

  }

  ngOnInit() {
  }

}

