import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectedModelGroupItems } from './selected-model-group-items';

@Component({
  selector: 'app-models-request-description',
  templateUrl: './models-request-description.component.html',
  styleUrls: ['./models-request-description.component.scss']
})
export class ModelsRequestDescriptionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<SelectedModelGroupItems>) { }

  ngOnInit() {
  }

}
