// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
/**
 * Items Description
 * a. production - This should indicate that the build config used is production mode
 * b. apiUrl - This is the URL of the Analytics API
 * c. uiRoute - This applications, route. This is used to configure this application's identity client
 * d. STSRoute - The route of the authetication client
 * e. ClientId - This application's client id on the authentication server clients configuration.
 * f. DemoMode - Note in demo mode, a flag (DEMO VERSION) is shown on the UI. Also, authetication is disabled by default
 * g. SkipAuthentication - Convinient Flag to disable authentication. For those times that you need to test other parts
 *    of the application without authentication standing in the way.
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
