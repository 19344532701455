import { APP_INITIALIZER, InjectionToken, NgModule } from "@angular/core";

import { SharedModule } from "./shared-module/shared.module";
import { PagesCoreComponent } from "./theme-contents/pages-core/pages-core.component";
import { LeftPanelComponent } from "./theme-contents/common/left-panel/left-panel.component";
import { RightPanelComponent } from "./theme-contents/common/right-panel/right-panel.component";
import { HeaderComponent } from "./theme-contents/common/header/header.component";
import { ContentSectionComponent } from "./theme-contents/content-section/content-section.component";

//////////////////////////// Shared Module With third-party packages /////////////////////////////////
import { ExternalLibrariesModule } from "./external-libraries-module/external-libraries.module";

import { DashboardComponent } from "./app-components/dashboard/dashboard.component";
import { LanguageChangeService } from "./app-services/language-change/language-change.service";
import { StringsService } from "./app-services/strings/strings.service";
import { ApiResponseInterceptorService } from "./app-services/api-response-interceptor/api-response-interceptor.service";
import {
  RippleGlobalOptions,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from "@angular/material/core";

import { MarkdownParserPipe } from "./utilities/markdown-parser/markdown-parser.pipe";
import { PerformanceAttributionComponent } from "./app-components/performance-attribution/performance-attribution.component";
import { AnalyticsDisplayViewComponent } from "./app-components/performance-attribution/analytics-display-view/analytics-display-view.component";
import { ModelMappingComponent } from "./app-components/setup/model-mapping/model-mapping.component";
import { AppComponent } from "./app.component";
import { AssetRegistryComponent } from "./app-components/asset-registry/asset-registry.component";
import { SideBarComponent } from "./app-components/side-bar/side-bar.component";
import { ApiResponseErrorComponent } from "./utilities/api-response-error/api-response-error.component";
import { ListedEquitiesComponent } from "./app-components/master-data/listed-equities/listed-equities.component";
import { DataGridComponent } from "./app-directives/data-grid/data-grid.component";
import { CommercialPaperComponent } from "./app-components/master-data/commercial-paper/commercial-paper.component";
import { FixedTreasuryBondComponent } from "./app-components/master-data/fixed-treasury-bond/fixed-treasury-bond.component";
import { OffshoreComponent } from "./app-components/master-data/offshore/offshore.component";
import { TreasuryBillComponent } from "./app-components/master-data/treasury-bill/treasury-bill.component";
import { UnitTrustComponent } from "./app-components/master-data/unit-trust/unit-trust.component";
import { FloatingTreasuryBondComponent } from "./app-components/master-data/floating-treasury-bond/floating-treasury-bond.component";
import { InstrumentClassesFormComponent } from "./app-components/setup/instrument-classes/instrument-classes-form/instrument-classes-form.component";
import { NavBarSettingsComponent } from "./utilities/nav-bar-settings/nav-bar-settings.component";
import { CorporateBondComponent } from "./app-components/master-data/corporate-bond/corporate-bond.component";
import { BenchMarksViewComponent } from "./app-components/bench-marks/bench-marks-view/bench-marks-view.component";
import { FundsComponent } from "./app-components/funds/funds.component";
import { InstrumentClassesComponent } from "./app-components/setup/instrument-classes/instrument-classes.component";
import {
  DataFormComponent,
  DataFormOptions,
} from "./app-directives/data-form/data-form.component";
import { SettingsComponent } from "./app-components/settings/settings.component";
import { FundManagerSetupComponent } from "./app-components/setup/fund-manager-setup/fund-manager-setup.component";
import { ModelsContainerDirectiveComponent } from "./app-components/performance-attribution/models-container-directive/models-container-directive.component";
import { AnalyticsAssetLevelSettingsComponent } from "./utilities/analytics-asset-level-settings/analytics-asset-level-settings.component";
import { MeanSampleComponent } from "./app-components/performance-attribution/mean-sample/mean-sample.component";
import { DocumentationSnackbarComponent } from "./app-services/documentation-snackbar/documentation-snackbar.component";
import { MarkdownComponent } from "./utilities/markdown/markdown.component";
import { StarRatingComponent } from "./utilities/star-rating/star-rating.component";
import { BenchmarkTypesComponent } from "./app-components/bench-marks/benchmark-types/benchmark-types.component";
import { LimitsComponent } from "./app-components/setup/limits/limits.component";
import { HoldingsComponent } from "./app-components/master-data/holdings/holdings/holdings.component";
import { LimitTypesComponent } from "./app-components/setup/limit-types/limit-types.component";
import { InvestorsComponent } from "./app-components/setup/investors/investors.component";
import { CustodiansComponent } from "./app-components/master-data/custodians/custodians.component";
import { CountriesComponent } from "./app-components/master-data/countries/countries.component";
import { BanksComponent } from "./app-components/master-data/banks/banks.component";
import { PurchasesSalesComponent } from "./app-components/master-data/purchases-sales/purchases-sales.component";
import { UnauthorizedComponent } from "./utilities/unauthorized/unauthorized.component";
import { ApplicationSettingsService } from "./app-services/app-settings/application-settings.service";
import {
  DefaultDateRange,
  ApplicationSettings,
} from "./app-services/app-settings/application-settings";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import {
  AuthModule,
  OidcConfigService,
  OpenIdConfiguration,
  AuthWellKnownEndpoints,
} from "angular-auth-oidc-client";
import { LoginComponent } from "./utilities/login/login.component";
import { OidcInterceptorService } from "./providers/oidc-interceptor/oidc-interceptor.service";
import { OidcGuardService } from "./providers/oidc-guard/oidc-guard.service";
import { DataExportComponent } from "./utilities/data-export/data-export.component";
import { AboutPageComponent } from "./utilities/about-page/about-page.component";
import { NonPortfolioAnalyticsComponent } from "./app-components/non-portfolio/non-portfolio-analytics/non-portfolio-analytics.component";
import { LoadingDialogComponent } from "./app-directives/loading-dialog/loading-dialog.component";
import { Error404Component } from "./utilities/error404/error404.component";
import { AssetCategoryResultsComponent } from "./app-components/non-portfolio/asset-category-results/asset-category-results.component";
import { ModelsViewComponent } from "./app-components/non-portfolio/models-view/models-view.component";
import { DatesSelectorComponent } from "./app-directives/dates-selector/dates-selector.component";
import { ModelsRequestDescriptionComponent } from "./app-components/non-portfolio/models-request-description/models-request-description.component";
import { PortfolioAnalyticsComponent } from "./app-components/portfolio-analytics/portfolio-analytics.component";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { SettingsInformationComponent } from "./utilities/settings-information/settings-information.component";
import { PortfolioModelsViewComponent } from "./app-components/portfolio-analytics/portfolio-models-view/portfolio-models-view.component";
import { ANALYTICS_CONFIG, APP_CONFIG } from "./app.config";
import { ReleaseInfoViewComponent } from "./utilities/release-info-view/release-info-view.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { RegulatoryComplianceComponent } from "./app-components/reports/regulatory-compliance/regulatory-compliance.component";
import { PredictiveAnalyticsComponent } from "./app-components/non-portfolio/predictive-analytics/predictive-analytics.component";

import { Angulartics2Module } from "angulartics2";
import { AnalyticsDashboardComponent } from "./app-components/analytics-dashboard/analytics-dashboard.component";

import { DxReportViewerModule } from "devexpress-reporting-angular";
import { SpreadsheetAllModule } from "@syncfusion/ej2-angular-spreadsheet";

import { PortfolioRebalancingReportComponent } from "./app-components/reports/portfolio-rebalancing-report/portfolio-rebalancing-report.component";
import { PortfolioReportsContainerComponent } from "./app-components/portfolio-reports-container/portfolio-reports-container.component";
import { FundConfigFormComponent } from "./app-directives/fund-config-form/fund-config-form.component";

import { ReportsComponent } from "./reports-module/reports/reports.component";
import { ReportGroupsViewComponent } from "./reports-module/reports/CMAReports/report-groups-view.component";
import { ReportsHelperComponent } from "./reports-module/reports/reports-helper/reports-helper.component";
import { FundsListComponent } from "./reports-module/reports/FundsFundmanagers/funds-list/funds-list.component";
import { FundManagersListComponent } from "./reports-module/reports/FundsFundmanagers/fund-managers-list/fund-managers-list.component";
import { FundManagersOnlyListComponent } from "./reports-module/reports/FundsFundmanagers/fund-managers-only/fund-managers-only-list.component";
import { ConfigService } from "./common/config.service";
import { PortfolioBondRiskAnalysisComponent } from "./app-components/portfolio-bond-risk-analysis/portfolio-bond-risk-analysis.component";
import { NonPortfolioBondRiskAnalysisComponent } from "./non-portfolio-bond-risk-analysis/non-portfolio-bond-risk-analysis.component";
import { PortfolioCorrelationAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-correlation-analysis/portfolio-correlation-analysis.component";
import { PortfolioPeerGroupAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-peer-group-analysis/portfolio-peer-group-analysis.component";
import { PortfolioRegressionAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-regression-analysis/portfolio-regression-analysis.component";
import { NonPortfolioRegressionAnalysisComponent } from "./app-components/non-portfolio/non-portfolio-regression-analysis/non-portfolio-regression-analysis.component";
import { NonPortfolioCorrelationAnalysisComponent } from "./app-components/non-portfolio/non-portfolio-correlation-analysis/non-portfolio-correlation-analysis.component";
import { PortfolioOptimizationComponent } from "./app-components/portfolio-analytics/portfolio-optimization/portfolio-optimization.component";
import { PortfolioRebalancingComponent } from "./app-components/portfolio-analytics/portfolio-rebalancing/portfolio-rebalancing.component";
import { InstrumentCategoriesComponent } from "./app-components/setup/instrument-categories/instrument-categories.component";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { UserComponent } from "./app-components/master-data/user/user.component";
import { NonportfolioAssetRegistryComponent } from "./app-components/asset-registry/nonportfolio-asset-registry/nonportfolio-asset-registry.component";
import { EquitiesDataImporterComponent } from "./app-components/data-importer/equities-data-importer/equities-data-importer.component";
import { ProgressComponent } from "./app-components/data-importer/progress/progress.component";
import { DepositDataImporterComponent } from "./app-components/data-importer/deposit-data-importer/deposit-data-importer.component";
import { TreasuryBondsImporterComponent } from "./app-components/data-importer/treasury-bonds-importer/treasury-bonds-importer.component";
import { CorporateBondsImporterComponent } from "./app-components/data-importer/corporate-bonds-importer/corporate-bonds-importer.component";
import { TreasuryBillsImporterComponent } from "./app-components/data-importer/treasury-bills-importer/treasury-bills-importer.component";
import { CommercialPaperImporterComponent } from "./app-components/data-importer/commercial-paper-importer/commercial-paper-importer.component";
import { CouponPaymentsImporterComponent } from "./app-components/data-importer/coupon-payments-importer/coupon-payments-importer.component";
import { DividendsImporterComponent } from "./app-components/data-importer/dividends-importer/dividends-importer.component";
import { CashTransactionsImporterComponent } from "./app-components/data-importer/cash-transactions-importer/cash-transactions-importer.component";
import { InvestorTypeComponent } from "./app-components/master-data/investor-type/investor-type.component";
import { InvestorSubTypeComponent } from "./app-components/master-data/investor-sub-type/investor-sub-type.component";
import { ZambiaSecReportComponent } from "./reports-module/reports/ZambiaSecReports/zambia-sec-report/zambia-sec-report.component";
import { DxAccordionModule } from "devextreme-angular";
import { ZambiaSecCustodianReportsComponent } from "./reports-module/reports/ZambiaSecReports/ZambiaCustodianReports/zambia-sec-custodian-reports/zambia-sec-custodian-reports.component";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { ReconReportsComponent } from "./reports-module/reports/ReconReports/recon-reports/recon-reports.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AssetRegistryDataGridComponent } from "./app-components/asset-registry/asset-registry-data-grid/asset-registry-data-grid.component";
import { CustodianPortfolioRegistryComponent } from "./app-components/asset-registry/custodian-portfolio-registry/custodian-portfolio-registry.component";
import { FundamentalAnalysisComponent } from "./app-components/fundamental-analysis/fundamental-analysis.component";
import { ChatBotComponent } from "./app-components/chat-bot/chat-bot.component";
import { NgxLoadingModule } from "ngx-loading";
import { ConvertTextToHtmlPipe } from "./app-components/chat-bot/convert-text-to-html.pipe";
import { ChatbotService } from "./app-components/chat-bot/chatbot.service";

const dataFormOptions: DataFormOptions = {
  formData: null,
  formItems: null,
  formTitle: null,
  postEndPoint: null,
  formID: null,
};

const ConfigDeps = new InjectionToken<(() => Function)[]>("configDeps");

export function configurationFactory(
  http: HttpClient,
  config: ConfigService,
  configDeps: (() => Function)[]
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      http
        .get("assets/settings/config.json") // Return Observable, although is not very useful in this early phase
        .toPromise() // Convert Observable to Promise as is easier to test
        .then((data) => {
          // load config
          config.loadConfig(data);
          // Return resolved Promise when dependant functions are resolved
          return Promise.all(configDeps.map((dep) => dep())); // configDeps received from the outside world
        })
        .then(() => {
          // Once configuration dependencies are resolved, then resolve factory
          resolve("");
        })
        .catch(() => {
          reject();
        });
    });
  };
}

// Create custom Injection Token

export function configureAuth(
  oidcConfigService: OidcConfigService,
  configService: ConfigService
) {
  return () =>
    oidcConfigService.withConfig(
      {
        stsServer: configService.config.STSURL,
        redirectUrl: configService.config.MatrixUIURL,
        clientId: configService.config.MatrixUIClientIdAsInSTS,
        scope: "api1 profile email openid",
        responseType: "code",
        silentRenew: false,
        postLogoutRedirectUri: `${configService.config.MatrixUIURL}`,
        storage: localStorage,
        disableIatOffsetValidation: false,
        maxIdTokenIatOffsetAllowedInSeconds: 600, // enable a window of 10 minutes difference
        startCheckSession: true,
      },
      {
        issuer: configService.config.STSURL,
        authorizationEndpoint:
          configService.config.STSURL + "/connect/authorize",
        tokenEndpoint: configService.config.STSURL + "/connect/token",
        userinfoEndpoint: configService.config.STSURL + "/connect/userinfo",
        endSessionEndpoint: configService.config.STSURL + "/connect/endsession",
        checkSessionIframe:
          configService.config.STSURL + "/connect/checksession",
        revocationEndpoint: configService.config.STSURL + "/connect/revocation",
        introspectionEndpoint:
          configService.config.STSURL + "/connect/introspect",
        jwksUri:
          configService.config.STSURL +
          "/.well-known/openid-configuration/jwks",
      }
    );
}

@NgModule({
  declarations: [
    DataExportComponent,
    AppComponent,
    UnauthorizedComponent,
    PagesCoreComponent,
    LeftPanelComponent,
    RightPanelComponent,
    HeaderComponent,
    ContentSectionComponent,
    DashboardComponent,
    PerformanceAttributionComponent,
    AnalyticsDisplayViewComponent,
    ModelMappingComponent,
    AssetRegistryComponent,
    DashboardComponent,
    SideBarComponent,
    ApiResponseErrorComponent,
    ListedEquitiesComponent,
    DataGridComponent,
    CommercialPaperComponent,
    FixedTreasuryBondComponent,
    OffshoreComponent,
    TreasuryBillComponent,
    UnitTrustComponent,
    FloatingTreasuryBondComponent,
    InstrumentClassesFormComponent,
    NavBarSettingsComponent,
    CorporateBondComponent,
    BenchMarksViewComponent,
    FundsComponent,
    InstrumentClassesComponent,
    FundManagerSetupComponent,
    ModelsContainerDirectiveComponent,
    AnalyticsDisplayViewComponent,
    AnalyticsAssetLevelSettingsComponent,
    MeanSampleComponent,
    DocumentationSnackbarComponent,
    MarkdownParserPipe,
    MarkdownComponent,
    StarRatingComponent,
    BenchmarkTypesComponent,
    LimitsComponent,
    HoldingsComponent,
    LimitTypesComponent,
    InvestorsComponent,
    CustodiansComponent,
    CountriesComponent,
    BanksComponent,
    ModelMappingComponent,
    PurchasesSalesComponent,
    DataFormComponent,
    LoginComponent,
    AboutPageComponent,
    SettingsComponent,
    NonPortfolioAnalyticsComponent,
    LoadingDialogComponent,
    Error404Component,
    AssetCategoryResultsComponent,
    ModelsViewComponent,
    DatesSelectorComponent,
    ModelsRequestDescriptionComponent,
    PortfolioAnalyticsComponent,
    SettingsInformationComponent,
    PortfolioModelsViewComponent,
    ReleaseInfoViewComponent,
    RegulatoryComplianceComponent,
    PredictiveAnalyticsComponent,
    AnalyticsDashboardComponent,
    PortfolioRebalancingReportComponent,
    PortfolioReportsContainerComponent,
    FundConfigFormComponent,
    ReportsComponent,
    ReportGroupsViewComponent,
    FundsListComponent,
    ReportsHelperComponent,
    FundManagersListComponent,
    FundManagersOnlyListComponent,
    PortfolioBondRiskAnalysisComponent,
    NonPortfolioBondRiskAnalysisComponent,
    PortfolioCorrelationAnalysisComponent,
    PortfolioPeerGroupAnalysisComponent,
    PortfolioRegressionAnalysisComponent,
    NonPortfolioRegressionAnalysisComponent,
    NonPortfolioCorrelationAnalysisComponent,
    PortfolioOptimizationComponent,
    PortfolioRebalancingComponent,
    InstrumentCategoriesComponent,
    UserComponent,
    NonportfolioAssetRegistryComponent,
    EquitiesDataImporterComponent,
    ProgressComponent,
    DepositDataImporterComponent,
    TreasuryBondsImporterComponent,
    CorporateBondsImporterComponent,
    TreasuryBillsImporterComponent,
    CommercialPaperImporterComponent,
    CouponPaymentsImporterComponent,
    DividendsImporterComponent,
    CashTransactionsImporterComponent,
    InvestorTypeComponent,
    InvestorSubTypeComponent,
    ZambiaSecReportComponent,
    ZambiaSecCustodianReportsComponent,
    ReconReportsComponent,
    AssetRegistryDataGridComponent,
    CustodianPortfolioRegistryComponent,
    FundamentalAnalysisComponent,
    ChatBotComponent,
    ConvertTextToHtmlPipe,
  ],
  imports: [
    SharedModule,
    ExternalLibrariesModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AuthModule.forRoot(),
    Angulartics2Module.forRoot(),
    DxReportViewerModule,
    CommonModule,
    SpreadsheetAllModule,
    BrowserAnimationsModule,
    DxAccordionModule,
    ToastrModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot("G-NNW7JN7130"),
    NgxGoogleAnalyticsRouterModule,
    FormsModule,
    NgxLoadingModule,
    ReactiveFormsModule,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [HttpClient, ConfigService, ConfigDeps],
      multi: true,
    },

    OidcConfigService,
    {
      provide: ConfigDeps,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (
        oidcConfig: OidcConfigService,
        configService: ConfigService
      ) => {
        // Easy to add or remove dependencies
        return [configureAuth(oidcConfig, configService)];
      },
      deps: [OidcConfigService, ConfigService],
    },

    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    LanguageChangeService,
    StringsService,
    ChatbotService,
    MarkdownParserPipe,
    {
      provide: DataFormOptions,
      useValue: dataFormOptions,
    },

    OidcGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OidcInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptorService,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: ANALYTICS_CONFIG,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: "app-component-main",
      },
    },
  ],
  exports: [ExternalLibrariesModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private appSettingsService: ApplicationSettingsService) {
    /**
     * For all the dates selections in the app, set defaults
     */

    // let month = new Date().getMonth();
    // if (month < 3) {
    //     //when in first quater show the date ranges of the last quater of the last year.
    //     let defaultDateRange = new DefaultDateRange(new Date(new Date().getFullYear() - 1, 9, 1), new Date(new Date().getFullYear() - 1, 11, 31))
    //     let settings = new ApplicationSettings();
    //     settings.DefaultDateRange = defaultDateRange
    //     this.appSettingsService.UpdateSettings(settings);
    // } else if (month < 6) {
    //     let defaultDateRange = new DefaultDateRange(new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 2, 31))
    //     let settings = new ApplicationSettings();
    //     settings.DefaultDateRange = defaultDateRange;
    //     this.appSettingsService.UpdateSettings(settings);
    // } else if (month < 9) {
    //     //Showing data for quater 2 . TO DO: do not start counting from first april. This is a code smell
    //     let defaultDateRange = new DefaultDateRange(new Date(new Date().getFullYear(), 3, 1), new Date(new Date().getFullYear(), 5, 30))
    //     let settings = new ApplicationSettings();
    //     settings.DefaultDateRange = defaultDateRange;
    //     this.appSettingsService.UpdateSettings(settings);
    // } else if (month < 12) {
    //     let defaultDateRange = new DefaultDateRange(new Date(new Date().getFullYear(), 6, 1), new Date(new Date().getFullYear(), 8, 30))
    //     let settings = new ApplicationSettings();
    //     settings.DefaultDateRange = defaultDateRange;
    //     this.appSettingsService.UpdateSettings(settings);
    // }

    let defaultDateRange = new DefaultDateRange(
      new Date(2022, 0, 1).toDateString(),
      new Date(2022, 3, 30).toDateString()
    );
    let settings = new ApplicationSettings();
    settings.DefaultDateRange = defaultDateRange;
    this.appSettingsService.UpdateSettings(settings);
  }
}
