<div class="content-section">
  <div class="innova-app-routing-area-title">
    Purchases and Sales
    <i class="material-icons md-18" (click)="documentationService.showHelpContent('purchaseses-sales')">info</i>
  </div>
  <mat-divider></mat-divider>
  <div class="analytics-section-selection-dates" style="margin-top: 30px;">
    <div style="margin-left: 2%; margin-right: 2%;">
      <mat-button-toggle-group #purchaseSaleButtonToogle="matButtonToggleGroup" name="purchaseSaleButtonToogle"
        aria-label="Font Style" color="accent">
        <mat-button-toggle value="purchases" checked="true" class="purchases-sales-purchases-button">
          <mat-icon>call_received</mat-icon>
          Purchases
        </mat-button-toggle>
        <mat-button-toggle value="sales" class="purchases-sales-sales-button">
          <mat-icon>call_made</mat-icon>
          Sales
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <span class="analytics-section-selection-title">Start Date</span>
      <dx-date-box displayFormat="dd MMM yyyy" #startDateBox></dx-date-box>
    </div>
    <div style="margin-left: 2%;">
      <span class="analytics-section-selection-title">End Date</span>
      <dx-date-box displayFormat="dd MMM yyyy" #endDateBox></dx-date-box>
    </div>

    <div style="margin-left: 2%;" *ngIf="(startDateBox.onClosed | async) || (endDateBox.onClosed | async)">
      <button mat-stroked-button matTooltip="Refresh Holdings after date range changes"
        (click)="openHoldings(null, true)" color="primary">
        <mat-icon>refresh</mat-icon> Refresh
      </button>
    </div>
  </div>

  <mat-divider style="margin-top:1%; margin-bottom: 1%;"></mat-divider>


  <mat-tab-group (selectedTabChange)="openHoldings($event, false)" #holdingTab [disablePagination]="true">
    <mat-tab label="Commercial Papers">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='commercialPapersDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}">
      </dx-data-grid>
    </mat-tab>
    <mat-tab label="Fixed Corporate Bonds">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='fixedCorporateBondsDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}">
      </dx-data-grid>
    </mat-tab>
    <mat-tab label="Floating Corporate Bonds">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='floatingCorporateBondDataSource'
        [wordWrapEnabled]="true" keyExpr="Id" [selection]="{mode:'single'}">
      </dx-data-grid>
    </mat-tab>
    <mat-tab label="Listed Equities">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='listedEquitiesDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}"></dx-data-grid>
    </mat-tab>
    <mat-tab label="Offshores">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='offshoresDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}"></dx-data-grid>
    </mat-tab>
    <mat-tab label="Time Deposits">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='timeDepositsDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}"></dx-data-grid>
    </mat-tab>
    <mat-tab label="Treasury Bills">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='tBillsDataSource' [wordWrapEnabled]="true" keyExpr="Id"
        [selection]="{mode:'single'}"></dx-data-grid>
    </mat-tab>
    <mat-tab label="Treasury Bonds">
      <dx-data-grid [rowAlternationEnabled]="true" [dataSource]='treasuryBondsDataSource' [wordWrapEnabled]="true"
        keyExpr="Id" [selection]="{mode:'single'}"></dx-data-grid>
    </mat-tab>

  </mat-tab-group>
</div>