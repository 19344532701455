import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Column } from "devextreme/ui/data_grid";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { forkJoin, Subscription } from "rxjs";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import {
  DxChartComponent,
  DxDataGridComponent,
  DxDateBoxComponent,
  DxDropDownBoxComponent,
  DxLookupComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { ApiService } from "src/app/app-services/api/api.service";
import { DxiRowComponent } from "devextreme-angular/ui/nested";
import { MatButton } from "@angular/material/button";
import { ComplianceLimitsVariance, ComplianceReport } from "./data-object";
import * as _ from "underscore";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import {
  Tenant,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";

export interface RegulatoryCompliance {
  AssetCategory: string;
  ActualHolding: number;
  RBALimitMin: number;
  RBALimitMax: number;
  VarianceWithRBAMin: number;
  VarianceWithRBAMax: number;
  IPSLimitMin: number;
  IPSLimitMax: number;
  VarianceWithIPSLimitMin: number;
  VarianceWithIPSLimitMax: number;
}
export class ComplianceRequestObject {
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
}

@Component({
  selector: "app-regulatory-compliance",
  templateUrl: "./regulatory-compliance.component.html",
  styleUrls: ["./regulatory-compliance.component.scss"],
})
export class RegulatoryComplianceComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("investorLookupInstance", { static: false })
  investorLookupInstance: DxLookupComponent;
  @ViewChild("complianceGridInstance", { static: false })
  complianceGridInstance: DxDataGridComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("refreshButton", { static: false })
  refreshButtonInstance: MatButton;
  @ViewChild("rba-regulatory-compliance-chart", { static: false })
  rbaChart: DxChartComponent;
  @ViewChild("cma-regulatory-compliance-chart", { static: false })
  cmaChart: DxChartComponent;
  @ViewChild(DxTreeListComponent, { static: false }) treelist;
  @ViewChild("FundDropDownBox") FundDropDownBox: DxDropDownBoxComponent;
  @ViewChild("fundDropdown", { static: false })
  funddropdownInstance: DxDropDownBoxComponent;

  gridKeyExpression = ["AssetCategory", "ActualHolding", "Date"]; // might need to have a guid from the API with each row data for uniqueness
  summaryColumnNames = ["ActualHolding"];
  applicationSettings: ApplicationSettings;
  chartTitle: string;

  _appSettingsServiceSubscription: Subscription;
  _investorsSubscription: Subscription;
  _startDateChangeSubscription: Subscription;
  _reportSubscription: Subscription;
  regulatoryComplianceGridData: any[] = [];
  regulatoryComplianceGridColumns: any[] = [];
  cmaRegulatoryComplianceData: Array<any>;
  rbaRegulatoryComplianceData: Array<any>;
  selectedFund: string = "";
  firstRegulatorName: string = "";
  secondRegulatorName: string = "";
  activeTenantName: string = "";
  treeBoxValue: string;
  FundsFundManagerDataSource: any;
  fundManagersArray: Array<any> = [];
  ComplianceRequestObject: ComplianceRequestObject =
    new ComplianceRequestObject();
  constructor(
    private settingsService: ApplicationSettingsService,
    navBarSettingsService: NavbarHeaderSettingsService,
    public apiService: ApiService,
    public notificationService: SnackNotificationsService,
    private tenantResolverService: TenantService
  ) {
    navBarSettingsService.ChangeActiveComponentName("Regulatory Compliance");
    this.settingsService.GetSettings().subscribe((settings) => {
      this.applicationSettings = settings;
    });
    this.treeBoxValue = "Select a Fund";
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
    if (this._reportSubscription !== undefined)
      this._reportSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    //this.cmaChart.instance.render();
    //this.rbaChart.instance.render();

    // this.investorLookupInstance.displayExpr = "Name";
    // this.investorLookupInstance.valueExpr = "ID";
    // this.investorLookupInstance.placeholder = "Select Investor/ Fund";
    // this.investorLookupInstance.usePopover = true;
    // this.investorLookupInstance.dropDownOptions = {
    //   width: "25%",
    //   title: "Show Compliance Report for: ",
    //   closeOnOutsideClick: true,
    // };
    // this.investorLookupInstance.width = "300";

    this._appSettingsServiceSubscription = this.settingsService
      .GetSettings()
      .subscribe((settings) => {
        this.startDateBoxInstance.value = settings.DefaultDateRange.EndDate;
        this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
    /**
     * Update end date minimum value on start date change
     *
     */
    this._startDateChangeSubscription =
      this.startDateBoxInstance.onValueChanged.subscribe((newDateChange) => {
        this.endDateBoxInstance.min = newDateChange.value;
      });

    // // 2. Get all investors
    // this._investorsSubscription = this.apiService
    //   .Get("Investors")
    //   .subscribe((result) => {
    //     this.investorLookupInstance.dataSource = result;
    //     this.investorLookupInstance.value = result[0].ID;
    //   });

    // this.investorLookupInstance.selectedItemChange.subscribe((valueConfig) => {
    //   this.chartTitle = "Aggregate Regulatory Compliance – " + valueConfig.Name;
    // });
  }

  ngOnInit() {
    //resolve the current tenant
    this.activeTenantName = this.tenantResolverService.getTenantName();
    if (this.activeTenantName == Tenant.Tenant2) {
      this.firstRegulatorName = "SEC Zambia";
      this.secondRegulatorName = "IPS";
    } else {
      this.firstRegulatorName = "CMA Kenya";
      this.secondRegulatorName = "RBA";
    }

    this.firstRegulatorName;
    this.secondRegulatorName;
    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      //let fundmanagers: Array<any> = result[0]
      //let funds: Array<any> = result[1]

      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
        });

        let investors = [];
        fundManager.MasterFunds.forEach((masterfund) => {
          let funds = masterfund.Investors;
          funds.forEach((investor) => {
            investors.push(investor);
          });
        });

        investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: fundManager.Id,
            TenantId: fundManager.TenantId,
          });
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });
  }

  ModelGroupsItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {
      this.treeBoxValue = event.selectedRowsData[0].Name;

      //when only a fund manager is selected
      if (event.selectedRowsData.length === 1) {
        let selectedRowsData = event.selectedRowsData[0];
        if (selectedRowsData.Head_ID === 0) {
          //then this is a fund manager
          const fundManagerId = selectedRowsData.ID;

          this.ComplianceRequestObject.Funds = [];
          this.ComplianceRequestObject.FundManagers = [];

          this.ComplianceRequestObject.FundManagers.push(fundManagerId);
        } else {
          //this is a single fund belonging to a fund manager
          let selectedRowsData = event.selectedRowsData[0];
          const fundId = selectedRowsData.ID;
          const fundManagerId = selectedRowsData.Head_ID;
          const selectedTenantId = selectedRowsData.TenantId;
          this.ComplianceRequestObject.TenantId = selectedTenantId;

          this.ComplianceRequestObject.Funds = [];
          this.ComplianceRequestObject.FundManagers = [];

          this.ComplianceRequestObject.Funds.push(fundId);
          this.ComplianceRequestObject.FundManagers.push(fundManagerId);

          this.funddropdownInstance.value = selectedRowsData.Name;
          this.funddropdownInstance.instance.close();
        }
      } else {
        // multiple selection
        const selectedItems = event.selectedRowsData;
        const fundManagerId = event.selectedRowsData[0].Head_ID;
        const selectedTenantId = event.selectedRowsData[0].TenantId;
        const fundIds = event.selectedRowsData.map((item) => item.ID);
        this.ComplianceRequestObject.Funds = [];
        this.ComplianceRequestObject.FundManagers = [];
        this.ComplianceRequestObject.TenantId = selectedTenantId;

        this.ComplianceRequestObject.Funds = fundIds;
        this.ComplianceRequestObject.FundManagers.push(fundManagerId);
      }
    }
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          // let formattedNumber = this.numberWithCommas(value)
          event.value = `${value}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.cellElement.classList.add(
            "data-grid-negative-number-cell-regulator-compliance"
          );
          event.value = Math.abs(value);
          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");
          event.cellElement.innerHTML = `(${textDisplayed})`;
        }
        if (value === -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
      }
    }

    //asset category goes here
    if (event.rowType == "data") {
      let text: string = event.text;
      if (text !== undefined) {
        if (text.search("/*")) {
          console.error(text);
        }
      }
    }

    if (event.rowType == "header") {
      if (event.columnIndex == 0) {
        if (event.column.dataField === "AssetCategory") {
          event.cellElement.classList.add(
            "regulatory-limit-grid-columns-categories"
          );
        } else {
          event.cellElement.classList.add("regulatory-limit-grid-columns");
        }
      } else {
        event.cellElement.classList.add("regulatory-limit-grid-columns");
      }
    }
  }

  FormatChartTitle() {}

  fetchReport() {
    if (this.treeBoxValue == null) {
      this.notificationService.ShowWarningNotification("Please Select a Fund");
      return;
    }
    this.fundManagersArray = [];
    this.fundManagersArray.push({
      Id: this.ComplianceRequestObject.FundManagers[0],
      TenantId: this.ComplianceRequestObject.TenantId,
      Funds: this.ComplianceRequestObject.Funds,
    });
    let request = {
      FundManagers: this.fundManagersArray,
      StartDate: this.startDateBoxInstance.value,
      EndDate: this.endDateBoxInstance.value,
    };

    this._reportSubscription = this.apiService
      //Get the Regulatory compliance Data
      .Post("reports/DetailedRegulatoryLimitsReport", request)
      .subscribe((complianceReportArray: ComplianceReport[]) => {
        if (complianceReportArray.length == 0) {
          this.notificationService.ShowErrorNotification(
            "No result found for the specified period and fund"
          );
          return;
        }
        this.formatGridData(complianceReportArray);
        this.closeRequest();

        //this.closeRequest();
        //this.formatGridData();

        /**********************************! from the first result make the columns */
        let theFirstResult = complianceReportArray[0];

        let columns: Column[] = [
          {
            caption: "Report Date",
            dataField: "Date",
            allowSorting: true,
            sortOrder: "asc",
            dataType: "date",
            groupIndex: 0,
            format: "dd MMM yyyy",
          },
          {
            caption: "Asset Category",
            dataField: "AssetCategory",
            sortIndex: 1,
            allowSorting: true,
            sortOrder: "asc",
            fixed: true,
            fixedPosition: "left",
            width: "300px",
            allowResizing: false,
            cssClass: "regulator-limit-grid-column-categories",
          },
          {
            caption: "Actual Holding (%)",
            dataField: "ActualHolding",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
            dataType: "number",
            cssClass: "cell-highlighted",
          },
        ];

        let complianceLimitVarianceOneItem =
          theFirstResult.ComplianceLimitsVariance;
        /**********************************! from the first result make the columns */

        let limitItemColumns: Column[] = [];

        complianceLimitVarianceOneItem.forEach((x, index) => {
          let limitNameWithSpaces = x.Limit;
          let limitName = x.Limit.replace(/\s/g, "").replace("-", "");

          let limitsColumns: Column[] = [
            {
              caption: limitNameWithSpaces,
              cssClass: `non-portfolio-grid-color-${index}`,
              columns: [
                {
                  caption: "Minimum Rate (%)",
                  dataField: limitName + "Minimum",
                  dataType: "number",
                  format: {
                    type: "fixedPoint",
                    precision:
                      this.applicationSettings.PercentageValuesDecimalPoints,
                  },
                  cssClass: `non-portfolio-grid-color-${index}`,
                },
                {
                  caption: "Maximum Rate (%)",
                  dataField: limitName + "Maximum",
                  dataType: "number",
                  format: {
                    type: "fixedPoint",
                    precision:
                      this.applicationSettings.PercentageValuesDecimalPoints,
                  },
                  cssClass: `non-portfolio-grid-color-${index}`,
                },
              ],
            },
            {
              caption: `Variance With ${limitNameWithSpaces}`,
              cssClass: `non-portfolio-grid-color-${index}`,
              columns: [
                {
                  caption: "Minimum Rate (%)",
                  dataField: limitName + "MinimumVariance",
                  dataType: "number",
                  format: {
                    type: "fixedPoint",
                    precision:
                      this.applicationSettings.PercentageValuesDecimalPoints,
                  },
                  cssClass: `non-portfolio-grid-color-${index}`,
                },
                {
                  caption: "Maximum Rate (%)",
                  dataField: limitName + "MaximumVariance",
                  dataType: "number",
                  format: {
                    type: "fixedPoint",
                    precision:
                      this.applicationSettings.PercentageValuesDecimalPoints,
                  },
                  cssClass: `non-portfolio-grid-color-${index}`,
                },
              ],
            },
          ];

          limitItemColumns.push(...limitsColumns);
        });

        columns.push(...limitItemColumns);

        /**********************************! from the first result make the columns */

        //@ts-ignore
        // this.regulatoryComplianceGridColumns = [];
        // this.regulatoryComplianceGridData = [];

        let complianceDataSource: Array<any> = [];

        //@ts-ignore
        let complianceDateGroups: Array<any> = _.chain(complianceReportArray)
          //@ts-ignore
          .groupBy((x) => x.Date)
          .map(function (value, key) {
            return {
              Key: key,
              Value: value,
            };
          })
          .value();

        //for each day
        complianceDateGroups.forEach((dateItem) => {
          let limitsList = dateItem.Value;

          limitsList.forEach((element) => {
            let dataSourceObject = {};

            let complianceDate = element.Date;

            Object.defineProperty(dataSourceObject, "Date", {
              value: complianceDate,
              writable: false,
            });

            let assetCat = element.AssetCategory;
            let actualHolding = element.ActualHolding;

            Object.defineProperty(dataSourceObject, "AssetCategory", {
              value: assetCat,
              writable: false,
            });

            Object.defineProperty(dataSourceObject, "ActualHolding", {
              value: actualHolding,
              writable: false,
            });
            //////////////////////////////////! limits section /////////////////////////////////////////
            let limitsVariances: Array<ComplianceLimitsVariance> =
              element.ComplianceLimitsVariance;

            limitsVariances.forEach((limitVariance) => {
              //there are two items here
              let limit = limitVariance.Limit.replace(/\s/g, "").replace(
                "-",
                ""
              );
              let maximum = limitVariance.Maximum;
              let maximumVariance = limitVariance.MaximumVariance;
              let minimum = limitVariance.Minimum;
              let minimumVariance = limitVariance.MinimumVariance;

              Object.defineProperty(dataSourceObject, limit + "Maximum", {
                value: maximum,
                writable: false,
              });
              Object.defineProperty(
                dataSourceObject,
                limit + "MaximumVariance",
                {
                  value: maximumVariance,
                  writable: false,
                }
              );
              Object.defineProperty(dataSourceObject, limit + "Minimum", {
                value: minimum,
                writable: false,
              });
              Object.defineProperty(
                dataSourceObject,
                limit + "MinimumVariance",
                {
                  value: minimumVariance,
                  writable: false,
                }
              );
            });
            //////////////////////////////////! limits section /////////////////////////////////////////
            complianceDataSource.push(dataSourceObject);
          });
        });
        this.regulatoryComplianceGridData = complianceDataSource;
        this.regulatoryComplianceGridColumns = columns;
        this._reportSubscription.unsubscribe();
      });
  }

  formatGridData(complianceSummaryReportArray: any) {
    //make api request
    //Get the Regulatory compliance summary Data to display on the charts
    // let request = {
    //   FundManagers: this.fundManagersArray,
    //   StartDate: this.endDateBoxInstance.value,
    //   EndDate: this.endDateBoxInstance.value,
    // };

    // this.apiService
    //   .Post("reports/DetailedRegulatoryLimitsReport", request)
    //   .subscribe((complianceSummaryReportArray: Array<any>) => {
    //     if (complianceSummaryReportArray.length == 0) {
    //       this.notificationService.ShowErrorNotification(
    //         "No result found for the specified period and fund"
    //       );
    //       this.closeRequest();
    //       return;
    //     }
    //set fund to display on grid

    this.selectedFund = this.treeBoxValue;

    let cmaDataSource: Array<any> = [];
    let rbaDataSource: Array<any> = [];

    complianceSummaryReportArray.forEach((element) => {
      let assetCategoty = element.AssetCategory;
      let actualHolding = element.ActualHolding;
      let complianceLimitsVariance = element.ComplianceLimitsVariance;

      let cmaData = complianceLimitsVariance[0];
      let rbaData = complianceLimitsVariance[1];
      //loop CMA data

      Object.keys(cmaData).forEach((key) => {
        let cmaMinimum: Number;
        let cmaMaximum: Number;
        if (key == "Minimum") {
          cmaMinimum = cmaData[key];
        }
        if (key == "Maximum") {
          cmaMaximum = cmaData[key];
        }
        cmaDataSource.push({
          state: assetCategoty,
          maximum: cmaMaximum,
          actual: actualHolding,
          minimum: cmaMinimum,
        });
      });

      //loop RBA data

      Object.keys(rbaData).forEach((key) => {
        let rbaMinimum: Number;
        let rbaMaximum: Number;
        if (key == "Minimum") {
          rbaMinimum = rbaData[key];
        }
        if (key == "Maximum") {
          rbaMaximum = rbaData[key];
        }
        rbaDataSource.push({
          state: assetCategoty,
          maximum: rbaMaximum,
          actual: actualHolding,
          minimum: rbaMinimum,
        });
      });
    });
    this.cmaRegulatoryComplianceData = cmaDataSource;
    this.rbaRegulatoryComplianceData = rbaDataSource;
  }

  treeList_itemSelectionChanged(e) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  /**
   * Closes the api request to fetch results
   */
  closeRequest() {
    if (this._reportSubscription !== undefined) {
      this._reportSubscription.unsubscribe();
      this._reportSubscription = undefined;
    }
  }

  customizeSummaryText(data) {
    let value = data.value;

    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let numberWithDecimalPoints = absoluteValue.toFixed(2);

      return "Total: " + `(${numberWithDecimalPoints})`;
    } else {
      let numberWithDecimalPoints = value.toFixed(2);
      return "Total: " + numberWithDecimalPoints;
    }
  }
}
