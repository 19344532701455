import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  // title = 'ng-oidc-client-app';
  // identity$: Observable<User>;
  // loading$: Observable<boolean>;
  // expiring$: Observable<boolean>;
  // expired$: Observable<boolean>;
  // loggedIn$: Observable<boolean>;
  // errors$: Observable<any>;

  constructor() {
    // this.loading$ = this.oidcFacade.loading$;
    // this.expiring$ = this.oidcFacade.expiring$;
    // this.expired$ = this.oidcFacade.expired$;
    // this.loggedIn$ = this.oidcFacade.loggedIn$;
    // this.errors$ = this.oidcFacade.errors$;
    // this.identity$ = this.oidcFacade.identity$;
  }

  ngOnInit() {
    // const user = this.oidcFacade.getOidcUser();
  }

  loginPopup() {
    // this.oidcFacade.signinPopup();
  }

  logoutPopup() {
    // this.oidcFacade.signoutPopup();
  }

}
