<div>
  <div class="information-title">
    <!-- <mat-icon>info</mat-icon> -->
    <i class="fas fa-info-circle"></i>
    Setup Information
  </div>

  <mat-divider></mat-divider>
  <div class="information-items">
    <ul>  
      <li *ngFor="let item of settingsInformationInput.settingsInformation">{{item}}</li>
    </ul>
  </div>
  <div class="more-information">
    <span>
      You can change this settings anytime by clicking

      <button disabled>
        <i class="fas fa-cogs"></i>
      </button>
      menu on the top right hand corner of the screen and choosing 'settings'
    </span>
  </div>
  <div class="close-section">
    <div>
      <button mat-stroked-button color="warn" (click)="closeThisDialog()">Close</button>
    </div>
  </div>
</div>