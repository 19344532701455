import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';



@Component({
	selector: 'app-offshore',
	templateUrl: './offshore.component.html',
	styleUrls: ['./offshore.component.scss']
})
export class OffshoreComponent implements OnInit {

	gridDataSource: any;
	EntityName = 'Offshores';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single'}
	Columns: Array<any> = [
		{ dataField: 'Name', caption: 'Name', },
		{ dataField: 'ISIN', caption: 'ISIN' },
		{ dataField: 'Code', caption: 'Code' },
		{ dataField: 'AccountCode', caption: 'Account Code' },
		{ dataField: 'AccountNumber', caption: 'Account Number' },
		{ dataField: 'Active', caption: 'Active', dataType: 'boolean' },
		{ dataField: 'Approved', caption: 'Approved', dataType: 'boolean' },
		{ dataField: 'ApplyMarketCharges', caption: 'Apply Market Charges', dataType: 'boolean' },
	];

	constructor(private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Offshores')
	 }

	ngOnInit() {
		this.apiService.Get('Offshores').subscribe(result => this.gridDataSource = result);
	}

	rowDoubleClicked(event: any) {

	}
}
