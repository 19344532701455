import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ViewChildren,
} from "@angular/core";
import { ReplaySubject, BehaviorSubject, Subscription, Subject } from "rxjs";
import {
  DxTabsComponent,
  DxFormComponent,
  DxLookupComponent,
  DxDateBoxComponent,
  DxTabPanelComponent,
  DxSwitchComponent,
  DxListComponent,
  DxDataGridComponent,
  DxRadioGroupComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { ApiService } from "../../app-services/api/api.service";

import { MatSnackBar } from "@angular/material/snack-bar";
import { AnalyticsModelClassCombination } from "../performance-attribution/selected-category";
import {
  AnalyticsModel,
  SelectedAnalyticsModelDescription,
} from "../performance-attribution/analytics-model";
import { ApplicationSettingsService } from "../../app-services/app-settings/application-settings.service";
import { DocumentationService } from "src/app/app-services/documentation/documentation.service";

import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import {
  TestInstrumentCategory,
  ModelItem,
  ModelClassification,
  ModelGroupItem,
  ModelGroup,
} from "../non-portfolio/non-portfolio-analytics/helper-classes";
import { Column } from "devextreme/ui/data_grid";
import {
  PortfolioAnalyticsRootObject,
  PortfolioAnalyticsFundModelResult,
  PortfolioModelGroupsResults,
  SecuritiesBondRiskAnalysis,
  ModelCategorizations,
  ModelData,
} from "./response-types";
import * as _ from "underscore";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { MatButton } from "@angular/material/button";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { autoExpand } from "devexpress-dashboard/model/index.metadata";
import { forkJoin } from "rxjs";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { QueryList } from "@angular/core";
import { LocalStorageService } from "src/app/app-services/services/local-storage.service";
import { MatAccordion } from "@angular/material/expansion";

export class List {
  id: number;
  text: string;
}

export class PortfolioRequestObject {
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
}

@Component({
  selector: "app-portfolio-analytics",
  templateUrl: "./portfolio-analytics.component.html",
  styleUrls: ["./portfolio-analytics.component.scss"],
})
export class PortfolioAnalyticsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(DxListComponent, { static: false }) fundsList: DxListComponent;
  @ViewChild("assetClassesTab", { static: false })
  AssetClassesTabInstance: DxTabsComponent;
  @ViewChild("modelsForm", { static: false })
  modelFormInstance: DxFormComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("assetCategoriesPanel", { static: false })
  assetCategoriesPanelInstance: DxTabPanelComponent;
  @ViewChild("modelsTabPanel", { static: false })
  modelsTabPanel: DxTabPanelComponent;
  @ViewChild("benchmarksLookup", { static: false })
  benchmarksLookupInstance: DxLookupComponent;
  @ViewChild("resultsDataGrid", { static: true })
  sampleResultsDataGRid: DxDataGridComponent;
  @ViewChild("weightRadioGroup", { static: false })
  weightRadioGroupInstance: DxRadioGroupComponent;
  @ViewChild("returnTypeGroup", { static: false })
  returnTypeGroupInstance: DxRadioGroupComponent;
  @ViewChild("fundManagerTreeList")
  fundManagerTree: DxTreeListComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild("refreshButton", { static: false })
  refreshButtonInstance: MatButton;

  @ViewChildren("resultsDataGrid") dataGrids: QueryList<DxDataGridComponent>;

  models = [
    {
      Name: "Performance Attribution",
      Id: "c42f40af-98f9-47e1-ba56-d68d060c050a",
    },
  ];
  assetClassModelObservable: BehaviorSubject<AnalyticsModelClassCombination> =
    new BehaviorSubject<AnalyticsModelClassCombination>(null);
  endDateObservable: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  startDateObservable: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  investorIdObservable: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  AnalyticsModels: Array<AnalyticsModel> = new Array<AnalyticsModel>();
  SelectedAnalyticsModels: ReplaySubject<
    Array<SelectedAnalyticsModelDescription>
  > = new ReplaySubject<Array<SelectedAnalyticsModelDescription>>(1);
  selectedAssetCategories: Array<string> = new Array<string>();
  fetchedAssetClasses: any;
  summarizedPerformanceDataSource: any = null;
  investorsList: Array<any> = null;

  DisplayResults: Array<any> = [];
  BondRiskObject = {};
  fundManagersArray: Array<any> = [];

  assetCategoryTabsSubject: Subject<Array<TestInstrumentCategory>> =
    new Subject<Array<TestInstrumentCategory>>();
  assetCategoryTabsObservable = this.assetCategoryTabsSubject.asObservable();

  assetCatBenchmarkSubject: Subject<any> = new Subject<any>();
  assetCatBenchmarkObservable = this.assetCatBenchmarkSubject.asObservable();

  ModelGroupItems: Array<any> = new Array<any>();
  DisplayModelCategoryResults: Subject<Array<PortfolioModelGroupsResults>> =
    new Subject<Array<PortfolioModelGroupsResults>>();
  DisplayModelCategoryResultsObservable =
    this.DisplayModelCategoryResults.asObservable();
  SelectedModelAnalyticIds: Array<any> = new Array<any>();
  SelectedBondRiskIds: Array<any> = new Array<any>();
  weightingOptions = ["True", "False"];
  panelOpenState = false;
  returnTypesOptions = [
    {
      text: "Price Based Return",
      helpInformation:
        "This return is based on the asset/security price. e.g. the return computed on the non-portfolio side uses the asset returns.",
      returnTypeValue: "assetbasedreturn",
    },
    // {
    //     text: 'Gross Return (GAV)',
    //     helpInformation: 'Unit based return on Gross Asset Value GAV – actual performance as computed by Innova (Using Gross Units). The gross (unweighted) model numbers on the portfolio side (based on units) should match those of the non-portfolio side (which are based on actual asset price changes…not units).',
    //     returnTypeValue: 'grossassetvalue'
    // },
    {
      text: "Net Return (NAV)",
      helpInformation:
        "Unit based return on Net Asset Value NAV – actual performance as computed by Innova (Using Net Units)",
      returnTypeValue: "netassetvalue",
    },
  ];

  NAVOnTotalFund = [
    {
      text: "Net Return (NAV)",
      helpInformation:
        "Unit based return on Net Asset Value NAV – actual performance as computed by Innova (Using Net Units)",
      returnTypeValue: "netassetvalue",
    },
  ];

  gridKeyExpression = ["Fund", "Security"];
  /**
   * Subscriptions
   */
  private _appSettingsServiceSubscription: Subscription;
  private _startDateBoxInstanceSubscription: Subscription;
  private _endDateBoxInstanceSubscription: Subscription;
  private _investorLookupSubscription: Subscription;
  private _assetClassesMixSubscription: Subscription;
  private _apiServiceSubscription: Subscription;
  private _modelsSubscription: Subscription;
  private _investorsSubscription: Subscription;
  private _summarySwitchSubscription: Subscription;
  private _startDateChangeSubscription: Subscription;
  public _apiFetchSubscription: Subscription;
  public _apiBondRiskFetchSubscription: Subscription;

  /**
   * @param apiService API service used to make requests to the API
   * @param matSnackBar Use the snack bar to show popups for informational purposes
   * @param appSettingsService AppSettings service provides default start and end dates
   * @param documentationService Documentation Service
   */

  pivotGridDataSource: any;
  systemSettings: ApplicationSettings;
  nonPercentageDecimalPlaces: any = 2;
  assetCategoryDataSource: any;
  assetLevelDataSource: any;
  FundsFundManagerDataSource: any;
  PortfolioRequestObject: PortfolioRequestObject = new PortfolioRequestObject();

  constructor(
    private apiService: ApiService,
    public matSnackBar: MatSnackBar,
    public appSettingsService: ApplicationSettingsService,
    public documentationService: DocumentationService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public notificationService: SnackNotificationsService,
    private localStorageService: LocalStorageService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName("Portfolio Models");

    this.assetCategoryDataSource = [];
    this.assetLevelDataSource = [];
  }

  ngOnInit() {
    //get settings
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
      this.nonPercentageDecimalPlaces =
        settings.NonPercentageValuesDecimalPoints;
    });

    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );
    /*let fundsFetch$ = this.apiService.Get("Investors");
 
    var fundsAndFundManagers = forkJoin([fundManagersFetch$, fundsFetch$])*/

    fundManagersFetch$.subscribe((fundManagers) => {
      //console.log(fundManagers);
      //let fundmanagers: Array<any> = result[0]
      //let funds: Array<any> = result[1]

      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
        });

        let investors = [];
        fundManager.MasterFunds.forEach((masterfund) => {
          let funds = masterfund.Investors;
          funds.forEach((investor) => {
            investors.push(investor);
          });
        });

        investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: fundManager.Id,
            TenantId: fundManager.TenantId,
          });
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });

    // this._apiServiceSubscription = this.apiService
    //   .Get("Investors")
    //   .subscribe((res) => {
    //     this.fundsList.dataSource = res;
    //   });

    this.apiService
      .Get("analyticssetup/GetAllInstrumentCategories")
      .subscribe((instrumentCategories: Array<any>) => {
        //Remove Indices, Yields, Forex, Shared Products, Macros from the asset categories
        let filteredAssetCategories = instrumentCategories.filter((x) => {
          if (
            x.Name == "Listed Equities" ||
            x.Name == "Fixed Treasury Bonds" ||
            x.Name == "Floating Corporate Bonds" ||
            x.Name == "Floating Treasury Bonds" ||
            x.Name == "Fixed Corporate Bonds" ||
            x.Name == "Floating Corporate Bonds" ||
            x.Name == "Treasury Bills" ||
            x.Name == "Offshore" ||
            x.Name == "Commercial Paper" ||
            x.Name == "REITS & Unit Trusts" ||
            x.Name == "Derivatives" ||
            x.Name == "Banks"
          ) {
            return x;
          }
        });

        //change Banks asset category to Time Deposit
        filteredAssetCategories.map(function (i) {
          if (i.Name == "Banks") {
            i.Name = "Time Deposit";
          }
          return i;
        });

        let assetCats = filteredAssetCategories.map((instrumentCategory) => {
          let category = new TestInstrumentCategory();
          category.id = instrumentCategory.Id;
          category.text = instrumentCategory.Name;
          category.code = instrumentCategory.Code;
          category.icon = "reorder";
          category.content = instrumentCategory.Name + " Asset Category";
          category.iconStyle = { color: "black" };
          category.analysisLevel = "asset level";
          return category;
        });
        /**\
         * Add total fund
         */
        let totalFundCategory = new TestInstrumentCategory();
        totalFundCategory.id = assetCats[0].id; //! This is a test, remove this eventually
        totalFundCategory.text = "Total Fund";
        totalFundCategory.code = "fixed corporate bonds";
        totalFundCategory.icon = "table_view";
        totalFundCategory.content =
          "Total Fund - More information on what total fund is goes here";
        totalFundCategory.iconStyle = {
          color: "red",
          "font-weight": "bold",
        };
        (totalFundCategory.analysisLevel = "total fund"),
          (totalFundCategory.defaultWeighting = "False");
        assetCats.push(totalFundCategory);
        this.assetCategoryTabsSubject.next(assetCats);
      });
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._startDateBoxInstanceSubscription !== undefined)
      this._startDateBoxInstanceSubscription.unsubscribe();
    if (this._endDateBoxInstanceSubscription !== undefined)
      this._endDateBoxInstanceSubscription.unsubscribe();
    if (this._investorLookupSubscription !== undefined)
      this._investorLookupSubscription.unsubscribe();
    if (this._assetClassesMixSubscription !== undefined)
      this._assetClassesMixSubscription.unsubscribe();
    if (this._apiServiceSubscription !== undefined)
      this._apiServiceSubscription.unsubscribe();
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._summarySwitchSubscription !== undefined)
      this._summarySwitchSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
    if (this._apiFetchSubscription !== undefined)
      this._apiFetchSubscription.unsubscribe();
    if (this._apiBondRiskFetchSubscription !== undefined)
      this._apiBondRiskFetchSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.returnTypeGroupInstance.value = this.returnTypesOptions[0];
    //* When there is data from the API, the weighting option change triggers an API request.
    //* Do this only when the values is not being changed for the first time(on setup)
    this.weightRadioGroupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });

    this.returnTypeGroupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });
    this.benchmarksLookupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });

    this.benchmarksLookupInstance.displayExpr = "Name";
    this.benchmarksLookupInstance.valueExpr = "Id";
    this.benchmarksLookupInstance.title = "Benchmarks";
    // this.benchmarksLookupInstance.onOpened()

    /**Set the default start and end date */
    this._appSettingsServiceSubscription = this.appSettingsService
      .GetSettings()
      .subscribe((settings) => {
        this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
        this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
    /**Update end date minumum value on start date change */
    this._startDateChangeSubscription =
      this.startDateBoxInstance.onValueChanged.subscribe((newDateChange) => {
        let dateValue = newDateChange.value;
        this.endDateBoxInstance.min = dateValue;
      });

    this.assetCategoriesPanelInstance.onSelectionChanged.subscribe((res) => {
      this.SelectedModelAnalyticIds = [];
      this.SelectedBondRiskIds = [];
      this.DisplayModelCategoryResults.next(null);

      let assetCategorySelected: TestInstrumentCategory = res.addedItems[0];
      this.ModelGroupItems = new Array<ModelGroupItem>();
      this.synchronizeAssetCategoryConfig(assetCategorySelected);
    });
    /**setup funds tree list options */
    // this.fundsList.showSelectionControls = true;
    // this.fundsList.selectionMode = "all";
    // this.fundsList.pageLoadMode = "scrollBottom";
    // this.fundsList.displayExpr = "Name";
    // this.fundsList.keyExpr = "ID";
  }

  /**Get the models in each asset category */
  public synchronizeAssetCategoryConfig(
    selectedAssetCategory: TestInstrumentCategory
  ) {
    let urlToGetModels = "";
    if (selectedAssetCategory.text === "Total Fund") {
      // this.benchmarksLookupInstance.disabled = true
      urlToGetModels = "analytics/ModelsCommonToAllInstrumentCategories";
      this.returnTypeGroupInstance.items = this.NAVOnTotalFund;
      this.returnTypeGroupInstance.value = this.NAVOnTotalFund[0];

      this.weightRadioGroupInstance.items = ["True"];
      this.weightRadioGroupInstance.value = "True";

      this.benchmarksLookupInstance.value =
        "12FEC142-0A40-E511-9C2F-B8CA3AFA18DD";
    } else {
      this.returnTypeGroupInstance.items = this.returnTypesOptions;
      this.returnTypeGroupInstance.value = this.returnTypesOptions[1];

      this.weightRadioGroupInstance.items = this.weightingOptions;
      //this.weightRadioGroupInstance.value = this.weightingOptions[0];

      this.benchmarksLookupInstance.disabled = false;
      // ! not sure. confirm this
      this.weightRadioGroupInstance.value =
        selectedAssetCategory.defaultWeighting;

      this.apiService
        .Get(
          `analyticssetup/instrumentcategorybenchmarks?instrumentcategorycode=${selectedAssetCategory.code}`
        )
        .subscribe((benchmarkResults) => {
          let allBenchmarks = benchmarkResults.Benchmarks;
          this.benchmarksLookupInstance.dataSource = allBenchmarks;
          let defaultBenchmark = benchmarkResults.DefaultBenchmark;
          this.benchmarksLookupInstance.value = defaultBenchmark.Id;
        });
      urlToGetModels = `analytics/NonPortfolioModels?instrumentCategoryId=${selectedAssetCategory.id}`;
    }

    this.apiService
      .Get(urlToGetModels)
      .subscribe((modelGroups: Array<ModelGroup>) => {
        if (selectedAssetCategory.code !== "listed equities") {
          modelGroups = modelGroups.filter(
            (x) => x.Name !== "Holdings Analysis Measures"
          ); //!requested to remove this by calvin and steve on 29 oct 2020
        }
        if (selectedAssetCategory.text === "Total Fund") {
          modelGroups = modelGroups.filter(
            (x) => x.Name !== "Bond Risk Analysis"
          );
        }

        modelGroups = modelGroups.filter(
          (x) => x.Name !== "Correlation Regression Analysis"
        ); //!requested to remove this by calvin and steve on 29 oct 2020
        modelGroups = modelGroups.filter(
          (x) => x.Name !== "Composite Return Measures"
        ); //!requested to remove this by calvin and steve on 29 oct 2020
        // modelGroups = modelGroups.filter(x => x.Name !== 'Bond Risk Analysis'); //!requested to remove this by calvin and steve on 29 oct 2020

        modelGroups.forEach((modelGroup) => {
          // todo To use this index to work on colors
          let modelItems: Array<ModelItem> = new Array<ModelItem>();
          let groupName = modelGroup.Name;
          let models = modelGroup.Models;

          models.forEach((model) => {
            if (model.Name === "Jarque-bera") {
              //!requested to remove this by calvin and steve on 29 oct 2020
              return;
            }

            let modelCode = model.Code;
            let modelName = model.Name;
            let modelVariables = model.Variables;
            let modelUniqueId = model.ModelId; // ! use this to refer to the model. This is the id saved in the database

            // *  e.g when you select arithmetic mean - pass 'price' and 'returns'- The system is parameter driven*/
            let modelVariableCodes = modelVariables.map(
              (modelVariable) => modelVariable.Code
            );

            let newModelObject: ModelItem = {
              ID: modelUniqueId,
              Head_ID: 0,
              ModelName: modelName,
              ModelValue: modelCode,
              ModelGroupName: groupName,
              ParentModelName: "",
              ModelClassification: ModelClassification.ParentModel,
              UniqueModelId: modelUniqueId,
              ModelParameters: modelVariableCodes,
            };
            modelItems.push(newModelObject);
          });

          try {
            let modelGroupItem = new ModelGroupItem();
            modelGroupItem.ModelGroupName = groupName;
            modelGroupItem.ModelItems = modelItems;
            this.ModelGroupItems.push(modelGroupItem);
          } catch (error) {
            console.error(error);
          }
        });
      });
  }

  /**Update models selection as it happens in portfolio-models-views component */
  modelsSelectionUpdate(eventData: Array<any>) {
    //map by id

    if (eventData.length == 0) {
      this.SelectedModelAnalyticIds = new Array<any>();
    } else {
      //Selected Model Analytics ID's
      //filter bond risk id's by modelgroupname
      let selectedModelAnalytics = eventData.filter((x) => {
        if (x.ModelGroupName !== "Bond Risk Analysis") {
          return x;
        }
      });
      //map the selected model Analtics Ids if the array is not empty
      if (selectedModelAnalytics.length > 0) {
        let selectedModelsIds = selectedModelAnalytics.map((x) => x.ID);
        this.SelectedModelAnalyticIds = selectedModelsIds;
      } else {
        this.SelectedModelAnalyticIds = [];
      }

      //Selected Bond Risk ID's
      //filter bond risk id's by modelgroupname
      let selectedBondRisk = eventData.filter((x) => {
        if (x.ModelGroupName == "Bond Risk Analysis") {
          return x;
        }
      });
      //map the selected Bond risks if the array is not empty
      if (selectedBondRisk.length > 0) {
        //map the selected bond Risk Ids
        let selectedBondRiskId = selectedBondRisk.map((x) => x.ID);
        this.SelectedBondRiskIds = selectedBondRiskId;
      } else {
        this.SelectedBondRiskIds = [];
      }
    }

    //Selected Bond Risk ID's

    // if (eventData.length == 0) {
    //   this.SelectedModelAnalyticIds = new Array<any>();
    // } else {
    //   this.SelectedModelAnalyticIds = new Array<any>();
    //   this.SelectedModelAnalyticIds = eventData;
    // }
  }

  fetchResults() {
    if (
      this.PortfolioRequestObject.FundManagers == null ||
      this.PortfolioRequestObject.Funds == null
    ) {
      //this.notificationService.ShowWarningNotification('Select Fund Manager/Funds')
      return;
    }
    if (
      this.SelectedModelAnalyticIds.length == 0 &&
      this.SelectedBondRiskIds.length == 0
    ) {
      //this.notificationService.ShowWarningNotification('Select atleast one Model')
      return;
    }
    let requestStartDate = this.startDateBoxInstance.value;
    let requestEndDate = this.endDateBoxInstance.value;
    let payload = {};
    let bondRiskPayload = {};

    // ! It could be performance attribution on asset category specific request. For performance attribution, this will be different

    let selectedAssetCategory: TestInstrumentCategory =
      this.assetCategoriesPanelInstance.selectedItem;
    let returnTypeValueObject = this.returnTypeGroupInstance.value;
    let actualReturnTypeValue = returnTypeValueObject.returnTypeValue;
    let result: Array<any> = [];

    payload = {
      FundManagers: this.PortfolioRequestObject.FundManagers,
      StartDate: requestStartDate,
      EndDate: requestEndDate,
      Models: this.SelectedModelAnalyticIds,
      Weighted: this.weightRadioGroupInstance.value,
      BenchmarkId: this.benchmarksLookupInstance.value,
      AnalysisLevel: selectedAssetCategory.analysisLevel,
      InstrumentCategoryCode: selectedAssetCategory.code,
      ReturnType: actualReturnTypeValue,
      TenantId: this.PortfolioRequestObject.TenantId,
    };
    bondRiskPayload = {
      FundManagers: this.PortfolioRequestObject.FundManagers,
      StartDate: requestEndDate,
      EndDate: requestEndDate,
      InstrumentCategoryCode: selectedAssetCategory.code,
      Funds: this.PortfolioRequestObject.Funds,
      Models: this.SelectedBondRiskIds,
      YieldChange: 0.02,
      Weighted: this.weightRadioGroupInstance.value,
      TenantId: this.PortfolioRequestObject.TenantId,
    };
    if (
      this.SelectedModelAnalyticIds.length > 0 &&
      this.SelectedBondRiskIds.length == 0
    ) {
      this._apiFetchSubscription = this.apiService
        .Post("analytics/portfolioanalytics", payload)
        .subscribe((res: PortfolioAnalyticsRootObject) => {
          this.closeAnalyticsFetchRequest();
          this.localStorageService.setItem("portfolio-analysis", res.Data);
          result = this.ProcessPortfolioAnalyticsRequestAPI(res);
          this.DisplayModelCategoryResults.next(result);
        });
    } else if (
      this.SelectedModelAnalyticIds.length == 0 &&
      this.SelectedBondRiskIds.length > 0
    ) {
      this._apiBondRiskFetchSubscription = this.apiService
        .Post("analytics/bondriskanalysis", bondRiskPayload)
        .subscribe((portfolioBondRiskAnalysisResponse: any) => {
          this.closeBondRiskFetchRequest();
          this.localStorageService.setItem(
            "portfolio-analysis",
            portfolioBondRiskAnalysisResponse.Data
          );
          let resultBondRisk: Array<any> =
            this.ProcessPortfolioRiskBondAnalysisRequestAPI(
              portfolioBondRiskAnalysisResponse
            );
          //get the object, push it to the datasource
          let resultBondRiskObject = resultBondRisk[0];
          result.push(resultBondRiskObject);
          this.DisplayModelCategoryResults.next(result);
        });
    } else if (
      this.SelectedModelAnalyticIds.length > 0 &&
      this.SelectedBondRiskIds.length > 0
    ) {
      //request analytics
      this._apiFetchSubscription = this.apiService
        .Post("analytics/portfolioanalytics", payload)
        .subscribe((res: PortfolioAnalyticsRootObject) => {
          this.closeAnalyticsFetchRequest();
          result = this.ProcessPortfolioAnalyticsRequestAPI(res);

          //request bondrisk
          this._apiBondRiskFetchSubscription = this.apiService
            .Post("analytics/bondriskanalysis", bondRiskPayload)
            .subscribe((portfolioBondRiskAnalysisResponse: any) => {
              this.closeBondRiskFetchRequest();
              let resultBondRisk: Array<any> =
                this.ProcessPortfolioRiskBondAnalysisRequestAPI(
                  portfolioBondRiskAnalysisResponse
                );
              //get the object, push it to the datasource
              let resultBondRiskObject = resultBondRisk[0];
              result.push(resultBondRiskObject);
              this.localStorageService.setItem(
                "portfolio-analysis",
                resultBondRiskObject
              );

              this.DisplayModelCategoryResults.next(result);
            });
        });
    }
  }

  ProcessPortfolioAnalyticsRequestAPI(res: PortfolioAnalyticsRootObject) {
    let PortfolioModelGroupsResultsInternal: PortfolioModelGroupsResults[] = [];

    if (res.Successful == false) {
      //this.notificationService.ShowErrorNotification(res.ErrorMessage);
    } else {
      let response = res.Data;
      // grouped by model category
      let modelCategories: Array<any> = [];
      response.forEach((element) => {
        let portfolioAnalysisResult = element.PortfolioAnalysisResult;
        let fundManager = element.FundManager;
        portfolioAnalysisResult.forEach((x) => {
          let modelCategory = x.ModelCategory;
          let modelCategoryResults = x.ModelCategoryResults;
          modelCategoryResults.forEach((element) => {
            element.FundManager = fundManager;
          });
          let results = modelCategories.filter((x) => {
            if (x.ModelCategory === modelCategory) {
              return x;
            }
          });

          let selectedModelCategory: any;
          if (results.length > 0) {
            selectedModelCategory = results[0];
          }
          if (!selectedModelCategory) {
            selectedModelCategory = x;
            modelCategories.push(selectedModelCategory);
          } else {
            modelCategoryResults.forEach((element) => {
              selectedModelCategory.ModelCategoryResults.push(element);
            });
          }
        });
      });

      let selectedAssetCategoryName =
        this.assetCategoriesPanelInstance.selectedItem.code;
      //this is for each model category - contains more than on investor
      modelCategories.forEach((x, modelGroupIndex) => {
        let _summaryColumnNames = [];
        let finalColumnDefinitions: Column[] = [
          {
            caption: "FundManager",
            dataField: "FundManager",
            groupIndex: 0,
            allowSorting: true,
            dataType: "string",
            cssClass: "performance-attr-grid-focus-2",
            sortOrder: "asc",
          },
          {
            caption: "Fund",
            dataField: "Fund",
            groupIndex: 1,
            allowSorting: true,
            dataType: "string",
            cssClass: "performance-attr-grid-focus-2",
            sortOrder: "asc",
          },
          {
            caption: "Security",
            sortIndex: 1,
            allowSorting: true,
            sortOrder: "asc",
            dataField: "Security",
            cssClass: "performance-attr-grid-focus-3",
            width: "250",
          },
        ];
        if (selectedAssetCategoryName !== "listed equities") {
          finalColumnDefinitions.push({
            caption: "Tenor",
            dataField: "Tenor",
            dataType: "string",
            cssClass: "performance-attr-grid-focus-2",
          });
        } else {
          finalColumnDefinitions.push({
            caption: "Sector",
            dataField: "Sector",
            dataType: "string",
            cssClass: "performance-attr-grid-focus-2",
            groupIndex: 2,
            minWidth: 200,
          });
        }

        if (selectedAssetCategoryName !== "listed equities") {
          finalColumnDefinitions.push({
            caption: "Time To Maturity",
            dataField: "TimeToMaturity",
            dataType: "number",
            width: "100px",
            minWidth: 100,
            cssClass: "performance-attr-grid-focus-1",
            calculateCellValue: (rowData) =>
              this.calculateNonPercentageValue(rowData["TimeToMaturity"]),
          });
        }

        let modelCategory = x.ModelCategory;
        let modelCategoryResults = x.ModelCategoryResults;
        //! Row thing okay
        let firstResult = modelCategoryResults[0];
        let firstModelResult = firstResult.ModelResults[0];
        if (firstModelResult == undefined) {
          this.notificationService.ShowSuccessNotification(
            "No result could be found for the specified period and fund(s)"
          );
          return;
        }
        firstModelResult.ModelsResults.forEach((element) => {
          // for each modelCategories, get its name so as to be used in summary
          let modelNameWithSpaces = element.Name;
          _summaryColumnNames.push(modelNameWithSpaces);
          //remove the white spaces
          let modelColumnName: Column = {
            caption: modelNameWithSpaces,
            dataField: `${modelNameWithSpaces}`,
            dataType: "number",
            cssClass: "performance-attr-grid-focus-5",
            calculateCellValue: (rowData) =>
              this.calculateNonPercentageValue(rowData[modelNameWithSpaces]),
          };
          finalColumnDefinitions.push(modelColumnName);
        });
        //! Row thing okay
        let resultObject: PortfolioModelGroupsResults;
        let finalDataSourceObjectArray = [];
        //each fund in the result - we need a row for each fund
        modelCategoryResults.forEach((fundResult) => {
          let fundName = fundResult.Fund;
          let fundManager = fundResult.FundManager;
          let fundModelResults = fundResult.ModelResults;
          let modelCategoryResult: PortfolioAnalyticsFundModelResult[] = [];
          //each fund might have multiple asset classes - this are the rows of data we are looking for
          fundModelResults.forEach((element) => {
            let security = element.Security;
            let securityLowerCase = security;
            let modelResultItems = element.ModelsResults;
            let sector = element.Sector;
            let tenorValue = element.Tenor;
            let timeToMaturity = element.TimeToMaturity;
            modelResultItems.forEach((el) => {
              let name = el.Name;
              let value = el.Value;
              modelCategoryResult.push({
                Security: securityLowerCase,
                ModelName: name,
                ModelValue: value,
                FundName: fundName,
                FundManager: fundManager,
                ModelCategory: modelCategory,
                Sector: sector,
                Tenor: tenorValue,
                TimeToMaturity: timeToMaturity,
              });
            });
          });

          let groupedModelsBySecurity: Array<any> = _.chain(modelCategoryResult)
            .groupBy("Security")
            .map((value, key) => {
              return {
                Security: key,
                SecurityModelValues: value,
                Sector: value[0].Sector,
                Tenor: value[0].Tenor,
                TimeToMaturity: value[0].TimeToMaturity,
              };
            })
            .value();

          groupedModelsBySecurity.forEach((x) => {
            let finalDataSourceObject = {};
            let securityName = x.Security;
            Object.defineProperty(finalDataSourceObject, "Security", {
              value: securityName,
              writable: true,
            });
            Object.defineProperty(finalDataSourceObject, "Sector", {
              value: x.Sector,
              writable: true,
            });
            Object.defineProperty(finalDataSourceObject, "Tenor", {
              value: x.Tenor,
              writable: true,
            });
            Object.defineProperty(finalDataSourceObject, "TimeToMaturity", {
              value: x.TimeToMaturity + "yrs",
              writable: true,
            });
            let sectorModelValues = x.SecurityModelValues;
            //group this model values by security

            let groupedModelDataBySecurity: Array<any> = _.chain(
              sectorModelValues
            )
              .groupBy("Security")
              .map((value, key) => {
                return {
                  Security: key,
                  ModelValue: value,
                };
              })
              .value();
            groupedModelDataBySecurity.forEach((securityGroup) => {
              let securityName = securityGroup.Security;
              let fundNameObj = fundName;
              let fundManagerObj = fundManager;

              Object.defineProperty(finalDataSourceObject, "Fund", {
                value: fundNameObj,
                writable: true,
              });
              Object.defineProperty(finalDataSourceObject, "FundManager", {
                value: fundManagerObj,
                writable: true,
              });
              let securityNameObj = securityName;
              Object.defineProperty(finalDataSourceObject, "Security", {
                value: securityNameObj,
                writable: true,
              });

              //this is a security at a time with the associated models
              let modelValue: PortfolioAnalyticsFundModelResult[] =
                securityGroup.ModelValue;

              modelValue.forEach((model) => {
                let modelname = model.ModelName;
                let modelValue = model.ModelValue;

                Object.defineProperty(finalDataSourceObject, modelname, {
                  value: modelValue,
                  writable: true,
                });
              });
            });
            finalDataSourceObjectArray.push(finalDataSourceObject);
          });
        });

        resultObject = {
          modelCategory: modelCategory,
          columns: finalColumnDefinitions,
          dataSource: finalDataSourceObjectArray,
          summaryColumnNames: _summaryColumnNames,
        };
        PortfolioModelGroupsResultsInternal.push(resultObject);
      });
    }
    return PortfolioModelGroupsResultsInternal;
  }

  calculateNonPercentageValue(rowData: any) {
    let nonpercentageDecimalPlaces =
      this.systemSettings.NonPercentageValuesDecimalPoints;

    return Number.parseFloat(rowData).toFixed(nonpercentageDecimalPlaces);
  }

  ProcessPortfolioRiskBondAnalysisRequestAPI(
    portfolioBondRiskAnalysisResponse: any
  ) {
    let PortfolioModelGroupsResultsInternal: PortfolioModelGroupsResults[] = [];
    if (portfolioBondRiskAnalysisResponse.Successful == false) {
      this.notificationService.ShowErrorNotification(
        portfolioBondRiskAnalysisResponse.ErrorMessage
      );
    } else {
      let containsData: boolean = this.ContainsSecurityBondRiskData(
        portfolioBondRiskAnalysisResponse
      );

      if (containsData) {
        let portfolioBondRiskAnalysisDataSource: Array<any> = [];
        let dataSourceObject = {};
        let resultObject: PortfolioModelGroupsResults;

        portfolioBondRiskAnalysisResponse.forEach((element) => {
          let _summaryColumnNames = [];
          let portfolioBondRiskColumn: Column[] = [
            {
              caption: "Fund Manager",
              dataField: "FundManager",
              allowSorting: true,
              sortOrder: "asc",
              dataType: "string",
              cssClass: "performance-attr-grid-focus-2",
              groupIndex: 0,
            },
            {
              caption: "Fund",
              dataField: "Fund",
              allowSorting: true,
              sortOrder: "asc",
              dataType: "string",
              cssClass: "performance-attr-grid-focus-2",
              groupIndex: 1,
            },
            {
              caption: "Security",
              dataField: "Security",
              dataType: "string",
              cssClass: "performance-attr-grid-focus-1",
            },
            {
              caption: "Tenor",
              dataField: "TenorRange",
              dataType: "string",
              cssClass: "performance-attr-grid-focus-3",
            },
            {
              caption: "Time To Maturity",
              dataField: "TimeToMaturity",
              dataType: "number",
              cssClass: "performance-attr-grid-focus-1",
              calculateCellValue: (rowData) =>
                this.calculateNonPercentageValue(rowData["TimeToMaturity"]),
            },
            {
              caption: "Date",
              dataField: "Date",
              dataType: "date",
              groupIndex: 1,
              sortOrder: "asc",
              format: "dd-MMM-yyyy",
            },
          ];
          let securityBondRiskData = element.SecuritiesBondRiskAnalysis;

          let firstsecurityBondRiskData = securityBondRiskData[0];

          let firstmodelCategorizationsDataResult =
            firstsecurityBondRiskData.ModelCategorizations;

          if (firstmodelCategorizationsDataResult == undefined) {
            this.notificationService.ShowSuccessNotification(
              "No result could be found for the specified period and fund(s)"
            );
            return;
          }

          firstmodelCategorizationsDataResult.forEach((x) => {
            let modelName = x.Model;
            _summaryColumnNames.push(modelName);
            // let modelData: Array<ModelData> = x.ModelData;
            let columnName: Column = {
              caption: modelName,
              dataField: `${modelName}`,
              dataType: "number",
              cssClass: "performance-attr-grid-focus-5",
              calculateCellValue: (rowData) =>
                this.calculateNonPercentageValue(rowData[modelName]),
            };
            portfolioBondRiskColumn.push(columnName);
          });

          //! Row Data
          //let resultObject: PortfolioModelGroupsResults;
          dataSourceObject = {};
          let tenorRange = "";
          let timeToMaturity = NaN;
          let date = "";
          let value = NaN;
          let model = "";
          let security = "";
          let fund = element.Fund;
          let fundManager = element.FundManager;

          //SecuritiesBondRiskAnalysis Section
          let securitiesBondRiskAnalysis: Array<SecuritiesBondRiskAnalysis> =
            element.SecuritiesBondRiskAnalysis;

          securitiesBondRiskAnalysis.forEach((element) => {
            security = element.Security;

            //Model Categorization Section
            let modelCategorization: Array<ModelCategorizations> =
              element.ModelCategorizations;

            modelCategorization.forEach((element) => {
              model = element.Model;

              //Date Value Section
              let modelData: Array<ModelData> = element.ModelData;

              modelData.forEach((element) => {
                tenorRange = element.TenorRange;
                timeToMaturity = element.TimeToMaturity;
                date = element.Date;
                value = element.Value;

                dataSourceObject = {
                  Fund: fund,
                  FundManager: fundManager,
                  Security: security,
                  TenorRange: tenorRange + "yrs",
                  TimeToMaturity: timeToMaturity,
                  Date: date,
                  [model]: value,
                };
                portfolioBondRiskAnalysisDataSource.push(dataSourceObject);
              });
            });
          });
          let groupingModelByDate: Array<any> = [];
          let groupingModelByDateFund: Array<any> = [];
          let groupingModelByDateFundSecurity: Array<any> = [];
          let groupingModelByDateFundSecurityFinal: Array<any> = [];
          let modelOutputData: Array<any> = [];
          let modelDataSourceArray: Array<any> = [];
          let finalModelDataSourceArray: Array<any> = [];

          let groupedModelByFund = _.mapObject(
            _.groupBy(portfolioBondRiskAnalysisDataSource, "Fund"),
            (clist) => clist.map((resultsData) => _.omit(resultsData, "Fund"))
          );

          //loop the new object
          for (const [key, value] of Object.entries(groupedModelByFund)) {
            //group by Date
            let groupedModelByFund = _.mapObject(
              _.groupBy(value, "Date"),
              (clist) => clist.map((resultsData) => _.omit(resultsData, "Date"))
            );
            //push the new object into an array
            groupingModelByDate.push({
              Fund: key,
              groupingByDate: groupedModelByFund,
            });

            groupingModelByDate.forEach((element) => {
              let fund = element.Fund;
              let groupingByDateData = element.groupingByDate;
              //loop through grouping by Date
              for (const [key, value] of Object.entries(groupingByDateData)) {
                groupingModelByDateFund.push({
                  Fund: fund,
                  groupingByDate: [{ date: key, SecurityData: value }],
                });
              }
            });
          }
          //group by security
          groupingModelByDateFund.forEach((element) => {
            let fund = element.Fund;
            let groupingByDateData = element.groupingByDate;
            groupingByDateData.forEach((element) => {
              let date = element.date;
              let securityData = element.SecurityData;
              //group by security -Need to loop here
              let groupedModelBySecurity = _.mapObject(
                _.groupBy(securityData, "Security"),
                (clist) =>
                  clist.map((securityData) => _.omit(securityData, "Security"))
              );
              groupingModelByDateFundSecurity.push({
                Fund: fund,
                groupingByDate: [
                  { date: date, groupingBySecurity: groupedModelBySecurity },
                ],
              });
            });
          });
          //loop the groupedBySecurity
          groupingModelByDateFundSecurity.forEach((element) => {
            let fund = element.Fund;
            let groupingByDate = element.groupingByDate;
            groupingByDate.forEach((element) => {
              let date = element.date;
              let securityGrouping = element.groupingBySecurity;
              //loop the security grouping
              for (const [key, value] of Object.entries(securityGrouping)) {
                groupingModelByDateFundSecurityFinal.push({
                  Fund: fund,
                  groupingByDate: [
                    {
                      date: date,
                      groupingBySecurity: [{ security: key, models: value }],
                    },
                  ],
                });
              }
            });
          });
          //finally loop and store the data
          groupingModelByDateFundSecurityFinal.forEach((element) => {
            let dateSecurityFundDataSource = {};
            let finalDataSourceObject = {};
            let fund = element.Fund;
            let groupingByDate = element.groupingByDate;
            groupingByDate.forEach((element) => {
              let date = element.date;
              let groupingBySecurity = element.groupingBySecurity;
              groupingBySecurity.forEach((element) => {
                let security = element.security;
                let modelData = element.models;
                //Merge model Data
                let mergeModelData = modelData.reduce(
                  (r, c) => Object.assign(r, c),
                  {}
                );
                //push the date, security and fund
                dateSecurityFundDataSource = {
                  Fund: fund,
                  Security: security,
                  Date: date,
                };
                //merge the two
                finalDataSourceObject = {
                  ...dateSecurityFundDataSource,
                  ...mergeModelData,
                };
                modelDataSourceArray.push(finalDataSourceObject);
              });
            });
          });

          //fix duplication issue
          let sortedArray = _.uniq(
            _.collect(modelDataSourceArray, function (finalArray) {
              return JSON.stringify(finalArray);
            })
          );
          // //parse final Array back to Json
          sortedArray.forEach((element) => {
            let removeStrings = JSON.parse(element);
            finalModelDataSourceArray.push(removeStrings);
          });
          //let finalfinalArray = JSON.parse(finalArray);

          resultObject = {
            modelCategory: "Bond Risk Analysis",
            columns: portfolioBondRiskColumn,
            dataSource: finalModelDataSourceArray,
            summaryColumnNames: _summaryColumnNames,
          };
        });

        PortfolioModelGroupsResultsInternal.push(resultObject);

        return PortfolioModelGroupsResultsInternal;
      } else {
        this.notificationService.ShowSuccessNotification(
          "No result could be found for the specified period and fund(s)"
        );
        return;
      }
    }
  }

  ContainsSecurityBondRiskData(data: any) {
    if (typeof data === "object") {
      let values = data[0].SecuritiesBondRiskAnalysis;
      if (values.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "date") {
      columnDataEntry.format = "dd MMM yyyy";
    }
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          let formattedNumber = this.numberWithCommas(value);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.systemSettings.NonPercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
      }
    }
  }

  customizeSummaryText(data) {
    let normalInString = localStorage.getItem("nrml-decPoints");
    let NonPercentageValuesDecimalPoints = Number.parseInt(normalInString);

    let value = data.value;

    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let numberWithDecimalPoints = absoluteValue.toFixed(
        NonPercentageValuesDecimalPoints
      );

      return `(${numberWithDecimalPoints})`;
    } else {
      let numberWithDecimalPoints = value.toFixed(
        NonPercentageValuesDecimalPoints
      );
      return numberWithDecimalPoints;
    }
  }

  ModelGroupsItemsSelected(event) {
    this.PortfolioRequestObject.Funds = [];
    this.PortfolioRequestObject.FundManagers = [];
    if (event.selectedRowsData.length !== 0) {
      //let selectedRowsData = event.selectedRowsData;
      this.fundManagerTree.instance
        .getSelectedRowsData("includeRecursive")
        .forEach((selectedRowsData) => {
          if (selectedRowsData.Head_ID === 0) {
          } else {
            const fundId = selectedRowsData.ID;
            const fundManagerId = selectedRowsData.Head_ID;
            const selectedTenantId = selectedRowsData.TenantId;

            let results = this.PortfolioRequestObject.FundManagers.filter(
              (x) => {
                if (x.Id === fundManagerId) {
                  return x;
                }
              }
            );
            let selectedFundManager: any;
            if (results.length > 0) {
              selectedFundManager = results[0];
            }

            if (!selectedFundManager) {
              selectedFundManager = {
                Id: fundManagerId,
                TenantId: selectedTenantId,
                Funds: [fundId],
              };
              this.PortfolioRequestObject.FundManagers.push(
                selectedFundManager
              );
            } else {
              selectedFundManager.Funds.push(fundId);
            }
          }
        });
    }
  }
  /**
   * Closes the request to fetch results from the API and changes the refresh button from cancel to refresh
   */
  closeAnalyticsFetchRequest() {
    if (this._apiFetchSubscription !== undefined) {
      this._apiFetchSubscription.unsubscribe();
      this._apiFetchSubscription = undefined;
    }
  }

  closeBondRiskFetchRequest() {
    if (this._apiBondRiskFetchSubscription !== undefined) {
      this._apiBondRiskFetchSubscription.unsubscribe();
      this._apiBondRiskFetchSubscription = undefined;
    }
  }

  IncrementGridId() {}
  //https://supportcenter.devexpress.com/ticket/details/t670833/tabpanel-justified-tabs
  onContentReady(e) {
    setTimeout(() => {
      var widthOfOneTab = 140 / e.component.option("items").length;
      var tabs = e.element.querySelectorAll(".dx-tab");
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].style.width = widthOfOneTab + "%";
      }
    });
  }

  //This is to fix the migAlignment bug
  //On tab selection change refresh the grids

  refreshGrid(event) {
    this.dataGrids.toArray().forEach((x) => {
      x.instance.refresh();
    });
  }
}
