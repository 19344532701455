import { Component, OnInit, ViewChild } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { arrayContains } from "devexpress-dashboard/data/_utils";
import {
  DxListComponent,
  DxTabsComponent,
  DxFormComponent,
  DxDateBoxComponent,
  DxTabPanelComponent,
  DxLookupComponent,
  DxDataGridComponent,
  DxRadioGroupComponent,
} from "devextreme-angular";
import { Column } from "devextreme/ui/data_grid";
import { Subject, Subscription } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import _ from "underscore";
import { result } from "underscore";
import {
  TestInstrumentCategory,
  ModelGroupItem,
  ModelGroup,
  ModelItem,
  ModelClassification,
} from "../app-components/non-portfolio/non-portfolio-analytics/helper-classes";
import { PortfolioModelGroupsResults } from "../app-components/portfolio-analytics/response-types";

import {
  ModelCategorizations,
  ModelData,
  SecuritiesBondRiskAnalysis,
} from "./data-objects";

@Component({
  selector: "non-portfolio-bond-risk-analysis",
  templateUrl: "./non-portfolio-bond-risk-analysis.component.html",
  styleUrls: ["./non-portfolio-bond-risk-analysis.component.scss"],
})
export class NonPortfolioBondRiskAnalysisComponent implements OnInit {
  @ViewChild(DxListComponent, { static: false }) fundsList: DxListComponent;
  @ViewChild("assetClassesTab", { static: false })
  AssetClassesTabInstance: DxTabsComponent;
  @ViewChild("modelsForm", { static: false })
  modelFormInstance: DxFormComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("assetCategoriesPanel", { static: false })
  assetCategoriesPanelInstance: DxTabPanelComponent;
  @ViewChild("modelsTabPanel", { static: false })
  modelsTabPanel: DxTabPanelComponent;
  @ViewChild("benchmarksLookup", { static: false })
  benchmarksLookupInstance: DxLookupComponent;
  @ViewChild("resultsDataGrid", { static: true })
  resultsDataGrid: DxDataGridComponent;
  @ViewChild("weightRadioGroup", { static: false })
  weightRadioGroupInstance: DxRadioGroupComponent;
  @ViewChild("returnTypeGroup", { static: false })
  returnTypeGroupInstance: DxRadioGroupComponent;

  @ViewChild("refreshButton", { static: false })
  refreshButtonInstance: MatButton;

  private _appSettingsServiceSubscription: Subscription;
  private _startDateBoxInstanceSubscription: Subscription;
  private _endDateBoxInstanceSubscription: Subscription;
  private _investorLookupSubscription: Subscription;
  private _assetClassesMixSubscription: Subscription;
  private _apiServiceSubscription: Subscription;
  private _modelsSubscription: Subscription;
  private _investorsSubscription: Subscription;
  private _summarySwitchSubscription: Subscription;
  private _startDateChangeSubscription: Subscription;
  public _apiFetchSubscription: Subscription;

  assetCategoryTabsSubject: Subject<
    Array<TestInstrumentCategory>
  > = new Subject<Array<TestInstrumentCategory>>();
  assetCategoryTabsObservable = this.assetCategoryTabsSubject.asObservable();
  // gridDataObservable
  gridDataObservable: Subject<any> = new Subject<any>();
  gridDataObservableData = this.gridDataObservable.asObservable();

  assetCatBenchmarkSubject: Subject<any> = new Subject<any>();
  assetCatBenchmarkObservable = this.assetCatBenchmarkSubject.asObservable();
  DisplayModelCategoryResults: Subject<
    Array<PortfolioModelGroupsResults>
  > = new Subject<Array<PortfolioModelGroupsResults>>();
  DisplayModelCategoryResultsObservable = this.DisplayModelCategoryResults.asObservable();

  ModelGroupItems: Array<any> = new Array<any>();
  SelectedModelsIds: Array<any> = new Array<any>();
  weightingOptions = [true, false];
  returnTypesOptions = [
    {
      text: "Price Based Return",
      helpInformation:
        "This return is based on the asset/security price. e.g. the return computed on the non-portfolio side uses the asset returns.",
      returnTypeValue: "assetbasedreturn",
    },
    {
      text: "Net Return (NAV)",
      helpInformation:
        "Unit based return on Net Asset Value NAV – actual performance as computed by Innova (Using Net Units)",
      returnTypeValue: "netassetvalue",
    },
  ];

  NAVOnTotalFund = [
    {
      text: "Net Return (NAV)",
      helpInformation:
        "Unit based return on Net Asset Value NAV – actual performance as computed by Innova (Using Net Units)",
      returnTypeValue: "netassetvalue",
    },
  ];

  gridKeyExpression = ["Fund", "Security"];

  constructor(
    private apiService: ApiService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public appSettingsService: ApplicationSettingsService,
    public notificationService: SnackNotificationsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Non Portfolio Bond Risk Analysis"
    );
  }

  /**Update models selection as it happens in portfolio-models-views component */
  modelsSelectionUpdate(eventData: Array<any>) {
    if (eventData.length == 0) {
      this.SelectedModelsIds = new Array<any>();
    } else {
      this.SelectedModelsIds = new Array<any>();
      this.SelectedModelsIds = eventData;
    }
  }

  gridInstanceCellPrepared(event) {}
  customizeSummaryText(data) {}

  ngOnInit() {
    this._apiServiceSubscription = this.apiService
      .Get("Investors")
      .subscribe((res) => {
        this.fundsList.dataSource = res;
      });

    this.apiService
      .Get("analyticssetup/GetAllInstrumentCategories")
      .subscribe((instrumentCategories: Array<any>) => {
        let assetCats = instrumentCategories.filter((instrumentCategory) => {
          if (
            instrumentCategory.Name == "Fixed Corporate Bonds" ||
            instrumentCategory.Name == "Fixed Treasury Bonds"
          ) {
            return instrumentCategory;
          }
        });

        let filteredAssetCats: TestInstrumentCategory[] = assetCats.map(
          (instrumentCategory) => {
            let category = new TestInstrumentCategory();
            category.id = instrumentCategory.Id;
            category.text = instrumentCategory.Name;
            category.code = instrumentCategory.Code;
            category.icon = "reorder";
            category.content = instrumentCategory.Name + " Asset Category";
            category.iconStyle = { color: "black" };
            category.analysisLevel = "asset level";

            return category;
          }
        );
        this.assetCategoryTabsSubject.next(filteredAssetCats);
      });
  }
  ngAfterViewInit(): void {
    /**setup funds tree list options */
    this.fundsList.showSelectionControls = true;
    this.fundsList.selectionMode = "all";
    this.fundsList.pageLoadMode = "scrollBottom";
    this.fundsList.displayExpr = "Name";
    this.fundsList.keyExpr = "ID";

    this.assetCategoriesPanelInstance.onSelectionChanged.subscribe((res) => {
      let assetCategorySelected: TestInstrumentCategory = res.addedItems[0];
      this.ModelGroupItems = new Array<ModelGroupItem>();
      this.synchronizeAssetCategoryConfig(assetCategorySelected);
    });

    this.returnTypeGroupInstance.value = this.returnTypesOptions[0];
    //* When there is data from the API, the weighting option change triggers an API request.
    //* Do this only when the values is not being changed for the first time(on setup)
    this.weightRadioGroupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });

    this.returnTypeGroupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });
    this.benchmarksLookupInstance.onValueChanged.subscribe((config) => {
      if (config.previousValue !== null) {
        this.fetchResults();
      }
    });

    this.benchmarksLookupInstance.displayExpr = "Name";
    this.benchmarksLookupInstance.valueExpr = "Id";
    this.benchmarksLookupInstance.title = "Benchmarks";

    /**Set the default start and end date */
    this._appSettingsServiceSubscription = this.appSettingsService
      .GetSettings()
      .subscribe((settings) => {
        this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
        this.endDateBoxInstance.value = "2019-04-05";

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
  }

  /**Get the models in each asset category */
  public synchronizeAssetCategoryConfig(
    selectedAssetCategory: TestInstrumentCategory
  ) {
    let urlToGetModels = "";

    this.returnTypeGroupInstance.items = this.returnTypesOptions;
    this.returnTypeGroupInstance.value = this.returnTypesOptions[0];

    this.weightRadioGroupInstance.items = this.weightingOptions;
    this.weightRadioGroupInstance.value = this.weightingOptions[0];

    this.benchmarksLookupInstance.disabled = false;
    // ! not sure. confirm this
    this.weightRadioGroupInstance.value =
      selectedAssetCategory.defaultWeighting;

    this.apiService
      .Get(
        `analyticssetup/instrumentcategorybenchmarks?instrumentcategorycode=${selectedAssetCategory.code}`
      )
      .subscribe((benchmarkResults) => {
        let allBenchmarks = benchmarkResults.Benchmarks;
        this.benchmarksLookupInstance.dataSource = allBenchmarks;
        let defaultBenchmark = benchmarkResults.DefaultBenchmark;
        this.benchmarksLookupInstance.value = defaultBenchmark.Id;
      });
    urlToGetModels = `analytics/NonPortfolioModels?instrumentCategoryId=${selectedAssetCategory.id}`;

    //get all the models that on the selected asset category
    //subscribe the response it to the Array<ModelGroup>
    this.apiService
      .Get(urlToGetModels)
      .subscribe((modelGroups: Array<ModelGroup>) => {
        modelGroups.forEach((modelGroup) => {
          // Filter the model group Name
          if (modelGroup.Name == "Bond Risk Analysis") {
            let modelItems: Array<ModelItem> = new Array<ModelItem>();
            let groupName = modelGroup.Name;
            let models = modelGroup.Models;

            models.forEach((model) => {
              let modelCode = model.Code;
              let modelName = model.Name;
              let modelVariables = model.Variables;
              let modelUniqueId = model.ModelId; // ! use this to refer to the model. This is the id saved in the database

              // *  e.g when you select arithmetic mean - pass 'price' and 'returns'- The system is parameter driven*/
              let modelVariableCodes = modelVariables.map(
                (modelVariable) => modelVariable.Code
              );

              let newModelObject: ModelItem = {
                ID: modelUniqueId,
                Head_ID: 0,
                ModelName: modelName,
                ModelValue: modelCode,
                ModelGroupName: groupName,
                ParentModelName: "",
                ModelClassification: ModelClassification.ParentModel,
                UniqueModelId: modelUniqueId,
                ModelParameters: modelVariableCodes,
              };
              modelItems.push(newModelObject);
            });

            try {
              let modelGroupItem = new ModelGroupItem();
              modelGroupItem.ModelGroupName = groupName;
              modelGroupItem.ModelItems = modelItems;
              this.ModelGroupItems.push(modelGroupItem);
            } catch (error) {
              console.error(error);
            }
          }
        });
      });
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._startDateBoxInstanceSubscription !== undefined)
      this._startDateBoxInstanceSubscription.unsubscribe();
    if (this._endDateBoxInstanceSubscription !== undefined)
      this._endDateBoxInstanceSubscription.unsubscribe();
    if (this._investorLookupSubscription !== undefined)
      this._investorLookupSubscription.unsubscribe();
    if (this._assetClassesMixSubscription !== undefined)
      this._assetClassesMixSubscription.unsubscribe();
    if (this._apiServiceSubscription !== undefined)
      this._apiServiceSubscription.unsubscribe();
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._summarySwitchSubscription !== undefined)
      this._summarySwitchSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
    if (this._apiFetchSubscription !== undefined)
      this._apiFetchSubscription.unsubscribe();
  }

  fetchResults() {
    if (this.SelectedModelsIds.length == 0) {
      // this.notificationService.ShowErrorNotification("Please Select a Model");
      return;
    }
    let requestStartDate = this.startDateBoxInstance.value;
    let requestEndDate = this.endDateBoxInstance.value;
    let selectedAssetCategory: TestInstrumentCategory = this
      .assetCategoriesPanelInstance.selectedItem;
    let returnTypeValueObject = this.returnTypeGroupInstance.value;
    let payload = {};

    payload = {
      StartDate: requestStartDate,
      EndDate: requestEndDate,
      InstrumentCategoryCode: selectedAssetCategory.code,
      Funds: [""],
      Models: this.SelectedModelsIds,
      YieldChange: 0.02,
    };

    this._apiFetchSubscription = this.apiService
      .Post("analytics/bondriskanalysis", payload)
      .subscribe((portfolioBondRiskAnalysisResponse: any) => {
        this.closeRequest();
        this.ProcessPortfolioRiskBondAnalysisRequestAPI(
          portfolioBondRiskAnalysisResponse
        );
      });
  }

  ProcessPortfolioRiskBondAnalysisRequestAPI(
    portfolioBondRiskAnalysisResponse: any
  ) {
    if (portfolioBondRiskAnalysisResponse.Successful == false) {
      this.notificationService.ShowErrorNotification(
        portfolioBondRiskAnalysisResponse.ErrorMessage
      );
    } else {
      let portfolioBondRiskAnalysisDataSource: Array<any> = [];
      let dataSourceObject = {};
      let modelColumns: Column[] = [];

      let portfolioBondRiskColumn: Column[] = [
        {
          caption: "Security",
          dataField: "Security",
          dataType: "string",
          cssClass: "performance-attr-grid-focus-1",
        },
        {
          caption: "Tenor",
          dataField: "TenorRange",
          dataType: "string",
          cssClass: "performance-attr-grid-focus-3",
        },
        {
          caption: "Time To Maturity",
          dataField: "TimeToMaturity",
          dataType: "string",
          cssClass: "performance-attr-grid-focus-1",
        },
        {
          caption: "Date",
          dataField: "Date",
          dataType: "date",
          groupIndex: 0,
          sortOrder: "asc",
          format: "dd-MMM-yyyy",
        },
        {
          caption: "Model",
          dataField: "Model",
          dataType: "string",
        },
        {
          caption: "Value",
          dataField: "Value",
          dataType: "number",
        },
      ];

      portfolioBondRiskAnalysisResponse.forEach((element) => {
        let securitiesBondRiskAnalysisData =
          element.SecuritiesBondRiskAnalysis[0];

        let firstmodelCategorizationsDataResult =
          securitiesBondRiskAnalysisData.ModelCategorizations[0];

        Array.prototype.forEach.call(
          firstmodelCategorizationsDataResult,
          (element) => {
            let modelName: string = element.Model;
            let firstModelValueData =
              firstmodelCategorizationsDataResult.ModelData[0].Value;
            let modelColumns: Column = {
              caption: modelName,
              dataField: `${modelName}`,
              dataType: "number",
            };
            portfolioBondRiskAnalysisDataSource.push(modelColumns);
          }
        );

        //! Row Data
        var fund = element.Fund;
        Object.defineProperty(dataSourceObject, "Fund", {
          value: fund,
          writable: false,
        });

        //SecuritiesBondRiskAnalysis Section
        let securitiesBondRiskAnalysis: Array<SecuritiesBondRiskAnalysis> =
          element.SecuritiesBondRiskAnalysis;

        securitiesBondRiskAnalysis.forEach((element) => {
          let security = element.Security;
          let tenorRange = element.TenorRange;
          let timeToMaturity = element.TimeToMaturity;

          Object.defineProperty(dataSourceObject, "Security", {
            value: security,
            writable: false,
          });

          Object.defineProperty(dataSourceObject, "TenorRange", {
            value: tenorRange + " Yrs",
            writable: false,
          });

          Object.defineProperty(dataSourceObject, "TimeToMaturity", {
            value: timeToMaturity + " Yrs",
            writable: false,
          });

          //Model Categorization Section
          let modelCategorization: Array<ModelCategorizations> =
            element.ModelCategorizations;

          modelCategorization.forEach((element) => {
            let model = element.Model;

            Object.defineProperty(dataSourceObject, "Model", {
              value: model,
              writable: false,
            });

            //Date Value Section
            let modelData: Array<ModelData> = element.ModelData;

            modelData.forEach((element) => {
              let date = element.Date;
              let val = element.Value;

              Object.defineProperty(dataSourceObject, "Date", {
                value: date,
                writable: false,
              });

              Object.defineProperty(dataSourceObject, "Value", {
                value: val,
                writable: false,
              });

              // //Check if model Name exists
              // var targetModelNameExist = portfolioBondRiskColumn.find(
              //   (x) => x.caption == model
              // );

              // if (!targetModelNameExist) {
              //   let captions = {
              //     caption: model,
              //     dataField: `${val}`,
              //   };
              //   portfolioBondRiskColumn.push(captions);
              // }
              portfolioBondRiskAnalysisDataSource.push(dataSourceObject);
              dataSourceObject = {};
            });
          });
        });
        portfolioBondRiskColumn.push(...modelColumns);
        //this.gridDataObservable.next(portfolioBondRiskAnalysisDataSource);
        this.resultsDataGrid.dataSource = portfolioBondRiskAnalysisDataSource;
        this.resultsDataGrid.columns = portfolioBondRiskColumn;
      });
    }
  }

  closeRequest() {
    if (this._apiFetchSubscription !== undefined) {
      this._apiFetchSubscription.unsubscribe();
      this._apiFetchSubscription = undefined;
    }
  }
}
