import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ApiService } from "../../../app-services/api/api.service";
import CustomStore from "devextreme/data/custom_store";
import { HttpParams } from "@angular/common/http";
import {
  DataFormComponent,
  DataFormOptions,
} from "../../../app-directives/data-form/data-form.component";
import { MatDialog } from "@angular/material/dialog";
import { InstrumentClassesFormComponent } from "./instrument-classes-form/instrument-classes-form.component";
import { DxLookupComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";

@Component({
  selector: "app-instrument-classes",
  templateUrl: "./instrument-classes.component.html",
  styleUrls: ["./instrument-classes.component.scss"],
})
export class InstrumentClassesComponent implements OnInit {
  EntityName = "Asset Classes";
  instrumentClassesData: any;

  columnsAssetClasses = [
    { caption: "Name", dataField: "Name" },
    { caption: "Asset Categories", dataField: "InstrumentCategoryName" },
    { caption: "Order", dataField: "InstrumentCategoryOrder" },
    { caption: "Investor", dataField: "Investor" },
    { caption: "Description", dataField: "Description" },
  ];

  constructor(
    private apiService: ApiService,
    private matDialog: MatDialog,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName("Asset Classes Setup");

    this.formItems = [
      {
        label: { text: "Name" },
        dataField: "Name",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
      },
      {
        dataField: "InvestorId",
        editorType: "dxLookup",
        searchEnabled: true,
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: (loadOptions: any) => {
              if (loadOptions.searchExpr === "Name") {
                let params = new HttpParams();
                params = params.append("name", loadOptions.searchValue);
                return this.apiService
                  .Get("Investors/FindByName?name=" + loadOptions.searchValue)
                  .toPromise();
              }
              return this.apiService.Get("investors").toPromise();
            },
            byKey: (key) => {
              let params = new HttpParams();

              params = params.append("id", key);
              return this.apiService
                .Get("Investors/GetById?id=" + key)
                .toPromise();
            },
          }),
          valueExpr: "ID",
          displayExpr: "Name",
        },
        validationRules: [
          {
            type: "required",
            message: "Investor is required",
          },
        ],
        cssClass: "required-form-item",
      },
      {
        dataField: "Description",
        editorType: "dxTextArea",
        editorOptions: {
          height: 70,
          placeholder: "Notes...",
        },
      },
      {
        dataField: "instrumentCategories",
        editorType: "dxTagBox",
        showClearButton: true,
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: () => {
              return this.apiService.Get("InstrumentCategories").toPromise();
            },
            byKey: (key) => {
              let params = new HttpParams();

              params = params.append("id", key);
              return this.apiService
                .Get("InstrumentCategories/" + key)
                .toPromise();
            },
          }),
          valueExpr: "Id",
          displayExpr: "Name",
          placeholder: "Select one or more Asset Categories...",
          height: 70,
        },
        // validationRules: [{
        //   type: 'required',
        //   message: 'Instrument Category is required'
        // }],
        // cssClass: 'required-form-item'
      },
    ];
  }

  formItems: any;

  ngOnInit() {
    this.apiService.Get("InstrumentClasses/GetAll").subscribe((res) => {
      this.formatData(res);
    });
  }

  formatData(data: any) {
    let InstumentClassesDataSource = [];
    data.forEach((element) => {
      let name = element.Name;
      let Id = element.Id;
      let description = element.Description;
      let instumentCategoryData = element.InstrumentCategories;
      instumentCategoryData.forEach((element) => {
        let dataSourceObject = {};
        let instrumentCategoryName = element.Name;
        let instrumentCategoryClass = element.InstrumentClass;
        let order = element.Order;
        let investor = element.Investor;
        let displayName = element.DisplayName;

        dataSourceObject = {
          Id: Id,
          Name: name,
          InstrumentCategoryName: instrumentCategoryName,
          InstrumentCategoryClass: instrumentCategoryClass,
          InstrumentCategoryOrder: order,
          Investor: investor,
          Description: description,
          DisplayName: displayName,
        };
        InstumentClassesDataSource.push(dataSourceObject);
      });
    });
    this.instrumentClassesData = InstumentClassesDataSource;
  }

  AddNew($event) {
    //this.matDialog.open(InstrumentClassesFormComponent, { width: '800px', data: {} });

    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "New Asset Class";
    dataFormOptions.postEndPoint = "InstrumentClasses";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = rowData;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "Edit Asset Class";
    dataFormOptions.postEndPoint = "InstrumentClasses";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });
  }
}
