import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Column } from "devextreme/ui/data_grid";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { forkJoin, Subscription } from "rxjs";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import {
  DxDataGridComponent,
  DxDateBoxComponent,
  DxDropDownBoxComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { ApiService } from "src/app/app-services/api/api.service";
import { MatButton } from "@angular/material/button";
import * as _ from "underscore";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";

export class RebalancingRequestObject {
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
}

@Component({
  selector: "portfolio-rebalancing",
  templateUrl: "./portfolio-rebalancing.component.html",
  styleUrls: ["./portfolio-rebalancing.component.scss"],
})
export class PortfolioRebalancingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("complianceGridInstance", { static: false })
  complianceGridInstance: DxDataGridComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("refreshButton", { static: false })
  refreshButtonInstance: MatButton;
  @ViewChild(DxTreeListComponent, { static: false }) treelist;
  @ViewChild("FundDropDownBox") FundDropDownBox: DxDropDownBoxComponent;

  gridKeyExpression = ["AssetCategory", "ActualHolding", "Date"]; // might need to have a guid from the API with each row data for uniqueness
  summaryColumnNames = ["ActualHolding"];
  applicationSettings: ApplicationSettings;
  chartTitle: string;

  _appSettingsServiceSubscription: Subscription;
  _investorsSubscription: Subscription;
  _startDateChangeSubscription: Subscription;
  _reportSubscription: Subscription;

  cmaRegulatoryComplianceData: Array<any> = [];
  rbaRegulatoryComplianceData: Array<any> = [];
  selectedFund: string = "";
  treeBoxValue: string;
  FundsFundManagerDataSource: any;
  fundManagersArray: Array<any> = [];
  RebalancingRequestObject: RebalancingRequestObject =
    new RebalancingRequestObject();

  constructor(
    private settingsService: ApplicationSettingsService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public apiService: ApiService,
    public notificationService: SnackNotificationsService
  ) {}

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
    if (this._reportSubscription !== undefined)
      this._reportSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this._appSettingsServiceSubscription = this.settingsService
      .GetSettings()
      .subscribe((settings) => {
        this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
        this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
    /**
     * Update end date minimum value on start date change
     *
     */
    this._startDateChangeSubscription =
      this.startDateBoxInstance.onValueChanged.subscribe((newDateChange) => {
        this.endDateBoxInstance.min = newDateChange.value;
      });
  }

  ngOnInit() {
    //change the active component name
    this.navBarSettingsService.ChangeActiveComponentName(
      "Portfolio Rebalancing"
    );

    //subscribe to the settings observable data
    this.settingsService.GetSettings().subscribe((settings) => {
      this.applicationSettings = settings;
    });

    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      //let fundmanagers: Array<any> = result[0]
      //let funds: Array<any> = result[1]

      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
        });

        let investors = [];
        fundManager.MasterFunds.forEach((masterfund) => {
          let funds = masterfund.Investors;
          funds.forEach((investor) => {
            investors.push(investor);
          });
        });

        investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: fundManager.Id,
            TenantId: fundManager.TenantId,
          });
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });
  }

  ModelGroupsItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {
      this.treeBoxValue = event.selectedRowsData[0].Name;

      //when only a fund manager is selected
      if (event.selectedRowsData.length === 1) {
        let selectedRowsData = event.selectedRowsData[0];
        if (selectedRowsData.Head_ID === 0) {
          //then this is a fund manager
          const fundManagerId = selectedRowsData.ID;

          this.RebalancingRequestObject.Funds = [];
          this.RebalancingRequestObject.FundManagers = [];

          this.RebalancingRequestObject.FundManagers.push(fundManagerId);
          this.treeBoxValue = null;
        } else {
          //this is a single fund belonging to a fund manager
          let selectedRowsData = event.selectedRowsData[0];
          const fundId = selectedRowsData.ID;
          const fundManagerId = selectedRowsData.Head_ID;
          const selectedTenantId = selectedRowsData.TenantId;
          this.RebalancingRequestObject.TenantId = selectedTenantId;

          this.RebalancingRequestObject.Funds = [];
          this.RebalancingRequestObject.FundManagers = [];

          this.RebalancingRequestObject.Funds.push(fundId);
          this.RebalancingRequestObject.FundManagers.push(fundManagerId);
        }
      } else {
        // multiple selection
        const selectedItems = event.selectedRowsData;
        const fundManagerId = event.selectedRowsData[0].Head_ID;
        const selectedTenantId = event.selectedRowsData[0].TenantId;
        const fundIds = event.selectedRowsData.map((item) => item.ID);
        this.RebalancingRequestObject.Funds = [];
        this.RebalancingRequestObject.FundManagers = [];
        this.RebalancingRequestObject.TenantId = selectedTenantId;

        this.RebalancingRequestObject.Funds = fundIds;
        this.RebalancingRequestObject.FundManagers.push(fundManagerId);
      }
    }
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          // let formattedNumber = this.numberWithCommas(value)
          event.value = `${value}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.cellElement.classList.add(
            "data-grid-negative-number-cell-regulator-compliance"
          );
          event.value = Math.abs(value);
          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");
          event.cellElement.innerHTML = `(${textDisplayed})`;
        }
        if (value === -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
      }
    }

    //asset category goes here
    if (event.rowType == "data") {
      let text: string = event.text;
      if (text !== undefined) {
        if (text.search("/*")) {
          console.error(text);
        }
      }
    }

    if (event.rowType == "header") {
      if (event.columnIndex == 0) {
        if (event.column.dataField === "AssetCategory") {
          event.cellElement.classList.add(
            "regulatory-limit-grid-columns-categories"
          );
        } else {
          event.cellElement.classList.add("regulatory-limit-grid-columns");
        }
      } else {
        event.cellElement.classList.add("regulatory-limit-grid-columns");
      }
    }
  }

  customizeSummaryText(data) {
    let value = data.value;
    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let numberWithDecimalPoints = absoluteValue.toFixed(2);

      return `(${numberWithDecimalPoints})`;
    } else {
      return value.toFixed(2);
    }
  }

  fetchReport() {
    if (this.treeBoxValue == null || this.treeBoxValue == "Select a Fund") {
      this.notificationService.ShowWarningNotification("Please Select a Fund");
      return;
    }
    this.fundManagersArray = [];
    this.fundManagersArray.push({
      Id: this.RebalancingRequestObject.FundManagers[0],
      TenantId: this.RebalancingRequestObject.TenantId,
      Funds: this.RebalancingRequestObject.Funds,
    });

    let request = {
      FundManagers: this.fundManagersArray,
      StartDate: this.startDateBoxInstance.value,
      EndDate: this.endDateBoxInstance.value,
    };

    this._reportSubscription = this.apiService
      //Get the Regulatory compliance Data
      .Post("reports/PortfolioRebalancingReport", request)
      .subscribe((rebalancingReportArray: any[]) => {
        if (rebalancingReportArray.length == 0) {
          this.notificationService.ShowErrorNotification(
            "No result found for the specified period and fund"
          );
          return;
        }
        this.closeRequest();

        /**********************************! from the first result make the columns */
        let theFirstResult = rebalancingReportArray[0];

        let columns: Column[] = [
          {
            caption: "Fund Name",
            dataField: "FundName",
            dataType: "string",
            groupIndex: 1,
            sortOrder: "asc",
            allowSorting: true,
            width: "300px",
          },
          {
            caption: "Date",
            dataField: "Date",
            dataType: "date",
            groupIndex: 1,
            sortOrder: "asc",
            format: "dd-MMM-yyyy",
          },
          {
            caption: "Stock Name",
            dataField: "StockName",
            allowSorting: true,
            width: "300px",
            sortOrder: "asc",
            cssClass: "performance-attr-grid-focus-3",
            dataType: "string",
          },
          {
            caption: "Share Price",
            dataField: "SharePrice",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-2",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "Number of Shares",
            dataField: "NumberofShares",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
          },
          {
            caption: "Market Value",
            dataField: "MarketValue",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "Weight(%)",
            dataField: "Weight",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "House View(%)",
            dataField: "HouseView",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "House View Cash",
            dataField: "HouseViewCash",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "House View Shares",
            dataField: "HouseViewShares",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "Buy/Sell",
            dataField: "RebalanceAction",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-1",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
          {
            caption: "Value",
            dataField: "Value",
            dataType: "number",
            allowSorting: true,
            allowResizing: false,
            cssClass: "performance-attr-grid-focus-2",
            format: {
              type: "fixedPoint",
              precision: this.applicationSettings.PercentageValuesDecimalPoints,
            },
          },
        ];
        let rebalancingDataSource = [];

        rebalancingReportArray.forEach((x) => {
          let fundName = x.FundName;
          let portfoilioRebalancingData = x.PortfoilioRebalancing;
          portfoilioRebalancingData.forEach((x) => {
            let dataSourceObject = {};
            let stockName = x.StockName;
            let sharePrice = x.SharePrice;
            let numberOfShares = x.NumberOfShares;
            let marketValue = x.MarketValue;
            let weight = x.Weight;
            let houseView = x.HouseView;
            let houseViewCash = x.HouseViewCash;
            let houseViewShares = x.HouseViewShares;
            let rebalanceAction = x.RebalanceAction;
            let date = x.Date;
            let value = x.Value;

            dataSourceObject = {
              FundName: fundName,
              StockName: stockName,
              SharePrice: sharePrice,
              NumberofShares: numberOfShares,
              MarketValue: marketValue,
              Weight: weight,
              HouseView: houseView,
              HouseViewCash: houseViewCash,
              HouseViewShares: houseViewShares,
              RebalanceAction: rebalanceAction,
              Date: date,
              Value: value,
            };
            rebalancingDataSource.push(dataSourceObject);
          });
        });

        this.complianceGridInstance.dataSource = rebalancingDataSource;
        this.complianceGridInstance.columns = columns;
      });
  }

  treeList_itemSelectionChanged(e) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  calculateGridHeight() {
    return 0.55 * window.screen.height + "px";
  }
  /**
   * Closes the api request to fetch results
   */
  closeRequest() {
    if (this._reportSubscription !== undefined) {
      this._reportSubscription.unsubscribe();
      this._reportSubscription = undefined;
    }
  }
}
