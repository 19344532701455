<div>
    <div style="font-weight: 700; margin-left: 5%; font-stretch: extra-expanded; font-size: 110%;">
        Fund Manager / Funds
    </div>
    <mat-divider></mat-divider>
    <div>
        <!-- <dx-tree-list #fundManagersList [dataSource]="DataSource | async" height="450">
        </dx-tree-list> -->

        <dx-tree-list height="430" #fundManagerTreeList [dataSource]="DataSource | async" keyExpr="ID"
            parentIdExpr="Head_ID" [allowColumnResizing]="true" [allowColumnReordering]="true" [showBorders]="true"
            (onSelectionChanged)="ModelGroupsItemsSelected($event)" noDataText="no data to display..." class="tree-list"
            [highlightChanges]="true" [repaintChangesOnly]="true" [focusedRowEnabled]="false" [showBorders]="true"
            [autoExpandAll]="true" [hoverStateEnabled]="true" [focusStateEnabled]="true"
            (onCellHoverChanged)="showMoreInfoOnHover($event)" (onCellPrepared)="onCellPrepared($event)">

            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple">
            </dxo-sorting>
            <dxi-column dataField="Name" caption="Select Funds under a Fund Manager" sortOrder="asc"
                style="font-size: 200%;">
            </dxi-column>
        </dx-tree-list>


    </div>
</div>