<mat-accordion multi>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Select Securities and Models </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="margin-bottom: 1%"></div>
    <div class="non-portfolio-config-area">
      <div
        class="non-portfolio-config-area-children"
        id="non-portfolio-config-area-section"
      >
        <div>
          <span class="component-inputs-label">Start Date</span>
          <dx-date-box
            id="analytics-start-date-input"
            displayFormat="dd-MMM-yyyy"
            openOnFieldClick="true"
            #startDateBox
            matTooltip="Analysis is done from and including this date"
            [disabled]="
              (nonPortfolioUpdaterService.UpdateButtonName$ | async) ===
              'Cancel'
            "
          >
          </dx-date-box>
        </div>
        <div>
          <span class="component-inputs-label">End Date</span>
          <dx-date-box
            id="analytics-end-date-input"
            displayFormat="dd-MMM-yyyy"
            openOnFieldClick="true"
            #endDateBox
            matTooltip="Analysis is done upto and including this date"
            [disabled]="
              (nonPortfolioUpdaterService.UpdateButtonName$ | async) ===
              'Cancel'
            "
            (onValueChanged)="dateChangedEvent()"
          >
          </dx-date-box>
        </div>
      </div>
      <div>
        <div
          *ngIf="
            nonPortfolioUpdaterService.UpdateButtonName$ | async as buttonname
          "
        >
          <div *ngIf="buttonname === 'Refresh'">
            <button
              mat-raised-button
              color="primary"
              id="refresh-button"
              (click)="renderResults(assetCategoriesPanel.selectedItem, false)"
              [disabled]="ModelGroupItems.length === 0"
              matTooltip="Select models and click to see the results"
            >
              <mat-icon>update</mat-icon>
              {{ nonPortfolioUpdaterService.UpdateButtonName$ | async }}
            </button>
          </div>
          <div *ngIf="buttonname === 'Cancel'">
            <button
              mat-raised-button
              color="warn"
              (click)="renderResults(assetCategoriesPanel.selectedItem, true)"
              matTooltip="Cancel this request"
            >
              <mat-icon>cancel_presentation</mat-icon>
              {{ nonPortfolioUpdaterService.UpdateButtonName$ | async }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="non-portfolio-content-section">
      <div class="non-portfolio-variables">
        <div
          class="securities-filter-title"
          matTooltip="Variables are parts of the security that are to be analyzed. Each model on the models panel consists of one or many variables."
        >
          Variables
        </div>
        <mat-divider></mat-divider>
        <div>
          <dx-list
            #variableList
            [disabled]="
              (nonPortfolioUpdaterService.UpdateButtonName$ | async) ===
              'Cancel'
            "
          ></dx-list>
        </div>
      </div>

      <div
        class="non-portfolio-securities"
        [ngClass]="
          (nonPortfolioUpdaterService.focusOnSecurities$ | async) === true
            ? 'highlighted-securities'
            : 'non-highlighted-securities'
        "
      >
        <div
          class="securities-filter-title"
          matTooltip="Choose one or more securities to analyze. This securites list is pulled from the Asset Category selected on your right. You must select at least one security to proceed"
        >
          {{ securitiesSectionCaption }}
          <span
            *ngIf="securitiesList"
            style="opacity: 0.5; font-weight: lighter"
            >selection: ({{ securitiesList.selectedItems.length }} of
            {{ securitiesLength }})</span
          >
        </div>
        <mat-divider></mat-divider>
        <div>
          <dx-list
            #securitiesList
            [height]="400"
            [disabled]="
              (nonPortfolioUpdaterService.UpdateButtonName$ | async) ===
              'Cancel'
            "
          ></dx-list>
        </div>
      </div>
      <div class="non-portfolio-models">
        <dx-tab-panel
          #assetCategoriesPanel
          [dataSource]="assetCategoryTabs"
          [selectedIndex]="0"
          [loop]="false"
          [animationEnabled]="true"
          [swipeEnabled]="true"
          [height]="400"
          [showNavButtons]="true"
          [disabled]="
            (nonPortfolioUpdaterService.UpdateButtonName$ | async) === 'Cancel'
          "
        >
          <div *dxTemplate="let item of 'title'">
            <div [ngStyle]="item.iconStyle" matTooltip="{{ item.content }}">
              <div class="tabpanel-item">
                <div>
                  <span class="icon">
                    <mat-icon [inline]="true">{{ item.icon }}</mat-icon>
                  </span>
                </div>
                <div>
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
          <div
            *dxTemplate="let selectedAsstCat of 'item'"
            class="non-portfolio-models-section-container"
          >
            <div
              class="non-portfolio-asset-categories-models"
              style="height: 50vh !important; overflow-y: scroll"
            >
              <app-models-view
                [ModelGroupItems]="ModelGroupItems"
                [AssetCategory]="selectedAsstCat"
                [Securities]="securitiesList.selectedItems"
                [StartDate]="startDateBox.value"
                [EndDate]="endDateBox.value"
                [ModelParameters]="variableList.selectedItems"
                [SelectedModelkeys]="SelectedModelKeys"
              >
              </app-models-view>
            </div>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> View Output Data </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="result-grids-container">
      <div>
        <div style="margin-left: 20px; font-weight: 300">
          Modify the output here. Changes to this inputs trigger an automatic
          reload with the updated data displayed on the grid.
        </div>
        <div class="results-controls">
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 110%;
              "
            >
              <div style="font-weight: 500">
                Market Capitalization Weighted?
              </div>
              <div>
                <dx-radio-group
                  #weightRadioGroup
                  [items]="weightingOptions"
                  layout="horizontal"
                >
                </dx-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider style="margin-bottom: 0.8%"></mat-divider>
      <div>
        <app-asset-category-results [ModelParameters]="variableList.items">
        </app-asset-category-results>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<app-chat-bot currentParentId="macrosecurityanalysis"></app-chat-bot>
