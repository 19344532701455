import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";
import jwt_decode from "jwt-decode";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "underscore";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private stsUrl = "";
  isAuthenticated: boolean;
  userData: any;
  decodedAccessToken: any;
  tenantId: any;

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.stsUrl = this.configService.config.STSURL;
    this.oidcSecurityService.isAuthenticated$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    //Get current User ID
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    this.userData = this.decodedAccessToken;
    this.tenantId = this.decodedAccessToken.TenantId;
  }

  getUsersByTenantId(): Observable<any> {
    return this.http.get(
      `${this.stsUrl}/account/getusers?tenantid=${this.tenantId}`
    );
  }
  //to review
  //https://stackoverflow.com/questions/54110377/angular-receive-and-return-observablet-response-in-http-post
  Post(endpoint: string, entity: any): Observable<any> {
    return this.http.post<any>(endpoint, entity, {
      headers: this.httpOptions.headers,
    });
  }
}
