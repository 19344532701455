<div [ngClass]="fontSize" class="content-section">

	<div class="settings-title-section">
		<div>
			<div class="settings-title" matTooltip="System wide settings">Settings <span *ngIf="UpdateMessage$ | async">
					<span style="color: green; font-size: 20px;">{{UpdateMessage$ | async}}</span>
				</span></div>

		</div>
		<div style="font-weight: 100; font-size: smaller;">
			<div>This settings apply to this browser only.</div>
			<div>Default settings are created <span style="font-weight: 500;">one-time </span> when you</div>
			<div>login using a different browser.</div>
		</div>

	</div>

	<mat-divider></mat-divider>


	<div class="settings-container settings-form">
		<div class="settings-child-container">
			<div>
				<div class="settings-item-title">
					Percentage values Decimal Places
				</div>
				<div class="settings-item-content">
					<dx-button-group [items]="decimalPoints" keyExpr="decimals" selectionMode="single"
						[(selectedItemKeys)]="selectedPercentageDecimalPoints"
						(onSelectionChanged)="logSelectionChanged()">
					</dx-button-group>
				</div>
				<div style="font-weight: 100;">
					<span class="sample-decimalPoints-section">sample:</span> <span
						class="sample-decimal-points">{{percentageValueSample}}%</span>
				</div>
			</div>
		</div>
		<div class="settings-child-container">
			<div>
				<div class="settings-item-title">
					Non-Percentage Decimal Places
				</div>
				<div class="settings-item-content">
					<dx-button-group [items]="decimalPoints" keyExpr="decimals" selectionMode="single"
						[(selectedItemKeys)]="selectedNonPercentageDecimalPoints"
						(onSelectionChanged)="logSelectionChanged()">
					</dx-button-group>
				</div>
				<div style="font-weight: 100;">
					<span class="sample-decimalPoints-section">sample:</span> <span
						class="sample-decimal-points">{{nonPercentageValueSample}}</span>
				</div>
			</div>
		</div>
	</div>


	<div class="actions-section">
		<div>
			<button mat-raised-button color="primary" matTooltip="Update changes" (click)="updateSettings()">
				<mat-icon>save</mat-icon> Update
			</button>
		</div>
		<div>
			<button mat-raised-button color="warn" matTooltip="Close this dialog" (click)="closeSettingsWindow()">
				<mat-icon>close</mat-icon> Close
			</button>
		</div>
	</div>
</div>