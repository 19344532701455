import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';


@Component({
	selector: 'app-treasury-bill',
	templateUrl: './treasury-bill.component.html',
	styleUrls: ['./treasury-bill.component.scss']
})
export class TreasuryBillComponent implements OnInit {

	gridDataSource: any;
	EntityName = 'Treasury Bills';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single' }

	Columns: Array<any> = [
		{ caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Asset Cat. Class', dataField: 'AssetCategoryClass' },
		{ caption: 'Account Code', dataField: 'AccountCode', dataType: 'date' },
		{ caption: 'Maturity Date', dataField: 'MaturityDate', dataType: 'date' },
		{ caption: 'Actual Maturity Date', dataField: 'ActualMaturityDate', dataType: 'date' },
		{ caption: 'ISIN', dataField: 'ISIN' },
		{ caption: 'Duration', dataField: 'Duration' },
		{ caption: 'Security Type', dataField: 'SecurityType', },
		{ caption: 'Yield', dataField: 'Yield', calculateDisplayValue: (rowData) => { return rowData.Yield + ' %' } }
	];

	constructor(private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Treasury Bills')
	}

	ngOnInit() {
		this.apiService.Get('TreasuryBills').subscribe(result => this.gridDataSource = result);
	}

	rowDoubleClicked(event: any) {

	}

}
