import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-mean-sample',
  templateUrl: './mean-sample.component.html',
  styleUrls: ['./mean-sample.component.scss']
})
export class MeanSampleComponent implements OnInit, AfterViewInit {


  @ViewChild('dataGrid', { static: true }) dataGridInstance: DxDataGridComponent;

  columnsAll = [
    { caption: 'Stock Name', dataField: 'StockName', sortOrder: 'asc' },
    { caption: 'Stock price ', dataField: 'StockPrice' },
    { caption: 'Purchase Value', dataField: 'PurchaseValue', sortOrder: 'asc' }
  ]
  columnsWithoutPurchaseValue = [
    { caption: 'Stock Name', dataField: 'StockName', sortOrder: 'asc' },
    { caption: 'Stock price ', dataField: 'StockPrice' },
  ]
  columnsWithStockPrice = [
    { caption: 'Stock Name', dataField: 'StockName', sortOrder: 'asc' },
    { caption: 'Stock price ', dataField: 'StockPrice' },
    { caption: 'Purchase Value', dataField: 'PurchaseValue', sortOrder: 'asc' }
  ]
  constructor() { }

  sampleData = [
    {
      ID: 1,
      StockName: 'Safaricom',
      StockPrice: 25.678,
      PurchaseValue: 23.67
    },
    {
      ID: 2,
      StockName: 'KQ',
      StockPrice: 2.45,
      PurchaseValue: 45.35
    },
    {
      ID: 3,
      StockName: 'EABL',
      StockPrice: 26.67,
      PurchaseValue: 25.65
    },
    {
      ID: 4,
      StockName: 'Kakuzi',
      StockPrice: 25.76,
      PurchaseValue: 26.78
    },
    {
      ID: 5,
      StockName: 'Barclays',
      StockPrice: 2.90,
      PurchaseValue: 2.87
    }
  ]

  gridColumnsSummaries: any = {
    totalItems: [{
      column: 'StockPrice',
      summaryType: 'avg'
    },
    {
      column: 'PurchaseValue',
      summaryType: 'avg'
    }
    ]
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataGridInstance.dataSource = this.sampleData;
    // @ts-ignore
    this.dataGridInstance.columns = this.columnsWithoutPurchaseValue;
    this.dataGridInstance.summary = this.gridColumnsSummaries;
  }
  checkBoxToggled(name: string) {
    if (name === 'PurchaseValue') {
      // @ts-ignore
      this.dataGridInstance.columns = this.columnsAll
    }
    if (name !== 'PurchaseValue') {
      // @ts-ignore
      this.dataGridInstance.columns = this.columnsWithoutPurchaseValue
    }
  }
}
