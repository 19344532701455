<div class="dashboard-section content-section">
	<div class="dashboard-content">

			<div id="chart-demo">
				<dx-chart id="zoomedChart" [dataSource]="stockPrices" title="kakuzi Ltd. Stock Prices">
					<dxi-series type="candleStick" openValueField="Open" highValueField="High" lowValueField="Low"
						closeValueField="Close" argumentField="Date">
						<dxo-aggregation [enabled]="true"></dxo-aggregation>
					</dxi-series>
					<dxo-margin [right]="10"></dxo-margin>
					<dxi-value-axis valueType="numeric"></dxi-value-axis>
					<dxo-argument-axis [valueMarginsEnabled]="false" argumentType="datetime">
						<dxo-grid [visible]="true"></dxo-grid>
						<dxo-label [visible]="false"></dxo-label>
					</dxo-argument-axis>
					<dxo-legend [visible]="false"></dxo-legend>
					<dxo-tooltip [enabled]="true"></dxo-tooltip>
				</dx-chart>
				<dx-range-selector [dataSource]="stockPrices" (onValueChanged)="valueChanged($event)">
					<dxo-size [height]="120"></dxo-size>
					<dxo-scale minorTickInterval="day" tickInterval="month" valueType="datetime"
						aggregationInterval="week" [placeholderHeight]="20">
					</dxo-scale>
					<dxo-behavior callValueChanged="onMoving" [snapToTicks]="false">
					</dxo-behavior>
					<dxo-chart>
						<dxo-value-axis valueType="numeric"></dxo-value-axis>
						<dxi-series type="line" valueField="Open" argumentField="Date">
							<dxo-aggregation [enabled]="true"></dxo-aggregation>
						</dxi-series>
					</dxo-chart>
				</dx-range-selector>
			</div>

			<div class="spider-web-demo">
				<dx-polar-chart id="chart" [useSpiderWeb]="true" title={{dashBoardStrings.fundsClassification}}
					[dataSource]="productionData">
					<dxi-series valueField="apples" name="Metric A"></dxi-series>
					<dxi-series valueField="grapes" name="Metric B"></dxi-series>
					<dxi-series valueField="lemons" name="Metric C"></dxi-series>
					<dxi-series valueField="oranges" name="Metric D"></dxi-series>
					<dxo-common-series-settings type="line"></dxo-common-series-settings>
					<dxo-export [enabled]="true"></dxo-export>
					<dxo-tooltip [enabled]="true"></dxo-tooltip>
				</dx-polar-chart>
			</div>

			<div class="range-selector-demo">
				<dx-chart id="zoomed-chart" [dataSource]="dataSource" title="Treasury Bonds Issuance(2018/2019)">
					<dxi-series></dxi-series>
					<dxo-argument-axis [valueMarginsEnabled]="false" [visualRange]="visualRange" type="logarithmic"
						[minorTickCount]="10">
						<dxo-label format="exponential"></dxo-label>
						<dxo-grid [visible]="true"></dxo-grid>
						<dxo-minor-grid [visible]="true"></dxo-minor-grid>
					</dxo-argument-axis>
					<dxi-value-axis [placeholderSize]="50"></dxi-value-axis>
					<dxo-legend [visible]="false"></dxo-legend>
				</dx-chart>
				<dx-range-selector id="range-selector" [dataSource]="dataSource" [(value)]="visualRange">
					<dxo-size [height]="120"></dxo-size>
					<dxo-chart>
						<dxi-series></dxi-series>
					</dxo-chart>
					<dxo-scale type="logarithmic" [minorTickCount]="10" [minRange]="1">
						<dxo-label format="exponential"></dxo-label>
					</dxo-scale>
					<dxo-slider-marker format="exponential"></dxo-slider-marker>
					<dxo-behavior callValueChanged="onMoving" [snapToTicks]="false">
					</dxo-behavior>
				</dx-range-selector>
			</div>
		
	</div>
</div>