import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-nav-bar-settings',
	templateUrl: './nav-bar-settings.component.html',
	styleUrls: ['./nav-bar-settings.component.scss']
})
export class NavBarSettingsComponent implements OnInit {

	//change this to start the select with the previously selected value
	defaultSelectedMenuItem = new FormControl('2');

	constructor(private _bottomSheetRef: MatBottomSheetRef<NavBarSettingsComponent>) { }

	ngOnInit() {
		/**
		 * On initialized get the settings. Probably from local storage on the browser.
		 */
	}
	dismissBottomNavBarSettings(event: any) {
		/**
		 * Before dismissing, check the value of the form and save the changes.
		 */
		let selectedMenuItem = this.defaultSelectedMenuItem.value;
		this._bottomSheetRef.dismiss();
		event.preventDefault();
	}

}
