import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class DividendsImporterService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportDividends(file: any): Observable<any> {
    return this.http
      .post<any>(`${this.url}/DividendsImport/DividendsImport`, file)
      .pipe(shareReplay());
  }

  SaveDividends(payload: any): Observable<any> {
    return this.apiService
      .Post("DividendsImport/SaveParsedDividendsPurchases", payload)
      .pipe(shareReplay());
  }

  getDividendColumns(): any {
    let dividendColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "ISIN", dataField: "StockISIN" },

      { caption: "Currency", dataField: "Currency" },
      { caption: "Amount", dataField: "Amount" },
      {
        caption: "Receipt Date",
        dataField: "ReceiptDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
    ];
    return dividendColumns;
  }
}
