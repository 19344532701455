<div class="nav-bar-settings-container">
	<div class="title-area">
		<h4>Analytics Section Settings</h4>
	</div>
	<mat-divider></mat-divider>
	<div class="settings-section">
		<div class="focused-menu-item-section">
			<div class="menu-items-description">
				When I select an <span style="font-weight: 900">Asset Class</span>, select all children <span
					style="font-weight: 900">Asset Categories</span>
			</div>
			<dx-check-box [(value)]="selectAllAssetCategoriesInAssetClass">
			</dx-check-box>
		</div>
		<div class="save-button" (click)="dismissBottomNavBarSettings($event)">
			<button mat-raised-button color="accent">Save</button>
		</div>
	</div>
</div>