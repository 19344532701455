import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Column } from "devextreme/ui/data_grid";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { Subject, Subscription } from "rxjs";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import {
  DxDataGridComponent,
  DxDateBoxComponent,
  DxListComponent,
  DxLookupComponent,
} from "devextreme-angular";
import { ApiService } from "src/app/app-services/api/api.service";
import { DxiRowComponent } from "devextreme-angular/ui/nested";
import { MatButton } from "@angular/material/button";

import * as _ from "underscore";
import { PortfolioRebalancingView } from "./data-object";
import { TestInstrumentCategory } from "../../non-portfolio/non-portfolio-analytics/helper-classes";
import { SelectedFundConfigService } from "src/app/app-services/selected-fund-config/selected-fund-config.service";

@Component({
  selector: "app-portfolio-rebalancing-report",
  templateUrl: "./portfolio-rebalancing-report.component.html",
  styleUrls: ["./portfolio-rebalancing-report.component.scss"],
})
export class PortfolioRebalancingReportComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  tabItems: [{ name: "Tab" }, { name: "Report" }];
  // @ViewChild('investorLookupInstance', { static: false }) investorLookupInstance: DxLookupComponent;
  @ViewChild("complianceGridInstance", { static: false })
  complianceGridInstance: DxDataGridComponent;
  // @ViewChild('startDateBox', { static: false }) startDateBoxInstance: DxDateBoxComponent;
  // @ViewChild('endDateBox', { static: false }) endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("refreshButton", { static: false })
  refreshButtonInstance: MatButton;
  @ViewChild("fundsListView", { static: false }) fundsList: DxListComponent;

  assetCategoryTabsSubject: Subject<Array<TestInstrumentCategory>> =
    new Subject<Array<TestInstrumentCategory>>();
  assetCategoryTabsObservable = this.assetCategoryTabsSubject.asObservable();

  gridKeyExpression = ["Date", "StockName"]; // might need to have a guid from the API with each row data for uniqueness
  summaryColumnNames = ["ActualHolding"];
  chartTitle: string;

  _appSettingsServiceSubscription: Subscription;
  _investorsSubscription: Subscription;
  _startDateChangeSubscription: Subscription;
  _reportSubscription: Subscription;
  _apiServiceSubscription: Subscription;

  rebalancingColumns: Column[] = [
    {
      dataField: "Date",
      caption: "Date",
      dataType: "date",
      groupIndex: 0,
      format: "dd-MMM-yyyy",
    },
    {
      dataField: "StockName",
      caption: "Stock Name",
      dataType: "string",
      sortOrder: "asc",
    },
    {
      dataField: "SharePrice",
      caption: "Share Price",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "NumberOfShares",
      caption: "No. of Shares",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "MarketValue",
      caption: "Market Value",
      dataType: "number",
      format: { precision: 2 },
    },
    // { dataField: 'AverageCost', caption: 'AverageCost', dataType: 'string' },
    {
      dataField: "Weight",
      caption: "% Portfolio",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "HouseView",
      caption: "H.V (%)",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "HouseViewCash",
      caption: "House View (KSh)",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "HouseViewShares",
      caption: "H.V Shares",
      dataType: "number",
      format: { precision: 2 },
    },
    {
      dataField: "RebalanceAction",
      caption: "(Buy / Sell)",
      dataType: "number",
      format: { precision: 2 },
      cssClass: "highlighted-columnn-1",
    },
  ];

  summaryColumns = [
    "MarketValue",
    "Weight",
    "HouseView",
    "HouseViewCash",
    "HouseViewShares",
    "RebalanceAction",
  ];

  isDrawerOpen: Boolean = true;

  toolbarContent = [
    {
      widget: "dxButton",
      location: "before",
      options: {
        icon: "menu",
        text: "Show / hide funds",
        type: "default",
        onClick: () => (this.isDrawerOpen = !this.isDrawerOpen),
      },
    },
  ];

  constructor(
    navBarSettingsService: NavbarHeaderSettingsService,
    public apiService: ApiService,
    public selectedFundConfigService: SelectedFundConfigService
  ) {
    navBarSettingsService.ChangeActiveComponentName("Portfolio Rebalancing");
  }

  ngOnInit() {
    this._apiServiceSubscription = this.apiService
      .Get("Investors")
      .subscribe((res) => {
        this.fundsList.showSelectionControls = true;
        this.fundsList.selectionMode = "all";
        this.fundsList.pageLoadMode = "scrollBottom";
        this.fundsList.displayExpr = "Name";
        this.fundsList.keyExpr = "ID";

        this.fundsList.dataSource = res;
        //! Test - remove this when done testing
        this.fundsList.selectedItemKeys = [
          "d5728c0d-f910-ea11-80ed-000c2995d985",
        ];
      });

    //fetch the asset categories

    this.apiService
      .Get("analyticssetup/GetAllInstrumentCategories")
      .subscribe((instrumentCategories: Array<any>) => {
        let assetCats = instrumentCategories.map((instrumentCategory) => {
          let category = new TestInstrumentCategory();
          category.id = instrumentCategory.Id;
          category.text = instrumentCategory.Name;
          category.code = instrumentCategory.Code;
          category.icon = "reorder";
          category.content = instrumentCategory.Name + " Asset Category";
          category.iconStyle = { color: "black" };
          category.analysisLevel = "asset level";
          return category;
        });
        /**\
         * Add total fund
         */
        let totalFundCategory = new TestInstrumentCategory();
        totalFundCategory.id = assetCats[0].id; //! This is a test, remove this eventually
        totalFundCategory.text = "Total Fund";
        totalFundCategory.code = "fixed corporate bonds";
        totalFundCategory.icon = "table_view";
        totalFundCategory.content =
          "Total Fund - More information on what total fund is goes here";
        totalFundCategory.iconStyle = {
          color: "red",
          "font-weight": "bold",
        };
        (totalFundCategory.analysisLevel = "total fund"),
          (totalFundCategory.defaultWeighting = "True");

        assetCats.push(totalFundCategory);
        this.assetCategoryTabsSubject.next(assetCats);
      });
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
    if (this._reportSubscription !== undefined)
      this._reportSubscription.unsubscribe();
    if (this._apiServiceSubscription !== undefined)
      this._apiServiceSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    /**
     * Update end date minimum value on start date change
     *
     */

    this.selectedFundConfigService
      .getlatestObservableValue()
      .subscribe((result) => {
        let requestObject = {
          InvestorId: result.SelectedInvestorId,
          StartDate: result.SelectedStartDate,
          EndDate: result.SelectedEndDate,
        };

        this._reportSubscription = this.apiService
          .Get(
            `reports/PortfolioRebalancingReport?InvestorId=${requestObject.InvestorId}&StartDate=${requestObject.StartDate}&EndDate=${requestObject.EndDate}`,
            requestObject
          )
          .subscribe((complianceReportArray: PortfolioRebalancingView[]) => {
            this.closeRequest();

            this.complianceGridInstance.dataSource = complianceReportArray;
            this.complianceGridInstance.columns = this.rebalancingColumns;
          });
      });

    this.chartTitle = "Portfolio Rebalancing";

    // this.fundsList.showSelectionControls = true
    // this.fundsList.selectionMode = 'all'
    // this.fundsList.pageLoadMode = 'scrollBottom'
    // this.fundsList.displayExpr = 'Name'
    // this.fundsList.keyExpr = 'ID'
  }

  calculatePercentageValue(rowData: number) {
    //! Not in use, the team insisted on using two decimal places, hence the settings are in the column definitions array with the property precision
    return rowData;
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          event.value = `${value}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);
          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");
          event.cellElement.innerHTML = `(${textDisplayed})`;
        }
        if (value === -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
      }
    }

    //asset category goes here
    if (event.rowType == "data") {
      let text: string = event.text;
      if (text !== undefined) {
        if (text.search("/*")) {
          console.error(text);
        }
      }
    }

    if (event.rowType == "header") {
      if (event.columnIndex == 0) {
        if (event.column.dataField === "AssetCategory") {
          event.cellElement.classList.add(
            "regulatory-limit-grid-columns-categories"
          );
        } else {
          event.cellElement.classList.add("regulatory-limit-grid-columns");
        }
      } else {
        event.cellElement.classList.add("regulatory-limit-grid-columns");
      }
    }
  }

  customizeSummaryText(data) {
    let numberWithCommas = (numberToModify: any) => {
      var parts = numberToModify.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    };

    let value = data.value;
    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let numberWithDecimalPoints = absoluteValue.toFixed(2);

      let formattedWithCommas = numberWithCommas(numberWithDecimalPoints);
      return `(${formattedWithCommas})`;
    } else {
      let formattedDecimal = value.toFixed(2);
      let formattedWithCommas = numberWithCommas(formattedDecimal);

      return formattedWithCommas;
    }
  }

  FormatChartTitle() {}

  onRowInsertingCheckbreach(e) {
    if (e.rowType == "data") {
      // const dataObjectForRow = e.data;
      //
      // const varianceWithRBAMin = dataObjectForRow.VarianceWithRBAMin
      // const varianceWithRBAMax = dataObjectForRow.VarianceWithRBAMax
      //
      // const varianceWithIPSMin = dataObjectForRow.VarianceWithIPSLimitMin
      // const varianceWithIPSMax = dataObjectForRow.VarianceWithIPSLimitMax
      //
      // if (varianceWithRBAMin <= -0 || varianceWithRBAMax <= -0 || varianceWithIPSMin <= -0 || varianceWithIPSMax <= -0) {
      //     // check rows columns 5 and 6 for variance with RBA limit
      //     // check columns 8 and 9 for variance with IPS limit
      //     e.rowElement.classList.add('regulator-report-item-in-breach')
      // }
    }
  }

  customizeAssetCatname(rowData: DxiRowComponent) {
    //@ts-ignore
    let rabaMin = rowData.VarianceWithRBAMin;
    //@ts-ignore
    let rbaMax = rowData.VarianceWithRBAMax;
    //@ts-ignore
    let ipsLimitMin = rowData.VarianceWithIPSLimitMin;
    //@ts-ignore
    let ipsLimitMax = rowData.VarianceWithIPSLimitMax;
    if (
      rabaMin <= -0 ||
      rbaMax <= -0 ||
      ipsLimitMin <= -0 ||
      ipsLimitMax <= -0
    ) {
      //@ts-ignore
      return rowData.AssetCategory + " *";
    } else {
      //@ts-ignore
      return rowData.AssetCategory;
    }
  }

  fetchReport() {
    // let selectedInvestor = this.investorLookupInstance.value;
    // let selectedStartDate = this.startDateBoxInstance.value;
    // let selectedEndDate = this.endDateBoxInstance.value;
    // let requestObject = {
    //   InvestorId: selectedInvestor,
    //   StartDate: selectedStartDate,
    //   EndDate: selectedEndDate
    // }
    // this._reportSubscription = this.apiService.Post('reports/PortfolioRebalancingReport', requestObject).subscribe((complianceReportArray: PortfolioRebalancingView[]) => {
    //   this.closeRequest()
    //   this.complianceGridInstance.dataSource = complianceReportArray;
    //   this.complianceGridInstance.columns = this.rebalancingColumns
    // })
  }

  /**
   * Closes the api request to fetch results
   */
  closeRequest() {
    if (this._reportSubscription !== undefined) {
      this._reportSubscription.unsubscribe();
      this._reportSubscription = undefined;
    }
  }
}
