<div class="portfolio-analytics-container">
  <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
  <div class="portfolio-analytics-dates-section">
    <div class="dates-container">
      <div>
        <div class="label">Investor/Fund</div>
        <dx-drop-down-box
          #funddropdownInstance
          [(value)]="treeBoxValue"
          valueExpr="ID"
          displayExpr="name"
          placeholder="Select a Fund..."
          [dataSource]="FundsFundManagerDataSource"
          width="300"
        >
          <dx-tree-list
            height="500"
            width="500"
            #fundManagerTreeList
            [dataSource]="FundsFundManagerDataSource"
            keyExpr="ID"
            parentIdExpr="Head_ID"
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [showBorders]="true"
            (onSelectionChanged)="InvestorSelectionChanged($event)"
            noDataText="no data to display..."
            class="tree-list"
            [highlightChanges]="true"
            [repaintChangesOnly]="true"
            [focusedRowEnabled]="false"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [focusStateEnabled]="true"
            (onItemSelectionChanged)="treeList_itemSelectionChanged($event)"
            [autoExpandAll]="false"
          >
            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"> </dxo-sorting>
            <dxi-column
              dataField="Name"
              caption="Select Funds under a Fund Manager"
              sortOrder="asc"
              style="font-size: 200%; font-weight: 700"
            >
            </dxi-column>
            <dxo-selection mode="multiple"> </dxo-selection>
          </dx-tree-list>
        </dx-drop-down-box>
      </div>
      <div style="margin-right: 10px">
        <div class="label">Select Date</div>
        <dx-date-box
          #EndDateBox
          displayFormat="dd MMM yyyy"
          matTooltip="Period start date"
          width="200"
        >
        </dx-date-box>
      </div>
    </div>
    <div class="render-button">
      <div>
        <div
          *ngIf="
            _fetchFundsSubscription === undefined;
            else fetchingInProgressTemp
          "
        >
          <button
            #refreshButton
            mat-flat-button
            color="primary"
            (click)="fetchResults()"
          >
            <mat-icon> update </mat-icon>
            Refresh
          </button>
        </div>

        <ng-template #fetchingInProgressTemp>
          <button
            mat-raised-button
            color="warn"
            (click)="closeAnalyticsFetchRequest()"
            matTooltip="Cancel this request"
          >
            <mat-icon> cancel_presentation </mat-icon>
            Cancel
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- -------------------------------------------- Data Grid----------------------------------- -->
  <div class="funds-asset-cats-container">
    <div class="asset-cats-section" *ngIf="gridDataSource">
      <!-- attach button only visible to custodians -->
      <div
        *ngIf="
          this.activeTenantType == 'Fund Manager' ||
          this.activeTenantType == 'Custodian'
        "
        style="margin-left: 90%"
      >
        <dx-button
          text="attach files"
          stylingMode="contained"
          icon="fa fa-paperclip"
          type="default"
          [width]="120"
          [height]="30"
        ></dx-button>
      </div>
      <mat-tab-group
        mat-align-tabs="start"
        (selectedTabChange)="refreshGrid($event)"
      >
        <mat-tab label="Asset Class and Security Reconciliation">
          <dx-data-grid
            #resultsDataGrid
            [dataSource]="gridDataSource"
            [columns]="reconColumns"
            columnResizingMode="widget"
            [allowColumnResizing]="true"
            [showBorders]="true"
            [showRowLines]="true"
            [groupPanel]="{ visible: true }"
            [grouping]="{ autoExpandAll: true }"
            [searchPanel]="{
              visible: true,
              placeholder: 'search for anything',
              width: 500
            }"
            [allowColumnReordering]="false"
            [rowAlternationEnabled]="false"
            [export]="{
              enabled: true,
              fileName: 'Non-Portfolio-Analytics',
              allowExportSelectedData: true
            }"
            [columnChooser]="{ enabled: true, mode: 'select' }"
            [wordWrapEnabled]="false"
            [columnFixing]="true"
            [twoWayBindingEnabled]="true"
            [repaintChangesOnly]="false"
            [activeStateEnabled]="true"
            (onCellPrepared)="gridInstanceCellPrepared($event)"
            [focusStateEnabled]="true"
            [focusedRowEnabled]="false"
            [scrolling]="{ mode: 'standard', preloadEnabled: true }"
            height="{{ calculateGridHeight() }}"
            [showBorders]="true"
            [remoteOperations]="true"
            [repaintChangesOnly]="true"
            (onRowUpdating)="UpdateComment($event)"
            [pager]="{
              allowedPageSizes: [100, 200, 300, 400, 500],
              showPageSizeSelector: true,
              showInfo: true,
              visible: true
            }"
            [paging]="{
              pageSize: 100,
              enabled: true
            }"
            [rowAlternationEnabled]="true"
          >
            <dxo-editing mode="row" [allowUpdating]="true"></dxo-editing>
            <dxo-master-detail
              [enabled]="true"
              template="Securities"
            ></dxo-master-detail>
            <div *dxTemplate="let security of 'Securities'">
              <div class="master-detail-caption">Securities</div>
              <dx-data-grid
                columnResizingMode="widget"
                [focusStateEnabled]="true"
                [allowColumnResizing]="true"
                [dataSource]="security.data.SecuritiesReconciliation"
                [columns]="security.data.SecurityColumns"
                [showBorders]="true"
                (onCellPrepared)="gridInstanceCellPrepared($event)"
                [pager]="{
                  allowedPageSizes: [100, 200, 300, 400, 500],
                  showPageSizeSelector: true,
                  showInfo: true,
                  visible: true
                }"
                [paging]="{
                  pageSize: 20,
                  enabled: true
                }"
                [rowAlternationEnabled]="true"
                [export]="{
                  enabled: true,
                  fileName: 'Securities',
                  allowExportSelectedData: true
                }"
                [columnChooser]="{
                  enabled: true,
                  mode: 'select'
                }"
                [selection]="{ mode: 'single' }"
                [columnAutoWidth]="true"
                [columnFixing]="true"
                (onContentReady)="onContentReady($event)"
              >
              </dx-data-grid>
            </div>
          </dx-data-grid>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <!-- <div
    *ngIf="
      this.activeTenantName == 'Capital Markets Authority' ||
      this.activeTenantRegulator == 'cma-kenya'
    "
  >
    <div style="margin-left: 1%">
      <mat-card>
        <button
          mat-raised-button
          color="accent"
          matBadge="8"
          matBadgePosition="before"
          matBadgeColor="accent"
        >
          coming soon
        </button>
      </mat-card>
    </div>
  </div> -->
</div>
