import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  AfterViewInit,
} from "@angular/core";
import { ApiService } from "../../../../app-services/api/api.service";
import CustomStore from "devextreme/data/custom_store";
import { HttpParams } from "@angular/common/http";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  DxTreeViewComponent,
  DxTextBoxComponent,
  DxLookupComponent,
  DxFormComponent,
  DxCheckBoxComponent,
} from "devextreme-angular";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { NotificationService } from "src/app/app-services/notification/notification.service";

@Component({
  selector: "app-instrument-classes-form",
  templateUrl: "./instrument-classes-form.component.html",
  styleUrls: ["./instrument-classes-form.component.scss"],
})
export class InstrumentClassesFormComponent implements OnInit, AfterViewInit {
  @ViewChild(DxTreeViewComponent, { read: false, static: false }) treeView;
  @ViewChild("instrumentClassName", { static: false })
  InstrumentClassNameInstance: DxTextBoxComponent;
  @ViewChild("fundLookup", { static: false })
  FundLookupInstance: DxLookupComponent;
  @ViewChild("dataForm", { static: true }) dataFormInstance: DxFormComponent;
  @ViewChild("categoriesBox", { static: false })
  CategoriesCheckBoxesInstance: DxCheckBoxComponent;

  formValidationGroupName: "data-form-validation-group";

  nameValidationRule = [
    {
      type: "required",
      message: "Name is required",
    },
  ];

  fundValidationRule = [
    {
      type: "required",
      message: "Fund is required",
    },
  ];

  assetClassValidationRules = [
    {
      type: "required",
      message: "Fund is required",
    },
  ];

  assetCategoriesValidationRules = [
    {
      type: "required",
      message: "Pick atleast one asset category",
    },
  ];

  fundIdEditorOptions: any;
  assetClassOptions: any;
  descriptionOptions: any;
  assetCategoriesOptions: any;
  assetCategories: any;
  isEditAction = false;
  disableActionControl: boolean;
  formData: any;
  formItems: any;
  postEndPoint: string;
  initialFormData: any;
  instrumentCategoriesObject: Array<any>;

  treeDataSource: any;
  treeBoxValue: string[];
  title = "Asset Class";
  constructor(
    @Inject(MAT_DIALOG_DATA) public formOptions: any,
    public dialogRef: MatDialogRef<InstrumentClassesFormComponent>,
    private apiService: ApiService,
    private matDialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.formData = formOptions.formData;
    this.formItems = formOptions.formItems;
    this.title = formOptions.formTitle;
    this.postEndPoint = formOptions.postEndPoint;

    this.fundIdEditorOptions = {
      dataSource: new CustomStore({
        key: "ID",
        load: (loadOptions: any) => {
          if (loadOptions.searchExpr === "Name") {
            let params = new HttpParams();
            params = params.append("name", loadOptions.searchValue);
            return this.apiService
              .Get("Investors/FindByName?name=" + loadOptions.searchValue)
              .toPromise();
          }
          return this.apiService.Get("investors").toPromise();
        },
        byKey: (key) => {
          let params = new HttpParams();
          params = params.append("id", key);
          return this.apiService.Get("investors/" + key).toPromise();
        },
      }),
      valueExpr: "ID",
      displayExpr: "Name",
    };
    this.descriptionOptions = {
      autoResizeEnabled: true,
      minHeight: 100,
      maxHeight: 200,
    };

    this.assetClassOptions = {
      dataSource: new CustomStore({
        key: "Id",
        load: (loadOptions: any) => {
          // if (loadOptions.searchExpr === 'Name') {
          // 	let params = new HttpParams();
          // 	params = params.append('name', loadOptions.searchValue);
          // 	return this.apiService.Get('AnalyticsSetup/FindInstrumentClasses?investorId=' + loadOptions.searchValue).toPromise();
          // }
          return this.apiService
            .Get("AnalyticsSetup/FindAssetClasses")
            .toPromise();
        },
        byKey: (key) => {
          let params = new HttpParams();
          params = params.append("id", key);
          return this.apiService
            .Get("AnalyticsSetup/FindInstrumentClasses?investorId/" + key)
            .toPromise();
        },
      }),
      valueExpr: "Id",
      displayExpr: "Name",
      label: "Asset Class",
    };

    this.treeDataSource = this.makeAsyncDataSource(
      this.apiService,
      "AssetCategories"
    );
  }

  makeAsyncDataSource(apiService: ApiService, url) {
    return new CustomStore({
      loadMode: "raw",
      key: "ID",
      load: function () {
        return apiService.Get(url).toPromise();
      },
    });
  }

  ngOnInit() {
    if (this.formData !== null) {
      this.isEditAction = true;
    }
    this.disableActionControl = true;

    const _intitialFormData = this.formData;

    this.initialFormData = _intitialFormData;

    return this.apiService
      .Get("InstrumentCategories")
      .subscribe((assetCats) => {
        this.assetCategories = assetCats;
      });
  }

  ngAfterViewInit(): void {
    this.dataFormInstance.validationGroup = this.formValidationGroupName;
    this.dataFormInstance.labelLocation = "top";
  }

  syncTreeViewSelection(e) {
    let component =
      (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(
        function (value) {
          component.selectItem(value);
        }.bind(this)
      );
    }
  }

  getSelectedItemsKeys(items) {
    let result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
    console.log(this.treeBoxValue);
  }

  SaveAction(event: any) {
    let validationRes = this.dataFormInstance.instance.validate();
    if (validationRes.isValid && this.treeBoxValue !== null) {
      this.dataFormInstance.disabled = true;
      this.disableActionControl = true;
      if (this.isEditAction === true) {
        let formData = this.dataFormInstance.formData;

        this.apiService.Put(this.postEndPoint, formData).subscribe(
          () => {
            let response = "Entity Updated";
            this.notificationService.showInfo(response, "");
            this.dialogRef.close();
          },
          (error) => {
            this.dataFormInstance.disabled = false;
            this.disableActionControl = false;
          }
        );
      } else {
        //is a save action
        let formData = this.dataFormInstance.formData;
        this.apiService.Post(this.postEndPoint, formData).subscribe(
          (result) => {
            let response = "New entity created";
            this.notificationService.showSuccess(response, "");
            // this.dialogRef.close();
            this.dataFormInstance.disabled = false;
          },
          (error) => {
            this.notificationService.showError(error, "");
            this.dialogRef.close();
          },
          () => {
            this.dataFormInstance.disabled = false;
            this.disableActionControl = false;
          }
        );
      }
    }
  }

  ClearFormContents(event) {}

  closeForm(event: any) {
    this.dialogRef.close();
  }
  ModelGroupsItemsSelected(e) {}
  treeList_itemSelectionChanged(e) {}
}
