import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';


@Component({
	selector: 'app-commercial-paper',
	templateUrl: './commercial-paper.component.html',
	styleUrls: ['./commercial-paper.component.scss']
})
export class CommercialPaperComponent implements OnInit {

	gridDataSource: any;
	EntityName = 'Commercial Papers';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single'}

	public Columns: Array<any> = [
		{ caption: 'Code', dataField: 'Code', width:250, cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Actual Maturity Date', dataField: 'ActualMaturityDate', dataType: 'date' },
		{ caption: 'Maturity Date', dataField: 'MaturityDate', dataType: 'date' },
		{ caption: 'Issue Date', dataField: 'IssueDate', dataType: 'date' },
		{ caption: 'Issue Price(KES)', dataField: 'IssuePrice', dataType: 'number', format: 'decimal fixedPoint' },
		{ caption: 'Issue Value(KES)', dataField: 'IssueValue', dataType: 'number', format: 'decimal fixedPoint' },
		{ caption: 'Security Type', dataField: 'SecurityType' },
		{ caption: 'Apply Market Charges', dataField: 'ApplyMarketCharges', dataType: 'boolean' },
		{ caption: 'Approved', dataField: 'Approved', dataType: 'boolean' },
	];
	constructor(private apiService: ApiService, private title: Title,private navBarSettingsService: NavbarHeaderSettingsService) { 
		this.navBarSettingsService.ChangeActiveComponentName('Commercial Papers')
	}

	ngOnInit() {
		this.apiService.Get('CommercialPapers').subscribe(result => this.gridDataSource = result);
	}

	rowDoubleClicked(event: any) {
	}
}
