export class ApplicationSettings {
  DefaultDateRange: DefaultDateRange = null;
  FontSize = null;
  PercentageValuesDecimalPoints: ApplicationDecimalPoints = 4;
  NonPercentageValuesDecimalPoints: ApplicationDecimalPoints = 4;
  constructor() {}
}
export enum ApplicationDecimalPoints {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
}

export class DefaultDateRange {
  StartDate: string;
  EndDate: string;
  constructor(startDate: string, endDate: string) {
    this.StartDate = startDate;
    this.EndDate = endDate;
  }
}
