<nav>
  <button (click)="toggle()" class="floating-button">
    <i class="fa" [ngClass]="{ 'fa-comments': !showX, 'fa-times': showX }"></i>
  </button>
</nav>
<div
  [@openClose]="isOpen ? 'open' : 'closed'"
  class="open-close-container"
  class="open-close-container"
>
  <div *ngIf="isOpen" class="chat draggable" appDraggable>
    <div class="chat-title">
      <h1>Innova Analytics</h1>
      <h2>Language Model AI</h2>
      <figure class="avatar">
        <img src="../../../assets/icons/chatbot2.png" />
      </figure>
    </div>
    <div #messagesContainer class="messages">
      <h2 class="welcome-message" *ngIf="messagesHistory.length < 1">
        Hello, How can I help today?
      </h2>
      <div
        *ngFor="let message of messagesHistory"
        [ngClass]="{ 'messages-content': message.parts }"
      >
        <span
          [ngClass]="{
            gemini: message.role === 'model',
            user: message.role === 'user'
          }"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-comments': message.role === 'model',
              'fa-user': message.role === 'user'
            }"
            aria-hidden="true"
          ></i>
          &nbsp;{{ message.role === "model" ? "Innova AI" : "User" }}:&nbsp;
        </span>
        <span
          class="text"
          [innerHTML]="message.parts | convertTextToHtml"
        ></span>
      </div>
      <div class="loading-container">
        <div class="loading">
          <ngx-loading [show]="loading" [config]="loadingConfig"></ngx-loading>
        </div>
      </div>
    </div>
    <div class="message-box">
      <div class="prepared-messages">
        <ul [@typeWritterEffect]>
          <li
            *ngFor="let question of bQuestions"
            (click)="sendMessage(question); userMessage = ''"
          >
            {{ question }}
          </li>
        </ul>
      </div>
      <textarea
        [(ngModel)]="userMessage"
        (keydown.enter)="sendMessage(userMessage); userMessage = null"
        type="text"
        class="message-input"
        placeholder="Ask Innova AI"
        rows="3"
      ></textarea>
      <button
        mat-raised-button
        (click)="toggleSendOrCancel(userMessage); userMessage = null"
        type="button"
        class="message-submit"
        [ngClass]="{ 'cancel-button': isSending }"
      >
        {{ isSending ? "Cancel" : "Send" }}
      </button>
    </div>
  </div>
</div>
