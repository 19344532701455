import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appDraggable]",
})
export class DraggableDirective {
  private pos1 = 0;
  private pos2 = 0;
  private pos3 = 0;
  private pos4 = 0;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.position = "absolute"; // Ensure element is positioned
  }

  @HostListener("mousedown", ["$event"]) onMouseDown(event: MouseEvent) {
    // Prevent dragging when clicking on input or textarea elements
    const target = event.target as HTMLElement;
    if (target.tagName === "TEXTAREA" || target.tagName === "INPUT") {
      return; // Skip dragging for inputs or textareas
    }

    event.preventDefault();
    this.pos3 = event.clientX;
    this.pos4 = event.clientY;

    document.onmouseup = this.closeDragElement.bind(this);
    document.onmousemove = this.elementDrag.bind(this);
  }

  private elementDrag(event: MouseEvent) {
    event.preventDefault();
    // Calculate the new cursor position
    this.pos1 = this.pos3 - event.clientX;
    this.pos2 = this.pos4 - event.clientY;
    this.pos3 = event.clientX;
    this.pos4 = event.clientY;

    // Set the element's new position
    this.el.nativeElement.style.top =
      this.el.nativeElement.offsetTop - this.pos2 + "px";
    this.el.nativeElement.style.left =
      this.el.nativeElement.offsetLeft - this.pos1 + "px";
  }

  private closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
