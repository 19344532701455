import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApplicationSettingsService } from 'src/app/app-services/app-settings/application-settings.service';
import { ConfigService } from 'src/app/common/config.service';

@Injectable({
  providedIn: 'root'
})
export class OidcGuardService implements CanActivate, CanLoad {
  constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private appSettingsService: ApplicationSettingsService, private configService: ConfigService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser();
  }

  canLoad(state: Route): Observable<boolean> {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    if (this.configService.config.SkipAuthentication)
      return of(true)
    else {
      return this.oidcSecurityService.checkAuth().pipe(
        map((isAuthorized: boolean) => {
          if (!isAuthorized) {
            this.router.navigate(['/login']);
            return false;
          }
          return true;
        })
      );
    }
  }
}
