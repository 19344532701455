import { Component, OnInit, OnDestroy } from "@angular/core";

import { ApiService } from "src/app/app-services/api/api.service";
import { MatDialog } from "@angular/material/dialog";
import CustomStore from "devextreme/data/custom_store";
import { HttpParams } from "@angular/common/http";
import {
  DataFormOptions,
  DataFormComponent,
} from "src/app/app-directives/data-form/data-form.component";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-investor-sub-type",
  templateUrl: "./investor-sub-type.component.html",
  styleUrls: ["./investor-sub-type.component.scss"],
})
export class InvestorSubTypeComponent implements OnInit {
  gridDataSource: any;
  EntityName = "Investor Sub Types";
  GridKeyExpression = "Name";

  public Columns: any = [
    {
      caption: "Name",
      dataField: "Name",
      cssClass: "grid-focus-column",
      alignment: "left",
      sortOrder: "acs",
      width: "300",
    },
    {
      caption: "InvestorType",
      dataField: "InvestorType",
      width: "300",
    },
    {
      caption: "Notes",
      dataField: "Notes",
    },
    { caption: "Active", dataField: "Active", dataType: "boolean" },
    { caption: "Approved", dataField: "Approved", dataType: "boolean" },
  ];

  CyclesEnum: any;
  formItems: Array<any>;
  ApiSubscription1: Subscription = undefined;

  constructor(
    private apiService: ApiService,
    public matDialog: MatDialog,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);

    this.formItems = [
      {
        label: { text: "Name" },
        dataField: "Name",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
        editorType: "dxTextBox",
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true,
        },
      },
      {
        dataField: "InvestorTypeId",
        cssClass: "required-form-item",
        validationRules: [
          {
            type: "required",
            message: "InvestorType is required",
          },
        ],
        editorType: "dxLookup",
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: (loadOption) => {
              return this.apiService.Get("InvestorTypes").toPromise();
            },
            byKey: (key) => {
              return this.apiService
                .Get("InvestorTypes/GetById?id=" + key)
                .toPromise();
            },
          }),
          valueExpr: "ID",
          displayExpr: "Name",
        },
        label: {
          text: "Investor Type",
        },
      },
      {
        dataField: "Notes",
        editorType: "dxTextArea",
        editorOptions: {
          height: 70,
          placeholder: "Notes...",
        },
      },
      {
        dataField: "Active",
        editorType: "dxCheckBox",
      },
      {
        dataField: "Approved",
        editorType: "dxCheckBox",
      },
    ];
  }

  ngOnInit() {
    this.ApiSubscription1 = this.apiService
      .Get("InvestorSubTypes")
      .subscribe((result) => (this.gridDataSource = result));
  }

  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe();
    }
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = rowData;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `Edit Investor Sub Type - ${rowData.Description}`;
    dataFormOptions.postEndPoint = "InvestorSubTypes";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });
    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((res) => {
      this.ngOnInit();
    });
  }

  AddNew(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `New Investor Sub Type`;
    dataFormOptions.postEndPoint = "InvestorSubTypes";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}
