import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";

import settingsJson from "../../assets/settings/config.json";

@Injectable()
export class ConfigService {
  config: IApplicationConfig;
  constructor(private http: HttpClient) {}

  loadConfig(config) {
    this.config = config;

    const jsonFile = "assets/settings/config.json";

    // return new Promise<string>((resolve, reject) => {
    //     this.http.get(jsonFile).toPromise().then((response: IApplicationConfig) => {
    //         this.config = <IApplicationConfig>response;
    //         console.log(this.config);
    //         resolve('configuration loaded');   //Return Sucess
    //     }).catch((response: any) => {
    //         reject(`Failed to load the config file`);
    //     });
    // });
  }
}

export interface IApplicationConfig {
  APIURL: string;
  MatrixUIURL: string;
  STSURL: string;
  LanguageModelAPI: string;
  MatrixUIClientIdAsInSTS: string;
  ReportServerURL: string;
  ShowDemoModeTagOnHeader: boolean;
  SkipAuthentication: boolean;
}
