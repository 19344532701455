import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private url = "";
  private reportUrl = "";

  private httpOptions = {
    headers: new HttpHeaders(),
  };

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
    this.reportUrl = this.configService.config.ReportServerURL;
    //this.httpOptions.headers.append("responseType", "arraybuffer");
    this.httpOptions.headers.append("observe", "response");
    this.httpOptions.headers.append("Content-Type", "application/json");
  }

  getAssetClassesByInvestorId(InvestorId: any, TenantId: any): Observable<any> {
    return this.http.get(
      `${this.url}/AnalyticsSetup/FindInstrumentClasses?investorId=${InvestorId}&tenantId=${TenantId}`
    );
  }

  getFundManagerCISReturnReports(requestPayload: any): Observable<any> {
    return this.http.post(`${this.reportUrl}/api/ZambiaSEC`, requestPayload, {
      responseType: "blob",
    });
  }
}
