<div class="main-container">
  <div>
    <dx-select-box #AssetLevelLookupInstance></dx-select-box>
  </div>

  <div>
  <div>
    two
  </div>

</div>
<div></div>


<div></div>
