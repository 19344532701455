<div>
  <dx-tab-panel
    #reportCategoriesPanel
    [showNavButtons]="true"
    style="
      height: 460px;
      width: 100%;
      background-color: red;
      background-color: rgba(255, 255, 255, 0.336);
    "
    [activeStateEnabled]="true"
  >
    <dxi-item
      title="Regulatory Compliance Reports"
      class="report-group-contents"
      icon="aligncenter"
    >
      <div *ngFor="let reportGroup of ReportGroupItems" style="margin-top: 2%">
        <dx-tree-list
          #custodianTreeList
          [id]="reportGroup.ReportGroupName"
          [dataSource]="reportGroup.ReportItems"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          [width]="300"
          (onCellClick)="
            ModelGroupsItemsSelected($event, reportGroup.ReportGroupName)
          "
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="true"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
          (onCellHoverChanged)="showMoreInfoOnHover($event)"
          (onCellPrepared)="onCellPrepared($event)"
        >
          <dxo-selection mode="single" [recursive]="false"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="ReportName"
            sortOrder="asc"
            [caption]="
              updateCaption(
                reportGroup.ReportGroupName,
                reportGroup.ReportItems.length
              )
            "
            style="font-size: 200%"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
    </dxi-item>
    <dxi-item title="Reconciliation Reports" icon="aligncenter">
      <mat-card>
        <button
          mat-raised-button
          color="accent"
          matBadge="8"
          matBadgePosition="before"
          matBadgeColor="accent"
        >
          coming soon
        </button>
      </mat-card>
    </dxi-item>
  </dx-tab-panel>
</div>
