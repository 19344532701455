<div class="error404-title">
    404
</div>

<div class="error404-body">
    The resource you are looking for could not be found
</div>

<div class="error404-actions">
    <!-- <div>
        <button mat-flat-button>Go Back</button>
    </div> -->
    <div>
        <button mat-raised-button color="warn" (click)="close($event)">Close</button>
    </div>
</div>