import { AssetLevel } from './asset-class-type';
import { SelectedAnalyticsModelDescription } from './analytics-model';

/**
 * The category selected for a specified investor
 */
export class SelectedCategory {
	/**
	 * Initialize the selected category class.
	 * @param name The name of the asset Classification
	 * @param propId The Id of the asset classification
	 */
	constructor(_name: string, _propId: string, _assetClassType: AssetLevel) {
		this.propertyName = _name;
		this.propertyId = _propId;
		this.assetClassType = _assetClassType;
	}
	propertyName: string;
	propertyId: string;
	assetClassType: AssetLevel;
}

export class AnalyticsModelClassCombination {
	selectedCategory: SelectedCategory;
	selectedAnalyticsModelDescription: SelectedAnalyticsModelDescription;

	/**
	 *
	 */
	constructor(SelectedCategory: SelectedCategory,
		SelectedAnalyticsModelDescription: SelectedAnalyticsModelDescription) {
		this.selectedCategory = SelectedCategory;
		this.selectedAnalyticsModelDescription = SelectedAnalyticsModelDescription;
	}
}