import { Component, OnInit, OnDestroy } from "@angular/core";

import { ApiService } from "src/app/app-services/api/api.service";
import { MatDialog } from "@angular/material/dialog";
import {
  DataFormOptions,
  DataFormComponent,
} from "src/app/app-directives/data-form/data-form.component";
import CustomStore from "devextreme/data/custom_store";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Subscription } from "rxjs";
import { Column } from "devextreme/ui/data_grid";

@Component({
  selector: "app-limits",
  templateUrl: "./limits.component.html",
  styleUrls: ["./limits.component.scss"],
})
export class LimitsComponent implements OnInit, OnDestroy {
  gridDataSource: any;
  EntityName = "Limits";

  public Columns: Array<Column> = [
    { caption: "Limit Type", dataField: "LimitType" },
    { caption: "Asset Category", dataField: "AssetCategory" },
    {
      caption: "Minimum Rate (%)",
      dataField: "MinimumRate",
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 },
    },
    {
      caption: "Preferred Rate (%)",
      dataField: "PreferredRate",
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 },
    },
    {
      caption: "Maximum Rate (%)",
      dataField: "MaximumRate",
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 },
    },
    { caption: "Effective Date", dataField: "EffectiveDate" },
    { caption: "Active", dataField: "Active" },
  ];

  CyclesEnum: any;
  formItems: Array<any>;
  ApiSubscription1: Subscription = undefined;

  constructor(
    private apiService: ApiService,
    public matDialog: MatDialog,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);

    this.formItems = [
      {
        dataField: "LimitTypeId",
        cssClass: "required-form-item",
        validationRules: [
          {
            type: "required",
            message: "Limit Type is required",
          },
        ],
        editorType: "dxLookup",
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: (loadOption) => {
              if (loadOption.searchExpr === "Name") {
                if (loadOption.searchValue !== null) {
                  return this.apiService
                    .Get("LimitTypes/GetByName?name=" + loadOption.searchValue)
                    .toPromise();
                }
              }
              return this.apiService.Get("LimitTypes").toPromise();
            },
            byKey: (key) => {
              return this.apiService
                .Get("LimitTypes/GetById?id=" + key)
                .toPromise();
            },
          }),
          valueExpr: "ID",
          displayExpr: "Name",
        },
        label: {
          text: "Limit Type",
        },
      },
      {
        dataField: "InvestorId",
        // cssClass: 'required-form-item',
        // validationRules: [{
        // 	type: 'required',
        // 	message: 'Investor is required'
        // }],
        editorType: "dxLookup",
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: (loadOption) => {
              if (loadOption.searchExpr === "Name") {
                if (loadOption.searchValue !== null) {
                  return this.apiService
                    .Get("Investors/FindByName?name=" + loadOption.searchValue)
                    .toPromise();
                }
              }
              return this.apiService.Get("Investors").toPromise();
            },
            byKey: (key) => {
              return this.apiService
                .Get("Investors/GetById?id=" + key)
                .toPromise();
            },
          }),
          valueExpr: "ID",
          displayExpr: "Name",
        },
        label: {
          text: "Investor",
        },
      },
      {
        dataField: "AssetCategoryId",
        cssClass: "required-form-item",
        validationRules: [
          {
            type: "required",
            message: "Asset Category is required",
          },
        ],
        editorType: "dxLookup",
        editorOptions: {
          dataSource: new CustomStore({
            key: "ID",
            load: (loadOption) => {
              if (loadOption.searchExpr === "Name") {
                if (loadOption.searchValue !== null) {
                  return this.apiService
                    .Get(
                      "AssetCategories/FindByName?name=" +
                        loadOption.searchValue
                    )
                    .toPromise();
                }
              }
              return this.apiService.Get("AssetCategories").toPromise();
            },
            byKey: (key) => {
              return this.apiService
                .Get("AssetCategories/GetById?id=" + key)
                .toPromise();
            },
          }),
          valueExpr: "ID",
          displayExpr: "Name",
        },
        label: {
          text: "Asset Category",
        },
      },
      {
        dataField: "MinimumRate",
        editorType: "dxNumberBox",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
      },
      {
        dataField: "PreferredRate",
        editorType: "dxNumberBox",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
      },
      {
        dataField: "MaximumRate",
        editorType: "dxNumberBox",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
      },
      {
        dataField: "EffectiveDate",
        editorType: "dxDateBox",
      },
      {
        colSpan: 2,
        dataField: "Notes",
        editorType: "dxTextArea",
        editorOptions: {
          height: 90,
        },
      },
      {
        dataField: "Active",
        editorType: "dxCheckBox",
      },
    ];
  }

  ngOnInit() {
    //get all limits
    this.ApiSubscription1 = this.apiService
      .Get("Limits")
      .subscribe((result) => (this.gridDataSource = result));
  }

  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe();
    }
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = rowData;
    dataFormOptions.formID = rowData.LimitTypeId;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "Edit limit";
    dataFormOptions.postEndPoint = "limits";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  AddNew(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "New Limit";
    dataFormOptions.postEndPoint = "limits";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}
