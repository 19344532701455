import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable } from "rxjs";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  url: string = "";

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.url = this.configService.config.LanguageModelAPI;
  }

  generateWithOpenAI(payload: object): Observable<any> {
    return this.http.post<any>(this.url, payload, {});
  }
}
