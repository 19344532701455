<div class="innova-app-routing-area-title">
	Listed Equities
</div>

<mat-divider></mat-divider>

<div class="mean-container">
	<div class="mean-selection-items">
		<div class="filter-title">Show mean for: </div>
		<div>
			<dx-check-box text="Stock Price" [value]="true" (onValueChanged)="checkBoxToggled('StockPrice')">
			</dx-check-box>
		</div>
		<div>
			<dx-check-box text="Purchase Value" [value]="false" (onValueChanged)="checkBoxToggled('PurchaseValue')">
			</dx-check-box>
		</div>

	</div>
	<div class="grid-section">
		<dx-data-grid #dataGrid></dx-data-grid>
	</div>
</div>