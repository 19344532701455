import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ApplicationSettings } from './application-settings';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ApplicationSettingsService {

	AppSettingsSubject: ReplaySubject<ApplicationSettings> = new ReplaySubject<ApplicationSettings>(1);

	constructor() { }

	GetSettings() {
		// console.error('i have been called get');

		return this.AppSettingsSubject.asObservable().pipe(map(x => {
			let normalInString = localStorage.getItem('nrml-decPoints')
			x.NonPercentageValuesDecimalPoints = Number.parseInt(normalInString)
			let percentageInString = localStorage.getItem('pcntg-decPoints')
			x.PercentageValuesDecimalPoints = Number.parseInt(percentageInString)
			return x;
		}))

	}
	UpdateSettings(incomingAppSettings: ApplicationSettings) {
		let localAppSettings: ApplicationSettings = new ApplicationSettings()

		this.AppSettingsSubject.asObservable().subscribe(settings => {
			localAppSettings = settings
		})

		if (incomingAppSettings.DefaultDateRange !== null) {
			localAppSettings.DefaultDateRange = incomingAppSettings.DefaultDateRange
		}
		if (incomingAppSettings.FontSize !== null) {
			localAppSettings.FontSize = incomingAppSettings.FontSize
		}
		if (incomingAppSettings.NonPercentageValuesDecimalPoints !== null) { //put the settings in local storage
			localStorage.setItem('nrml-decPoints', incomingAppSettings.NonPercentageValuesDecimalPoints.toString())
			localAppSettings.NonPercentageValuesDecimalPoints = incomingAppSettings.NonPercentageValuesDecimalPoints
		}
		if (incomingAppSettings.PercentageValuesDecimalPoints !== null) { //put the settings in local storage
			localStorage.setItem('pcntg-decPoints', incomingAppSettings.PercentageValuesDecimalPoints.toString())
			localAppSettings.PercentageValuesDecimalPoints = incomingAppSettings.PercentageValuesDecimalPoints
		}

		this.AppSettingsSubject = new ReplaySubject<ApplicationSettings>(1);


		this.AppSettingsSubject.next(localAppSettings);
	}

}
