import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";

import {
  DxDateBoxComponent,
  DxListComponent,
  DxRadioGroupComponent,
  DxSwitchComponent,
  DxTabPanelComponent,
} from "devextreme-angular";
import { ApiService } from "src/app/app-services/api/api.service";
import { Subscription } from "rxjs";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import {
  AssetCategorySecurities,
  ModelClassification,
  ModelGroup,
  ModelGroupItem,
  ModelItem,
  ModelVariable,
  RenderButtonConfig,
  TestInstrumentCategory,
} from "./helper-classes";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";

import { MatDialog } from "@angular/material/dialog";
import { NonPortolioUpdaterService } from "../updater/non-portfilio-updater.service";
import { MatButton } from "@angular/material/button";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";

@Component({
  selector: "app-non-portfolio-analytics",
  templateUrl: "./non-portfolio-analytics.component.html",
  styleUrls: ["./non-portfolio-analytics.component.scss"],
})
export class NonPortfolioAnalyticsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("assetCategoriesPanel", { static: false })
  assetCategoriesPanelInstance: DxTabPanelComponent;
  @ViewChild("startDateBox", { static: false })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: false })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("securitiesList", { static: true })
  securitiesListInstance: DxListComponent;
  @ViewChild("variableList", { static: true })
  variableListInstance: DxListComponent;
  @ViewChild("modelsCacheSwitch", { static: false })
  modelsCacheSwitchInstance: DxSwitchComponent;

  @ViewChild("renderButton", { static: false }) renderButtonInstance: MatButton;

  @ViewChild("weightRadioGroup", { static: false })
  weightRadioGroupInstance: DxRadioGroupComponent;

  _appSettingsServiceSubscription: Subscription;
  _statisticalAnalysisSubscription: Subscription;
  _securitiesSubscription: Subscription;
  _instrumentCategoriesSubscription: Subscription;
  _modelGroupItemsSubscription: Subscription;
  _modelCategoriesSubscription: Subscription;
  _modelVariablesSubscription: Subscription;
  _modelGroupsSubscription: Subscription;
  _AssetCategoriesPanelSelectionSubscription: Subscription;
  _startDateChangeSubscription: Subscription;

  Selection = { mode: "single" };
  assetCategoryTabs: TestInstrumentCategory[];
  securitiesLength: number;
  ModelGroupItems: Array<ModelGroupItem>;
  SelectedModelKeys: Array<string>;
  DecimalPlacesSetting: number;
  weightingOptions = ["True", "False"];

  securitiesSectionCaption = "Securities";

  showSecurities: boolean = true;
  currentAssetCategory: any;
  panelOpenState = false;

  constructor(
    private apiService: ApiService,
    private appSettingsService: ApplicationSettingsService,
    public notificationService: SnackNotificationsService,
    public dialog: MatDialog,
    public nonPortfolioUpdaterService: NonPortolioUpdaterService,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Non-Portfolio analytics"
    );

    this.ModelGroupItems = new Array<ModelGroupItem>();
    this.SelectedModelKeys = new Array<string>();
  }

  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe();
    if (this._statisticalAnalysisSubscription !== undefined)
      this._statisticalAnalysisSubscription.unsubscribe();
    if (this._securitiesSubscription !== undefined)
      this._securitiesSubscription.unsubscribe();
    if (this._instrumentCategoriesSubscription !== undefined)
      this._instrumentCategoriesSubscription.unsubscribe();
    if (this._modelGroupItemsSubscription !== undefined)
      this._modelGroupItemsSubscription.unsubscribe();
    if (this._modelCategoriesSubscription)
      this._modelCategoriesSubscription.unsubscribe();
    if (this._modelVariablesSubscription !== undefined)
      this._modelVariablesSubscription.unsubscribe();
    if (this._modelGroupsSubscription !== undefined)
      this._modelGroupsSubscription.unsubscribe();
    if (this._AssetCategoriesPanelSelectionSubscription !== undefined)
      this._AssetCategoriesPanelSelectionSubscription.unsubscribe();
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    //default weighting
    this.weightRadioGroupInstance.value = this.weightingOptions[1];
    this.nonPortfolioUpdaterService.renderButtonDisableEnableConfig$.subscribe(
      (state) => {
        this.renderButtonInstance.disabled = state;
      }
    );

    this._AssetCategoriesPanelSelectionSubscription =
      this.assetCategoriesPanelInstance.onSelectionChanged.subscribe((res) => {
        let assetCategorySelected: TestInstrumentCategory = res.addedItems[0];
        this.currentAssetCategory = assetCategorySelected.text;
        let assetCategoryUnselected: TestInstrumentCategory =
          res.removedItems[0];

        //Disable weighting for Fixed Income asset categories

        this.weightRadioGroupInstance.disabled =
          assetCategorySelected.text === "Treasury Bills" ||
          assetCategorySelected.text === "Time Deposits";

        if (assetCategorySelected.text === "Macro-economic Variables") {
          this.securitiesSectionCaption = "Countries";
          //change from securities to countries

          this.apiService.Get("Countries/Get").subscribe((countries) => {
            // this.securitiesListInstance.visible = false

            let countriesList = countries.map((item) => {
              let equityResult: AssetCategorySecurities = {
                Id: item.ID,
                SecurityName: item.Name,
              };
              return equityResult;
            });

            this.securitiesListInstance.dataSource = countriesList;
            this.securitiesListInstance.instance.reload();
          });
          // replace securities with countrie
        } else {
          this.securitiesListInstance.visible = true;

          this.securitiesSectionCaption = "Securities";
          //change from securities to countries
          // SHOW THE SECURITIES
        }

        // * Update the selected asset category in the results section via a service
        this.nonPortfolioUpdaterService.updateSelectedAssetCategory(
          assetCategorySelected
        );
        if (assetCategoryUnselected !== undefined) {
          this._modelGroupsSubscription.unsubscribe();
          this._modelVariablesSubscription.unsubscribe();
        }
        this.ModelGroupItems = new Array<ModelGroupItem>();
        this.synchronizeAssetCategoryConfig(
          assetCategorySelected,
          "assetCategoriesDefinition"
        );
      });

    this.variableListInstance.keyExpr = "Code"; //not sure
    this.variableListInstance.displayExpr = "Name";
    this.variableListInstance.indicateLoading = true;
    this.variableListInstance.dataSource = [];

    this.variableListInstance.searchEnabled = true;
    this.variableListInstance.searchMode = "contains";

    this.variableListInstance.height = "400";
    this.variableListInstance.showSelectionControls = true;
    this.variableListInstance.selectionMode = "all";
    this.variableListInstance.pageLoadMode = "scrollBottom";

    this.variableListInstance.onSelectionChanged.subscribe((e) => {
      this.SelectedModelKeys = new Array<string>();
      let selectedVaribles = this.variableListInstance.selectedItems;

      let allModelNames = this.ModelGroupItems.map((item) => {
        // * take parent modelsOnly
        let modelsWithoutParams = item.ModelItems.filter(
          (x) => x.ModelClassification === ModelClassification.ParentModel
        );
        // * get the code of the model, eg variance, tryenor index e.t.c
        return modelsWithoutParams.map((model) => model.ModelValue);
      });
      let flattenedModelNames: Array<string> = [].concat.apply(
        [],
        allModelNames
      );

      let selectedVariableNames = selectedVaribles.map((item) => {
        // * take the parameter id and append the modelName
        // * e.g parameterId for peice is 'xxxx-xxx-xxx' (some guid) then append the model name e.g variance.
        // * the result sill be ''xxxx-xxx-xxxvariance'
        return flattenedModelNames.map((element) => {
          return item.Id + element;
        });
      });

      let flatSelectedVariableNames: Array<string> = [].concat.apply(
        [],
        selectedVariableNames
      );

      this.SelectedModelKeys.push(...flatSelectedVariableNames);
    });

    this.securitiesListInstance.displayExpr = "SecurityName";
    this.securitiesListInstance.keyExpr = "Id";
    this.securitiesListInstance.searchEnabled = true;
    this.securitiesListInstance.searchMode = "contains";
    this.securitiesListInstance.dataSource = [];
    this.securitiesListInstance.searchExpr = "SecurityName";

    this.securitiesListInstance.height = "400";
    this.securitiesListInstance.showSelectionControls = true;
    this.securitiesListInstance.selectionMode = "all";
    this.securitiesListInstance.selectAllMode = "allPages";
    this.securitiesListInstance.pageLoadMode = "scrollBottom";

    // this._appSettingsServiceSubscription = this.appSettingsService.GetSettings().subscribe((settings) => {
    //   this.DecimalPlacesSetting = settings.NonPercentageValuesDecimalPoints; //on non-portfolio we do not have percentages - use this setting here
    //   // this.startDateBoxInstance.dateSerializationFormat = 'dd-MMM-yyyy'
    //   // this.endDateBoxInstance.dateSerializationFormat = 'dd-MMM-yyyy'
    //   this.startDateBoxInstance.value = new Date(2019, 3, 1)
    //   // this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
    //   this.endDateBoxInstance.value = new Date(2019, 5, 30)
    //   // this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    // });

    /**Set the default start and end date */
    this._appSettingsServiceSubscription = this.appSettingsService
      .GetSettings()
      .subscribe((settings) => {
        /**
         * on non-portfolio we do not have percentages - use this setting here
         */
        this.DecimalPlacesSetting = settings.NonPercentageValuesDecimalPoints;

        this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
        this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;

        this.endDateBoxInstance.min = this.startDateBoxInstance.value;
      });
    /**Update end date minimum value on start date change */
    this._startDateChangeSubscription =
      this.startDateBoxInstance.onValueChanged.subscribe((newDateChange) => {
        this.endDateBoxInstance.min = newDateChange.value;
      });

    this._instrumentCategoriesSubscription = this.apiService
      .Get("analyticssetup/GetAllInstrumentCategories")
      .subscribe((instrumentCategories: Array<any>) => {
        let temporaryAssetCategories: TestInstrumentCategory[] =
          new Array<TestInstrumentCategory>();
        instrumentCategories.forEach((instrumentCategory) => {
          let category = new TestInstrumentCategory();
          category.id = instrumentCategory.Id;
          category.text = instrumentCategory.Name;
          category.icon = "reorder";
          category.content =
            "Scroll left or right to view other asset category";
          category.iconStyle = { color: "black" };

          temporaryAssetCategories.push(category);
        });

        // add Macro Economics tab on asset categories
        // from Vincent : When you select the Macro-economic Models tab, what should load on the variables section are
        // the various macro variables.On the Securities listbox, Instead of securities we will list Countries
        // so we are able to analyze macros of any country selected.
        //let macroEconomicsTab: TestInstrumentCategory = new TestInstrumentCategory();

        // macroEconomicsTab.id = temporaryAssetCategories[0].id;
        // macroEconomicsTab.text = "Macro-economic Variables";
        // macroEconomicsTab.icon = "user";
        // macroEconomicsTab.icon = "table_view";
        // macroEconomicsTab.content = "User tab content";
        // macroEconomicsTab.iconStyle = {
        //   color: "red",
        //   "font-weight": "bold",
        // };

        // temporaryAssetCategories.push(macroEconomicsTab);

        this.assetCategoryTabs = temporaryAssetCategories;
      });
  }

  public synchronizeAssetCategoryConfig(
    selectedAssetCategory: any,
    source: string
  ) {
    this._modelVariablesSubscription = this.apiService
      .Get(
        `analytics/NonPortfolioModelVariables?instrumentCategoryId=${selectedAssetCategory.id}`
      )
      .subscribe((variables: Array<ModelVariable>) => {
        this.variableListInstance.selectedItemKeys = new Array<any>();
        this.SelectedModelKeys = new Array<string>();
        this.variableListInstance.dataSource = variables;
      });
    this.ModelGroupItems = new Array<ModelGroupItem>();
    this._modelGroupsSubscription = this.apiService
      .Get(
        `analytics/NonPortfolioModels?instrumentCategoryId=${selectedAssetCategory.id}`
      )
      .subscribe((modelGroups: Array<ModelGroup>) => {
        modelGroups = modelGroups.filter(
          (x) => x.Name !== "Holdings Analysis Measures"
        );
        modelGroups = modelGroups.filter(
          (x) => x.Name !== "Correlation Regression Analysis"
        );
        modelGroups = modelGroups.filter(
          (x) => x.Name !== "Composite Return Measures"
        );

        modelGroups.forEach((modelGroup, index) => {
          if (modelGroup.Name === "Return Analysis") {
            return;
          }
          if (selectedAssetCategory.text === "Macro-economic Variables") {
            //add test variables
            new Array<ModelVariable>();

            if (modelGroup.Name === "Return Analysis") {
              return;
            }
            if (modelGroup.Name === "Bond Risk Analysis") {
              return;
            }
            if (modelGroup.Name === "Return Analysis") {
              return;
            }
            if (modelGroup.Name === "Risk Adjusted Performance Ratios") {
              return;
            }
            if (modelGroup.Name === "Composite Return Measures") {
              return;
            }
            if (modelGroup.Name === "Relative Return Measures") {
              return;
            }
            if (modelGroup.Name === "Holdings Analysis Measures") {
              return;
            }
          }

          if (selectedAssetCategory.text === "Banks") {
            //add test variables
            new Array<ModelVariable>();
            if (modelGroup.Name === "Bond Risk Analysis") {
              return;
            }
            if (modelGroup.Name === "Relative Return Measures") {
              return;
            }
          }

          // console.error(selectedAssetCategory);

          // todo To use this index to work on colors
          // console.error('index is: ' + index);

          let modelItems: Array<ModelItem> = new Array<ModelItem>();
          let groupName = modelGroup.Name;
          let models = modelGroup.Models;

          models.forEach((model) => {
            if (model.Name === "Jarque-bera") {
              //!requested to remove this by calvin and steve on 29 oct 2020
              return;
            }

            let modelCode = model.Code;
            let modelName = model.Name;
            let modelVariables = model.Variables;
            let modelUniqueId = model.ModelId; // ! use this to refer to the model. This is the id saved in the database

            // *  e.g when you select arithmetic mean - pass 'price' and 'returns'- The system is parameter driven*/
            let modelVariableCodes = modelVariables.map(
              (modelVariable) => modelVariable.Code
            );

            let newModelObject: ModelItem = {
              ID: modelUniqueId,
              Head_ID: 0,
              ModelName: modelName,
              ModelValue: modelCode,
              ModelGroupName: groupName,
              ParentModelName: "",
              ModelClassification: ModelClassification.ParentModel,
              UniqueModelId: modelUniqueId,
              ModelParameters: modelVariableCodes,
            };

            let modelSpecificVariables = modelVariables.map((modelVariable) => {
              let variableCode = modelVariable.Code;
              let variableName = modelVariable.Name;
              let variableId = modelVariable.Id;

              let variableModel: ModelItem = {
                ID: variableId + modelCode, //append the model code - variables are not unique.
                // A combination of modelCode and variableCode is unique, e.g price+variance, price+arithmetic mean
                Head_ID: modelUniqueId,
                ModelName: variableName, //e.g for return it is 'Rate of Return'
                ModelValue: variableCode, //e.g returns, price
                ModelGroupName: groupName,
                ParentModelName: modelName,
                UniqueModelId: modelUniqueId,
                ModelClassification: ModelClassification.Parameter,
                ModelParameters: null,
              };
              return variableModel;
            });
            modelItems.push(newModelObject);
            modelItems.push(...modelSpecificVariables);
          });

          try {
            let modelGroupItem = new ModelGroupItem();
            modelGroupItem.ModelGroupName = groupName;
            modelGroupItem.ModelItems = modelItems;
            this.ModelGroupItems.push(modelGroupItem);
          } catch (error) {
            console.error(error);
          }
        });
      });
    /**
     * !Bad Code. Ask this to be part of the masterdata api end point
     */
    if (selectedAssetCategory.text === "Listed Equities") {
      this._securitiesSubscription = this.apiService
        .Get("ListedEquities")
        .subscribe((result: Array<any>) => {
          this.securitiesLength = result.length;
          this.securitiesListInstance.dataSource = [];
          this.securitiesListInstance.selectedItems = [];
          this.securitiesListInstance.dataSource = result.map((item) => {
            let equityResult: AssetCategorySecurities = {
              Id: item.ID,
              SecurityName: item.Name,
            };
            return equityResult;
          });
        });
    }
    //Get Banks
    if (selectedAssetCategory.text === "Banks") {
      this.securitiesSectionCaption = "Banks";
      this._securitiesSubscription = this.apiService
        .Get("analyticsSetup/banks")
        .subscribe((banks: Array<any>) => {
          this.securitiesLength = banks.length;
          this.securitiesListInstance.dataSource = [];
          this.securitiesListInstance.selectedItems = [];
          this.securitiesListInstance.dataSource = banks.map((item) => {
            let banksResult: AssetCategorySecurities = {
              Id: item.Id,
              SecurityName: item.Name,
            };
            return banksResult;
          });
        });
    }
    if (selectedAssetCategory.text === "Commercial Paper")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedCommercialPapers?endingDate=${this.endDateBoxInstance.value}`
      );
    if (selectedAssetCategory.text === "Fixed Treasury Bonds")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedTreasuryBonds?endingDate=${this.endDateBoxInstance.value}`
      );
    if (selectedAssetCategory.text === "Fixed Corporate Bonds")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedCorporateBonds?endingDate=${this.endDateBoxInstance.value}`
      );
    if (selectedAssetCategory.text === "Treasury Bills")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedTreasuryBills?endingDate=${this.endDateBoxInstance.value}`
      );
    if (selectedAssetCategory.text === "Time Deposits")
      this.getSecurities("MasterData/GetTimeDeposits");

    if (
      selectedAssetCategory.text !== "Commercial Paper" ||
      selectedAssetCategory.text !== "Fixed Treasury Bonds" ||
      selectedAssetCategory.text !== "Fixed Corporate Bonds" ||
      selectedAssetCategory.text !== "Time Deposits" ||
      selectedAssetCategory.text !== "Treasury Bills" ||
      selectedAssetCategory.text !== "Listed Equities"
    )
      this.securitiesListInstance.dataSource = null;
  }

  private getSecurities(assetCategoryEndPoint: string) {
    this._securitiesSubscription = this.apiService
      .Get(assetCategoryEndPoint)
      .subscribe((result: Array<any>) => {
        this.securitiesLength = result.length;
        this.securitiesListInstance.dataSource = [];
        this.securitiesListInstance.selectedItems = [];
        this.securitiesListInstance.dataSource = result.map((item) => {
          let securityConfig: AssetCategorySecurities = {
            Id: item.Id,
            SecurityName: item.SecurityName,
          };
          return securityConfig;
        });
      });
  }

  //executed for T.Bond, T.Bills, C.Bonds and Commercial Paper
  dateChangedEvent() {
    if (this.currentAssetCategory === "Commercial Paper")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedCommercialPapers?endingDate=${this.endDateBoxInstance.value}`
      );
    if (this.currentAssetCategory === "Fixed Treasury Bonds")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedTreasuryBonds?endingDate=${this.endDateBoxInstance.value}`
      );
    if (this.currentAssetCategory === "Fixed Corporate Bonds")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedCorporateBonds?endingDate=${this.endDateBoxInstance.value}`
      );
    if (this.currentAssetCategory === "Treasury Bills")
      this.GetNonMaturedBonds(
        `masterdata/GetNonMaturedTreasuryBills?endingDate=${this.endDateBoxInstance.value}`
      );
  }

  GetNonMaturedBonds(assetCategoryEndPoint: string) {
    this.apiService
      .Get(assetCategoryEndPoint)
      .subscribe((result: Array<any>) => {
        this.securitiesLength = result.length;
        this.securitiesListInstance.dataSource = [];
        this.securitiesListInstance.selectedItems = [];
        this.securitiesListInstance.dataSource = result.map((item) => {
          let securityConfig: AssetCategorySecurities = {
            Id: item.Id,
            SecurityName: item.SecurityName,
          };
          return securityConfig;
        });
      });
  }

  calculatePercentageValue(rowData) {
    if (rowData === undefined) return "---";
    if (rowData === "-") return "---";
    else {
      return rowData.toFixed(this.DecimalPlacesSetting);
    }
  }

  ShowAssetCategoryName(name) {
    return `*** ${name} Asset Category ***. An asset class is a grouping of investments that exhibit similar characteristics and are subject to the same laws and regulations. Equities (stocks), fixed Income (bonds), cash and cash equivalents, real estate, commodities, futures, and other financial derivatives are examples of asset classes.`;
  }

  renderResults(
    selectedAssetCategory: TestInstrumentCategory,
    cancelRequest: boolean
  ) {
    let selectedCatId = selectedAssetCategory.id;
    let renderButtonConfig: RenderButtonConfig = {
      IsClicked: true,
      SelectedAssetCategoryId: selectedCatId,
      cancelRequest: cancelRequest,
      weightOption: "False",
    };
    if (this.weightRadioGroupInstance.disabled === false) {
      // if the weighting option is not disabled, add weighting option to the request
      renderButtonConfig.weightOption = this.weightRadioGroupInstance.value;
    }
    this.nonPortfolioUpdaterService.processRenderRequest(renderButtonConfig);
  }

  testScrolling(event) {
    console.error(event);
  }

  //https://supportcenter.devexpress.com/ticket/details/t670833/tabpanel-justified-tabs
  onContentReady(e) {
    setTimeout(() => {
      var widthOfOneTab = 150 / e.component.option("items").length;
      var tabs = e.element.querySelectorAll(".dx-tab");
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].style.width = widthOfOneTab + "%";
      }
    });
  }
}
