import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  DxChartComponent,
  DxDataGridComponent,
  DxDateBoxComponent,
  DxDropDownBoxComponent,
  DxLookupComponent,
  DxTabPanelComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { forkJoin, observable, Observable, Subject, Subscription } from "rxjs";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { InstrumentCategory } from "./helper-classes";
import { NonPortfolioAssetRegistryService } from "./non-portfolio-asset-registry.service";
import { ApiService } from "src/app/app-services/api/api.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { any, result } from "underscore";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import notify from "devextreme/ui/notify";

@Component({
  selector: "app-nonportfolio-asset-registry",
  templateUrl: "./nonportfolio-asset-registry.component.html",
  styleUrls: ["./nonportfolio-asset-registry.component.scss"],
})
export class NonportfolioAssetRegistryComponent implements OnInit {
  @ViewChild("CountryLookup", { static: false })
  countryLookupInstance: DxLookupComponent;
  @ViewChild("BenchmarkLookup", { static: false })
  benchmarkLookupInstance: DxLookupComponent;
  @ViewChild("ExchangeLookup", { static: false })
  exchangeLookupInstance: DxLookupComponent;
  @ViewChild("VariableTreeList", { static: false })
  VariableTreeListInstance: DxTreeListComponent;
  @ViewChild("securityDropdown")
  securityDropdownInstance: DxDropDownBoxComponent;
  @ViewChild("SecurityTreeList", { static: false })
  SecurityTreeListInstance: DxTreeListComponent;

  @ViewChild("startDateBox", { static: true })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("assetCategoriesPanel", { static: false })
  assetCategoriesPanel: DxTabPanelComponent;
  @ViewChild("endDateBox", { static: true })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("tabGroup") private tabGroup: MatTabGroup;
  @ViewChild("chart", { static: false }) chartInstance: DxChartComponent;

  //props
  assetCategoryTabs: Observable<any>;
  CountryDataSource: any;
  BenchmarkDataSource: any;
  ExchangeDataSource: Observable<any>;
  SecurityDataSource: any;
  VariableDataSource: any;
  currentAssetCategoryId: any;
  currentCountryId: any;
  currentBenchmarkId: any;
  currentCoutryName: any;
  currentExchangeId: any = "";
  currentAssetCategoryCode: any;
  variablesTreeBoxValue: string;
  securityTreeBoxValue: string;
  preDataLoad: boolean = false;
  equitiesShareVolumesColumns: any;
  gridDataSource: any;
  chartDataSource: any;
  chartName: string;
  VariableValue: string;
  currentSecurityName: any;
  currentSecurityId: any;
  currentVariableTab: any;
  SecurityRequestData: Array<any> = [];
  VariableRequestData: Array<any> = [];
  mergedResponseData: any = [];
  applicationSettings: ApplicationSettings;
  macrosAssetCategorySelected: boolean = false;
  ListedEquitiesSelected: boolean = false;
  priceOrReturnsVariableNotSelected: boolean = true;

  //observables

  DisplayAssetRegistryResults: Subject<any> = new Subject<any>();
  DisplayAssetRegistryResultsObservable =
    this.DisplayAssetRegistryResults.asObservable();

  //subsciptions
  public _apiFetchSubscription: Subscription;
  public _instrumentCategoriesSubscription: Subscription;
  public _variablesSubscription: Subscription;
  public _countrySubscription: Subscription;
  public _benchmarkSubscription: Subscription;
  public _benchmarkReturnSubscription: Subscription;
  public _exchangeSubscription: Subscription;
  public _securitySubscription: Subscription;

  constructor(
    public appSettingsService: ApplicationSettingsService,
    private settingsService: ApplicationSettingsService,
    private nonPortfolioAssetRegistryService: NonPortfolioAssetRegistryService,
    private apiService: ApiService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public notificationService: SnackNotificationsService
  ) {}

  ngOnInit() {
    //update active component Name on header
    this.navBarSettingsService.ChangeActiveComponentName(
      "Non Portfolio Asset Registry"
    );
    //get settings
    this.settingsService.GetSettings().subscribe((settings) => {
      this.applicationSettings = settings;
    });

    //set the initial value of treeBox
    this.variablesTreeBoxValue = "Select Variables";
    this.securityTreeBoxValue = "Select Securities";

    //Initialize default dates
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    });

    //populate the Asset Registry Tabs
    this._instrumentCategoriesSubscription =
      this.nonPortfolioAssetRegistryService
        .getAssetCategories()
        .subscribe((instrumentCategories) => {
          let filteredAssetCategories = instrumentCategories.filter((x) => {
            if (
              x.Name == "Listed Equities" ||
              x.Name == "Fixed Treasury Bonds" ||
              x.Name == "Fixed Corporate Bonds" ||
              x.Name == "Treasury Bills" ||
              x.Name == "Macro-economic Variables"
            ) {
              return x;
            }
          });
          let assetCats = filteredAssetCategories.map((instrumentCategory) => {
            let category = new InstrumentCategory();
            category.id = instrumentCategory.Id;
            category.text = instrumentCategory.Name;
            category.code = instrumentCategory.Code;
            category.icon = "reorder";
            return category;
          });
          this.assetCategoryTabs = assetCats;
        });

    //populate the Country DataSource
    this._countrySubscription = this.nonPortfolioAssetRegistryService
      .getCountries()
      .subscribe((countries: any[]) => {
        //sort countries by name
        var sortedCountries = countries.sort((a, b) =>
          a.Name > b.Name ? 1 : -1
        );
        this.CountryDataSource = sortedCountries;
      });
  }

  CountrySelectionChanged(e) {
    //Get the selected CountryID
    let countryId = e.selectedItem.ID;
    this.currentCountryId = countryId;
    this.currentCoutryName = e.selectedItem.Name;

    //get Exhange Data and populate the DxLookup
    this._countrySubscription = this.nonPortfolioAssetRegistryService
      .getExchangeByCountryId(countryId)
      .subscribe((result) => {
        this.ExchangeDataSource = result;
      });
  }

  BenchmarkSelectionChanged(e) {
    //Get the selected CountryID
    let benchmarkId = e.selectedItem.Id;
    this.currentBenchmarkId = benchmarkId;

    //get the current variable code using the variable Name
    let currentVariableTab = this.currentVariableTab;
    let currentVariable = this.VariableDataSource.filter((x) => {
      if (x.Name == currentVariableTab) {
        return x;
      }
    });

    let variableCode = currentVariable.map((x) => x.Code);

    //get current security ID from the chartDataSource
    let currentSecurityNames = this.chartDataSource.map((x) => x.Security);
    let currentSecurityName = currentSecurityNames[0];

    //to avoid case where the chartDataSource == BenchmarkReturnData
    // which does not include the SecurityId
    if (currentSecurityName !== undefined) {
      let currentSecurityData = this.SecurityDataSource.filter((x) => {
        if (x.Name == currentSecurityName) {
          return x;
        }
      });
      // update currentSecurityId
      this.currentSecurityId = currentSecurityData.map((x) => x.Id);
    }

    //make request to benchmark data
    let selectedAssetCategoryCode = this.assetCategoriesPanel.selectedItem.code;
    let startDate = this.startDateBoxInstance.value;
    let endDate = this.endDateBoxInstance.value;

    let requestPayload = {
      startDate: startDate,
      endDate: endDate,
      variableCode: variableCode[0],
      instrumentCategoryCode: selectedAssetCategoryCode,
      benchmarkId: benchmarkId,
      Securities: this.currentSecurityId,
    };

    this._benchmarkReturnSubscription = this.nonPortfolioAssetRegistryService
      .getNonPortfolioAssetRegistryBenchmarksData(requestPayload)
      .subscribe((res) => (this.chartDataSource = res));
  }

  ExchangeSelectionChanged(e) {
    //get Security Data and populate the DxLookup
    //empty the Security DataSource
    this.SecurityDataSource = [];

    //Get the selected CountryID
    let stockExchangeID = e.selectedItem.ID;

    //To be used when AssetCategory Selection Changes
    this.currentExchangeId = stockExchangeID;

    if (this.currentAssetCategoryCode == "listed equities") {
      this.LoadEquitiesSecurities(
        stockExchangeID,
        this.currentAssetCategoryCode
      );
    } else if (this.currentAssetCategoryCode == "fixed treasury bonds") {
      this.LoadFixedTreasuryBondsSecurities(
        stockExchangeID,
        this.currentAssetCategoryCode
      );
    } else if (this.currentAssetCategoryCode == "fixed corporate bonds") {
      this.LoadFixedCorporateBondsSecurities(
        stockExchangeID,
        this.currentAssetCategoryCode
      );
    } else if (this.currentAssetCategoryCode == "treasury bills") {
      this.LoadFixedTreasuryBillsSecurities(
        stockExchangeID,
        this.currentAssetCategoryCode
      );
    }
  }

  SecuritySelectionChanged(e) {}

  VariableSelectionChanged(e) {}

  assetCategoriesSelectionChanged(e) {
    let assetCategorySelected = e.addedItems[0];
    this.currentAssetCategoryId = assetCategorySelected.id;
    this.currentAssetCategoryCode = assetCategorySelected.code;

    //empty the Variables dataSource
    this.VariableRequestData = [];
    this.VariableDataSource = null;

    //empty the Security dataSource
    this.SecurityRequestData = [];
    this.SecurityDataSource = null;

    //empty the Benchmarks dataSource
    this.BenchmarkDataSource = null;
    //empty the charts
    this.chartDataSource = null;
    this.chartName = "";

    //to be removed, to hide Benchmarks
    if (this.currentAssetCategoryCode == "listed equities") {
      this.ListedEquitiesSelected = true;
      //Load Benchmarks by assetCategoryID
      this._benchmarkSubscription = this.nonPortfolioAssetRegistryService
        .getBenchmarksByInstrumentCategory(this.currentAssetCategoryCode)
        .subscribe((res) => (this.BenchmarkDataSource = res));
    } else if (this.currentAssetCategoryCode == "fixed treasury bonds") {
      this.ListedEquitiesSelected = true;
      //Load Benchmarks by assetCategoryID
      this._benchmarkSubscription = this.nonPortfolioAssetRegistryService
        .getBenchmarksByInstrumentCategory(this.currentAssetCategoryCode)
        .subscribe((res) => (this.BenchmarkDataSource = res));
    } else if (this.currentAssetCategoryCode == "fixed corporate bonds") {
      this.ListedEquitiesSelected = true;
      //Load Benchmarks by assetCategoryID
      this._benchmarkSubscription = this.nonPortfolioAssetRegistryService
        .getBenchmarksByInstrumentCategory(this.currentAssetCategoryCode)
        .subscribe((res) => (this.BenchmarkDataSource = res));
    } else if (this.currentAssetCategoryCode == "treasury bills") {
      this.ListedEquitiesSelected = true;
      //Load Benchmarks by assetCategoryID
      this._benchmarkSubscription = this.nonPortfolioAssetRegistryService
        .getBenchmarksByInstrumentCategory(this.currentAssetCategoryCode)
        .subscribe((res) => (this.BenchmarkDataSource = res));
    } else {
      this.ListedEquitiesSelected = false;
    }

    //disable security Lookup if macros is selected
    if (this.currentAssetCategoryCode == "macros") {
      this.macrosAssetCategorySelected = true;
    } else {
      this.macrosAssetCategorySelected = false;
    }

    //fetch Variables by assetCategoryID
    this._variablesSubscription = this.nonPortfolioAssetRegistryService
      .getVariablesByAssetCategoryId(this.currentAssetCategoryId)
      .subscribe((variables) => {
        let nodes: Array<any> = [];
        variables.forEach((element) => {
          nodes.push({
            Name: element.Name,
            Id: element.Id,
            Code: element.Code,
            ParentId: 0,
          });
        });
        this.VariableDataSource = nodes;
      });

    //load Securities
    if (this.currentExchangeId != "") {
      //empty the grids and charts
      this.DisplayAssetRegistryResults.next(null);
      let selectedAssetCategoryCode =
        this.assetCategoriesPanel.selectedItem.code;
      if (selectedAssetCategoryCode == "listed equities") {
        this.LoadEquitiesSecurities(
          this.currentExchangeId,
          this.currentAssetCategoryCode
        );
      } else if (selectedAssetCategoryCode == "fixed treasury bonds") {
        this.LoadFixedTreasuryBondsSecurities(
          this.currentExchangeId,
          this.currentAssetCategoryCode
        );
      } else if (selectedAssetCategoryCode == "fixed corporate bonds") {
        this.LoadFixedCorporateBondsSecurities(
          this.currentExchangeId,
          this.currentAssetCategoryCode
        );
      } else if (selectedAssetCategoryCode == "treasury bills") {
        this.LoadFixedTreasuryBillsSecurities(
          this.currentExchangeId,
          this.currentAssetCategoryCode
        );
      }
    }
  }

  LoadEquitiesSecurities(stockExchangeID: any, currentAssetCategoryCode: any) {
    this._securitySubscription = this.nonPortfolioAssetRegistryService
      .getSecuritiesByExhangeIdandAssetCategoryId(
        stockExchangeID,
        currentAssetCategoryCode
      )
      .subscribe((securities) => {
        let securityNode: Array<any> = [];
        securities.forEach((element) => {
          securityNode.push({
            Name: element.Name,
            Id: element.ListedEquityID,
            ParentId: 0,
          });
        });
        this.SecurityDataSource = securityNode;
      });
  }
  LoadFixedTreasuryBondsSecurities(
    stockExchangeID: any,
    currentAssetCategoryCode: any
  ) {
    this._securitySubscription = this.nonPortfolioAssetRegistryService
      .getSecuritiesByExhangeIdandAssetCategoryId(
        stockExchangeID,
        currentAssetCategoryCode
      )
      .subscribe((securities) => {
        let securityNode: Array<any> = [];
        securities.forEach((element) => {
          securityNode.push({
            Name: element.Code,
            Id: element.FixedTreasuryBondID,
            ParentId: 0,
          });
        });
        this.SecurityDataSource = securityNode;
      });
  }
  LoadFixedCorporateBondsSecurities(
    stockExchangeID: any,
    currentAssetCategoryCode: any
  ) {
    this._securitySubscription = this.nonPortfolioAssetRegistryService
      .getSecuritiesByExhangeIdandAssetCategoryId(
        stockExchangeID,
        currentAssetCategoryCode
      )
      .subscribe((securities) => {
        let securityNode: Array<any> = [];
        securities.forEach((element) => {
          securityNode.push({
            Name: element.Code,
            Id: element.FixedCorporateBondID,
            ParentId: 0,
          });
        });
        this.SecurityDataSource = securityNode;
      });
  }
  LoadFixedTreasuryBillsSecurities(
    stockExchangeID: any,
    currentAssetCategoryCode: any
  ) {
    this._securitySubscription = this.nonPortfolioAssetRegistryService
      .getSecuritiesByExhangeIdandAssetCategoryId(
        stockExchangeID,
        currentAssetCategoryCode
      )
      .subscribe((securities) => {
        let securityNode: Array<any> = [];
        securities.forEach((element) => {
          securityNode.push({
            Name: element.Code,
            Id: element.TreasuryBillID,
            ParentId: 0,
          });
        });
        this.SecurityDataSource = securityNode;
      });
  }

  variableTreeList_itemSelectionChanged(e) {
    //this.variablesTreeBoxValue = e.component.getSelectedNodeKeys();
  }

  securityTreeList_itemSelectionChanged(e) {
    //this.securityTreeBoxValue = e.component.getSelectedNodeKeys();
  }

  VariablesItemsSelected(e) {}

  calculatePaneldHeight() {
    return 0.6 * window.screen.height + "px";
  }
  calculateGridHeight() {
    return 0.45 * window.screen.height + "px";
  }

  fetchResults() {
    if (
      this.SecurityRequestData.length == 0 &&
      this.currentAssetCategoryCode !== "macros"
    ) {
      notify(
        { message: "Select atleast one Security", width: 600, shading: true },
        "warning",
        800
      );
      return;
    }
    if (this.VariableRequestData.length == 0) {
      notify(
        { message: "Select atleast one Variable", width: 600, shading: true },
        "warning",
        800
      );
      return;
    }
    //fetch data
    let selectedAssetCategoryCode = this.assetCategoriesPanel.selectedItem.code;
    let startDate = this.startDateBoxInstance.value;
    let endDate = this.endDateBoxInstance.value;
    let selectedVariables = this.VariableRequestData[0];
    this.mergedResponseData = [];
    let countryId = this.currentCountryId;
    let benchmarkId = this.currentBenchmarkId;

    // Make Multiple Requests depending on the size of the VariableRequestData
    // Not a good idea, to revisit
    // https://stackoverflow.com/questions/41619312/send-multiple-asynchronous-http-get-requests/41620361#41620361

    selectedVariables.forEach((variableCode) => {
      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        variableCode: variableCode,
        instrumentCategoryCode: selectedAssetCategoryCode,
        securities: this.SecurityRequestData[0],
        country: countryId,
        benchmarkId: benchmarkId,
      };

      this.requestDataMultipleTimes(requestPayload).subscribe((response) => {
        //push the columns here
        //mergedResponseData.push(response);
        let variableData = response["VariableData"];
        if (variableData.length > 0) {
          //loop through the first Object to get the columns
          let firstColumnData = variableData[0];
          let finalSecurityColumns = [];
          for (const key in firstColumnData) {
            if (key == "Security") {
              let columnObject = {
                caption: `${key}`,
                dataField: `${key}`,
                cssClass: "performance-attr-grid-focus-3",
                alignment: "left",
                sortOrder: "asc",
              };
              finalSecurityColumns.push(columnObject);
            } else if (key == "Sector") {
              let columnObject = {
                caption: `${key}`,
                dataField: `${key}`,
                groupIndex: 0,
              };
              finalSecurityColumns.push(columnObject);
            } else if (key == "Date") {
              let columnObject = {
                caption: `${key}`,
                dataField: `${key}`,
                dataType: "date",
                format: "dd MMM yyyy",
              };
              finalSecurityColumns.push(columnObject);
            } else if (key == "Value") {
              let columnObject = {
                caption: `${key}`,
                dataField: `${key}`,
                dataType: "number",
                format: {
                  type: "fixedPoint",
                  precision:
                    this.applicationSettings.PercentageValuesDecimalPoints,
                },
              };
              finalSecurityColumns.push(columnObject);
            } else {
              let columnObject = {
                caption: `${key}`,
                dataField: `${key}`,
              };
              finalSecurityColumns.push(columnObject);
            }
          }
          //push the columns to the Object
          response.VariableColumns = finalSecurityColumns;
          //use the variable Code to get the variable Name
          let variableCode = response["Variable"];
          let currentVariable = this.VariableDataSource.filter((x) => {
            if (x.Code == variableCode) {
              return x;
            }
          });

          let variableName = currentVariable.map((x) => x.Name);
          //push the variable name to the object
          response.VariableName = variableName[0];

          //push the data to mergedResponseData
          this.mergedResponseData.push(response);
          this.DisplayAssetRegistryResults.next(this.mergedResponseData);
        } else {
          //push empty columns to the Object
          response.VariableColumns = [];
          //use the variable Code to get the variable Name
          let variableCode = response["Variable"];
          let currentVariable = this.VariableDataSource.filter((x) => {
            if (x.Code == variableCode) {
              return x;
            }
          });

          let variableName = currentVariable.map((x) => x.Name);
          //push the variable name to the object
          response.VariableName = variableName[0];

          //push the data to mergedResponseData
          this.mergedResponseData.push(response);
          this.DisplayAssetRegistryResults.next(this.mergedResponseData);
        }
      });
    });
  }
  updateChart(e) {
    if (this.currentAssetCategoryCode == "macros") {
      //get the selected Security
      let selectedCountry = e.key.Country;
      // filter the data using the current Variable Name
      let filteredDataByVariableName = this.mergedResponseData.filter((x) => {
        if (x.VariableName == this.currentVariableTab) {
          return x;
        }
      });
      let variableData = filteredDataByVariableName[0].VariableData;

      let filteredDataBySecurityName = variableData.filter((x) => {
        if (x.Country == selectedCountry) {
          return x;
        }
      });

      this.chartDataSource = filteredDataBySecurityName;
      this.chartName = this.currentCoutryName + " " + this.currentVariableTab;
      this.VariableValue = this.currentVariableTab + " Value";
    } else {
      //get the selected Security7
      let selectedSecurity = e.key.Security;
      // filter the data using the current Variable Name
      let filteredDataByVariableName = this.mergedResponseData.filter((x) => {
        if (x.VariableName == this.currentVariableTab) {
          return x;
        }
      });
      let variableData = filteredDataByVariableName[0].VariableData;

      let filteredDataBySecurityName = variableData.filter((x) => {
        if (x.Security == selectedSecurity) {
          return x;
        }
      });

      this.chartDataSource = filteredDataBySecurityName;
      this.chartName = selectedSecurity + " " + this.currentVariableTab;
      this.VariableValue = this.currentVariableTab + " Value";
    }
  }

  onTabChanged(e: MatTabChangeEvent) {
    this.currentVariableTab = e.tab.textLabel;

    if (this.currentVariableTab !== "") {
      //To be removed
      if (
        this.currentVariableTab == "Stock Price" ||
        this.currentVariableTab == "Stock Returns" ||
        this.currentVariableTab == "Clean Price" ||
        this.currentVariableTab == "Yield Rate" ||
        this.currentVariableTab == "Market Price" ||
        this.currentVariableTab == "Fixed Coupon"
        // this.currentVariableTab == "Rate of Return"
      ) {
        this.priceOrReturnsVariableNotSelected = false;
      } else {
        this.priceOrReturnsVariableNotSelected = true;
      }

      //update charts
      //filter the data by the current variable
      let filteredDataByVariableName = this.mergedResponseData.filter((x) => {
        if (x.VariableName == this.currentVariableTab) {
          return x;
        }
      });

      let variableData = filteredDataByVariableName[0].VariableData;
      if (this.currentAssetCategoryCode == "macros") {
        let country = filteredDataByVariableName[0].VariableData[0].Country;

        let filteredDataByCountryName = variableData.filter((x) => {
          if (x.Country == country) {
            return x;
          }
        });

        this.chartDataSource = filteredDataByCountryName;
        this.chartName = country + " " + this.currentVariableTab;
        this.VariableValue = this.currentVariableTab + " Value";
      } else {
        let firstSecurity =
          filteredDataByVariableName[0].VariableData[0].Security;

        let filteredDataBySecurityName = variableData.filter((x) => {
          if (x.Security == firstSecurity) {
            return x;
          }
        });

        this.chartDataSource = filteredDataBySecurityName;
        this.chartName = firstSecurity + " " + this.currentVariableTab;
        this.VariableValue = this.currentVariableTab + " Value";
      }
    }
  }

  gridInstanceContentReady(event) {}

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;

    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          let formattedNumber = this.numberWithCommas(value);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.applicationSettings.NonPercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
      }
    }
  }
  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  requestDataMultipleTimes(payload: Object): Observable<any> {
    let variableDataObservables =
      this.nonPortfolioAssetRegistryService.getNonPortfolioAssetRegistryData(
        payload
      );
    return variableDataObservables;
  }
  //When Security Tree Selection Changes
  SecurityItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {
      this.SecurityRequestData = [];
      // let selectedRowsData =
      //   this.SecurityTreeListInstance.instance.getSelectedRowsData(
      //     "excludeRecursive"
      //   );
      let selectedSecurityIds = event.selectedRowsData.map((x) => x.Id);

      this.SecurityRequestData.push(selectedSecurityIds);
    }
  }

  //When Variable Tree Selection Changes
  VariableItemsSelected(event) {
    if (event.selectedRowsData.length !== 0) {
      this.VariableRequestData = [];
      let selectedVariableCodes = event.selectedRowsData.map((x) => x.Code);

      this.VariableRequestData.push(selectedVariableCodes);
    }
  }

  ngOnDestroy(): void {
    if (this._instrumentCategoriesSubscription !== undefined)
      this._instrumentCategoriesSubscription.unsubscribe();
    if (this._variablesSubscription !== undefined)
      this._variablesSubscription.unsubscribe();
    if (this._countrySubscription !== undefined)
      this._countrySubscription.unsubscribe();
    if (this._exchangeSubscription !== undefined)
      this._exchangeSubscription.unsubscribe();
    if (this._benchmarkSubscription !== undefined)
      this._benchmarkSubscription.unsubscribe();
    if (this._securitySubscription !== undefined)
      this._securitySubscription.unsubscribe();
  }
}
