import {
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { DataService } from "./data.service";
import { FormControl, FormGroup } from "@angular/forms";
import { ngxLoadingAnimationTypes } from "ngx-loading";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { ChatbotService } from "./chatbot.service";
import { LocalStorageService } from "src/app/app-services/services/local-storage.service";
import { NotificationService } from "src/app/app-services/notification/notification.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-chat-bot",
  templateUrl: "./chat-bot.component.html",
  styleUrls: ["./chat-bot.component.scss"],
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          height: "600px",
          opacity: 1,
        })
      ),
      state("closed", style({})),
      transition("open => closed", [animate("1s")]),
      transition("closed => open", [animate("0.2s")]),
    ]),
    trigger("typeWritterEffect", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("2s", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ChatBotComponent {
  @ViewChild("messagesContainer") private messagesContainer!: ElementRef;
  @Input() currentParentId: string;
  constructor(
    private chatbotService: ChatbotService,
    private localStorage: LocalStorageService,
    private notificationService: NotificationService
  ) {}
  isOpen = false;
  showX: boolean = false;
  outputData: any = null;
  isSending: boolean = false; // flag to track sending state
  apiSubscription: Subscription | null = null;

  public messagesHistory: { role: string; parts: string }[] = [];
  public userMessage!: string | null;
  public loading = false;
  public loadingTemplate!: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingConfig = {
    animationType: ngxLoadingAnimationTypes.circleSwish,
    primaryColour: "#ffffff",
    secondaryColour: "#ccc",
    tertiaryColour: "#dd0031",
    backdropBorderRadius: "3px",
  };

  public bQuestions = [
    "Which security asset is the  best performing based on the results for the selected period?",
    "Which combination of securities can build a portfolio with higher returns and minimal risk?",
    "Provide a summary of risk exposure by sector based on the data?",
  ];

  toggle() {
    this.isOpen = !this.isOpen;
    this.showX = !this.showX;
  }

  toggleSendOrCancel(message: string) {
    if (this.isSending) {
      this.cancelApiRequest();
    } else {
      this.sendMessage(message);
    }
  }

  // Function to cancel the API request
  cancelApiRequest() {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe(); // Cancel the API call
      this.loading = false;
      this.isSending = false;
      this.apiSubscription = null;
    }
  }

  sendMessage(message: string) {
    if (!message || this.loading) return;
    if (this.currentParentId == "macrosecurityanalysis") {
      this.getOutputData("macro-security-analysis");
    } else if (this.currentParentId == "portfolioanalysis") {
      this.getOutputData("portfolio-analysis");
    }

    if (this.outputData == undefined) {
      this.notificationService.showError(
        "There is no data output, kindly select securities and models to proceed",
        "Error"
      );
      return;
    }

    setTimeout(() => this.scrollToBottom(), 0);
    this.loading = true;
    this.isSending = true;
    this.messagesHistory.push({
      role: "user",
      parts: message,
    });

    let payload = {
      question: message,
      data: this.outputData,
    };

    this.apiSubscription = this.chatbotService
      .generateWithOpenAI(payload)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.isSending = false;
          this.userMessage = null;
          this.messagesHistory = this.messagesHistory.slice(0, -2);
          this.messagesHistory.push(
            {
              role: "user",
              parts: message,
            },
            {
              role: "model",
              parts: res.Response,
            }
          );
          setTimeout(() => this.scrollToBottom(), 0);
        },
        error: (error) => {
          this.loading = false;
          console.error("Error generating content:", error);
          this.messagesHistory.push({
            role: "model",
            parts: "Sorry, something went wrong. Please try again later.",
          });
          setTimeout(() => this.scrollToBottom(), 0);
        },
      });
  }

  getOutputData(key: string) {
    this.outputData = this.localStorage.getItem(key);
  }

  scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }
}
