<app-fund-config-form></app-fund-config-form>
<dx-tab-panel
  [showNavButtons]="true"
  style="background-color: rgba(255, 255, 255, 0.336)"
  [activeStateEnabled]="true"
>
  <dxi-item class="tabpanel-item" title="Grid">
    <div style="margin-top: 4px">
      <div class="regulator-compliance-main-container">
        <div style="margin-top: 1%"></div>
        <!-- drawer section -->
        <div class="drawer-container">
          <dx-toolbar [items]="toolbarContent"> </dx-toolbar>
          <dx-drawer
            openedStateMode="shrink"
            position="left"
            revealMode="slide"
            template="template"
            [(opened)]="isDrawerOpen"
            height="100%"
            [closeOnOutsideClick]="true"
          >
            <div
              *dxTemplate="let data of 'template'"
              style="width: 300px"
              class="rebalancing-container-top-margin"
            >
              <div class="funds-section">
                <div
                  style="
                    font-weight: 700;
                    margin-left: 5%;
                    font-stretch: extra-expanded;
                    font-size: 170%;
                  "
                >
                  Funds
                </div>
                <mat-divider></mat-divider>
                <div>
                  <dx-list
                    [hoverStateEnabled]="false"
                    [activeStateEnabled]="false"
                    [focusStateEnabled]="false"
                    #fundsListView
                  >
                  </dx-list>
                </div>
              </div>
            </div>

            <div
              class="rebalancing-container-top-margin rebalancing-drawer-content"
            >
              <div class="regulator-compliance-grid-main-container">
                <div class="asset-cats-section">
                  <!-- !test on [selectedIndex]="2" return to [selectedIndex]="0"-->
                  <dx-tab-panel
                    #assetCategoriesPanel
                    [dataSource]="assetCategoryTabsObservable | async"
                    [selectedIndex]="0"
                    [loop]="false"
                    [animationEnabled]="true"
                    [swipeEnabled]="true"
                    [deferRendering]="false"
                  >
                    <div *dxTemplate="let item of 'title'">
                      <div
                        [ngStyle]="item.iconStyle"
                        matTooltip="{{ item.content }}"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <div>
                            <span>
                              <mat-icon>{{ item.icon }}</mat-icon>
                            </span>
                          </div>
                          <div>
                            {{ item.text }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      *dxTemplate="let selectedAsstCat of 'item'"
                      style="overflow-y: scroll"
                    >
                      <!-- //datagrid goes here -->
                      <div style="padding: 10px; border: 2px solid grey">
                        <dx-data-grid
                          class="rebalancing-data-grid"
                          #complianceGridInstance
                          columnResizingMode=" widget"
                          [allowColumnResizing]="true"
                          [showBorders]="true"
                          [showRowLines]="true"
                          [groupPanel]="{ visible: true }"
                          [grouping]="{ autoExpandAll: false }"
                          [searchPanel]="{
                            visible: true,
                            placeholder: 'search for anything',
                            width: 500
                          }"
                          [allowColumnReordering]="false"
                          [rowAlternationEnabled]="true"
                          [export]="{
                            enabled: true,
                            fileName: 'Non-Portfolio-Analytics',
                            allowExportSelectedData: true
                          }"
                          [selection]="{
                            mode: 'multiple',
                            selectAllMode: 'allPages',
                            showCheckBoxesMode: 'always'
                          }"
                          [columnChooser]="{ enabled: true, mode: 'select' }"
                          [wordWrapEnabled]="false"
                          [columnFixing]="true"
                          [twoWayBindingEnabled]="true"
                          [repaintChangesOnly]="false"
                          [activeStateEnabled]="true"
                          (onCellPrepared)="gridInstanceCellPrepared($event)"
                          [keyExpr]="gridKeyExpression"
                          [focusStateEnabled]="true"
                          [focusedRowEnabled]="false"
                          (onRowPrepared)="onRowInsertingCheckbreach($event)"
                          [scrolling]="{
                            mode: 'standard',
                            preloadEnabled: true
                          }"
                          [focusedRowEnabled]="true"
                        >
                          <dxo-paging [enabled]="false"></dxo-paging>
                          <dxo-sorting mode="single"> </dxo-sorting>
                          <dxo-summary>
                            <dxi-group-item
                              *ngFor="let colDef of summaryColumns"
                              [column]="colDef"
                              summaryType="sum"
                              [customizeText]="customizeSummaryText"
                              [showInGroupFooter]="true"
                              [alignByColumn]="true"
                            >
                            </dxi-group-item>
                          </dxo-summary>
                        </dx-data-grid>
                      </div>
                    </div>
                  </dx-tab-panel>
                </div>
              </div>
            </div>
          </dx-drawer>
        </div>
        <!-- drawer section -->
      </div>
    </div>
  </dxi-item>
  <dxi-item class="tabpanel-item" title="Report">
    <!-- <div style="margin-top: 4px; height: 100%;">
            <app-portfolio-rebalancing-printable-report></app-portfolio-rebalancing-printable-report>
        </div> -->
  </dxi-item>
</dx-tab-panel>
