<div class="component-loading-section-main ">

    <div>
        <mat-progress-bar mode="buffer" color="accent" [value]="progressBarValue | async"></mat-progress-bar>
    </div>
    <div mat-dialog-content style="height: 100%;">
        <div style="margin-top: 2%; font-weight: 100; text-align: center;">
            {{data.Title}}
        </div>
        <!-- <div class="component-loading-section">
            <mat-spinner color="primary" diameter=50></mat-spinner>
        </div> -->
        <div style="margin-top: 2%; font-weight: 100; text-align: center;">
            {{data.Description}}
        </div>
    </div>

    <div mat-dialog-actions style="float: right;">
        <button mat-stroked-button color="primary" mat-dialog-close cdkFocusInitial>Complete in the background</button>
    </div>
</div>