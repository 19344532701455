import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import notify from "devextreme/ui/notify";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { CorporateBondsImporterService } from "./corporate-bonds-importer.service";

@Component({
  selector: "app-corporate-bonds-importer",
  templateUrl: "./corporate-bonds-importer.component.html",
  styleUrls: ["./corporate-bonds-importer.component.scss"],
})
export class CorporateBondsImporterComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;

  //props
  files: any[] = [];
  currentVariableTab: string;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  bondsColumns: any;
  bondsPurchasesResponse: any;
  bondsHoldingsResponse: any;
  bondsSalesResponse: any;
  gridDataSource: any;
  importStatus: any;
  FileSuccess: boolean = true;
  FileNotSuccess: boolean;
  ImportErrors: Array<any> = [];
  EntityName = "Corporate Bonds Data Importer";

  //subsciptions
  public _bondsPurchasesFilesImportSubscription: Subscription;
  public _bondsSalesFilesImportSubscription: Subscription;
  public _bondsHoldingsFilesImportSubscription: Subscription;
  public _bondsPurchasesSaveParsedSubscription: Subscription;
  public _bondsSalesSaveParsedSubscription: Subscription;
  public _bondsHoldingsSaveParsedSubscription: Subscription;

  constructor(
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService,
    private _formBuilder: FormBuilder,
    private corporateBondsService: CorporateBondsImporterService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);
  }

  ngOnInit() {
    // https://www.techiediaries.com/angular-formdata/
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }
  handleFileInput(files: FileList) {
    console.error(files);
  }

  goBack() {
    this.myStepper.previous();
  }

  goForward() {
    this.myStepper.next();
  }

  processFile() {
    //append the file
    const formData: FormData = new FormData();
    formData.append(
      "file",
      this.firstFormGroup.get("firstCtrl").value,
      this.firstFormGroup.get("firstCtrl").value.name
    );

    //for bonds purchases
    if (
      this.currentVariableTab == "Purchases" ||
      this.currentVariableTab == undefined
    ) {
      this._bondsPurchasesFilesImportSubscription = this.corporateBondsService
        .ImportCorporateBondPurchases(formData)
        .subscribe((res) => {
          // save the response
          this.bondsPurchasesResponse = res;

          //bonds purchases columns
          this.bondsColumns =
            this.corporateBondsService.getCorporateBondPurchasesColumns();
          //bonds purchases data grid
          this.gridDataSource = res["Data"];

          //Errors Data if success is false
          if (res["Success"] == false) {
            this.FileSuccess = false;
            this.FileNotSuccess = true;
            //get error
            this.ImportErrors = res["Errors"];
          } else {
            this.FileSuccess = true;
            this.FileNotSuccess = false;
          }
        });
    } else if (this.currentVariableTab == "Sales") {
      //for bonds sales
      this._bondsSalesFilesImportSubscription = this.corporateBondsService
        .ImportCorporateBondSales(formData)
        .subscribe((res) => {
          // save the response
          this.bondsSalesResponse = res;

          //bonds sales columns
          this.bondsColumns =
            this.corporateBondsService.getCorporateBondSalesColumns();

          //bonds sales data
          this.gridDataSource = res["Data"];

          //Errors Data if success is false
          if (res["Success"] == false) {
            this.FileSuccess = false;
            this.FileNotSuccess = true;
            //get error
            this.ImportErrors = res["Errors"];
          } else {
            this.FileSuccess = true;
            this.FileNotSuccess = false;
          }
        });
    } else if (this.currentVariableTab == "Holdings") {
      //for bonds sales
      this._bondsHoldingsFilesImportSubscription = this.corporateBondsService
        .ImportCorporateBondHoldings(formData)
        .subscribe((res) => {
          // save the response
          this.bondsHoldingsResponse = res;

          //bonds sales columns
          this.bondsColumns =
            this.corporateBondsService.getCorporateBondHoldingsColumns();

          //bonds sales data
          this.gridDataSource = res["Data"];

          //Errors Data if success is false
          if (res["Success"] == false) {
            this.FileSuccess = false;
            this.FileNotSuccess = true;
            //get error
            this.ImportErrors = res["Errors"];
          } else {
            this.FileSuccess = true;
            this.FileNotSuccess = false;
          }
        });
    }
  }

  SaveFile() {
    //for bonds purchases
    if (
      this.currentVariableTab == "Purchases" ||
      this.currentVariableTab == undefined
    ) {
      this._bondsPurchasesSaveParsedSubscription = this.corporateBondsService
        .SaveCorporateBondsPurchases(this.bondsPurchasesResponse)
        .subscribe((res) => {
          if (res["ImportStatus"] == "Successful") {
            this.importStatus = "Successful";
            //notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify(
              { message: "Unsuccessful, try again later", width: 600 },
              "error",
              3000
            );
          }
        });
    } else if (this.currentVariableTab == "Sales") {
      //for bonds sales
      this._bondsPurchasesSaveParsedSubscription = this.corporateBondsService
        .SaveCorporateBondSales(this.bondsSalesResponse)
        .subscribe((res) => {
          if (res["ImportStatus"] == "Successful") {
            this.importStatus = "Successful";
            // notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify(
              { message: "Unsuccessful, try again later", width: 600 },
              "error",
              3000
            );
          }
        });
    } else if (this.currentVariableTab == "Holdings") {
      //for stock Holdings
      this._bondsHoldingsSaveParsedSubscription = this.corporateBondsService
        .SaveCorporateBondHoldings(this.bondsHoldingsResponse)
        .subscribe((res) => {
          if (res["Success"] == true) {
            this.importStatus = "Successful";
            // notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify(
              { message: "Unsuccessful, try again later", width: 600 },
              "error",
              3000
            );
          }
        });
    }
  }

  reset() {
    this.myStepper.reset();
    this.files = [];
  }

  onTabChanged(e: MatTabChangeEvent) {
    this.currentVariableTab = e.tab.textLabel;
    this.reset();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    const file = $event.target.files[0];

    this.firstFormGroup.get("firstCtrl").setValue(file);

    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.firstFormGroup.get("firstCtrl").setValue(file);
      this.prepareFilesList($event.target.files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 30);
      }
    }, 500);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      //This to be removed incase you want to upload multiple files
      this.files = [];
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy(): void {
    if (this._bondsPurchasesFilesImportSubscription !== undefined)
      this._bondsPurchasesFilesImportSubscription.unsubscribe();
    if (this._bondsSalesFilesImportSubscription !== undefined)
      this._bondsSalesFilesImportSubscription.unsubscribe();
    if (this._bondsPurchasesSaveParsedSubscription !== undefined)
      this._bondsPurchasesSaveParsedSubscription.unsubscribe();
    if (this._bondsSalesSaveParsedSubscription !== undefined)
      this._bondsSalesSaveParsedSubscription.unsubscribe();
  }
}
