<div *ngIf="ModelGroupItems" class="model-group-models-count-section" matTooltip="Number of model groups">
    {{ModelGroupItems.length}}</div>
<div class="portfolio-asset-categories-models">

    <div *ngFor="let modelGroup of ModelGroupItems" class="model-group-item">
        <dx-tree-list [dataSource]="modelGroup.ModelItems" keyExpr="ID" parentIdExpr="Head_ID"
            [allowColumnResizing]="true" [allowColumnReordering]="true" [showBorders]="true" [width]="200"
            (onSelectionChanged)="ModelGroupsItemsSelected($event, modelGroup.ModelGroupName)"
            noDataText="no data to display..." class="tree-list" [highlightChanges]="true" [repaintChangesOnly]="true"
            [focusedRowEnabled]="true" [showBorders]="true">

            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple">
                <!-- or "multiple" | "none" -->
            </dxo-sorting>
            <!-- For uniqueness, The storage key comprises of the selected asset category text,model group name and 'strg'. Check the developer tools for item -->
            <!-- <dxo-state-storing [enabled]="true" type="sessionStorage"
                [storageKey]="SelectedAssetCategory + modelGroup.ModelGroupName + 'strg'">
            </dxo-state-storing> -->

            <dxi-column dataField="ModelName" sortOrder="asc"
                [caption]="updateCaption(modelGroup.ModelGroupName, modelGroup.ModelItems.length)"
                style="font-size: 200%;">
            </dxi-column>
        </dx-tree-list>
    </div>
</div>