import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UILanguage } from '../strings/strings.service';

/**
 * Propage language changes to all components.
 */
@Injectable()
export class LanguageChangeService {

  private selectedLanguage: ReplaySubject<UILanguage> = new ReplaySubject<UILanguage>();

  /**
   * Called in nav-bar-component to change the ui language
   * @param language The selected language
   */
  public ChangeLanguage(language: UILanguage) {
    this.selectedLanguage.next(language);
  }

  /**
   * Called in the constructor of all components
   */
  public GetCurrentLanguage(): Observable<UILanguage> {
    return this.selectedLanguage.asObservable();
  }

  constructor() { }
}
