import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { ApiService } from 'src/app/app-services/api/api.service';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ApplicationSettingsService } from 'src/app/app-services/app-settings/application-settings.service';
import { HttpParams } from '@angular/common/http';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatTabGroup } from '@angular/material/tabs';
import { ReplaySubject } from 'rxjs';
import { DocumentationService } from 'src/app/app-services/documentation/documentation.service';

export enum SalePurchaseDescriptor {
  Sales = 1,
  Purchases
}

@Component({
  selector: 'app-purchases-sales',
  templateUrl: './purchases-sales.component.html',
  styleUrls: ['./purchases-sales.component.scss']
})
export class PurchasesSalesComponent implements OnInit, AfterViewInit {
  @ViewChild('startDateBox', { static: true }) startDateBoxInstance: DxDateBoxComponent;
  @ViewChild('endDateBox', { static: true }) endDateBoxInstance: DxDateBoxComponent;
  @ViewChild('holdingTab', { static: true }) holdingsTabInstance: MatTabGroup;
  @ViewChild('purchaseSaleButtonToogle', { static: true }) purchaseSaleButtonToogleInstance: MatButtonToggleGroup;

  PurchaseSaleOptionValue: any;
  commercialPapersDataSource: any;
  floatingTBondDataSource: any;
  fixedCorporateBondsDataSource: any;
  floatingCorporateBondDataSource: any;

  timeDepositsDataSource: any;
  treasuryBondsDataSource: any;
  offshoresDataSource: any;
  tBillsDataSource: any;
  listedEquitiesDataSource: any;
  SelectedCategoryReplaySubject: ReplaySubject<any> = new ReplaySubject<string>(1);
  selectedCategory = '';

  constructor(private apiService: ApiService, private appSetttingsService: ApplicationSettingsService, public documentationService: DocumentationService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    this.appSetttingsService.GetSettings().subscribe(settings => {
      this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    });

    /** Reload purchases and sales when someone toogles between both */
    this.purchaseSaleButtonToogleInstance.valueChange.subscribe(info => {
      if (info !== undefined) {
        this.openHoldings(null, true);
      };
    });


    let selectedIndex = this.holdingsTabInstance.selectedIndex;
    if (selectedIndex === 0) {
      let theselectedOption = this.PurchaseSaleOptionValue;

      this.SelectedCategoryReplaySubject.next('Commercial Papers');
      let params = new HttpParams();
      //@ts-ignore
      params = params.append('startDate', this.startDateBoxInstance.value.toDateString());
      //@ts-ignore
      params = params.append('endDate', this.startDateBoxInstance.value.toDateString());
      params = params.append('assetCategoryName', 'CommercialPapers');
      params = params.append('saleOrPurchase', '2');
      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.commercialPapersDataSource = res;
      });
    }
  }

  openHoldings(tabChangeEvent, isRefreshAction: boolean) {

    let params = new HttpParams();
    //@ts-ignore
    params = params.append('StartDate', this.startDateBoxInstance.value.toDateString());
    //@ts-ignore
    params = params.append('EndDate', this.startDateBoxInstance.value.toDateString());

    let typeSelected = this.purchaseSaleButtonToogleInstance.value;
    let typeDesccriptor: SalePurchaseDescriptor;
    if (typeSelected === 'purchases')
      typeDesccriptor = SalePurchaseDescriptor.Purchases;
    else
      typeDesccriptor = SalePurchaseDescriptor.Sales;

    params = params.append('saleOrPurchase', typeDesccriptor.toString());

    let label = '';

    if (isRefreshAction === true) {
      this.SelectedCategoryReplaySubject.subscribe(previouslySelectedCategory => {
        label = previouslySelectedCategory;
      });
    }
    else {
      label = tabChangeEvent.tab.textLabel;
      this.SelectedCategoryReplaySubject.next(label);
    }

    if (label === 'Treasury Bonds') {
      params = params.append('assetCategoryName', 'TreasuryBond');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.treasuryBondsDataSource = res;
      });
    }

    if (label === 'Commercial Papers') {
      params = params.append('assetCategoryName', 'CommercialPapers');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.commercialPapersDataSource = res;
      });
    }
    if (label === 'Floating Treasury Bonds') {
      params = params.append('assetCategoryName', 'FloatingTreasuryBonds');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.floatingTBondDataSource = res;
      });
    }
    if (label === 'Fixed Corporate Bonds') {
      params = params.append('assetCategoryName', 'FixedCorporateBonds');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.fixedCorporateBondsDataSource = res;
      });
    }
    if (label === 'Floating Corporate Bonds') {
      params = params.append('assetCategoryName', 'FloatingCorporateBonds');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.floatingCorporateBondDataSource = res;
      });
    }
    if (label === 'Treasury Bills') {
      params = params.append('assetCategoryName', 'TreasuryBill');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.tBillsDataSource = res;
      });
    }
    if (label === 'Listed Equities') {
      params = params.append('assetCategoryName', 'ListedEquities');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.listedEquitiesDataSource = res;
      });
    }
    if (label === 'Offshores') {
      params = params.append('assetCategoryName', 'Offshores');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.offshoresDataSource = res;
      });
    }
    if (label === 'Time Deposits') {
      params = params.append('assetCategoryName', 'TimeDeposit');

      this.apiService.Get(`SalesPurchases`, { params: params }).subscribe(res => {
        this.timeDepositsDataSource = res;
      });
    }
    else {
    }
  }


}
