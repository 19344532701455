import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavbarHeaderSettingsService {
  /**Request to render results in non-portfolio */
  private ActiveComponentTitleSubject = new BehaviorSubject<string>("");
  ActiveComponentTitleSubject$ = this.ActiveComponentTitleSubject.asObservable()

  constructor(public title: Title) { }


  ChangeActiveComponentName(input: string) {
    this.title.setTitle(input)
    this.ActiveComponentTitleSubject.next(input)
  }
}
