import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';


/**
 * Show http response error dialog with information on why the request failed and any help information that
 * might be of use to the user.
 */
@Component({
  selector: 'app-api-response-error',
  templateUrl: './api-response-error.component.html',
  styleUrls: ['./api-response-error.component.scss']
})
export class ApiResponseErrorComponent implements OnInit, OnDestroy {

  public httpErrorResponse: HttpErrorResponse = undefined;
  public showInfo = false;
  public retryIntervalSubscription: Subscription = undefined;

  constructor(private dialogRef: MatDialogRef<ApiResponseErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public error: { httpResponseError: HttpErrorResponse }) {

    this.httpErrorResponse = error.httpResponseError;
    if (this.httpErrorResponse.status === 0) {
      this.httpErrorResponse.error.Source = 'Analytics';
      this.httpErrorResponse.error.message = 'You internet connection is not working';

    }
  }

  ngOnInit() {
  }


  ngOnDestroy() {
    if (this.retryIntervalSubscription !== undefined && !this.retryIntervalSubscription.closed)
      this.retryIntervalSubscription.unsubscribe();
  }
  public Close() {
    this.dialogRef.close();
  }

  public toogleDiv() {

    if (this.showInfo === false) {
      this.showInfo = true;
    }

    const retryInterval = interval(1000).pipe(take(1));

    this.retryIntervalSubscription = retryInterval.subscribe(next => {
    }, (error) => {
    }, () => {
      this.showInfo = false;
    });
  }
}
