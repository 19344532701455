import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class NonPortfolioAssetRegistryService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  getAssetCategories(): Observable<any> {
    return this.apiService.Get("analyticssetup/GetAllInstrumentCategories");
  }

  getCountries(): Observable<any> {
    return this.http.get<any[]>(`${this.url}/Countries/Get`);
  }

  getVariablesByAssetCategoryId(assetCategoryId: any): Observable<any> {
    return this.http.get(
      `${this.url}/analytics/NonPortfolioModelVariables?instrumentCategoryId=${assetCategoryId}`
    );
  }

  getExchangeByCountryId(CountryId: any): Observable<any> {
    return this.http.get(`${this.url}/StockExchanges/country/${CountryId}`);
  }

  getSecuritiesByExhangeIdandAssetCategoryId(
    exchangeId: any,
    assetCategoryCode: any
  ): Observable<any> {
    return this.http.get(
      `${this.url}/AnalyticsSetup/SecuritiesInStockExchange?stockExchangeId=${exchangeId}&instrumentCategoryCode=${assetCategoryCode}`
    );
  }

  getNonPortfolioAssetRegistryData(requestPayload: any): Observable<any> {
    return this.apiService
      .Post("NonPortfolioAssetRegistry/AssetRegistryData", requestPayload)
      .pipe(
        map((res) => res["Data"]),
        shareReplay()
      );
  }

  getNonPortfolioAssetRegistryBenchmarksData(
    requestPayload: any
  ): Observable<any> {
    return this.apiService
      .Post("NonPortfolioAssetRegistry/BenchmarkReturns", requestPayload)
      .pipe(
        map((res) => res["Data"]),
        shareReplay()
      );
  }

  getBenchmarksByInstrumentCategory(
    instrumentCategoryCode: any
  ): Observable<any> {
    return this.http
      .get(
        `${this.url}/AnalyticsSetup/InstrumentCategoryBenchmarks?instrumentCategoryCode=${instrumentCategoryCode}`
      )
      .pipe(
        map((res) => res["Benchmarks"]),
        shareReplay()
      );
  }
}
