import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { DxReportViewerComponent } from "devexpress-reporting-angular";
import {
  DxListComponent,
  DxLookupComponent,
  DxPopupComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { Subscription } from "rxjs";
import { FundConfigFormComponent } from "src/app/app-directives/fund-config-form/fund-config-form.component";
import { ApiService } from "src/app/app-services/api/api.service";

import {
  ReportEndUserSpecification,
  ReportItem,
} from "./CMAReports/helper-classes";

import DevExpressReporting from "devexpress-reporting/dx-webdocumentviewer";
import DevExpress from "@devexpress/analytics-core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoadingDialogComponent } from "src/app/app-directives/loading-dialog/loading-dialog.component";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { ConfigService } from "src/app/common/config.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { NonPortfolioAssetRegistryService } from "src/app/app-components/asset-registry/nonportfolio-asset-registry/non-portfolio-asset-registry.service";
import dxList from "devextreme/ui/list";
import _, { object } from "underscore";
import {
  Regulators,
  Tenant,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";
import { ReportService } from "./report.service";
import { SpreadsheetComponent } from "@syncfusion/ej2-angular-spreadsheet";
import { NotificationService } from "src/app/app-services/notification/notification.service";

export class ReportsRequestObject {
  public ReportName: string;
  public StartDate: any;
  public EndDate: any;
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
  public AssetClassId: string;
  public IncludeSummary: boolean;
  public IncludeCash: boolean;
  public PerformanceAttribution: any = undefined;
}

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, AfterViewInit {
  FundsFundManagerDataSource: any;
  FundsCustodianDataSource: any;
  EntityName: "Reports";
  fundHoldingsReport: any;
  hostUrl: string;
  _apiServiceSubscription: Subscription;
  @ViewChild("FundsFundManagersView", { read: ViewContainerRef, static: false })
  FundsFundManagersViewContainer: ViewContainerRef;
  @ViewChild("errorNotificationPopup", { static: false })
  errorNotificationPopupInstance: DxPopupComponent;
  @ViewChild("DatesConfig", { static: false })
  DatesConfigInstance: FundConfigFormComponent;
  @ViewChild("reportViewer", { static: false })
  ReportViewerInstance: DxReportViewerComponent;
  @ViewChild("fundManagerTreeList", { static: false })
  fundManagerTree: DxTreeListComponent;
  @ViewChild("PerfomanceMeasurementFundTreeList", { static: false })
  perfomanceMeasurementFundTreeList: DxTreeListComponent;
  @ViewChild("custodianTreeList", { static: false })
  custodianTree: DxTreeListComponent;
  @ViewChild("regulatorcustodianTreeList", { static: false })
  regulatorcustodianTreeList: DxTreeListComponent;
  @ViewChild("FundManagerInstance", { static: false })
  fundManagerInstance: DxLookupComponent;
  @ViewChild("fundmanagerList", { static: false })
  fundmanagerListInstance: dxList;
  @ViewChild("fundsList", { static: false }) fundsListInstance: DxListComponent;
  @ViewChild("spreadsheet") public spreadsheetObj: SpreadsheetComponent;

  ReportServerError: ReportServerError;
  ReportsRequestObject: ReportsRequestObject = new ReportsRequestObject();
  public loadingDialogRef: MatDialogRef<LoadingDialogComponent> = undefined;

  SelectedReportName: string = undefined;
  IncludePerfomanceAttrSummary: boolean = false;
  IncludePerfomanceAttrCash: boolean = true;
  AssetClassId: string = null;
  fundManagerSelectionMode: string = "multiple";
  selectAllMode: boolean = true;
  isQuarterlyPerfomanceMeasurementReport: boolean = false;
  isCurrentUserRegulator: boolean;
  selectedFund: string = null;
  selectedTenant: string = null;
  fundManagersArray: Array<any> = [];
  fundmanagerData: any;
  fundsData: any;
  authToken: any;
  selectedReportCategoryName: any;
  selectedFundManager: any;
  selectedFundManagerId: any;
  selectedTenantId: any;
  selectedFundByDefault: Array<any> = [];
  activeTenantName: string;
  activeTenantType: string;
  activeTenantRegulator: string;
  panelOpenState = false;
  custodianTabSelected: boolean = false;

  //subscriptions
  ZambiaSecReports: Subscription = undefined;

  constructor(
    private apiService: ApiService,
    public httpClient: HttpClient,
    private factoryResolver: ComponentFactoryResolver,
    public matDialog: MatDialog,
    private configService: ConfigService,
    public navBarSettingsService: NavbarHeaderSettingsService,
    private oidcSecurityService: OidcSecurityService,
    private nonPortfolioAssetRegistryService: NonPortfolioAssetRegistryService,
    private tenantResolverService: TenantService,
    private reportService: ReportService,
    private notificationService: NotificationService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);
    this.hostUrl = this.configService.config.ReportServerURL + "/";
    this.authToken = oidcSecurityService.getToken();
  }

  ngAfterViewInit(): void {
    this.errorNotificationPopupInstance.visible = false;
  }

  ngOnInit() {
    //resolve the current tenant
    this.activeTenantName = this.tenantResolverService.getTenantName();
    this.activeTenantType = this.tenantResolverService.getTenantType();
    this.activeTenantRegulator =
      this.tenantResolverService.getTenantRegulatorName();

    //is current tenant Regulator
    this.isCurrentUserRegulator = this.tenantResolverService.isRegulator();

    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      //for Quarterly Perfomance Measurement
      this.fundmanagerData = fundManagers;
      this.selectedFundByDefault.push(fundManagers[0]);
      // this.fundManagerInstance.dataSource = fundManagers;

      //for other reports
      //added MasterFund Property as a quickfix, to solve the recurive issue, master fund was being taken as a fund when selected
      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
          Masterfund: true,
          FundManagerID: fundManager.Id,
        });

        //for the case where master fund not is null
        fundManager.MasterFunds.forEach((masterFund) => {
          if (masterFund.Id != null) {
            nodes.push({
              Name: masterFund.Name,
              ID: masterFund.Id,
              Head_ID: fundManager.Id,
              TenantId: fundManager.TenantId,
              Masterfund: true,
              FundManagerID: fundManager.Id,
            });
          }

          if (masterFund.Id != null) {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: masterFund.Id,
                TenantId: fundManager.TenantId,
                Masterfund: false,
                FundManagerID: fundManager.Id,
              });
            });
          } else {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: fundManager.Id,
                TenantId: fundManager.TenantId,
                masterFund: false,
                FundManagerID: fundManager.Id,
              });
            });
          }
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });

    let custodianFetch$ = this.apiService.Get("analyticssetup/custodians");

    custodianFetch$.subscribe((custodians) => {
      let custodianNodes: Array<any> = [];
      let custodianData = custodians["Data"];

      custodianData.forEach((custodian) => {
        custodianNodes.push({
          Name: custodian.Name,
          ID: custodian.TenantId,
          Head_ID: 0,
          TenantId: custodian.TenantId,
          Masterfund: true,
          FundManagerID: null,
        });

        custodian.FundManagers.forEach((fundmanager) => {
          custodianNodes.push({
            Name: fundmanager.Name,
            ID: fundmanager.TenantId,
            Head_ID: custodian.TenantId,
            TenantId: fundmanager.TenantId,
            Masterfund: true,
            FundManagerID: fundmanager.Id,
          });

          //for the case where master fund not is null
          fundmanager.MasterFunds.forEach((masterFund) => {
            if (masterFund.Id != null) {
              custodianNodes.push({
                Name: masterFund.Name,
                ID: masterFund.Id,
                Head_ID: fundmanager.TenantId,
                TenantId: fundmanager.TenantId,
                Masterfund: true,
                FundManagerID: fundmanager.Id,
              });

              masterFund.Investors.forEach((investor) => {
                custodianNodes.push({
                  Name: investor.Name,
                  ID: investor.Id,
                  Head_ID: masterFund.Id,
                  TenantId: fundmanager.TenantId,
                  Masterfund: false,
                  FundManagerID: fundmanager.Id,
                });
              });
            } else {
              masterFund.Investors.forEach((investor) => {
                custodianNodes.push({
                  Name: investor.Name,
                  ID: investor.Id,
                  Head_ID: fundmanager.TenantId,
                  TenantId: fundmanager.TenantId,
                  Masterfund: false,
                  FundManagerID: fundmanager.Id,
                });
              });
            }
          });
        });
      });
      this.FundsCustodianDataSource = custodianNodes;
    });
  }

  RefreshReport(event: any) {
    if (
      this.ReportsRequestObject.FundManagers == null ||
      this.ReportsRequestObject.FundManagers.length == 0
    ) {
      this.notificationService.showError("Select Fund Manager/Funds", "");
      return;
    }

    if (this.ReportsRequestObject.ReportName == null) {
      this.notificationService.showError("Select a Report", "");
      return;
    }

    DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = {
      headers: {
        Authorization: "Bearer " + this.oidcSecurityService.getToken(),
      },
    };

    this.authToken = this.oidcSecurityService.getToken();
    let startDate = event.startDate;
    let endDate = event.endDate;
    this.ReportsRequestObject.StartDate = startDate;
    this.ReportsRequestObject.EndDate = endDate;
    this.ReportsRequestObject.PerformanceAttribution = {
      AssetClassId: this.AssetClassId,
      Summary: true,
      IncludeCashInPortfolioValuation: this.IncludePerfomanceAttrCash,
    };

    //console.log(reportRequestFinal);

    //for zambia sec - tempo
    if (
      this.activeTenantName == Tenant.Tenant2 ||
      this.activeTenantRegulator == Regulators.Regulator2
    ) {
      let zambiaSECreportRequestFinal = {
        FundManagers: this.ReportsRequestObject.FundManagers,
        EndDate: this.ReportsRequestObject.EndDate,
        StartDate: this.ReportsRequestObject.StartDate,
        ReportName: this.ReportsRequestObject.ReportName,
        PerformanceAttribution:
          this.ReportsRequestObject.PerformanceAttribution,
        CustodianReport: this.custodianTabSelected,
      };

      //zambia sec does not use xtrareports
      this.ZambiaSecReports = this.reportService
        .getFundManagerCISReturnReports(zambiaSECreportRequestFinal)
        .subscribe(
          (result) => {
            // if (this.ReportsRequestObject.ReportName != "cisReviewReport") {
            //   let blob = this.convertToBlob(result);
            //   let file = new File([blob], "report.xlsx");
            //   this.spreadsheetObj.open({ file: file });
            //   this.notificationService.showSuccess(
            //     "Loading Report....",
            //     "Success"
            //   );
            // } else {
            this.notificationService.showSuccess(
              "Downloading Report....",
              "Success"
            );
            this.downloadFile(result);
            //}
            this.loadingDialogRef.close();
          },
          (err) => {
            this.loadingDialogRef.close();
            //this.notificationService.showError(err.error, "Error");
            // this.notificationService.showError(
            //   "Error your report, try again later",
            //   "Error"
            // );
          }
        );
    } else if (
      this.activeTenantName == Tenant.Tenant1 ||
      this.activeTenantRegulator == Regulators.Regulator1
    ) {
      let cmaReportRequestFinal = {
        FundManagers: this.ReportsRequestObject.FundManagers,
        EndDate: this.ReportsRequestObject.EndDate,
        StartDate: this.ReportsRequestObject.StartDate,
        ReportName: this.ReportsRequestObject.ReportName,
        PerformanceAttribution:
          this.ReportsRequestObject.PerformanceAttribution,
      };
      console.log(cmaReportRequestFinal);
      let jsonRepresentation = JSON.stringify(cmaReportRequestFinal);
      this.ReportViewerInstance.bindingSender.OpenReport(jsonRepresentation);
    }

    this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
      width: "400",
      height: "400",
      disableClose: true,
      hasBackdrop: true,
      data: {
        Title: `Fetching ${this.SelectedReportName} report`,
        Description: "Please wait...",
      },
    });
  }

  OnCustomizeExportOptions(event) {
    event.args.HideFormat(
      DevExpressReporting.Reporting.Viewer.ExportFormatID.DOCX
    );
    event.args.HideFormat(
      DevExpressReporting.Reporting.Viewer.ExportFormatID.HTML
    );
    event.args.HideFormat(
      DevExpressReporting.Reporting.Viewer.ExportFormatID.MHT
    );
    event.args.HideFormat(
      DevExpressReporting.Reporting.Viewer.ExportFormatID.RTF
    );
    event.args.HideFormat(
      DevExpressReporting.Reporting.Viewer.ExportFormatID.Text
    );
  }

  ReportItemSelected(event: ReportItem) {
    this.SelectedReportName = event.ReportName;
    /**
     * What is available here:
     * a. The ids of the selected funds and the fundmanagers
     */
    this.ReportsRequestObject.ReportName = event.ID;
  }

  created() {
    fetch(
      "https://js.syncfusion.com/demos/ejservices/data/Spreadsheet/LargeData.xlsx"
    ) // fetch the remote url
      .then((response) => {
        response.blob().then((fileBlob) => {
          // convert the excel file to blob
          let file = new File([fileBlob], "Sample.xlsx"); //convert the blob into file
          this.spreadsheetObj.open({ file: file }); // open the file into Spreadsheet
        });
      });
  }

  updateFundManagerTreeList(selectedReportCategoryName: string) {
    this.selectedReportCategoryName = selectedReportCategoryName;

    if (selectedReportCategoryName == "Regulatory Compliance Reports") {
      //fundmanager Tree List Selection is multiple
      this.fundManagerSelectionMode = "multiple";
      this.isQuarterlyPerfomanceMeasurementReport = false;
      this.selectAllMode = true;
      this.fundsData = [];
    } else if (selectedReportCategoryName == "Perfomance Attribution Reports") {
      //fundmanager Tree List Selection is single
      this.fundManagerSelectionMode = "single";
      this.isQuarterlyPerfomanceMeasurementReport = false;

      this.selectAllMode = false;
      //this.fundManagerTree.instance.deselectAll();

      //also update the ReportName
      this.ReportsRequestObject.ReportName = "PerformanceAttribution";
      this.SelectedReportName = "Performance Attribution";
      this.fundsData = [];
    } else if (
      selectedReportCategoryName == "Performance Measurement Reports"
    ) {
      this.fundManagerSelectionMode = "multiple";
      this.selectAllMode = false;
      this.isQuarterlyPerfomanceMeasurementReport = true;
      this.fundManagerTree.instance.deselectAll();

      //also update the ReportName
      //this.ReportsRequestObject.ReportName = "CMASummaryInformation";
      this.SelectedReportName = "Performance Measurement Reports";
    }
  }

  /**
   * Gets the error that is thrown by the server incase one does.
   * At this point, clost the loading bar
   * @param errorEvent
   */
  OnServerError(errorEvent) {
    this.loadingDialogRef.close();
    let error = errorEvent.args.Error.errorThrown;
    this.ReportServerError = { errorMessage: error };
    this.errorNotificationPopupInstance.visible = true;
  }

  /**
   * This is called on succesful report fetch. Close the loading bar at this point
   * @param readyEvent
   */
  ReportDocumentReady(readyEnvent: any) {
    this.loadingDialogRef.close();
  }

  PerfomanceAttrSummaryValue(IncludeSummary: boolean) {
    this.IncludePerfomanceAttrSummary = IncludeSummary;
  }

  PerfomanceAttrCashValue(IncludeCash: boolean) {
    this.IncludePerfomanceAttrCash = IncludeCash;
  }

  UpdateAssetClassSelected(assetClass: any) {
    this.AssetClassId = assetClass.Id;
  }

  ModelGroupsItemsSelected(event) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];

    if (event.selectedRowsData.length !== 0) {
      this.fundManagerTree.instance
        .getSelectedRowsData("includeRecursive")
        .forEach((selectedRowsData) => {
          if (selectedRowsData.Masterfund == true) {
          } else {
            const fundId = selectedRowsData.ID;
            //send selectedFund to child component
            this.selectedFund = fundId;
            const fundManagerId = selectedRowsData.FundManagerID;
            const selectedTenantId = selectedRowsData.TenantId;
            //send selectedFund to child component
            this.selectedTenant = selectedTenantId;

            let results = this.ReportsRequestObject.FundManagers.filter((x) => {
              if (x.Id === fundManagerId) {
                return x;
              }
            });
            let selectedFundManager: any;
            if (results.length > 0) {
              selectedFundManager = results[0];
            }

            if (!selectedFundManager) {
              selectedFundManager = {
                Id: fundManagerId,
                TenantId: selectedTenantId,
                Funds: [fundId],
              };
              this.ReportsRequestObject.FundManagers.push(selectedFundManager);
            } else {
              selectedFundManager.Funds.push(fundId);
            }
          }
        });
    }
  }

  CustodianItemsSelected(event) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];

    if (event.selectedRowsData.length !== 0) {
      this.regulatorcustodianTreeList.instance
        .getSelectedRowsData("includeRecursive")
        .forEach((selectedRowsData) => {
          if (selectedRowsData.Masterfund == true) {
            console.log(selectedRowsData.Name + " ingored");
          } else {
            const fundId = selectedRowsData.ID;
            //send selectedFund to child component
            this.selectedFund = fundId;
            const fundManagerId = selectedRowsData.FundManagerID;
            const selectedTenantId = selectedRowsData.TenantId;
            //send selectedFund to child component
            this.selectedTenant = selectedTenantId;

            let results = this.ReportsRequestObject.FundManagers.filter((x) => {
              if (x.Id === fundManagerId) {
                return x;
              }
            });
            let selectedFundManager: any;
            if (results.length > 0) {
              selectedFundManager = results[0];
            }

            if (!selectedFundManager) {
              selectedFundManager = {
                Id: fundManagerId,
                TenantId: selectedTenantId,
                Funds: [fundId],
              };
              this.ReportsRequestObject.FundManagers.push(selectedFundManager);
            } else {
              selectedFundManager.Funds.push(fundId);
            }
          }
        });
    }
  }

  //for Quarterly Perfomance Measurement Reports
  FundManagerSelectionChanged(e) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];
    this.fundsData = [];
    let nodes: Array<any> = [];

    let selectedFundManager = e.addedItems[0];
    this.selectedFundManagerId = e.addedItems[0].Id;
    this.selectedTenantId = e.addedItems[0].TenantId;

    nodes.push({
      Name: selectedFundManager.Name,
      ID: selectedFundManager.Id,
      Head_ID: 0,
      TenantId: selectedFundManager.TenantId,
      Masterfund: true,
      FundManagerID: selectedFundManager.Id,
    });

    selectedFundManager.MasterFunds.forEach((masterFund) => {
      if (masterFund.Id != null) {
        nodes.push({
          Name: masterFund.Name,
          ID: masterFund.Id,
          Head_ID: selectedFundManager.Id,
          TenantId: selectedFundManager.TenantId,
          Masterfund: true,
          FundManagerID: selectedFundManager.Id,
        });
      }

      if (masterFund.Id != null) {
        masterFund.Investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: masterFund.Id,
            TenantId: selectedFundManager.TenantId,
            Masterfund: false,
            FundManagerID: selectedFundManager.Id,
          });
        });
      } else {
        masterFund.Investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: selectedFundManager.Id,
            TenantId: selectedFundManager.TenantId,
            masterFund: false,
            FundManagerID: selectedFundManager.Id,
          });
        });
      }
    });

    this.fundsData = nodes;
  }

  //for Quarterly Perfomance Measurement Reports
  FundsSelectionChanged(event) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];

    if (event.selectedRowsData.length !== 0) {
      this.perfomanceMeasurementFundTreeList.instance
        .getSelectedRowsData("includeRecursive")
        .forEach((selectedRowsData) => {
          if (selectedRowsData.Masterfund == true) {
          } else {
            const fundId = selectedRowsData.ID;
            //send selectedFund to child component
            this.selectedFund = fundId;
            const fundManagerId = selectedRowsData.FundManagerID;
            const selectedTenantId = selectedRowsData.TenantId;
            //send selectedFund to child component
            this.selectedTenant = selectedTenantId;

            let results = this.ReportsRequestObject.FundManagers.filter((x) => {
              if (x.Id === fundManagerId) {
                return x;
              }
            });
            let selectedFundManager: any;
            if (results.length > 0) {
              selectedFundManager = results[0];
            }

            if (!selectedFundManager) {
              selectedFundManager = {
                Id: fundManagerId,
                TenantId: selectedTenantId,
                Funds: [fundId],
              };
              this.ReportsRequestObject.FundManagers.push(selectedFundManager);
            } else {
              selectedFundManager.Funds.push(fundId);
            }
          }
        });
    }
  }

  FundManagerListContentReady() {
    let firstFundManager = this.fundmanagerData[0];
    this.selectedFundByDefault.push(firstFundManager);
    let nodes: Array<any> = [];

    this.selectedFundManagerId = firstFundManager.Id;
    this.selectedTenantId = firstFundManager.TenantId;

    nodes.push({
      Name: firstFundManager.Name,
      ID: firstFundManager.Id,
      Head_ID: 0,
      TenantId: firstFundManager.TenantId,
      Masterfund: true,
      FundManagerID: firstFundManager.Id,
    });

    firstFundManager.MasterFunds.forEach((masterFund) => {
      if (masterFund.Id != null) {
        nodes.push({
          Name: masterFund.Name,
          ID: masterFund.Id,
          Head_ID: firstFundManager.Id,
          TenantId: firstFundManager.TenantId,
          Masterfund: true,
          FundManagerID: firstFundManager.Id,
        });
      }

      if (masterFund.Id != null) {
        masterFund.Investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: masterFund.Id,
            TenantId: firstFundManager.TenantId,
            Masterfund: false,
            FundManagerID: firstFundManager.Id,
          });
        });
      } else {
        masterFund.Investors.forEach((investor) => {
          nodes.push({
            Name: investor.Name,
            ID: investor.Id,
            Head_ID: firstFundManager.Id,
            TenantId: firstFundManager.TenantId,
            masterFund: false,
            FundManagerID: firstFundManager.Id,
          });
        });
      }
    });

    this.fundsData = nodes;
  }

  fundManagerCustodianSelectionChanged(e) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];
    this.ReportsRequestObject.ReportName = null;
    let selectedTabName = e.addedItems[0].title;
    if (selectedTabName == "Custodians") {
      this.custodianTabSelected = true;
      //clear fundmanager selections
      this.fundManagerTree.instance.deselectAll();
    } else {
      this.custodianTabSelected = false;
      //clear custodian selections
      this.regulatorcustodianTreeList.instance.deselectAll();
    }
  }

  downloadFile(response: any) {
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  convertToBlob(response: any): Blob {
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }

  ngOnDestroy(): void {
    if (this.ZambiaSecReports !== undefined) {
      this.ZambiaSecReports.unsubscribe();
    }
  }

  onCellPrepared(e) {
    if (e.data.Head_ID == 0) {
      e.cellElement.classList.add("adaptiveRowStyle");
    }
  }
}

export interface ReportServerError {
  errorMessage: string;
}
