import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ReportsSelectedIdsConfig } from '../ReportsSelectedIdsConfig';


@Component({
  selector: 'fund-managers-only-list',
  templateUrl: './fund-managers-only-list.component.html',
  styleUrls: ['./fund-managers-only-list.component.scss']
})
export class FundManagersOnlyListComponent implements OnInit, AfterViewInit {


  @ViewChild(DxListComponent, { static: false }) fundsList: DxListComponent;
  @Input() DataSource: Observable<any>;

  ReportsSelectedIdsConfig: BehaviorSubject<ReportsSelectedIdsConfig> = new BehaviorSubject<ReportsSelectedIdsConfig>(null);

  constructor() { }
  ngAfterViewInit(): void {
    /**setup funds tree list options */
    this.fundsList.showSelectionControls = true
    this.fundsList.selectionMode = 'all'
    this.fundsList.pageLoadMode = 'scrollBottom'
    this.fundsList.displayExpr = 'Name'
    this.fundsList.keyExpr = 'ID'

    this.fundsList.selectedItemsChange.subscribe((items: Array<any>) => {

      if (items.length !== 0) {
        let selectedIds = items.map(item => item.ID);
        let IdsSelectionConfig: ReportsSelectedIdsConfig = { parentListName: 'FundManagers', selectedFundIds: [], selectedFundManagerIds: selectedIds }

        this.ReportsSelectedIdsConfig.next(IdsSelectionConfig);
      }
    })
  }

  ngOnInit() {

  }

}
