import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService } from 'src/app/app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-countries',
	templateUrl: './countries.component.html',
	styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit, OnDestroy {
	gridDataSource: any;
	EntityName = 'Countries';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single' }
	ApiSubscription: Subscription = undefined

	public Columns: Array<any> = [
		{ caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Code', dataField: 'Code', dataType: 'date' },
		{ caption: 'Days To Settle', dataField: 'DaysToSettle' },
		{ caption: 'Day Count', dataField: 'DayCount' },
		{ caption: 'Market Closing', dataField: 'MarketClosing', dataType: 'date' },
		{ caption: 'Organization Identifier', dataField: 'OrganizationIdentifier' },
		{ caption: 'Use Calendar Days', dataField: 'UseCalendarDays', dataType: 'boolean' },
		{ caption: 'Active', dataField: 'Active', dataType: 'boolean' },
		{ caption: 'Approved', dataField: 'Approved', dataType: 'boolean' }
	];
	constructor(private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Countries')
	}
	ngOnDestroy(): void {
		if (this.ApiSubscription !== undefined) {
			this.ApiSubscription.unsubscribe()
		}
	}

	ngOnInit() {
		this.ApiSubscription = this.apiService.Get('Countries/Get').subscribe(result => this.gridDataSource = result);
	}

	calculateGridHeight() {
		return 0.55 * window.screen.height + "px";
	}

	rowDoubleClicked(event: any) {
	}
}
