<div class="asset-registry-container">
  <div class="portfolio-analytics-dates-section">
    <div class="dates-container">
      <div style="margin-right: 1%">
        <div class="label">Country</div>
        <dx-lookup
          #CountryLookup
          [dataSource]="CountryDataSource"
          valueExpr="ID"
          [width]="200"
          displayExpr="Name"
          placeholder="Select a Country"
          (onSelectionChanged)="CountrySelectionChanged($event)"
        >
          <dxo-drop-down-options
            [closeOnOutsideClick]="true"
            [showTitle]="false"
            height="600"
          >
          </dxo-drop-down-options>
        </dx-lookup>
      </div>
      <div style="margin-right: 1%">
        <div class="label">Exchange</div>
        <dx-lookup
          #ExchangeLookup
          [dataSource]="ExchangeDataSource"
          [width]="200"
          displayExpr="Name"
          valueExpr="ID"
          placeholder="Select an Exchange"
          (onSelectionChanged)="ExchangeSelectionChanged($event)"
        >
          <dxo-drop-down-options
            [closeOnOutsideClick]="true"
            [showTitle]="false"
          >
          </dxo-drop-down-options>
        </dx-lookup>
      </div>
      <div style="margin-right: 1%">
        <div class="label">Start Date</div>
        <dx-date-box
          id="analytics-start-date-input"
          displayFormat="dd MMM yyyy"
          #startDateBox
          matTooltip="Period start date"
          width="120"
        >
        </dx-date-box>
      </div>
      <div>
        <div class="label">End Date</div>
        <dx-date-box
          id="analytics-end-date-input"
          displayFormat="dd MMM yyyy"
          #endDateBox
          matTooltip="Period end date"
          width="120"
        >
        </dx-date-box>
      </div>
    </div>
    <!-- render button -->
    <div class="render-button">
      <div
        *ngIf="_apiFetchSubscription === undefined; else fetchingInProgressTemp"
      >
        <button
          #refreshButton
          mat-flat-button
          color="primary"
          (click)="fetchResults()"
        >
          <mat-icon> update </mat-icon>
          Refresh
        </button>
      </div>

      <ng-template #fetchingInProgressTemp>
        <button
          mat-raised-button
          color="warn"
          (click)="closeAnalyticsFetchRequest()"
          matTooltip="Cancel this request"
        >
          <mat-icon> cancel_presentation </mat-icon>
          Cancel
        </button>
      </ng-template>
    </div>
  </div>
  <!-- -------------------------------------------- Asset category Registry Tabs----------------------------------- -->
  <div class="funds-asset-cats-container">
    <dx-tab-panel
      #assetCategoriesPanel
      [dataSource]="assetCategoryTabs"
      [selectedIndex]="0"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      [showNavButtons]="true"
      width="100%"
      (onSelectionChanged)="assetCategoriesSelectionChanged($event)"
    >
      <div *dxTemplate="let item of 'title'">
        <div [ngStyle]="item.iconStyle" matTooltip="{{ item.content }}">
          <div class="tabpanel-item">
            <div>
              <span class="icon">
                <mat-icon [inline]="true">{{ item.icon }}</mat-icon>
              </span>
            </div>
            <div>
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>

      <div *dxTemplate="let selectedAsstCat of 'item'">
        <div class="SecurityVariablesWrapper">
          <div class="SecurityVariableslookups">
            <div style="margin-right: 10%">
              <div class="label">Securities</div>
              <dx-drop-down-box
                #securityDropdown
                [(value)]="securityTreeBoxValue"
                valueExpr="Id"
                displayExpr="Name"
                placeholder="Select Security(ies)..."
                [dataSource]="SecurityDataSource"
                width="300"
                [disabled]="macrosAssetCategorySelected"
              >
                <dx-tree-list
                  #SecurityTreeList
                  height="400"
                  width="280"
                  keyExpr="Id"
                  displayExpr="Name"
                  parentIdExpr="ParentId"
                  [dataSource]="SecurityDataSource"
                  [allowColumnResizing]="true"
                  [allowColumnReordering]="true"
                  [showBorders]="true"
                  noDataText="Select Country and Exchage to display..."
                  class="tree-list"
                  [highlightChanges]="true"
                  [repaintChangesOnly]="true"
                  [focusedRowEnabled]="false"
                  [showBorders]="true"
                  [hoverStateEnabled]="true"
                  [focusStateEnabled]="true"
                  (onSelectionChanged)="SecurityItemsSelected($event)"
                  (onItemSelectionChanged)="
                    securityTreeList_itemSelectionChanged($event)
                  "
                  [autoExpandAll]="true"
                >
                  <dxo-selection
                    mode="multiple"
                    [recursive]="true"
                  ></dxo-selection>
                  <dxo-filter-row [visible]="true"></dxo-filter-row>
                  <dxo-sorting mode="multiple"> </dxo-sorting>
                  <dxi-column
                    dataField="Name"
                    caption="Select all Securities"
                    sortOrder="asc"
                    style="font-size: 200%; font-weight: 700"
                  >
                  </dxi-column>
                  <dxo-selection mode="multiple"> </dxo-selection>
                </dx-tree-list>
              </dx-drop-down-box>
            </div>
            <div style="margin-right: 10%">
              <div class="label">Variables</div>
              <dx-drop-down-box
                #funddropdownInstance
                [(value)]="variablesTreeBoxValue"
                valueExpr="Id"
                displayExpr="Name"
                placeholder="Select Variable(s)..."
                [dataSource]="VariableDataSource"
                width="270"
              >
                <dx-tree-list
                  #VariableTreeList
                  height="250"
                  width="250"
                  keyExpr="Id"
                  displayExpr="Name"
                  parentIdExpr="ParentId"
                  [dataSource]="VariableDataSource"
                  [allowColumnResizing]="true"
                  [allowColumnReordering]="true"
                  [showBorders]="true"
                  noDataText="Select Country and Exchage to display..."
                  class="tree-list"
                  [highlightChanges]="true"
                  [repaintChangesOnly]="true"
                  [focusedRowEnabled]="false"
                  [showBorders]="true"
                  [hoverStateEnabled]="true"
                  [focusStateEnabled]="true"
                  (onSelectionChanged)="VariableItemsSelected($event)"
                  (onItemSelectionChanged)="
                    variableTreeList_itemSelectionChanged($event)
                  "
                  [autoExpandAll]="true"
                >
                  <dxo-selection
                    mode="multiple"
                    [recursive]="true"
                  ></dxo-selection>
                  <dxo-filter-row [visible]="true"></dxo-filter-row>
                  <dxo-sorting mode="multiple"> </dxo-sorting>
                  <dxi-column
                    dataField="Name"
                    caption="Select all Variables"
                    sortOrder="asc"
                    style="font-size: 200%; font-weight: 700"
                  >
                  </dxi-column>
                  <dxo-selection mode="multiple"> </dxo-selection>
                </dx-tree-list>
              </dx-drop-down-box>
            </div>
          </div>
        </div>

        <!-- SecurityVariableLookupsEnds -->
        <mat-tab-group
          #tabGroup
          [disableRipple]="true"
          [animationDuration]="0"
          (selectedTabChange)="onTabChanged($event)"
        >
          <mat-tab
            *ngFor="
              let item of DisplayAssetRegistryResultsObservable | async;
              let i = index
            "
            [label]="item.VariableName"
          >
            <!-- <ng-template mat-tab-label>
              <mat-icon [inline]="true" color="accent" style="padding: 5px"
                >view_stream</mat-icon
              >
              l{{ item.VariableName }}
            </ng-template> -->

            <div class="wrapper">
              <mat-divider></mat-divider>

              <!--grid -->
              <div class="one">
                <dx-data-grid
                  #resultsDataGrid
                  [dataSource]="item.VariableData"
                  [columns]="item.VariableColumns"
                  columnResizingMode="widget"
                  [allowColumnResizing]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [groupPanel]="{ visible: true }"
                  (onRowDblClick)="updateChart($event)"
                  [grouping]="{ autoExpandAll: true }"
                  [searchPanel]="{
                    visible: true,
                    placeholder: 'search for anything',
                    width: 500
                  }"
                  [allowColumnReordering]="false"
                  [rowAlternationEnabled]="false"
                  [export]="{
                    enabled: true,
                    fileName: 'Non-Portfolio-Analytics',
                    allowExportSelectedData: true
                  }"
                  [columnChooser]="{ enabled: true, mode: 'select' }"
                  [wordWrapEnabled]="false"
                  [columnFixing]="true"
                  [twoWayBindingEnabled]="true"
                  [repaintChangesOnly]="false"
                  [activeStateEnabled]="true"
                  (onCellPrepared)="gridInstanceCellPrepared($event)"
                  (OnContentReady)="gridInstanceContentReady($event)"
                  [keyExpr]="gridKeyExpression"
                  [focusStateEnabled]="true"
                  [focusedRowEnabled]="false"
                  [scrolling]="{ mode: 'standard', preloadEnabled: true }"
                  height="{{ calculateGridHeight() }}"
                >
                  <dxo-selection
                    mode="single"
                    [deferred]="true"
                  ></dxo-selection>
                  <dxo-paging [enabled]="true"></dxo-paging>
                  <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
              </div>

              <!-- visualizations -->
              <div class="two">
                <dx-chart id="chart" [dataSource]="chartDataSource">
                  <dxo-size width="100%"></dxo-size>
                  <dxo-argument-axis argumentType="datetime">
                  </dxo-argument-axis>
                  <dxi-value-axis position="right"> </dxi-value-axis>
                  <dxo-title [text]="chartName">
                    <dxo-font
                      [size]="16"
                      [color]="black"
                      [weight]="700"
                    ></dxo-font>
                  </dxo-title>
                  <dxo-common-series-settings argumentField="Date" type="line">
                  </dxo-common-series-settings>
                  <dxi-series
                    valueField="Value"
                    color="#03a9f4"
                    [name]="VariableValue"
                    width="1"
                  ></dxi-series>
                  <dxi-series
                    valueField="BenchmarkReturnValue"
                    color="#FF0000"
                    name="Benchmark Value"
                    width="1"
                  >
                  </dxi-series>
                  <dxo-legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  >
                  </dxo-legend>
                  <dxo-tooltip [enabled]="true" [shared]="true" color="#FFFFFF">
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxo-tooltip>
                  <dxo-legend
                    verticalAlignment="top"
                    horizontalAlignment="center"
                  >
                  </dxo-legend>
                  <dxo-export [enabled]="true"></dxo-export>
                </dx-chart>
                <div class="benchmark" *ngIf="ListedEquitiesSelected">
                  <div class="label">Benchmarks</div>
                  <dx-lookup
                    #BenchmarkLookup
                    [dataSource]="BenchmarkDataSource"
                    valueExpr="BenchmarkID"
                    displayExpr="Name"
                    [width]="200"
                    displayExpr="Name"
                    valueExpr="Id"
                    placeholder="Select a Benchmark"
                    (onSelectionChanged)="BenchmarkSelectionChanged($event)"
                    [disabled]="priceOrReturnsVariableNotSelected"
                  >
                    <dxo-drop-down-options
                      [closeOnOutsideClick]="true"
                      [showTitle]="false"
                    >
                    </dxo-drop-down-options>
                  </dx-lookup>
                </div>
              </div>
            </div>
          </mat-tab>
          <ng-template #placeHolderTemplate> no data guys </ng-template>
        </mat-tab-group>
      </div>
    </dx-tab-panel>
  </div>
</div>
