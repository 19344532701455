import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface AnalyticsRequestObject {
  investorId: any
  startDate: any;
  endDate: any;
  // assetClass: any;
  modelDescption: any;
  includeCash: any;
  TenantId: string;

}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsRequestObjectService {

  private requestObjectSubject = new Subject<AnalyticsRequestObject>();
  requestObjectObservable$ = this.requestObjectSubject.asObservable()

  constructor() { }

  updateRequestObject(obj: AnalyticsRequestObject) {
    this.requestObjectSubject.next(obj)
  }
}
