import { Injectable, OnDestroy } from "@angular/core";
import {
  HttpInterceptor,
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarDismiss,
  MatSnackBarRef,
  SimpleSnackBar,
} from "@angular/material/snack-bar";
import { tap } from "rxjs/operators";

import { ApiResponseErrorComponent } from "../../utilities/api-response-error/api-response-error.component";
import { Router } from "@angular/router";
import { LoadingDialogService } from "../loading-dialog/loading-dialog.service";
import { Error404Component } from "src/app/utilities/error404/error404.component";
import { NotificationService } from "../notification/notification.service";

@Injectable({
  providedIn: "root",
})
export class ApiResponseInterceptorService
  implements HttpInterceptor, OnDestroy
{
  public retryMessage: string = undefined;
  retryIntervalSubscription: Subscription = undefined;
  private errorSnackSubscription: Subscription = undefined;
  public showProgress: Observable<boolean> = new Observable<boolean>();
  public showProgressBeahviour: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public router: Router,
    public loadingDialogService: LoadingDialogService,
    private notificationService: NotificationService
  ) {
    this.showProgress = this.showProgressBeahviour.asObservable();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {
          // This tap operator can be used for handling any responses, if needed.
        },
        (error: HttpErrorResponse) => this.handleError(error)
      )
    );
  }

  private handleError(error: HttpErrorResponse): void {
    switch (error.status) {
      case 0:
        const message = error.message;
        this.notificationService.showError(message, "Error");
        break;
      case 401:
        const unauthorizedMessage =
          "Unauthorized access. Redirecting to login.";
        this.notificationService.showError(unauthorizedMessage, "Error");
        this.router.navigate(["/login"]);
        break;
      case 404:
        const notFoundMessage =
          "The resource you are looking for could not be found.";
        this.notificationService.showError(notFoundMessage, "Error");
        break;
      case 500:
        const internalServerErrorMessage =
          "An internal server error occurred. Please try again later or contact support.";
        this.notificationService.showError(
          internalServerErrorMessage,
          "Server Error"
        );
        break;
      case 429:
        const limitReachedErrorMessage =
          "Request is too large, kindly select a few securities.";
        this.notificationService.showError(
          limitReachedErrorMessage,
          "Server Error"
        );
        break;
      default:
        const generic = "Unknown Error, Kindly contact your administrator";
        this.notificationService.showError(generic, "Error");
        break;
    }
  }
  ngOnDestroy(): void {
    if (
      this.errorSnackSubscription !== undefined &&
      !this.errorSnackSubscription.closed
    )
      this.errorSnackSubscription.unsubscribe();
  }
}
