import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class CouponImporterService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportCorporateBondsCouponPayment(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/CouponPaymentsImport/FixedCorporateBondCouponPaymentsFileImport`,
        file
      )
      .pipe(
        // map((res) => res["Data"]),
        shareReplay()
      );
  }

  ImportTreasuryBondsCouponPayment(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/CouponPaymentsImport/FixedTreasuryBondCouponPaymentsFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  SaveTreasuryBondsCouponPayment(payload: any): Observable<any> {
    return this.apiService
      .Post(
        "CouponPaymentsImport/SaveParsedFixedTreasuryBondCouponPayments",
        payload
      )
      .pipe(shareReplay());
  }

  SaveCorporateBondsCouponPayment(payload: any): Observable<any> {
    return this.apiService
      .Post(
        "CouponPaymentsImport/SaveParsedFixedCorporateBondCouponPayments",
        payload
      )
      .pipe(shareReplay());
  }

  getTreasuryBondsCouponPaymentColumns(): any {
    let treasuryBondsCouponPaymentColumns = [
      {
        caption: "Investor Code",
        dataField: "InvestorCode",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 180,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },

      { caption: "Investor Name", dataField: "InvestorName" },
      {
        caption: "Fixed Treasury Bond Code",
        dataField: "FixedTreasuryBondCode",
      },
      {
        caption: "Coupon Date",
        dataField: "CouponDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Amount", dataField: "Amount" },
      { caption: "Reference", dataField: "PurchaseReference" },
    ];
    return treasuryBondsCouponPaymentColumns;
  }

  getCorporateBondsCouponPaymentColumns(): any {
    let corporateBondsCouponPaymentColumns = [
      {
        caption: "Fixed Corporate Bond Code",
        dataField: "FixedCorporateBondCode",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 180,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },

      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      {
        caption: "Coupon Date",
        dataField: "CouponDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Amount", dataField: "Amount" },
      { caption: "Reference", dataField: "PurchaseReference" },
    ];

    return corporateBondsCouponPaymentColumns;
  }
}
