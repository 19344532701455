import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  constructor(private dialogRef: MatDialogRef<Error404Component>) { }

  ngOnInit() {
  }

  close(event) {
    this.dialogRef.close();
  }
}
