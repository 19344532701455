import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/app-services/api/api.service';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
  selector: 'app-unit-trust',
  templateUrl: './unit-trust.component.html',
  styleUrls: ['./unit-trust.component.scss']
})
export class UnitTrustComponent implements OnInit {
  gridDataSource: any;
	EntityName = 'Fixed Treasury Bonds';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single'}


	constructor(private apiService: ApiService, private title: Title,private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Fixed Treasury Bonds')
	 }

	ngOnInit() {
		this.apiService.Get('UnitTrusts').subscribe(result => this.gridDataSource = result);
	}

	rowDoubleClicked(event: any) {

	}

}
