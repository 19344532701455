//
import { Injectable } from "@angular/core";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { Column } from "devextreme/ui/data_grid";

export interface SummarItemInfo {
  value: any;
  valueText: any;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsDisplayViewGridColumnsService {
  systemSettings: ApplicationSettings;

  constructor(private settingsService: ApplicationSettingsService) {
    this.settingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
    });
  }

  totalFundSummaryGridSummary = {
    totalItems: [
      // { column: 'ActualPerformance', summaryType: 'sum', customizeText: (itemInfo) => this.customizeSummaryText(itemInfo), displayFormat: '{0}', cssClass: 'summary-cell-item' },
      {
        column: "CategoryWeight",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "WeightedPerformance",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "BenchmarkWeight",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "WeightedBenchmarkReturn",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "ActiveWeight",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "ActiveReturn",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "AllocationEffect",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "SelectionEffect",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "InteractionEffect",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
      {
        column: "ActiveManagementEffect",
        summaryType: "sum",
        customizeText: (itemInfo) => this.customizeSummaryText(itemInfo),
        displayFormat: "{0}",
      },
    ],
  };

  totalFundSummaryGridColumns: Column[] = [
    {
      caption: "Asset Category",
      dataField: "AssetCategory",
      sortOrder: "asc",
      fixed: true,
      fixedPosition: "left",
      minWidth: 200,
      allowResizing: false,
    },
    {
      caption: "Actual Performance (%)",
      dataField: "ActualPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActualPerformance),
    },
    {
      caption: "Category Weight (%)",
      dataField: "CategoryWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.CategoryWeight),
    },
    {
      caption: "Weighted Performance (%)",
      dataField: "WeightedPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedPerformance),
      cssClass: "performance-attr-grid-focus-1",
    },
    {
      caption: "Benchmark Name",
      dataField: "BenchmarkName",
      minWidth: 100,
      allowResizing: true,
      width: 150,
    },
    {
      caption: "Benchmark Return (%)",
      dataField: "BenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkReturn),
    },
    {
      caption: "Benchmark Weight (%)",
      dataField: "BenchmarkWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkWeight),
    },
    {
      caption: "Weighted Benchmark Return (%)",
      dataField: "WeightedBenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedBenchmarkReturn),
    },
    {
      caption: "Active Weight (%)",
      dataField: "ActiveWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveWeight),
    },
    {
      caption: "Active Return (%)",
      dataField: "ActiveReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveReturn),
    },
    {
      caption: "Selection Effect (%)",
      dataField: "SelectionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.SelectionEffect),
      cssClass: "performance-attr-grid-focus-2",
    },
    {
      caption: "Interaction Effect (%)",
      dataField: "InteractionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.InteractionEffect),
      cssClass: "performance-attr-grid-focus-3",
    },
    {
      caption: "Allocation Effect (%)",
      dataField: "AllocationEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AllocationEffect),
      cssClass: "performance-attr-grid-focus-5",
    },
    {
      caption: "Active Management Effect (%)",
      dataField: "ActiveManagementEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveManagementEffect),
      cssClass: "performance-attr-grid-focus-4",
    },
  ];

  assetClassGridColumns: Column[] = [
    {
      caption: "Holdings Date",
      dataField: "HoldingsDate",
      dataType: "date",
      sortOrder: "asc",
      format: "dd-MMM-yyyy",
      fixedPosition: "left",
      fixed: true,
      allowFixing: true,
    },
    {
      caption: "Opening Market Value",
      dataField: "OpeningMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.OpeningMarketValue),
    },
    {
      caption: "Creations",
      dataField: "Creations",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Creations),
    },
    {
      caption: "Redemptions",
      dataField: "Redemptions",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Redemptions),
    },
    {
      caption: "Closing Market Value",
      dataField: "ClosingMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.ClosingMarketValue),
    },
    {
      caption: "Units",
      dataField: "Units",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Units),
    },
    {
      caption: "Unit Price",
      dataField: "UnitPrice",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.UnitPrice),
    },
    {
      caption: "Actual Performance (%)",
      dataField: "ActualPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActualPerformance),
      cssClass: "performance-attr-grid-focus-1",
    },
    {
      caption: "Weight (%)",
      dataField: "Weight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.Weight),
    },
    {
      caption: "Weighted Performance (%)",
      dataField: "WeightedPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedPerformance),
    },
    { caption: "Benchmark Name", dataField: "BenchmarkName", groupIndex: 0 },
    {
      caption: "Benchmark Return (%)",
      dataField: "BenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkReturn),
    },
    {
      caption: "Benchmark Weight (%)",
      dataField: "BenchmarkWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(
          rowData.BenchmarkWeight
        ) /*headerCellTemplate: 'titleHeaderTemplate', name: 'purchaseses-sales'*/,
    },
    {
      caption: "Weighted Benchmark Return (%)",
      dataField: "WeightedBenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedBenchmarkReturn),
    },
    {
      caption: "Active Weight (%)",
      dataField: "ActiveWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveWeight),
    },
    {
      caption: "Active Return (%)",
      dataField: "ActiveReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveReturn),
    },
    {
      caption: "Selection Effect (%)",
      dataField: "SelectionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.SelectionEffect),
      cssClass: "performance-attr-grid-focus-2",
    },
    {
      caption: "Interaction Effect (%)",
      dataField: "InteractionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.InteractionEffect),
      cssClass: "performance-attr-grid-focus-3",
    },
    {
      caption: "Allocation Effect (%)",
      dataField: "AllocationEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AllocationEffect),
      cssClass: "performance-attr-grid-focus-5",
    },
    {
      caption: "Active Management Effect (%)",
      dataField: "ActiveManagementEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveManagementEffect),
      cssClass: "performance-attr-grid-focus-4",
    },
  ];
  gridColumnsAssetCategory = [
    {
      caption: "Asset Category",
      dataField: "AssetCategory",
      fixed: true,
      fixedPosition: "left",
      minWidth: 100,
      allowResizing: true,
      width: 150,
    },
    {
      caption: "Opening Market Value",
      dataField: "OpeningMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.OpeningMarketValue),
    },
    {
      caption: "Creations",
      dataField: "Creations",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Creations),
    },
    {
      caption: "Redemptions",
      dataField: "Redemptions",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Redemptions),
    },
    {
      caption: "Closing Market Value",
      dataField: "ClosingMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.ClosingMarketValue),
    },
    {
      caption: "Units",
      dataField: "Units",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Units),
    },

    {
      caption: "Unit Price",
      dataField: "UnitPrice",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.UnitPrice),
    },
    {
      caption: "Actual Performance (%)",
      dataField: "ActualPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActualPerformance),
      cssClass: "performance-attr-grid-focus-1",
    },
    {
      caption: "Weight (%)",
      dataField: "Weight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.Weight),
    },
    {
      caption: "Weighted Performance (%)",
      dataField: "WeightedPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedPerformance),
    },
    {
      caption: "Benchmark Return (%)",
      dataField: "BenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkReturn),
    },
    {
      caption: "Benchmark Weight (%)",
      dataField: "BenchmarkWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkWeight),
    },
    {
      caption: "Weighted Benchmark Return (%)",
      dataField: "WeightedBenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedBenchmarkReturn),
    },
    {
      caption: "Active Weight (%)",
      dataField: "ActiveWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveWeight),
    },
    {
      caption: "Active Return (%)",
      dataField: "ActiveReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveReturn),
    },
    {
      caption: "Selection Effect (%)",
      dataField: "SelectionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.SelectionEffect),
      cssClass: "performance-attr-grid-focus-2",
    },
    {
      caption: "Interaction Effect (%)",
      dataField: "InteractionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.InteractionEffect),
      cssClass: "performance-attr-grid-focus-3",
    },
    {
      caption: "Allocation Effect (%)",
      dataField: "AllocationEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AllocationEffect),
      cssClass: "performance-attr-grid-focus-5",
    },
    {
      caption: "Active Management Effect (%)",
      dataField: "ActiveManagementEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveManagementEffect),
      cssClass: "performance-attr-grid-focus-4",
    },
  ];
  gridColumnsAssetLevel: Column[] = [
    {
      caption: "Asset Name",
      dataField: "Asset",
      fixed: true,
      fixedPosition: "left",
      width: 250,
      allowResizing: true,
      allowFixing: true,
    },
    {
      caption: "Opening Market Value",
      dataField: "OpeningMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.OpeningMarketValue),
    },
    {
      caption: "Creations",
      dataField: "Creations",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Creations),
    },
    {
      caption: "Redemptions",
      dataField: "Redemptions",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Redemptions),
    },
    {
      caption: "Closing Market Value",
      dataField: "ClosingMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.ClosingMarketValue),
    },
    {
      caption: "Units",
      dataField: "Units",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.Units),
    },
    {
      caption: "Unit Price",
      dataField: "UnitPrice",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.UnitPrice),
    },
    {
      caption: "Actual Performance  (%)",
      dataField: "ActualPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActualPerformance),
      cssClass: "performance-attr-grid-focus-1",
    },
    {
      caption: "Weight (%)",
      dataField: "Weight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.Weight),
    },
    {
      caption: "Weighted Performance (%)",
      dataField: "WeightedPerformance",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedPerformance),
    },
    {
      caption: "Benchmark Return (%)",
      dataField: "BenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkReturn),
    },
    {
      caption: "Benchmark Weight (%)",
      dataField: "BenchmarkWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkWeight),
    },
    {
      caption: "Weighted Benchmark Return (%)",
      dataField: "WeightedBenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedBenchmarkReturn),
    },
    {
      caption: "Active Weight (%)",
      dataField: "ActiveWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveWeight),
    },
    {
      caption: "Active Return (%)",
      dataField: "ActiveReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveReturn),
    },
    {
      caption: "Selection Effect (%)",
      dataField: "SelectionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.SelectionEffect),
      cssClass: "performance-attr-grid-focus-2",
    },
    {
      caption: "Interaction Effect (%)",
      dataField: "InteractionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.InteractionEffect),
      cssClass: "performance-attr-grid-focus-3",
    },
    {
      caption: "Allocation Effect (%)",
      dataField: "AllocationEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AllocationEffect),
      cssClass: "performance-attr-grid-focus-5",
    },
    {
      caption: "Active Management Effect (%)",
      dataField: "ActiveManagementEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveManagementEffect),
      cssClass: "performance-attr-grid-focus-4",
    },
  ];

  totalFundColumns: Column[] = [
    {
      caption: "Date",
      dataField: "Date",
      dataType: "date",
      sortOrder: "asc",
      groupIndex: 0,
      format: "dd-MMM-yyyy",
    },
    {
      caption: "Asset Category",
      dataField: "AssetCategory",
      fixed: true,
      fixedPosition: "left",
      width: "150px",
      allowResizing: true,
      sortOrder: "asc",
    },
    { caption: "Asset Class", dataField: "AssetClass", width: "150px" },
    {
      caption: "Opening Market Value",
      dataField: "OpeningMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.OpeningMarketValue),
    },
    {
      caption: "Closing Market Value",
      dataField: "ClosingMarketValue",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculateNonPercentageValue(rowData.ClosingMarketValue),
    },
    {
      caption: "Asset Category Return (%)",
      dataField: "AssetCategoryReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AssetCategoryReturn),
      cssClass: "performance-attr-grid-focus-1",
    },
    {
      caption: "Asset Category Weight (%)",
      dataField: "AssetCategoryWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AssetCategoryWeight),
    },
    {
      caption: "Weighted Asset Category Return (%)",
      dataField: "WeightedAssetCategoryReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedAssetCategoryReturn),
    },
    {
      caption: "Benchmark Return (%)",
      dataField: "BenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkReturn),
    },
    {
      caption: "Benchmark Weight (%)",
      dataField: "BenchmarkWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.BenchmarkWeight),
    },
    {
      caption: "Weighted Benchmark Return (%)",
      dataField: "WeightedBenchmarkReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.WeightedBenchmarkReturn),
    },
    {
      caption: "Active Weight (%)",
      dataField: "ActiveWeight",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveWeight),
    },
    {
      caption: "Active Return (%)",
      dataField: "ActiveReturn",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveReturn),
    },
    {
      caption: "Selection Effect (%)",
      dataField: "SelectionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.SelectionEffect),
      cssClass: "performance-attr-grid-focus-2",
    },
    {
      caption: "Interaction Effect (%)",
      dataField: "InteractionEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.InteractionEffect),
      cssClass: "performance-attr-grid-focus-3",
    },
    {
      caption: "Allocation Effect (%)",
      dataField: "AllocationEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.AllocationEffect),
      cssClass: "performance-attr-grid-focus-5",
    },
    {
      caption: "Active Management Effect (%)",
      dataField: "ActiveManagementEffect",
      dataType: "number",
      calculateCellValue: (rowData) =>
        this.calculatePercentageValue(rowData.ActiveManagementEffect),
      cssClass: "performance-attr-grid-focus-4",
    },
  ];

  performanceAttributionSummaryItems: Array<any> = [
    {
      column: "OpeningMarketValue",
      summaryType: "max",
      showInGroupFooter: true,
      alignByColumn: true,
    },
    {
      column: "OpeningMarketValue",
      summaryType: "max",
      showInGroupFooter: true,
      alignByColumn: true,
    },
    {
      column: "OpeningMarketValue",
      summaryType: "max",
      showInGroupFooter: true,
      alignByColumn: true,
    },
    {
      column: "OpeningMarketValue",
      summaryType: "max",
      showInGroupFooter: true,
      alignByColumn: true,
    },
    {
      column: "OpeningMarketValue",
      summaryType: "max",
      showInGroupFooter: true,
      alignByColumn: true,
    },
  ];
  calculatePercentageValue(rowData: any) {
    let percentageDecimalPlaces =
      this.systemSettings.PercentageValuesDecimalPoints;

    return Number.parseFloat(rowData).toFixed(percentageDecimalPlaces);
  }
  calculateNonPercentageValue(rowData: any) {
    let nonpercentageDecimalPlaces =
      this.systemSettings.NonPercentageValuesDecimalPoints;

    return Number.parseFloat(rowData).toFixed(nonpercentageDecimalPlaces);
  }

  customizeSummaryText(itemInfo: SummarItemInfo) {
    let itemValue: number = itemInfo.value;
    let percentageInString = localStorage.getItem("pcntg-decPoints");
    let percentageValuesDecimalPoints = Number.parseInt(percentageInString);
    let convertedValue = itemValue.toFixed(percentageValuesDecimalPoints);
    let parsedIntValue = Number(convertedValue);

    if (parsedIntValue < 0) {
      let finaleAbsoluteValue = Math.abs(parsedIntValue);

      let finalValue = "(" + finaleAbsoluteValue + ")";
      return finalValue;
    }
    return parsedIntValue;
  }
}
