import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/app-services/api/api.service';
import { ConfigService } from 'src/app/common/config.service';

@Injectable({
  providedIn: 'root'
})
export class BenchmarksService {
  private url = '';

  constructor(private http: HttpClient, private apiService: ApiService, private configService: ConfigService) { 
    this.url = this.configService.config.APIURL;
  }

  
  loadBenchMarks(): Observable<any> {
    return this.apiService.Get('Benchmarks/Get');
  }

  loadBenchMarkTypes(): Observable<any> {
    return this.apiService.Get('BenchmarkTypes/Get');
  }

  getBenchMarkTypeById(benchmarkTypeId: any): Observable<any> {
    return this.http.get(`${this.url}/BenchmarkTypes/GetById?id=${benchmarkTypeId}`);
  }

}
