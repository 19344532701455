<div class="results-container">
  <div class="non-portfolio-results-title">
    <div class="help-items">
      <!-- <div>
                <span>
                    <i class="fa fa-question-circle" aria-hidden="true">
                    </i> To see in details what a column represents, Hover over the column title and wait to see help.
                </span>
            </div> -->
      <div>
        <span>
          <i class="fa fa-question-circle" aria-hidden="true"> </i> To scroll
          horizontally, Press and hold SHIFT key and use your mouse scroll wheel
          or touchpad.
        </span>
      </div>
    </div>
    <div *ngIf="updaterService.selectedAssetCategory$ | async as assetCategory">
      <mat-chip-list>
        <mat-chip
          [matTooltip]="assetCategoryResultInformation(assetCategory.text)"
        >
          {{ assetCategory.text }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div>
    <mat-tab-group
      #resultsTab
      [disableRipple]="true"
      [animationDuration]="0"
      (selectedTabChange)="refreshGrid($event)"
    >
      <!-- [label]="customizeParameterName(item)" -->
      <mat-tab *ngFor="let item of ModelParameterResults | async">
        <ng-template mat-tab-label>
          <div
            class="analytics-tabs-header-name-section"
            matTooltip="{{ item.parameterName }} results"
          >
            <div>
              <mat-icon color="accent">view_stream</mat-icon>
            </div>
            <div style="font-weight: 400">
              {{ customizeParameterName(item) }}
            </div>
          </div>
        </ng-template>
        <!-- have a grid for each parameter -->
        <div>
          <dx-data-grid
            #resultsDataGrid
            columnResizingMode="widget"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            keyExpr="Security"
            [showBorders]="true"
            [showRowLines]="true"
            [groupPanel]="{ visible: true }"
            (onCellPrepared)="gridInstanceCellPrepared($event)"
            [searchPanel]="{
              visible: true,
              placeholder: 'search for anything',
              width: 500
            }"
            [allowColumnReordering]="true"
            [rowAlternationEnabled]="true"
            [export]="{
              enabled: true,
              fileName: 'Non-Portfolio-Analytics',
              allowExportSelectedData: true
            }"
            [selection]="{ mode: 'single' }"
            [columnChooser]="{ enabled: true, mode: 'select' }"
            [wordWrapEnabled]="true"
            [columnFixing]="true"
            [(dataSource)]="item.dataSource"
            [(columns)]="item.columns"
            [columnAutoWidth]="true"
            [activeStateEnabled]="true"
            [height]="600"
            [repaintChangesOnly]="false"
            [twoWayBindingEnabled]="true"
          >
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-group-panel visible="auto"></dxo-group-panel>
            <dxo-grouping
              expandMode="rowClick"
              [allowCollapsing]="true"
              class="grid-groupby-column-highlight"
            >
            </dxo-grouping>
            <dxo-summary *ngIf="item.showSummary === 'True'">
              <dxi-total-item
                *ngFor="let colDef of item.summaryColumns"
                [column]="colDef"
                summaryType="sum"
                [customizeText]="customizeSummaryText"
              >
              </dxi-total-item>
            </dxo-summary>
            <!-- </div> -->
          </dx-data-grid>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
