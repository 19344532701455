import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";

@Component({
  selector: "app-asset-registry-data-grid",
  templateUrl: "./asset-registry-data-grid.component.html",
  styleUrls: ["./asset-registry-data-grid.component.scss"],
})
export class AssetRegistryDataGridComponent implements OnInit, OnChanges {
  @ViewChild("resultsDataGrid", { static: true })
  GridInstance: DxDataGridComponent;
  summaryColumns = ["MarketValue", "ShareVolume", "CurrentValue", "FaceValue"];

  @Input() EntityDataArray: Array<any>;
  @Input() EntityColumns: any;

  systemSettings: ApplicationSettings;
  constructor(public appSettingsService: ApplicationSettingsService) {
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.EntityColumns) {
      const currentItem: SimpleChange = changes.EntityColumns;
      if (currentItem.currentValue) {
        this.GridInstance.columns = [...currentItem.currentValue];
      }
    }
    if (changes.EntityDataArray) {
      const currentItem = changes.EntityDataArray;
      if (currentItem.currentValue) {
        this.GridInstance.dataSource = [...currentItem.currentValue];
      }
    }
  }

  gridInstanceCellPrepared(event) {
    let cellValue = event.value;

    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "date") {
      columnDataEntry.format = "dd-MMM-yyyy";
    }
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          let floatNumber = parseFloat(value).toFixed(2);
          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.systemSettings.PercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
      }
    }
  }

  numberWithCommas(numberToModify: any) {
    var parts = numberToModify.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  calculateGridHeight() {
    return 0.45 * window.screen.height + "px";
  }

  customizeSummaryText(data) {
    let value = data.value;

    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let floatNumber = absoluteValue.toFixed(2);
      //let formattedNumber = this.numberWithCommas(floatNumber);
      return "Total: " + `(${floatNumber})`;
    } else if (value > 0) {
      let floatNumber = parseFloat(value).toFixed(2);
      //to clean, use numberWithCommas() instead
      var parts = floatNumber.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var joined = parts.join(".");

      return "Total: " + joined;
    } else if (value == 0) {
      let numberWithDecimalPoints = value.toFixed(2);
      return "Total: " + numberWithDecimalPoints;
    }
  }
}
