import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
  selector: 'app-portfolio-reports-container',
  templateUrl: './portfolio-reports-container.component.html',
  styleUrls: ['./portfolio-reports-container.component.scss']
})
export class PortfolioReportsContainerComponent implements OnInit {

  background: ThemePalette = 'primary';
  
  tabs = [
    { text: "Regulatory Compliance" },
    { text: "Portfolio Rebalancing" },
];
 
  constructor(navBarSettingsService: NavbarHeaderSettingsService) {
    navBarSettingsService.ChangeActiveComponentName('Portfolio Reports')
   }

  ngOnInit() {
  }

}
