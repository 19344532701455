import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService } from 'src/app/app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-banks',
	templateUrl: './banks.component.html',
	styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit, OnDestroy {

	gridDataSource: any;
	EntityName = 'Banks';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single' }

	public Columns: Array<any> = [
		{ caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Code', dataField: 'Code' },
		{ caption: 'Swift Code', dataField: 'SwiftCode', },
		{ caption: 'Home Bank', dataField: 'HomeBank', dataType: 'boolean' },
		{ caption: 'Approved', dataField: 'Approved', dataType: 'boolean' },
		{ caption: 'Active', dataField: 'Active', dataType: 'boolean' },
	];
	ApiSubscription: Subscription;

	constructor(private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Banks')
	}

	ngOnInit() {
		this.ApiSubscription = this.apiService.Get('Banks').subscribe(result => this.gridDataSource = result);
	}

	ngOnDestroy(): void {
		if (this.ApiSubscription !== undefined) {
			this.ApiSubscription.unsubscribe()
		}
	}

	rowDoubleClicked(event: any) {
	}

}
