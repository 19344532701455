import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-helper',
  templateUrl: './reports-helper.component.html',
  styleUrls: ['./reports-helper.component.scss']
})
export class ReportsHelperComponent implements OnInit {

  @Input() reportName: string = "FundManagerHoldingsReport"
  @Input() reportServerURL: string = "FundManagerHoldingsReport"

  constructor() { }
  ngOnInit() { }
  
  BeforeRender(pEvent: any) {
    pEvent.args.reportPreview.zoom(1);
  }
}
