import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService } from 'src/app/app-services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataFormOptions, DataFormComponent } from 'src/app/app-directives/data-form/data-form.component';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-custodians',
	templateUrl: './custodians.component.html',
	styleUrls: ['./custodians.component.scss']
})
export class CustodiansComponent implements OnInit, OnDestroy {

	gridDataSource: any;
	EntityName = 'Custodians';
	GridKeyExpression = 'ID';
	gridColumns: Array<any> = [
		{ dataField: 'Name' },
		{ dataField: 'Code' },
		{ dataField: 'MainPhone' },
		{ dataField: 'Email' },
		{ dataField: 'Website' },
		{ dataField: 'PhysicalAddress' },
		{ dataField: 'Active' },
		{ dataField: 'Approved' }
	];

	formItems: Array<any>;
	ApiSubscription: Subscription = undefined

	constructor(private apiService: ApiService, private matDialog: MatDialog, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Custodians')
		this.formItems = [
			{
				dataField: 'Name', validationRules: [{
					type: 'required',
					message: 'Fund is required'
				}],
				cssClass: 'required-form-item'
			},
			{
				dataField: 'CountryId',
				cssClass: 'required-form-item',
				validationRules: [{
					type: 'required',
					message: 'Country is required'
				}],
				editorType: 'dxLookup',
				editorOptions: {
					dataSource: new CustomStore({
						key: 'ID',
						load: (loadOption) => {
							if (loadOption.searchExpr === 'Name') {
								if (loadOption.searchValue !== null) {
									return this.apiService.Get('countries/FindByName?name=' + loadOption.searchValue).toPromise();
								}
							}
							return this.apiService.Get('countries').toPromise();
						},
						byKey: (key) => {
							return this.apiService.Get('countries/GetById?id=' + key).toPromise();
						}
					}),
					valueExpr: 'ID',
					displayExpr: 'Name'
				},
				label: {
					text: 'Country'
				}
			},
			{
				dataField: 'MainPhone',
				validationRules: [{
					type: 'required',
					message: 'Main Phone is required'
				}],
				cssClass: 'required-form-item'

			},
			{
				dataField: 'AlternatePhone',
				validationRules: [{
					type: 'required',
					message: 'Alternate Phone is required'
				}],
				cssClass: 'required-form-item',
			},
			{
				dataField: 'Email', validationRules: [{ type: 'email' }, {
					type: 'required',
					message: 'Email is required'
				}],
				cssClass: 'required-form-item',
			},
			{
				dataField: 'Website', validationRules: [{
					type: 'required',
					message: 'website is required'
				}],
				cssClass: 'required-form-item',
			},
			{
				dataField: 'PhysicalAddress', validationRules: [{
					type: 'required',
					message: 'Physical Address is required'
				}],
				cssClass: 'required-form-item',
			},
			{
				dataField: 'PostalAddress', validationRules: [{
					type: 'required',
					message: 'Postal Address is required'
				}],
				cssClass: 'required-form-item',
			},
			{ dataField: 'Active', editorType: 'dxSwitch' },
			{ dataField: 'Approved', editorType: 'dxSwitch' }
		];
	}
	ngOnDestroy(): void {
		if (this.ApiSubscription !== undefined) {
			this.ApiSubscription.unsubscribe()
		}
	}

	ngOnInit() {
		this.ApiSubscription = this.apiService.Get(this.EntityName).subscribe(result => {
			this.gridDataSource = result;
		});
	}

	rowDoubleClicked(rowData: any) {
		let dataFormOptions = new DataFormOptions();
		dataFormOptions.formData = rowData;
		dataFormOptions.formItems = this.formItems;
		dataFormOptions.formTitle = `Edit ${this.EntityName}`;
		dataFormOptions.postEndPoint = this.EntityName;
		let dialogRef = this.matDialog.open(DataFormComponent,
			{ width: '800px', data: dataFormOptions });
		/** A cheap but expensive way to refresh */
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}
	AddNew(event: any) {
		let dataFormOptions = new DataFormOptions();
		dataFormOptions.formData = null;
		dataFormOptions.formItems = this.formItems;
		dataFormOptions.formTitle = `New ${this.EntityName}`;
		dataFormOptions.postEndPoint = this.EntityName;
		let dialogRef = this.matDialog.open(DataFormComponent,
			{ width: '800px', data: dataFormOptions });

		/** A cheap but expensive way to refresh */
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}

}

