import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-documentation-snackbar',
	templateUrl: './documentation-snackbar.component.html',
	styleUrls: ['./documentation-snackbar.component.scss']
})
export class DocumentationSnackbarComponent implements OnInit {

	rating = 0;
	starCount = 5;
	starColor = 'accent';


	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DocumentationSnackbarComponent>) {
	}

	ngOnInit() {
	}

	closeDialog() {
		this.dialogRef.close();
	}

	onRatingChanged($event) {
		this.rating = $event;
	}
}
