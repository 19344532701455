<div>
    <div style="font-weight: 700; margin-left: 5%; font-stretch: extra-expanded; font-size: 110%;">
        Funds
    </div>
    <mat-divider></mat-divider>
    <div>
        <dx-list #fundsList height="450" [dataSource]="DataSource | async">
        </dx-list>
    </div>
    <div style="border-bottom: 1px solid coral; margin-top: 10px;"></div>
</div>