import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DataFormOptions, DataFormComponent } from 'src/app/app-directives/data-form/data-form.component';
import { ApiService } from 'src/app/app-services/api/api.service';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { UserService } from './user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  gridDataSource: any;
  EntityName = 'Users';
  Genders: any;


  public Columns: any = [
    { caption: 'First Name', dataField: 'firstName', alignment: 'left', sortOrder: 'acs' },
    { caption: 'Last Name', dataField: 'lastName', },
    { caption: 'Username', dataField: 'username', },
    { caption: 'Email', dataField: 'email' },
    { caption: 'Gender', dataField: 'gender' },
    { caption: 'Email Verified', dataField: 'emailVerified', dataType: 'boolean' },
    { caption: 'Active', dataField: 'Active', dataType: 'boolean' },
  ];

  formItems: Array<any>;
  ApiSubscription1: Subscription = undefined;

  constructor(private apiService: ApiService, public matDialog: MatDialog, private title: Title, 
    private navBarSettingsService: NavbarHeaderSettingsService, private userService: UserService) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName)

    this.Genders = {
      properties: [
        { id: 0, description: 'Male' },
        { id: 1, description: 'Female' },
        { id: 2, description: 'Other' },
      ]
    };

    this.formItems = [
      {
        label: { text: 'First Name' },
        dataField: 'FirstName',
        validationRules: [{
          type: 'required',
          message: 'First Name is required'
        }
      ],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },
      },
      {
        label: { text: 'Last Name' },
        dataField: 'LastName',
        validationRules: [{
          type: 'required',
          message: 'Last Name is required'
        },
      ],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },
      },
      {
        label: { text: 'Email' },
        dataField: 'Email',
        validationRules: [{
          type: 'required',
          message: 'Email is required'
        },
        {
          type: 'email',
          message: 'Email is Invalid'
        }
      ],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },
      },
      {
        dataField: 'Gender',
        editorType: 'dxSelectBox',
        editorOptions: {
          dataSource: this.Genders.properties,
          valueExpr: 'description',
          displayExpr: 'description'
        },
        validationRules: [{
          type: 'required',
          message: 'Gender is required',
        }],
        cssClass: 'required-form-item',
        label: {
          text: 'Gender'
        }
      },
      {
        label: { text: 'Password' },
        dataField: 'Password',
        validationRules: [{
          type: 'required',
          message: 'Password must have at least one special character, at least one digit and at least one uppercase letter.'
        },
        // {
        //   type: 'pattern',
        //   pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]$',
        //   message: 'Password must have at least one special character, at least one digit and at least one uppercase letter.'
        // }
      ],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },
      },
      {
        dataField: 'Active',
        editorType: 'dxCheckBox',
      }
     ];
  }

  ngOnInit() {
    //Bind data to Grid
    this.ApiSubscription1 = this.userService.getUsersByTenantId().subscribe(result => this.gridDataSource = result);
  }


  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe()
    } 
  }

  rowDoubleClicked(rowData: any) {

      let dataFormOptions = new DataFormOptions();
      dataFormOptions.formData = rowData;
      dataFormOptions.formItems = this.formItems;
      dataFormOptions.formTitle = `Edit User - ${rowData.Name}`;
      dataFormOptions.postEndPoint = 'Users';
      let dialogRef = this.matDialog.open(DataFormComponent,
        { width: '800px', data: dataFormOptions });
      /** A cheap but expensive way to refresh */
      dialogRef.afterClosed().subscribe(res => {
        this.ngOnInit();
      })
  }
  
  AddNew(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `New User`
    dataFormOptions.postEndPoint = 'account/RegisterUser';
    let dialogRef = this.matDialog.open(DataFormComponent,
      { width: '800px', data: dataFormOptions });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

}


