<div class="content-section">
	<div class="routing-area-view-area">
		<div class="global-title-section-name-refresh-button">
			<div style="margin-left: 4%;">
				<button mat-stroked-button matTooltip="Refresh {{EntityName}}" (click)="ngOnInit()" color="primary">
					<mat-icon>refresh</mat-icon> Refresh
				</button>
			</div>
		</div>
		<div class="routing-area-view-new-button">
			<button mat-mini-fab color="accent" (click)="AddNew($event)" matTooltip="Add New {{EntityName}}">
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</div>


	<div>
		<!-- <div>
		<button mat-button (click)="ngOnInit()">
			<mat-icon color="accent">refresh</mat-icon> Refresh
		</button>
	</div> -->
		<div>
			<app-data-grid [EntityDataArray]="gridDataSource" [EntityColumns]="gridColumns"
				(EditEvent)="rowDoubleClicked($event)" [EntityIdentifier]="EntityName"
				[GridKeyExpression]="GridKeyExpression">
			</app-data-grid>
		</div>
	</div>
</div>