<h2 mat-dialog-title class="loading">Fetching</h2>
<mat-dialog-content>
    <div class="request-description-page-items">
        <div *ngFor="let item of data">
            <div style="font-weight: bold;">{{item.ModelGroupName}}</div>
            <div *ngFor="let model of item.Models">{{model}}
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>