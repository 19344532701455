import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../app-services/api/api.service';

@Component({
	selector: 'app-funds',
	templateUrl: './funds.component.html',
	styleUrls: ['./funds.component.scss']
})
export class FundsComponent implements OnInit {

	funds: any;
	constructor(private apiService: ApiService) { }

	ngOnInit() {
		this.apiService.Get('UmbrellaFunds').subscribe(res => {
			this.funds = res;
		});
	}

}
