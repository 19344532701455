import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Title } from "@angular/platform-browser";
import notify from "devextreme/ui/notify";
import { Subscription } from "rxjs";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { DepositImporterService } from "./deposit-importer.service";

@Component({
  selector: "app-deposit-data-importer",
  templateUrl: "./deposit-data-importer.component.html",
  styleUrls: ["./deposit-data-importer.component.scss"],
})
export class DepositDataImporterComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;

  //props
  files: any[] = [];
  currentVariableTab: string;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  depositColumns: any;
  depositPurchasesResponse: any;
  depositSalesResponse: any;
  depositHoldingsResponse: any;
  gridDataSource: any;
  importStatus: any;
  FileSuccess: boolean = true;
  FileNotSuccess: boolean;
  ImportErrors: Array<any> = [];
  EntityName = "Deposits Data Importer";

  //subsciptions
  public _depositPurchasesFilesImportSubscription: Subscription;
  public _depositSalesFilesImportSubscription: Subscription;
  public _depositPurchasesSaveParsedSubscription: Subscription;
  public _depositSalesSaveParsedSubscription: Subscription;
  public _depositHoldingsFilesImportSubscription: Subscription;
  public _depositHoldingsSaveImportSubscription: Subscription;

  constructor(
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService,
    private _formBuilder: FormBuilder,
    private depositImporterService: DepositImporterService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);
  }

  ngOnInit() {
    // https://www.techiediaries.com/angular-formdata/
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }
  handleFileInput(files: FileList) {
    console.error(files);
  }

  goBack() {
    this.myStepper.previous();
  }

  goForward() {
    this.myStepper.next();
  }

  processFile() {
    //append the file
    const formData: FormData = new FormData();
    formData.append(
      "file",
      this.firstFormGroup.get("firstCtrl").value,
      this.firstFormGroup.get("firstCtrl").value.name
    );

    //for deposit purchases
    if (
      this.currentVariableTab == "Purchases" ||
      this.currentVariableTab == undefined
    ) {
      this._depositPurchasesFilesImportSubscription =
        this.depositImporterService
          .ImportDepositPurchases(formData)
          .subscribe((res) => {
            // save the response
            this.depositPurchasesResponse = res;

            //deposit purchases columns
            this.depositColumns =
              this.depositImporterService.getDepositPurchasesColumns();
            //deposit purchases data grid
            this.gridDataSource = res["Data"];

            //Errors Data if success is false
            if (res["Success"] == false) {
              this.FileSuccess = false;
              this.FileNotSuccess = true;
              //get error
              this.ImportErrors = res["Errors"];
            } else {
              this.FileSuccess = true;
              this.FileNotSuccess = false;
            }
          });
    } else if (this.currentVariableTab == "Sales") {
      //for deposit sales
      this._depositSalesFilesImportSubscription = this.depositImporterService
        .ImportDepositSales(formData)
        .subscribe((res) => {
          // save the response
          this.depositSalesResponse = res;

          //deposit sales columns
          this.depositColumns =
            this.depositImporterService.getDepositSalesColumns();

          //deposit sales data
          this.gridDataSource = res["Data"];

          //Errors Data if success is false
          if (res["Success"] == false) {
            this.FileSuccess = false;
            this.FileNotSuccess = true;
            //get error
            this.ImportErrors = res["Errors"];
          } else {
            this.FileSuccess = true;
            this.FileNotSuccess = false;
          }
        });
    } else if (this.currentVariableTab == "Holdings") {
      //for deposit Holdings
      this._depositHoldingsFilesImportSubscription = this.depositImporterService
        .ImportDepositHoldings(formData)
        .subscribe((res) => {
          // save the response
          this.depositHoldingsResponse = res;

          //deposit sales columns
          this.depositColumns =
            this.depositImporterService.getDepositHoldingsColumns();

          //deposit sales data
          this.gridDataSource = res["Data"];

          //Errors Data if success is false
          if (res["Success"] == false) {
            this.FileSuccess = false;
            this.FileNotSuccess = true;
            //get error
            this.ImportErrors = res["Errors"];
          } else {
            this.FileSuccess = true;
            this.FileNotSuccess = false;
          }
        });
    }
  }

  SaveFile() {
    //for deposit purchases
    if (
      this.currentVariableTab == "Purchases" ||
      this.currentVariableTab == undefined
    ) {
      this._depositPurchasesSaveParsedSubscription = this.depositImporterService
        .SaveDepositPurchases(this.depositPurchasesResponse)
        .subscribe((res) => {
          if (res["ImportStatus"] == "Successful") {
            this.importStatus = "Successful";
            //notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify(
              { message: "Unsuccessful, try again later", width: 600 },
              "error",
              3000
            );
          }
        });
    } else if (this.currentVariableTab == "Sales") {
      //for deposit sales
      this._depositPurchasesSaveParsedSubscription = this.depositImporterService
        .SaveDepositSales(this.depositSalesResponse)
        .subscribe((res) => {
          if (res["ImportStatus"] == "Successful") {
            this.importStatus = "Successful";
            // notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify(
              { message: "Unsuccessful, try again later", width: 600 },
              "error",
              3000
            );
          }
        });
    } else if (this.currentVariableTab == "Holdings") {
      //for deposit Holdings
      this._depositHoldingsSaveImportSubscription = this.depositImporterService
        .SaveDepositHoldings(this.depositHoldingsResponse)
        .subscribe((res) => {
          if (res["Success"] == true) {
            this.importStatus = "Successful";
            // notify({ message: "Import successful", width: 600 }, "info", 3000);
          } else {
            this.importStatus = "UnSuccessful";
            notify({ message: res["ErrorMessage"], width: 600 }, "error", 3000);
          }
        });
    }
  }

  reset() {
    this.myStepper.reset();
    this.files = [];
  }

  onTabChanged(e: MatTabChangeEvent) {
    this.currentVariableTab = e.tab.textLabel;
    this.reset();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    const file = $event.target.files[0];

    this.firstFormGroup.get("firstCtrl").setValue(file);

    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.firstFormGroup.get("firstCtrl").setValue(file);
      this.prepareFilesList($event.target.files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 30);
      }
    }, 500);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      //This to be removed incase you want to upload multiple files
      this.files = [];
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy(): void {
    if (this._depositPurchasesFilesImportSubscription !== undefined)
      this._depositPurchasesFilesImportSubscription.unsubscribe();
    if (this._depositSalesFilesImportSubscription !== undefined)
      this._depositSalesFilesImportSubscription.unsubscribe();
    if (this._depositPurchasesSaveParsedSubscription !== undefined)
      this._depositPurchasesSaveParsedSubscription.unsubscribe();
    if (this._depositSalesSaveParsedSubscription !== undefined)
      this._depositSalesSaveParsedSubscription.unsubscribe();
    if (this._depositHoldingsFilesImportSubscription !== undefined)
      this._depositHoldingsFilesImportSubscription.unsubscribe();
    if (this._depositHoldingsSaveImportSubscription !== undefined)
      this._depositHoldingsSaveImportSubscription.unsubscribe();
  }
}
