<div class="portfolio-analytics-container">
  <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
  <div class="portfolio-analytics-dates-section">
    <div class="dates-container">
      <div style="margin-right: 25px">
        <div class="label">Investor/Fund</div>
        <dx-drop-down-box
          #fundDropdown
          [(value)]="treeBoxValue"
          valueExpr="ID"
          displayExpr="name"
          placeholder="Select a Fund..."
          [dataSource]="FundsCustodianDataSource"
          width="380"
        >
          <dx-tree-list
            height="500"
            width="500"
            #fundManagerTreeList
            [dataSource]="FundsCustodianDataSource"
            keyExpr="ID"
            parentIdExpr="Head_ID"
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [showBorders]="true"
            (onSelectionChanged)="InvestorSelectionChanged($event)"
            noDataText="no data to display..."
            class="tree-list"
            [highlightChanges]="true"
            [repaintChangesOnly]="true"
            [focusedRowEnabled]="false"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [focusStateEnabled]="true"
            (onItemSelectionChanged)="treeList_itemSelectionChanged($event)"
            [autoExpandAll]="true"
          >
            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"> </dxo-sorting>
            <dxi-column
              dataField="Name"
              caption="Select Funds under a Custodian"
              sortOrder="asc"
              style="font-size: 200%; font-weight: 700"
            >
            </dxi-column>
            <dxo-selection mode="single"> </dxo-selection>
          </dx-tree-list>
        </dx-drop-down-box>
      </div>
      <div style="margin-right: 25px">
        <div class="label">Start Date</div>
        <dx-date-box
          id="analytics-start-date-input"
          displayFormat="dd MMM yyyy"
          #startDateBox
          matTooltip="Period start date"
          width="120"
        >
        </dx-date-box>
      </div>
      <div>
        <div class="label">End Date</div>
        <dx-date-box
          id="analytics-end-date-input"
          displayFormat="dd MMM yyyy"
          #endDateBox
          matTooltip="Period end date"
          width="120"
        >
        </dx-date-box>
      </div>
    </div>
    <div class="render-button">
      <div>
        <div
          *ngIf="
            _apiFetchSubscription === undefined;
            else fetchingInProgressTemp
          "
        >
          <button
            #refreshButton
            mat-flat-button
            color="primary"
            (click)="fetchResults()"
          >
            <mat-icon> update </mat-icon>
            Refresh
          </button>
        </div>

        <ng-template #fetchingInProgressTemp>
          <button
            mat-raised-button
            color="warn"
            (click)="closeAnalyticsFetchRequest()"
            matTooltip="Cancel this request"
          >
            <mat-icon> cancel_presentation </mat-icon>
            Cancel
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- -------------------------------------------- asset category Registry----------------------------------- -->
  <div class="funds-asset-cats-container">
    <div class="asset-cats-section">
      <div
        *ngIf="
          !(
            activeTenantName === 'SEC Zambia' ||
            activeTenantRegulator === 'zambia-sec'
          )
        "
      >
        <ng-container
          *ngTemplateOutlet="InnovaClientsAssetRegistry"
        ></ng-container>
      </div>
      <!-- for non innova clients -->
      <div
        *ngIf="
          activeTenantName == 'SEC Zambia' ||
          activeTenantRegulator == 'zambia-sec'
        "
      >
        <ng-container
          *ngTemplateOutlet="NonInnovaClientsAssetRegistry"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #InnovaClientsAssetRegistry>
  <dx-tab-panel
    #assetCategoriesPanel
    [showNavButtons]="true"
    [selectedIndex]="0"
    [loop]="false"
    [animationEnabled]="true"
    [swipeEnabled]="true"
    height="{{ calculatePaneldHeight() }}"
    [deferRendering]="false"
  >
    <!-- Cash Account -->
    <dxi-item icon="aligncenter" title="Cash">
      <div class="flex-container" style="background-color: #f7f7f7">
        <div class="Investorlabel">Investor Account</div>
        <div class="InvestorAccountLookup">
          <dx-lookup
            #InvestorAccountLookup
            [dataSource]="investorAccountDataSource"
            [width]="250"
            displayExpr="AccountNumber"
            valueExpr="ID"
            placeholder="Select an Investor Account"
          >
            <dxo-drop-down-options
              [closeOnOutsideClick]="true"
              [showTitle]="false"
            >
            </dxo-drop-down-options>
          </dx-lookup>
        </div>
        <div class="Investorlabel">Hide Reversals</div>
        <div class="hideTraversals">
          <dx-switch
            #HideTraversalSwitch
            [value]="false"
            matTooltip="Hide or Show Traversals."
          >
          </dx-switch>
        </div>
      </div>
      <div class="resultGrid" style="margin-top: 1%">
        <mat-divider></mat-divider>
        <div
          class="analytics-nofetched-data"
          *ngIf="InvestorAccountNotSelected == true"
        >
          Select Investors -> Investor Account -> Refresh
        </div>
        <div *ngIf="cashGridDataSource">
          <app-asset-registry-data-grid
            [EntityDataArray]="cashGridDataSource"
            [EntityColumns]="cashColumns"
          ></app-asset-registry-data-grid>
        </div>
      </div>
    </dxi-item>
    <!-- Equities -->
    <dxi-item icon="aligncenter" title="Equities">
      <dx-tab-panel
        #equitiesPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- Equity Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ equityHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="stockHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="stockHoldingGridDataSource"
                [EntityColumns]="stockHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!-- Purchases Holdings -->
        <dxi-item icon="aligncenter" title="{{ equityPurchasesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="purchasesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="purchasesHoldingGridDataSource"
                [EntityColumns]="purchasesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <dxi-item icon="aligncenter" title="{{ equitySalesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="salesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="salesHoldingGridDataSource"
                [EntityColumns]="salesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
    <!-- Time Deposits -->
    <dxi-item icon="aligncenter" title="Time Deposits">
      <dx-tab-panel
        #timeDepositPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- fixed deposits -->
        <dxi-item icon="aligncenter" title="Fixed Deposits">
          <dx-tab-panel
            #fixedDepositPanel
            [showNavButtons]="true"
            [selectedIndex]="0"
            [loop]="false"
            [animationEnabled]="true"
            [swipeEnabled]="true"
            height="{{ calculatePaneldHeight() }}"
            [deferRendering]="false"
          >
            <dxi-item
              icon="aligncenter"
              title="{{ fixedDepositsHoldingsTitle }}"
            >
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="fixedDepositHoldingDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="fixedDepositHoldingDataSource"
                    [EntityColumns]="fixedDepositHoldingColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item>
            <dxi-item icon="aligncenter" title="{{ equityPurchasesTitle }}">
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="fixedDepositPurchasesHoldingDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="fixedDepositPurchasesHoldingDataSource"
                    [EntityColumns]="fixedDepositPurchasesHoldingColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item>
            <dxi-item icon="aligncenter" title="{{ equitySalesTitle }}">
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="fixedDepositSalesHoldingDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="fixedDepositSalesHoldingDataSource"
                    [EntityColumns]="fixedDepositSalesHoldingColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item>
          </dx-tab-panel>
        </dxi-item>
        <!-- call Deposits -->
        <dxi-item icon="aligncenter" title="Call Deposits">
          <dx-tab-panel
            #callDepositPanel
            [showNavButtons]="true"
            [selectedIndex]="0"
            [loop]="false"
            [animationEnabled]="true"
            [swipeEnabled]="true"
            height="{{ calculatePaneldHeight() }}"
            [deferRendering]="false"
          >
            <dxi-item icon="aligncenter" title="{{ callDepositHoldingsTitle }}">
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="callDepositDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="callDepositDataSource"
                    [EntityColumns]="callDepositColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item>
            <!-- <dxi-item icon="aligncenter" title="{{ equityPurchasesTitle }}">
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="callDepositPurchasesDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="callDepositPurchasesDataSource"
                    [EntityColumns]="callDepositPurchasesColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item>
            <dxi-item icon="aligncenter" title="{{ equitySalesTitle }}">
              <div class="resultGrid" style="margin-top: 1%">
                <mat-divider></mat-divider>
                <div *ngIf="callDepositSalesDataSource">
                  <app-asset-registry-data-grid
                    [EntityDataArray]="callDepositSalesDataSource"
                    [EntityColumns]="callDepositSalesColumns"
                  ></app-asset-registry-data-grid>
                </div>
              </div>
            </dxi-item> -->
          </dx-tab-panel>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>

    <!-- Fixed Treasury Bonds -->
    <dxi-item icon="aligncenter" title="Fixed Treasury Bonds">
      <dx-tab-panel
        #treasuryBondsPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- Treasury Bonds Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ tBondsHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="tBondsHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="tBondsHoldingGridDataSource"
                [EntityColumns]="tBondssHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!-- Purchases Holdings -->
        <dxi-item icon="aligncenter" title="{{ tBondsPurchasesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="tBondsPurchasesHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="tBondsPurchasesHoldingGridDataSource"
                [EntityColumns]="tBondsPurchasesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <dxi-item icon="aligncenter" title="{{ tBondsSalesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="tBondsSalesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="tBondsSalesHoldingGridDataSource"
                [EntityColumns]="tBondsSalesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>

    <!-- Fixed Corporate Bonds -->
    <dxi-item icon="aligncenter" title="Fixed Corporate Bonds">
      <dx-tab-panel
        #corporateBondsPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- Corporate Bonds Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ corporateBondsHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="corporateBondsHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="corporateBondsHoldingGridDataSource"
                [EntityColumns]="corporateBondssHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!-- Purchases Holdings -->
        <dxi-item icon="aligncenter" title="{{ corporateBondsPurchasesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="corporateBondsPurchasesHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="corporateBondsPurchasesHoldingGridDataSource"
                [EntityColumns]="corporateBondsPurchasesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <dxi-item icon="aligncenter" title="{{ corporateBondsSalesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="corporateBondsSalesHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="corporateBondsSalesHoldingGridDataSource"
                [EntityColumns]="corporateBondsSalesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>

    <!-- Floating Corporate Bonds -->
    <dxi-item icon="aligncenter" title="Floating Corporate Bonds">
      <dx-tab-panel
        #floatingBondsPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- Floating Corporate Bonds Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ floatingCorporateBondsHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="floatingCorporateBondsHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="floatingCorporateBondsHoldingDataSource"
                [EntityColumns]="floatingCorporateBondssHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!-- Purchases Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ floatingCorporateBondsPurchasesTitle }}"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="floatingCorporateBondsPurchasesHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="
                  floatingCorporateBondsPurchasesHoldingDataSource
                "
                [EntityColumns]="floatingCorporateBondsPurchasesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <dxi-item
          icon="aligncenter"
          title="{{ floatingCorporateBondsSalesTitle }}"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="floatingCorporateBondsSalesHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="
                  floatingCorporateBondsSalesHoldingGridDataSource
                "
                [EntityColumns]="floatingCorporateBondsSalesColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
    <!-- Treasury Bills -->
    <dxi-item icon="aligncenter" title="Treasury Bills">
      <dx-tab-panel
        #treasuryBillsPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- Treasury Bills Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ billsHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="billsHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="billsHoldingGridDataSource"
                [EntityColumns]="billsHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!-- Purchases Holdings -->
        <dxi-item icon="aligncenter" title="{{ billsPurchasesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="billsPurchasesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="billsPurchasesHoldingGridDataSource"
                [EntityColumns]="billsPurchasesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <dxi-item icon="aligncenter" title="{{ billsSalesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="billsSalesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="billsSalesHoldingGridDataSource"
                [EntityColumns]="billsSalesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>

    <!-- Commercial Paper -->
    <dxi-item icon="aligncenter" title="Commercial Paper">
      <dx-tab-panel
        #treasuryBondsPanel
        [showNavButtons]="true"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="{{ calculatePaneldHeight() }}"
        [deferRendering]="false"
      >
        <!-- CP Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ commercialPaperHoldingsTitle }}"
          style="margin: 1%"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="cpHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="cpHoldingGridDataSource"
                [EntityColumns]="cpHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!--CP Purchases Holdings -->
        <dxi-item
          icon="aligncenter"
          title="{{ commercialPaperPurchasesTitle }}"
        >
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="cpPurchasesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="cpPurchasesHoldingGridDataSource"
                [EntityColumns]="cpPurchasesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
        <!--CP Sales Holdings -->
        <dxi-item icon="aligncenter" title="{{ commercialPaperSalesTitle }}">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="cpSalesHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="cpSalesHoldingGridDataSource"
                [EntityColumns]="cpSalesHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
  </dx-tab-panel>
</ng-template>
<ng-template #NonInnovaClientsAssetRegistry>
  <dx-tab-panel
    #assetCategoriesPanel
    [showNavButtons]="true"
    [selectedIndex]="0"
    [loop]="false"
    [animationEnabled]="true"
    [swipeEnabled]="true"
    height="{{ calculatePaneldHeight() }}"
    [deferRendering]="false"
  >
    <!-- Cash Account -->
    <dxi-item icon="aligncenter" title="Cash">
      <div class="flex-container" style="background-color: #f7f7f7">
        <div class="Investorlabel">Investor Account</div>
        <div class="InvestorAccountLookup">
          <dx-lookup
            #InvestorAccountLookup
            [dataSource]="investorAccountDataSource"
            [width]="250"
            displayExpr="AccountNumber"
            valueExpr="ID"
            placeholder="Select an Investor Account"
          >
            <dxo-drop-down-options
              [closeOnOutsideClick]="true"
              [showTitle]="false"
            >
            </dxo-drop-down-options>
          </dx-lookup>
        </div>
        <div class="Investorlabel">Hide Reversals</div>
        <div class="hideTraversals">
          <dx-switch
            #HideTraversalSwitch
            [value]="false"
            matTooltip="Hide or Show Traversals."
          >
          </dx-switch>
        </div>
      </div>
      <div class="resultGrid" style="margin-top: 1%">
        <mat-divider></mat-divider>
        <div
          class="analytics-nofetched-data"
          *ngIf="InvestorAccountNotSelected == true"
        >
          Select Investors -> Investor Account -> Refresh
        </div>
        <div *ngIf="cashGridDataSource">
          <app-asset-registry-data-grid
            [EntityDataArray]="cashGridDataSource"
            [EntityColumns]="cashColumns"
          ></app-asset-registry-data-grid>
        </div>
      </div>
    </dxi-item>
    <!-- Equities -->
    <dxi-item icon="aligncenter" title="Equities">
      <!-- Equity Holdings -->
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="equityHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="stockHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="stockHoldingGridDataSource"
                [EntityColumns]="stockHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>
    <!-- Time Deposits -->
    <dxi-item icon="aligncenter" title="Time Deposits">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="fixedDepositsHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="fixedDepositHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="fixedDepositHoldingDataSource"
                [EntityColumns]="fixedDepositHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="callDepositHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="callDepositDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="callDepositDataSource"
                [EntityColumns]="callDepositColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>

    <!-- Fixed Treasury Bonds -->
    <dxi-item icon="aligncenter" title="Fixed Treasury Bonds">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="tBondsHoldingsTitle">
          <div *ngIf="tBondsHoldingGridDataSource">
            <app-asset-registry-data-grid
              [EntityDataArray]="tBondsHoldingGridDataSource"
              [EntityColumns]="tBondssHoldingColumns"
            ></app-asset-registry-data-grid>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>

    <!-- Fixed Corporate Bonds -->
    <dxi-item icon="aligncenter" title="Fixed Corporate Bonds">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="corporateBondsHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="corporateBondsHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="corporateBondsHoldingGridDataSource"
                [EntityColumns]="corporateBondssHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>

    <!-- Floating Corporate Bonds -->
    <dxi-item icon="aligncenter" title="Floating Corporate Bonds">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="floatingCorporateBondsHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="floatingCorporateBondsHoldingDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="floatingCorporateBondsHoldingDataSource"
                [EntityColumns]="floatingCorporateBondssHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>
    <!-- Treasury Bills -->
    <dxi-item icon="aligncenter" title="Treasury Bills">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="billsHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="billsHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="billsHoldingGridDataSource"
                [EntityColumns]="billsHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>

    <!-- Commercial Paper -->
    <dxi-item icon="aligncenter" title="Commercial Paper">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="commercialPaperHoldingsTitle">
          <div class="resultGrid" style="margin-top: 1%">
            <mat-divider></mat-divider>
            <div *ngIf="cpHoldingGridDataSource">
              <app-asset-registry-data-grid
                [EntityDataArray]="cpHoldingGridDataSource"
                [EntityColumns]="cpHoldingColumns"
              ></app-asset-registry-data-grid>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </dxi-item>
  </dx-tab-panel>
</ng-template>
