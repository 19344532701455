import { Component, OnInit, ViewChild } from '@angular/core';
import { UILanguage, StringsService, DashBoardStrings } from '../../app-services/strings/strings.service';
import { Observable } from 'rxjs';
import { LanguageChangeService } from '../../app-services/language-change/language-change.service';
import { DxChartComponent } from 'devextreme-angular';
import { StockPrice, SampleService } from './sample-service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';


export class Production {
  arg: string;
  apples: number;
  grapes: number;
  lemons: number;
  oranges: number;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild(DxChartComponent, { static: true }) chart: DxChartComponent;
  stockPrices: StockPrice[];

  private selectedLanguage: Observable<UILanguage> = new Observable<UILanguage>();

  public dashBoardStrings: DashBoardStrings = new DashBoardStrings();

  dataSource: any[] = [];
  visualRange: any = {};
  productionData: Production[];
  ;

  constructor(private languageChangeService: LanguageChangeService, private stringsService: StringsService, private service: SampleService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {

    this.navBarSettingsService.ChangeActiveComponentName('Dashboard')


    this.selectedLanguage = languageChangeService.GetCurrentLanguage();
    this.selectedLanguage.subscribe((lang: UILanguage) => {
      this.dashBoardStrings = stringsService.getDashBoardStrings(lang);
    });
    //chart section
    let max = 100;

    for (let i = 0; i < max; i++) {
      this.dataSource.push({
        arg: Math.pow(10, i * 0.1),
        val: Math.log(i + 1) / Math.log(0.5) + (Math.random() - 0.5) * (100 / (i + 1)) + 10
      });
    }

    this.productionData = [{
      arg: 'Fund A',
      apples: 4.21,
      grapes: 6.22,
      lemons: 0.8,
      oranges: 7.47
    }, {
      arg: 'Fund B',
      apples: 3.33,
      grapes: 8.65,
      lemons: 1.06,
      oranges: 5
    }, {
      arg: 'Fund C',
      apples: 2.6,
      grapes: 4.25,
      lemons: 0.78,
      oranges: 1.71
    }, {
      arg: 'Fund D',
      apples: 2.2,
      grapes: 7.78,
      lemons: 0.52,
      oranges: 2.39
    }, {
      arg: 'Fund E',
      apples: 2.16,
      grapes: 2.26,
      lemons: 3.09,
      oranges: 6.26
    }];

    //another test  chart
    this.stockPrices = this.service.getStockPrices();

  }

  //sample
  valueChanged(e: any) {
    this.chart.instance.zoomArgument(new Date(e.value[0]), new Date(e.value[1]));
  }
  ngOnInit() {
  }

}
