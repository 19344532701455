<div class="app-component-main">
  <div style="position: absolute; top: 0">
    <ngx-loading-bar
      color="orange"
      height="8px"
      includeSpinner="true"
      diameter="12px"
      [fixed]="true"
    ></ngx-loading-bar>
  </div>

  <div *ngIf="isAuthenticated$ | async; else notLoggedIn">
    <router-outlet> </router-outlet>
  </div>
  <ng-template #notLoggedIn>
    <div class="login-page-container">
      <app-login></app-login>

      <div
        class="login-notification-area"
        *ngIf="showAuthenticationErrorMessage$ | async"
      >
        <div class="notification-message-item1">
          <div>
            <mat-icon>error</mat-icon>
          </div>
          <div style="margin-right: 3px">
            We Could not log you in at the moment
          </div>
        </div>
        <div class="login-notification-area-item1">
          Your system clock appears not to be in sync with the clock on the
          Analytics server. The following is a snapshot of the clock readings.

          <div class="time-sections">
            <div>
              <div style="font-weight: 600; font-style: italic">
                Your system time
              </div>
              <div style="font-style: italic">
                {{ currentBrowserTime$ | async }}
              </div>
            </div>
            <div>
              <div style="font-weight: 600; color: blue">Server time</div>
              <div style="color: blue">{{ currentServerTime$ | async }}</div>
            </div>
          </div>
          <div>
            <span>Suggested Fixes</span>
            <ul>
              <li>Manually set your clock to the correct time.</li>
              <li>If available, use Automatic Date and Time</li>
              <li>
                If your Date and Time settings are domain controlled and locked,
                Contact your System Administrator.
              </li>
            </ul>
          </div>
          <div>Correct this and click the login button to proceed.</div>
        </div>
      </div>
      <!-- <div class="logo-section">
        <div class="powered-by-section">powered by innova ©{{currentCopyrightYear}}</div>
      </div> -->
    </div>
  </ng-template>
</div>
