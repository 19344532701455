import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BidiModule } from '@angular/cdk/bidi';
import { CdkStepperModule } from '@angular/cdk/stepper';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';


import {
  DxFormModule, DxTabsModule, DxTabPanelModule, DxDataGridModule,
  DxRangeSelectorModule, DxChartModule, DxPolarChartModule, DxTreeViewModule,
  DxButtonModule, DxDropDownBoxModule, DxLookupModule, DxDateBoxModule,
  DxScrollViewModule, DxCheckBoxModule, DxTextBoxModule, DxListModule,
  DxTagBoxModule, DxButtonGroupModule, DxSelectBoxModule, DxSwitchModule, DxValidatorModule,
  DxValidationGroupModule, DxToolbarModule, DxDrawerModule, DxRadioGroupModule, DxTreeListModule,
  DxTooltipModule, DxPieChartModule, DxSpeedDialActionModule, DxPivotGridModule, DxPopupModule, DxTextAreaModule
} from 'devextreme-angular';

/**
 * Shared Module can be shared by different modules in the application.
 * Shared module is one place where UI modules from various vendors are imported and exported
 * for use by other modules in the application.
 */
@NgModule({
  declarations: [],
  imports: [FormsModule,
    BidiModule, ReactiveFormsModule, CommonModule, MatToolbarModule,
    MatIconModule, MatButtonModule, MatTooltipModule, DxButtonModule, MatMenuModule,
    MatTabsModule, MatAutocompleteModule, MatInputModule, MatDatepickerModule,
    MatFormFieldModule, MatOptionModule, MatProgressBarModule, MatDialogModule,
    DxFormModule, MatDividerModule, DxDataGridModule, MatCardModule, MatSnackBarModule
    , MatStepperModule, MatExpansionModule, MatCheckboxModule, MatSidenavModule, MatListModule,
    MatSelectModule, DxTabsModule, DxTabPanelModule, DxRangeSelectorModule, DxChartModule,
    DxPolarChartModule, DxTreeViewModule, DxLookupModule, DxCheckBoxModule, DxDateBoxModule,
    DxScrollViewModule, MatNativeDateModule, DxDropDownBoxModule, DxTextBoxModule, MatBottomSheetModule,
    DxListModule, DxTagBoxModule, MatSliderModule, MatChipsModule, DxButtonGroupModule, DxSelectBoxModule,
    MatButtonToggleModule, MatProgressSpinnerModule, MatSlideToggleModule, DxSwitchModule, DxValidatorModule,
    DxValidationGroupModule, DxToolbarModule, DxDrawerModule, DxRadioGroupModule,
    DxTreeListModule, DxTooltipModule, DxPieChartModule, DxChartModule, DxSpeedDialActionModule,
    DxPivotGridModule, MatRadioModule, CdkStepperModule, DxPopupModule, DxTextAreaModule
  ],
  exports: [FormsModule, BidiModule, ReactiveFormsModule, CommonModule, MatToolbarModule, MatIconModule,
    MatButtonModule, MatTooltipModule, MatTabsModule, MatAutocompleteModule,
    MatFormFieldModule, MatOptionModule, MatInputModule, MatProgressBarModule, MatDialogModule, DxFormModule,
    DxDataGridModule, MatCardModule, MatSnackBarModule, MatMenuModule, MatStepperModule, MatExpansionModule,
    MatCheckboxModule, MatSidenavModule, MatListModule, MatSelectModule, DxTabsModule, DxTabPanelModule, DxButtonModule,
    DxRangeSelectorModule, DxChartModule, MatDatepickerModule, DxPolarChartModule, DxTreeViewModule, DxLookupModule,
    DxDateBoxModule, MatBottomSheetModule, DxScrollViewModule, MatNativeDateModule, MatDividerModule, DxCheckBoxModule,
    DxDropDownBoxModule, DxTextBoxModule, DxListModule, DxTagBoxModule, MatSliderModule,
    MatChipsModule, DxButtonGroupModule, DxSelectBoxModule, MatButtonToggleModule, MatProgressSpinnerModule,
    MatSlideToggleModule, DxSwitchModule, DxValidatorModule, DxValidationGroupModule, DxToolbarModule,
    DxDrawerModule, DxRadioGroupModule, DxTreeListModule, DxTooltipModule, DxPieChartModule, DxChartModule,
    DxSpeedDialActionModule, DxPivotGridModule, MatRadioModule, CdkStepperModule, DxPopupModule, DxTextAreaModule
  ]
})
export class ExternalLibrariesModule { }
