import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class AssetRegistryService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  getInvestorAccountsById(investorId: any, tenantId: any): Observable<any> {
    return this.http.get(
      `${this.url}/safekeepingaccounts/findinvestoraccounts?investorid=${investorId}&tenantid=${tenantId}`
    );
  }

  getCashTransactions(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CashTransactions",
      requestPayload
    );
  }

  getStocksHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post("AssetRegistry/StocksHoldings", requestPayload);
  }

  getFixedDepositHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/FixedDepositsHoldings",
      requestPayload
    );
  }

  getCallDepositHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CallDepositsHoldings",
      requestPayload
    );
  }

  getTreasuryBillsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getTreasuryBondsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCorporateBondsHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCommercialPaperHoldings(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/CommercialPapersHoldings",
      requestPayload
    );
  }

  getFloatingCorporateBondsHoldingsData(requestPayload: any): Observable<any> {
    return this.apiService.Post(
      "AssetRegistry/PortfolioAssetRegistryViewModel",
      requestPayload
    );
  }

  getCashColumns(): any {
    let cashColumns = [
      {
        caption: "Transaction Date",
        dataField: "TransactionDate",
        cssClass: "grid-focus-column",
        alignment: "left",
        sortOrder: "asc",
        dataType: "date",
        format: "dd-MMM-yyyy",
      },
      {
        caption: "Transaction Types",
        dataField: "TransactionType",
        alignment: "right",
      },
      { caption: "Description", dataField: "Description", alignment: "right" },
      { caption: "Debit", dataField: "Debit", alignment: "right" },
      { caption: "Credit", dataField: "Credit", alignment: "right" },
      { caption: "Balance", dataField: "Balance", alignment: "right" },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
    ];
    return cashColumns;
  }

  getEquityHoldingsColumns(): any {
    let stockHoldingColumns = [
      {
        caption: "Stock Name",
        dataField: "Name",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        dataType: "date",
        width: 250,
      },
      {
        caption: "Average Price",
        dataField: "AveragePrice",
        alignment: "right",
      },
      {
        caption: "Market Price",
        dataField: "MarketPrice",
      },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Share Volume", dataField: "ShareVolume" },
      { caption: "Corporate Actions", dataField: "CorporateActions" },
      { caption: "Outstanding Dividends", dataField: "OutstandingDividends" },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
      { caption: "Current Value", dataField: "CurrentValue" },
      { caption: "Gain/Loss", dataField: "GainOrLoss" },
    ];
    return stockHoldingColumns;
  }

  getPurchasesHoldingsColumns(): any {
    let purchasesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      { caption: "Code", dataField: "Code", alignment: "left", width: 120 },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "ShareVoulume" },
      { caption: "Commission", dataField: "Comission" },
      { caption: "SaleValue", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Value", dataField: "Value" },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
      },
      { caption: "Status", dataField: "Status" },
    ];
    return purchasesColumns;
  }

  getSaleHoldingsColumns(): any {
    let salesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 250,
      },
      { caption: "Code", dataField: "Code", alignment: "left", width: 120 },
      { caption: "Sale Date", dataField: "SaleDate", dataType: "date" },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "ShareVoulume" },
      { caption: "Commission", dataField: "Comission" },
      { caption: "SaleValue", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Value", dataField: "Value" },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
      },
      { caption: "Status", dataField: "Status" },
    ];

    return salesColumns;
  }

  getFixedDepositHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      {
        caption: "Deposit Bank",
        dataField: "Deposit Bank",
        alignment: "right",
      },
      { caption: "Deposit Date", dataField: "DepositDate", alignment: "right" },
      { caption: "Last Redeemed", dataField: "LastRedeemed" },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        alignment: "right",
      },
      { caption: "Deposit", dataField: "Deposit" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Opening", dataField: "Opening" },
      { caption: "Withdrawal", dataField: "Withdrawal" },
      { caption: "D.Interest", dataField: "D.Interest" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "C.Interest", dataField: "C.Interest" },
      { caption: "Currency", dataField: "Currency", alignment: "right" },
      { caption: "Market Value", dataField: "MarketValue" },
    ];
    return fixedDepositColumns;
  }

  getFixedDepositPurchasesHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Deposit Date", dataField: "DepositDate" },
      { caption: "Last Redeemed", dataField: "LastRedeemed" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Deposit Amount", dataField: "DepositAmount" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
    ];
    return fixedDepositColumns;
  }

  getFixedDepositSalesHoldingColumns(): any {
    let fixedDepositColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Withdrawal Date", dataField: "WithdrawalDate" },
      { caption: "Sales Amount", dataField: "SalesAmount" },
      { caption: "Penalty", dataField: "Penalty" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
    ];
    return fixedDepositColumns;
  }

  getCallDepositHoldingColumns(): any {
    let callDepositHoldingColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Deposit Date", dataField: "DepositDate" },
      { caption: "Last Redeemed", dataField: "LastRedeemed" },
      { caption: "Deposit", dataField: "Deposit" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Opening", dataField: "Opening" },
      { caption: "Withdrawals", dataField: "Withdrawals" },
      { caption: "D.Interest", dataField: "D.Interest" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "C.Interest", dataField: "C.Interest" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Market Value", dataField: "MarketValue" },
    ];
    return callDepositHoldingColumns;
  }

  getCallDepositPurchasesColumns(): any {
    let callDepositPurchasesColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Deposit Date", dataField: "DepositDate" },
      { caption: "Deposit Amount", dataField: "DepositAmount" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
    ];
    return callDepositPurchasesColumns;
  }

  getCallDepositSalesColumns(): any {
    let callDepositHoldingColumns = [
      {
        caption: "Account Number",
        dataField: "AccountNumber",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Deposit Bank", dataField: "DepositBank", alignment: "left" },
      { caption: "Withdrawal Date", dataField: "WithdrawalDate" },
      { caption: "Sale Amount", dataField: "SaleAmount" },
      { caption: "Penalty", dataField: "Penalty" },
      { caption: "Interest Rate", dataField: "InterestRate" },
      { caption: "Value", dataField: "Value" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
    ];
    return callDepositHoldingColumns;
  }

  //Treasury Bills Columns
  getBillsHoldingsColumns(): any {
    let billsHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Rate", dataField: "Rate", alignment: "left" },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Transfer", dataField: "Transfer" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Daily Interest", dataField: "DailyInterest" },
      { caption: "Net Interest", dataField: "NetInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Gain/Loss", dataField: "GainorLoss" },
    ];
    return billsHoldingColumns;
  }

  getBillsPurchasesHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  getBillsSaleHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  //Treasury Bonds Columsn
  getTbondsHoldingsColumns(): any {
    let billsHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Rate", dataField: "Rate", alignment: "left" },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Transfer", dataField: "Transfer" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Daily Interest", dataField: "DailyInterest" },
      { caption: "Net Interest", dataField: "NetInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Gain/Loss", dataField: "GainorLoss" },
    ];
    return billsHoldingColumns;
  }

  getTBondsPurchasesHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  getTbondsSaleHoldingsColumns(): any {
    let billsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return billsPurchasesHoldingColumns;
  }

  //Corporate Bonds Columsn
  getCorporateBondsHoldingsColumns(): any {
    let corporateBondsHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Rate", dataField: "Rate", alignment: "left" },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Transfer", dataField: "Transfer" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Daily Interest", dataField: "DailyInterest" },
      { caption: "Net Interest", dataField: "NetInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Gain/Loss", dataField: "GainorLoss" },
    ];
    return corporateBondsHoldingColumns;
  }

  getCorporateBondsPurchasesColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getcorporateBondsSaleColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  //Corporate Bonds Columsn
  getFloatingCorporateBondsHoldingsColumns(): any {
    let corporateBondsHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Rate", dataField: "Rate", alignment: "left" },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Transfer", dataField: "Transfer" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Daily Interest", dataField: "DailyInterest" },
      { caption: "Net Interest", dataField: "NetInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Gain/Loss", dataField: "GainorLoss" },
    ];
    return corporateBondsHoldingColumns;
  }

  getFloatingCorporateBondsPurchasesColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getFloatingCorporateBondsSaleColumns(): any {
    let corporateBondsPurchasesHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return corporateBondsPurchasesHoldingColumns;
  }

  getCpHoldingsColumns(): any {
    let cpHoldingColumns = [
      {
        caption: "Bill",
        dataField: "Bill",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Rate", dataField: "Rate", alignment: "left" },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Maturity Date", dataField: "MaturityDate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Opening Market Value", dataField: "OpeningMarketValue" },
      { caption: "Purchases", dataField: "Purchases" },
      { caption: "Sales", dataField: "Sales" },
      { caption: "Transfer", dataField: "Transfer" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Daily Interest", dataField: "DailyInterest" },
      { caption: "Net Interest", dataField: "NetInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Gain/Loss", dataField: "GainorLoss" },
    ];
    return cpHoldingColumns;
  }
  getCPHoldingsPurchasesColumns(): any {
    let cpPurchasesHoldingColumns = [
      {
        caption: "Commercial Paper",
        dataField: "CommercialPaper",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Purchase Date", dataField: "PurchaseDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return cpPurchasesHoldingColumns;
  }
  getCPHoldingSalesColumns(): any {
    let CPHoldingSalesColumns = [
      {
        caption: "Commercial Paper",
        dataField: "CommercialPaper",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Sale Date", dataField: "SaleDate" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Commission", dataField: "Commission" },
      { caption: "Taxes", dataField: "Taxes" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Status", dataField: "Status" },
      { caption: "Settlement Date", dataField: "SettlementDate" },
    ];
    return CPHoldingSalesColumns;
  }
}
