<div>
  <!-- <div
    *ngIf="ReportGroupItems"
    class="report-group-items-count-section"
    matTooltip="Number of model groups"
  >
    {{ ReportGroupItems.length }}
  </div> -->

  <dx-tab-panel
    #reportCategoriesPanel
    [showNavButtons]="true"
    style="
      width: 100%;
      height: 460px;
      background-color: red;
      background-color: rgba(255, 255, 255, 0.336);
    "
    [activeStateEnabled]="true"
  >
    <dxi-item
      title="Regulatory Compliance Reports"
      class="report-group-contents"
      icon="aligncenter"
    >
      <div *ngFor="let reportGroup of ReportGroupItems" style="margin-top: 2%">
        <dx-tree-list
          #fundManagerTreeList
          [id]="reportGroup.ReportGroupName"
          [dataSource]="reportGroup.ReportItems"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          [width]="300"
          (onCellClick)="
            ModelGroupsItemsSelected($event, reportGroup.ReportGroupName)
          "
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="true"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
          (onCellHoverChanged)="showMoreInfoOnHover($event)"
          (onCellPrepared)="onCellPrepared($event)"
        >
          <dxo-selection mode="single" [recursive]="false"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="ReportName"
            sortOrder="asc"
            [caption]="
              updateCaption(
                reportGroup.ReportGroupName,
                reportGroup.ReportItems.length
              )
            "
            style="font-size: 200%"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
    </dxi-item>
    <dxi-item
      title="Performance Measurement Reports"
      class="report-group-contents"
      icon="aligncenter"
    >
      <div
        *ngFor="let reportGroup of PerfomanceMeasurementReports"
        style="margin-top: 2%"
      >
        <dx-tree-list
          [id]="reportGroup.ReportGroupName"
          [dataSource]="reportGroup.ReportItems"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          [width]="300"
          (onCellClick)="
            ModelGroupsItemsSelected($event, reportGroup.ReportGroupName)
          "
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="true"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
          (onCellHoverChanged)="showMoreInfoOnHover($event)"
          (onCellPrepared)="onCellPrepared($event)"
        >
          <dxo-selection mode="single" [recursive]="false"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="ReportName"
            sortOrder="asc"
            [caption]="
              updateCaption(
                reportGroup.ReportGroupName,
                reportGroup.ReportItems.length
              )
            "
            style="font-size: 200%"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
    </dxi-item>
    <dxi-item
      class="tabpanel-item"
      title="Perfomance Attribution Reports"
      icon="aligncenter"
    >
      <div class="SecurityVariablesWrapper">
        <div class="SecurityVariableslookups">
          <div class="hide">
            <span
              id="analytics-asset-class-caption"
              class="analytics-section-selection-title"
              >Asset Class/ Total Fund</span
            >
            <dx-lookup
              id="analytics-asset-classes-input"
              displayExpr="Name"
              valueExpr="Id"
              #assetClassLookupInstance
              itemTemplate="listItem"
              [width]="200"
              matTooltip="Select from a list of asset classes or the total fund"
            >
              <div *dxTemplate="let item of 'listItem'">
                <span *ngIf="item.Name == 'Total Fund'; else theAssetClasses">
                  <div
                    style="font-weight: 600; color: red"
                    matTooltip="The total fund is made up of all the above asset classes"
                  >
                    {{ item.Name }}
                  </div>
                </span>
                <ng-template #theAssetClasses>
                  <span style="font-weight: 500">{{ item.Name }}</span>
                </ng-template>
              </div>
            </dx-lookup>
          </div>
          <div class="hide">
            <span
              id="analytics-summary-caption"
              class="analytics-section-selection-title"
              style="margin-left: 20px"
              >Summary</span
            >
            <div style="margin-left: 20px">
              <dx-switch
                #summarySwitch
                [value]="true"
                matTooltip="Opt to show performance attribution as a summary for the specified period given a specific investor."
              >
              </dx-switch>
            </div>
          </div>
          <div>
            <span
              class="analytics-section-selection-title"
              style="margin-left: 20px"
              >Include Cash In Portfolio Valuation</span
            >
            <div style="margin-left: 20px">
              <dx-switch
                #includeCashSwitch
                [value]="true"
                matTooltip="Opt to show performance attribution as a summary for the specified period given a specific investor."
              >
              </dx-switch>
            </div>
          </div>
        </div>
      </div>
    </dxi-item>

    <!-- <dxi-item
      class="tabpanel-item"
      title="Analytical Reports"
      icon="aligncenter"
    >
      <div style="margin-top: 4px"></div>
    </dxi-item> -->
  </dx-tab-panel>
</div>
