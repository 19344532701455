
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HodingsGridColumnDefinitionsService {

	fixedTBondColumns: Array<any> = [
		{ caption: 'Accrued Interest', dataField: 'AccruedInterest', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Clean Mark To Market Value', dataField: 'CleanMarkToMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Cumulative Cost', dataField: 'CumulativeCost', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Dirty Mark to Market Value', dataField: 'DirtyMarkToMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Effective Carry', dataField: 'EffectiveCarry', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Effective Interest Amortization', dataField: 'EffectiveInterestAmortization', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Face Value', dataField: 'FaceValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Holdings Date', dataField: 'HoldingsDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Interest Rate', dataField: 'InterestRate', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Price', dataField: 'MarketPrice', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Value', dataField: 'MarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Maturity Date', dataField: 'MaturityDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Opening Market Value', dataField: 'OpeningMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Purchase Date', dataField: 'PurchaseDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Purchase Value', dataField: 'PurchaseValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Straight Line Amortization', dataField: 'StraightLineAmortization', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Unitized', dataField: 'Unitized', dataType: 'boolean' },
	];

	ListedEquiyHoldings: Array<any> = [
		{ caption: 'Cumulative Cost', dataField: 'CumulativeCost', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Holdings', dataField: 'Holdings', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Holdings Date', dataField: 'HoldingsDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Market Price', dataField: 'MarketPrice', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Value', dataField: 'MarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Opening Market Value', dataField: 'OpeningMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Purchase Value', dataField: 'PurchaseValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Unitized', dataField: 'Unitized', dataType: 'boolean' },
	];
	TreasuryBillColumns: Array<any> = [
		{ caption: 'Cumulative Cost', dataField: 'CumulativeCost', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Face Value', dataField: 'FaceValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Holdings Date', dataField: 'HoldingsDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Interest Rate', dataField: 'InterestRate', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Price', dataField: 'MarketPrice', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Value', dataField: 'MarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Maturity Date', dataField: 'MaturityDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Opening Market Value', dataField: 'OpeningMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Purchase Date', dataField: 'PurchaseDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Purchase Price', dataField: 'PurchasePrice', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Purchase Value', dataField: 'PurchaseValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Unitized', dataField: 'Unitized', dataType: 'boolean' },
	];

	FixedCorporateBondsColumns: Array<any> = [
		{ caption: 'Accrued Interest', dataField: 'AccruedInterest', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Clean Mark to Market Value', dataField: 'CleanMarktoMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Cumulative Cost', dataField: 'CumulativeCost', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Effective Carry', dataField: 'EffectiveCarry', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Effective Interest Ammortization', dataField: 'EffectiveInterestAmmortization', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Face Value', dataField: 'FaceValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Holdings Date', dataField: 'HoldingsDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Interest Rate', dataField: 'InterestRate', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Price', dataField: 'MarketPrice', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Market Value', dataField: 'MarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Maturity Date', dataField: 'MaturityDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Opening Market Value', dataField: 'OpeningMarketValue', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Purchase Date', dataField: 'PurchaseDate', dataType: 'date', format: 'dd MMM yyyy' },
		{ caption: 'Unit Cost', dataField: 'UnitCost', dataType: 'number', format: '#,##0.####' },
		{ caption: 'Unitized', dataField: 'Unitized', dataType: 'boolean' },
	];
}
