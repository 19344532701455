import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable } from 'rxjs';
import { repeat, take } from 'rxjs/operators';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  progressBarValue: Observable<number>;

  constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadingDialogComponentData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let progressValue = (interval(100).pipe(take(100))).pipe(repeat())
    this.progressBarValue = progressValue
  }

}

export interface LoadingDialogComponentData {
  Title: string;
  Description: string;
}

