<div class="portfolio-analytics-container">
  <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
  <div class="portfolio-analytics-dates-section">
    <div class="dates-container">
      <div>
        <span id="analytics-start-date-caption" class="component-inputs-label"
          >Start Date</span
        >
        <dx-date-box
          id="analytics-start-date-input"
          displayFormat="dd-MMM-yyyy"
          #startDateBox
          matTooltip="Period start date"
        >
        </dx-date-box>
      </div>
      <div>
        <span id="analytics-end-date-caption" class="component-inputs-label"
          >End Date</span
        >
        <dx-date-box
          id="analytics-end-date-input"
          displayFormat="dd-MMM-yyyy"
          #endDateBox
          matTooltip="Period end date"
        >
        </dx-date-box>
      </div>
    </div>

    <div class="render-button">
      <div>
        <div
          *ngIf="
            _apiFetchSubscription === undefined;
            else fetchingInProgressTemp
          "
        >
          <button
            #refreshButton
            mat-flat-button
            color="primary"
            (click)="fetchResults()"
          >
            <mat-icon> update </mat-icon>
            Refresh
          </button>
        </div>

        <ng-template #fetchingInProgressTemp>
          <button
            mat-raised-button
            color="warn"
            (click)="closeRequest()"
            matTooltip="Cancel this request"
          >
            <mat-icon> cancel_presentation </mat-icon>
            Cancel
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
  <div class="funds-asset-cats-container">
    <div class="funds-section">
      <div
        style="
          font-weight: 700;
          margin-left: 5%;
          font-stretch: extra-expanded;
          font-size: 110%;
        "
      >
        Funds
      </div>
      <mat-divider></mat-divider>
      <div>
        <dx-list #fundsList height="400"> </dx-list>
      </div>
    </div>
    <div class="asset-cats-section">
      <!-- !test on [selectedIndex]="2" return to [selectedIndex]="0"-->
      <dx-tab-panel
        #assetCategoriesPanel
        [dataSource]="assetCategoryTabsObservable | async"
        [selectedIndex]="0"
        [loop]="false"
        [animationEnabled]="true"
        [swipeEnabled]="true"
        height="400"
        [deferRendering]="false"
      >
        <div *dxTemplate="let item of 'title'">
          <div [ngStyle]="item.iconStyle" matTooltip="{{ item.content }}">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div>
                <span>
                  <mat-icon>{{ item.icon }}</mat-icon>
                </span>
              </div>
              <div>
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>

        <div
          *dxTemplate="let selectedAsstCat of 'item'"
          style="overflow-y: scroll"
        >
          <app-portfolio-models-view
            [ModelGroupItems]="ModelGroupItems"
            (ModelsSelectionUpdateEvent)="modelsSelectionUpdate($event)"
            [SelectedAssetCategory]="selectedAsstCat.text"
          >
          </app-portfolio-models-view>
        </div>
      </dx-tab-panel>
    </div>
  </div>
  <div class="results-section">
    <div>
      <div style="margin-left: 20px; font-weight: 300">
        Modify the output here. Changes to this inputs trigger an automatic
        reload with the updated data displayed on the grid.
      </div>
      <div class="results-controls">
        <div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 110%;
            "
          >
            <div style="font-weight: 500">Return Types?</div>
            <div>
              <dx-radio-group
                #returnTypeGroup
                [items]="returnTypesOptions"
                layout="horizontal"
                color="accent"
              >
                <div *dxTemplate="let returnItem of 'item'">
                  <div [matTooltip]="returnItem.helpInformation">
                    {{ returnItem.text }}
                  </div>
                </div>
              </dx-radio-group>
            </div>
          </div>
        </div>
        <div>
          <mat-divider [vertical]="true" style="height: 40px"></mat-divider>
        </div>
        <div>
          <!-- <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;"> -->
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 110%;
            "
          >
            <div style="font-weight: 500">Weighted?</div>
            <div>
              <dx-radio-group
                #weightRadioGroup
                [items]="weightingOptions"
                layout="horizontal"
              >
              </dx-radio-group>
            </div>
          </div>
        </div>
        <div>
          <mat-divider
            [vertical]="true"
            style="height: 40px; width: 50px"
            color="accent"
          ></mat-divider>
        </div>
        <div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 30%;
            "
          >
            <div style="font-weight: 500; margin-right: 10px">Benchmark</div>
            <div>
              <dx-lookup
                [width]="200"
                placeholder="Benchmark"
                #benchmarksLookup
              >
                <dxo-drop-down-options
                  [showTitle]="false"
                ></dxo-drop-down-options>
              </dx-lookup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- [dataSource]="gridDataObservableData | async" -->
    <div class="portfolio-bond-risk-analysis">
      <dx-data-grid
        #resultsDataGrid
        columnResizingMode="widget"
        [allowColumnResizing]="true"
        [showBorders]="true"
        [showRowLines]="true"
        [groupPanel]="{ visible: true }"
        [searchPanel]="{
          visible: true,
          placeholder: 'search for anything',
          width: 500
        }"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [export]="{
          enabled: true,
          fileName: 'Portfolio Bond Risk Analysis',
          allowExportSelectedData: true
        }"
        [selection]="{ mode: 'single' }"
        [columnChooser]="{ enabled: true, mode: 'select' }"
        [wordWrapEnabled]="true"
        [columnFixing]="true"
        [twoWayBindingEnabled]="true"
        [repaintChangesOnly]="false"
        (onCellPrepared)="gridInstanceCellPrepared($event)"
        [scrolling]="{ mode: 'standard', preloadEnabled: true }"
        [height]="400"
        [keyExpr]="gridKeyExpression"
        [focusStateEnabled]="true"
        [focusedRowEnabled]="true"
      >
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-sorting mode="multiple">
          <!-- or "multiple" | "none" -->
        </dxo-sorting>

        <div *ngIf="weightRadioGroup.value == true">
          <dxo-summary> </dxo-summary>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
