export class ReportGroupItemsGenerator {
  constructor() {}

  public generate(): Array<ReportGroupItem> {
    //compliance and portfolio rebalancing
    const regulatoryCompliance: ReportItem = {
      ID: "RegulatoryLimitsReport",
      Head_ID: 0,
      ReportName: "Regulatory Compliance",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const portfolioRebalancing: ReportItem = {
      ID: "PortfolioRebalancingReport",
      Head_ID: 0,
      ReportName: "Single Issuer Limits",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const complianceAndRebalancingGroup = new ReportGroupItem();
    complianceAndRebalancingGroup.ReportGroupName =
      "Compliance and Exposure Analysis";
    complianceAndRebalancingGroup.ReportItems = [
      regulatoryCompliance,
      portfolioRebalancing,
    ];

    /**
     * START
     * Fund Manager Asset Holdings
     */

    const CMAFundManagerAssetHoldingsDetailed: ReportItem = {
      ID: "CMAFundManagerHoldingsDetailedReport",
      Head_ID: 0,
      ReportName: "CMA Fund Manager Asset Holdings - Detailed",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const CMAFundManagerAssetHoldingsCorporateActions: ReportItem = {
      ID: "CMAFundManagerHoldingsCorporateActionsReport",
      Head_ID: 0,
      ReportName: "CMA Fund Manager Asset Holdings - Corporate Actions",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const CMAFundManagerAssetHoldingsSummary: ReportItem = {
      ID: "CMAFundManagerHoldingsSummaryReport",
      Head_ID: 0,
      ReportName: "CMA Fund Manager Asset Holdings - Summary",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const FundHoldings: ReportItem = {
      ID: "FundsHoldingsReport",
      Head_ID: 0,
      ReportName: "Fund Holdings",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const FundManagerHoldings: ReportItem = {
      ID: "FundManagerHoldingsReport",
      Head_ID: 0,
      ReportName: "Fund Manager(s) Holdings",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const returnsAndValuationgroup = new ReportGroupItem();
    returnsAndValuationgroup.ReportGroupName = "Fund Manager Asset Holdings";
    returnsAndValuationgroup.ReportItems = [
      CMAFundManagerAssetHoldingsDetailed,
      CMAFundManagerAssetHoldingsCorporateActions,
      CMAFundManagerAssetHoldingsSummary,
      FundHoldings,
      FundManagerHoldings,
    ];

    /**
     * END
     * Fund Manager Asset Holdings
     */

    /**
     * START
     * Fund Manager Portfolio Returns
     */

    const cmaFundManagerPortfolioReturns: ReportItem = {
      ID: "CMAFundManagerDistributionPortfolioReturn",
      Head_ID: 0,
      ReportName: "Fund Manager Portfolio",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const investorProfile: ReportItem = {
      ID: "CMAFundManagerDistributionInvestorProfile",
      Head_ID: 0,
      ReportName: "Investor Profile",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const bankConcentration: ReportItem = {
      ID: "CMAFundManagerDistributionBankConcentration",
      Head_ID: 0,
      ReportName: "Bank Concentration",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const fees: ReportItem = {
      ID: "CMAFundManagerDistributionFundManagerFees",
      Head_ID: 0,
      ReportName: "Fund Manager Fees",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const regulatoryLimits: ReportItem = {
      ID: "CMAFundManagerDistributionRegulatoryLimits",
      Head_ID: 0,
      ReportName: "Regulatory limits",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };
    const FundManagerPortfolioReturns = new ReportGroupItem();
    FundManagerPortfolioReturns.ReportGroupName =
      "CMA Fund Manager Asset Distribution";
    FundManagerPortfolioReturns.ReportItems = [
      investorProfile,
      bankConcentration,
      cmaFundManagerPortfolioReturns,
      fees,
      regulatoryLimits,
    ];
    /**
     * END
     * Fund Manager Portfolio Returns
     */
    const reportGroupItems: Array<ReportGroupItem> = new Array<ReportGroupItem>(
      returnsAndValuationgroup,
      complianceAndRebalancingGroup,
      FundManagerPortfolioReturns
    );

    return reportGroupItems;
  }

  public generatePerfomanceMeasurementReports(): Array<ReportGroupItem> {
    //compliance and portfolio rebalancing
    const summaryInformationReport: ReportItem = {
      ID: "SummaryInformationReport",
      Head_ID: 0,
      ReportName: "Summary Information",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const returnCalculation: ReportItem = {
      ID: "ReturnCalculationReport",
      Head_ID: 0,
      ReportName: "Return Calculation",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const feesReport: ReportItem = {
      ID: "FeesReport",
      Head_ID: 0,
      ReportName: "Fees",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const fundAnalysisReport: ReportItem = {
      ID: "FundAnalysisReport",
      Head_ID: 0,
      ReportName: "Fund Names",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const fundCompositionReport: ReportItem = {
      ID: "FundCompositionReport",
      Head_ID: 0,
      ReportName: "Fund Composition",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.CMA,
      ReportModelConfig: null,
    };

    const perfomanceMeasurementReports = new ReportGroupItem();
    perfomanceMeasurementReports.ReportGroupName =
      "Performance Measurement Reports";
    perfomanceMeasurementReports.ReportItems = [
      summaryInformationReport,
      returnCalculation,
      feesReport,
      fundAnalysisReport,
      fundCompositionReport,
    ];

    const reportGroupItems: Array<ReportGroupItem> = new Array<ReportGroupItem>(
      perfomanceMeasurementReports
    );

    return reportGroupItems;
  }
  //Zambia SEC reports

  public generateRegulatoryComplianceReports(): Array<ReportGroupItem> {
    //Regulatory compliance
    const consolidatedCisReturn: ReportItem = {
      ID: "ConsolidatedCisReturnReport",
      Head_ID: 0,
      ReportName: "Consolidated CIS Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCustodySummary: ReportItem = {
      ID: "ConsolidatedCustodySummaryReport",
      Head_ID: 0,
      ReportName: "Consolidated Custody Summary",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCisCpeByFund: ReportItem = {
      ID: "ConsolidatedCisCpeByFundReport",
      Head_ID: 0,
      ReportName: "Consolidated CIS CPE By Fund",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCisCpeByAssetClass: ReportItem = {
      ID: "ConsolidatedCisCpeByAssetClass",
      Head_ID: 0,
      ReportName: "Consolidated CIS CPE Asset Class",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisReview: ReportItem = {
      ID: "cisReviewReport",
      Head_ID: 0,
      ReportName: "CIS Review",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisMonthlyReturn: ReportItem = {
      ID: "cisMonthlyReturnReport",
      Head_ID: 0,
      ReportName: "CIS Monthly Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisMemberRegister: ReportItem = {
      ID: "cisMemberRegistersReport",
      Head_ID: 0,
      ReportName: "CIS Member Registers",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const assetReconcialiation: ReportItem = {
      ID: "AssetReconcialiationReport",
      Head_ID: 0,
      ReportName: "Asset Reconcialiation Report",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisCpeByFund: ReportItem = {
      ID: "CISCPEByFundReport",
      Head_ID: 0,
      ReportName: "CIS CPE By Fund",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisDailyUnitPrice: ReportItem = {
      ID: "CISDailyUnitPriceReport",
      Head_ID: 0,
      ReportName: "CIS Daily Unit Price",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisMaturitiesSchedule: ReportItem = {
      ID: "CISMaturitiesScheduleReport",
      Head_ID: 0,
      ReportName: "CIS Maturities Schedule",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCISMonthlyReturnsSEC = new ReportGroupItem();
    consolidatedCISMonthlyReturnsSEC.ReportGroupName =
      "Consolidated CIS Monthly Returns";
    consolidatedCISMonthlyReturnsSEC.ReportItems = [
      consolidatedCisReturn,
      consolidatedCustodySummary,
      cisMonthlyReturn,
      cisReview,
      //consolidatedCisCpeByFund,
      //consolidatedCisCpeByAssetClass,
      //cisMemberRegister,
      //assetReconcialiation,
      //cisCpeByFund,
      //cisDailyUnitPrice,
      //cisMaturitiesSchedule,
    ];

    const CustodianReturns: ReportItem = {
      ID: "CustodianReturnsReport",
      Head_ID: 0,
      ReportName: "Custodian Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const securityExposureAnalysis = new ReportGroupItem();
    securityExposureAnalysis.ReportGroupName = "Security Exposure Analysis";
    securityExposureAnalysis.ReportItems = [];

    const custodianReturns = new ReportGroupItem();
    custodianReturns.ReportGroupName = "Custodian Returns";
    custodianReturns.ReportItems = [CustodianReturns];

    const reportGroupItems: Array<ReportGroupItem> = new Array<ReportGroupItem>(
      consolidatedCISMonthlyReturnsSEC,
      custodianReturns
    );

    return reportGroupItems;
  }

  public generateRegulatoryComplianceCustodianReports(): Array<ReportGroupItem> {
    const consolidatedCisReturn: ReportItem = {
      ID: "ConsolidatedCisReturnReport",
      Head_ID: 0,
      ReportName: "Consolidated CIS Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCustodySummary: ReportItem = {
      ID: "ConsolidatedCustodySummaryReport",
      Head_ID: 0,
      ReportName: "Consolidated Custody Summary",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisReview: ReportItem = {
      ID: "cisReviewReport",
      Head_ID: 0,
      ReportName: "CIS Review",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const cisMonthlyReturn: ReportItem = {
      ID: "cisMonthlyReturnReport",
      Head_ID: 0,
      ReportName: "CIS Monthly Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const consolidatedCISMonthlyReturnsSEC = new ReportGroupItem();
    consolidatedCISMonthlyReturnsSEC.ReportGroupName =
      "Consolidated CIS Monthly Returns";
    consolidatedCISMonthlyReturnsSEC.ReportItems = [
      consolidatedCisReturn,
      consolidatedCustodySummary,
      cisMonthlyReturn,
      cisReview,
    ];
    const CustodianReturns: ReportItem = {
      ID: "CustodianReturnsReport",
      Head_ID: 0,
      ReportName: "Custodian Return",
      ReportClassification: ReportClassification.ParentReport,
      ReportEndUserSpecification: ReportEndUserSpecification.ZambiaSEC,
      ReportModelConfig: null,
    };

    const custodianReturns = new ReportGroupItem();
    custodianReturns.ReportGroupName = "Custodian Returns";
    custodianReturns.ReportItems = [CustodianReturns];

    const sectoralAnalysis = new ReportGroupItem();
    sectoralAnalysis.ReportGroupName = "Sectoral Analysis";
    sectoralAnalysis.ReportItems = [];

    const geographicalAnalysis = new ReportGroupItem();
    geographicalAnalysis.ReportGroupName = "Geographical Analysis";
    geographicalAnalysis.ReportItems = [];

    const counterpartyExposure = new ReportGroupItem();
    counterpartyExposure.ReportGroupName = "Counterparty Exposure";
    counterpartyExposure.ReportItems = [];

    const forexExposureAnalysis = new ReportGroupItem();
    forexExposureAnalysis.ReportGroupName = "FOREX Exposure Analysis";
    forexExposureAnalysis.ReportItems = [];

    const reportGroupItems: Array<ReportGroupItem> = new Array<ReportGroupItem>(
      consolidatedCISMonthlyReturnsSEC,
      custodianReturns,
      sectoralAnalysis,
      geographicalAnalysis,
      counterpartyExposure,
      forexExposureAnalysis
    );

    return reportGroupItems;
  }
}

export class ReportGroupItem {
  ReportGroupName: string;
  ReportItems: Array<ReportItem>;
  SelectedReportKeys: Array<any>; //possibly strings eventually
}

export enum ReportClassification {
  ParentReport,
  Parameter,
}

export enum ReportEndUserSpecification {
  CMA,
  FundManager,
  Both,
  ZambiaSEC,
}

export interface ReportModelConfig {
  StartDate: Date;
  EndDate: Date;
  SelectedItems: Array<string>;
  ItemsComponentInstance: any;
}

export interface ReportItem {
  ID: string;
  Head_ID: any;
  ReportName: string;
  ReportClassification: ReportClassification;
  ReportEndUserSpecification: ReportEndUserSpecification;
  ReportModelConfig: ReportModelConfig;
}
