import { Injectable, Inject } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DocumentationSnackbarComponent } from '../documentation-snackbar/documentation-snackbar.component';
import { MarkdownParserPipe } from '../../utilities/markdown-parser/markdown-parser.pipe';

@Injectable({
	providedIn: 'root'
})
export class DocumentationService {

	sampleMarkdown = `# Asset Classes
	[Marked] lets you convert [Markdown] into HTML.  Markdown is a simple text format whose goal is
	to be very easy to read and write, even when not converted to HTML.  This demo page will let you
	type anything you like and see how it gets converted.  Live.  No more waiting around.

	How To Use The Demo
	-------------------

	1. Type in stuff on the left.
	2. See the live updates on the right.

	That's it.  Pretty simple.  There's also a drop-down option in the upper right to switch between various views:

	- __Preview:__  A live display of the generated HTML as it would render in a browser.
	- **HTML Source:**  The generated HTML before your browser makes it pretty.
	- **Lexer Data:**  What [marked] uses internally, in case you like gory stuff like this.
	- **Quick Reference:**  A brief run-down of how to format things using markdown.

	Why Markdown?
	-------------

	It's easy.  It's not overly bloated, unlike HTML.  Also, as the creator of [markdown] says,

	> The overriding design goal for Markdown's
	> formatting syntax is to make it as readable
	> as possible. The idea is that a
	> Markdown-formatted document should be
	> publishable as-is, as plain text, without
	> looking like it's been marked up with tags
	> or formatting instructions.

	Ready to start writing?  Either start changing stuff on the left or
	[clear everything](/demo/?text=) with a simple click.

	[Marked]: https://github.com/markedjs/marked/
	[Markdown]: http://daringfireball.net/projects/markdown/`;

	purchasesSalesDocumentation = `# Purchases and Sales
	See Purchases and Sales in different Asset Categories

	Switching between asset categories
	-------------------
	1. Purchases are shown by default.
	2. To see sales, use the toogle control. Select Sales.`;

	documentationDialogRef: MatDialogRef<DocumentationSnackbarComponent, any> = undefined;
	dialogSubscription: Subscription = undefined;

	// reference to update this to accept HTML
	// https://stackoverflow.com/questions/53954319/how-to-add-html-content-on-snackbar-in-angular-material
	constructor(public dialog: MatDialog, public markdownParser: MarkdownParserPipe, @Inject('BASE_URL') private baseUrl: string) { }

	showHelpContent(name: string) {

		this.dialog.closeAll()
		if (this.dialogSubscription !== undefined) {
			this.dialogSubscription.unsubscribe();
			console.error('closing an already defined subscription');

		}
		if (this.documentationDialogRef !== undefined) {
			this.documentationDialogRef.close();
		}

		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = true;
		dialogConfig.height = '300';
		dialogConfig.width = '300';
		dialogConfig.hasBackdrop = true;
		dialogConfig.closeOnNavigation = true;
		const source = timer(1000);
		this.dialogSubscription = source.subscribe(val => {
			if (name === 'purchaseses-sales') {
				let documentation = this.markdownParser.transform(this.sampleMarkdown);
				dialogConfig.data = documentation;
				this.documentationDialogRef = this.dialog.open(DocumentationSnackbarComponent, dialogConfig);
			}
		});
	}

	closeDialog(event) {
		console.error(event);

		if (this.dialogSubscription) {
			const source = timer(500);
			this.dialogSubscription = source.subscribe(val => {
				this.dialog.closeAll()
				this.documentationDialogRef.close()
			})
		}
	}
}
