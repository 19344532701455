<div *ngIf="apiVersion !== null;else apiversionnotloaded">
    <div class="about-header">
        <div class="analytics-icon">
            <mat-icon class="flux-brand-logo" color="accent">timeline</mat-icon>
        </div>

        <div>
            <span class="app-name">Innova Analytics</span>
        </div>
    </div>

    <div class="about-body">
        <div>
            <span class="version-keyword">API Version</span> : {{apiVersion}}
        </div>
        <div>
            <span class="version-keyword">UI Version</span> : {{uiVersion}}
        </div>
    </div>

<!--    <div class="components-title">Components-->
<!--    </div>-->
<!--    <div class="component-section">-->
<!--        <div class="components-span">Portfolio Performance Attribution:</div>-->
<!--        <div class="components-span">Asset Level Statistical Analysis</div>-->
<!--        <div class="components-span">Asset Level Risk Adjusted Performance Ratios</div>-->
<!--        <div class="components-span">Asset Level Correlation Regression Analysis</div>-->
<!--        <div class="components-span">Asset Level Relative Return Measures</div>-->
<!--        <div class="components-span">Asset Level Fat Tail Analysis</div>-->
<!--    </div>-->
</div>

<ng-template #apiversionnotloaded>
    <div style="margin: auto; text-align: center;">
        <mat-spinner></mat-spinner> loading...
    </div>
</ng-template>