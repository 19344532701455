import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
  selector: 'app-floating-treasury-bond',
  templateUrl: './floating-treasury-bond.component.html',
  styleUrls: ['./floating-treasury-bond.component.scss']
})
export class FloatingTreasuryBondComponent implements OnInit {

  constructor(private title: Title,private navBarSettingsService: NavbarHeaderSettingsService) { 
    this.navBarSettingsService.ChangeActiveComponentName('Floating Treasury Bonds')
  }

  ngOnInit() {
  }

}
