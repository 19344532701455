import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-predictive-analytics',
  templateUrl: './predictive-analytics.component.html',
  styleUrls: ['./predictive-analytics.component.scss']
})
export class PredictiveAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
