<div style="height: 100%;">
    <div class="regulator-compliance-grid-main-container">
        <dx-report-viewer height="1000px" [reportUrl]="reportName">
            <dxrv-request-options invokeAction="DXXRDV" [host]="reportServerURL">
            </dxrv-request-options>
            <dxrv-callbacks (BeforeRender)="BeforeRender($event)"></dxrv-callbacks>
        </dx-report-viewer>
    </div>

    <!-- <dx-report-viewer [reportUrl]="reportName" height="800px">
        <dxrv-request-options invokeAction="DXXRDV" [host]="reportServerURL"></dxrv-request-options>
    </dx-report-viewer> -->

</div>