import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationSettingsService } from '../app-settings/application-settings.service';
import { ConfigService } from 'src/app/common/config.service';

/**
 * Generic API endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  $postUrlObservable: Observable<any>;

  private  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private url = '';
  constructor(private httpClient: HttpClient, private appSettingsService: ApplicationSettingsService,private configService: ConfigService) {

    this.url = this.configService.config.APIURL;
  }

  Get(endpoint: string, params: any = null): Observable<any> {
    if (params !== null) {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json').set('Accept', 'application/json');

      return this.httpClient
        .get(`${this.url}/${endpoint}`, { params: params, headers: headers });
    }
    else {
      return this.httpClient
        .get(`${this.url}/${endpoint}`)
    }
  }

  Delete(endpoint: string, ID: any) {
    return this.httpClient.delete(`${this.url}/${endpoint}/${ID}`);
  }

 

  GetSingle(endpoint: string): Observable<any> {
    return this.httpClient.get(`${this.url}/${endpoint}`);
  }

  Post(endpoint: string, entity: any) {
    return this.httpClient.post(`${this.url}/${endpoint}`, entity, { headers: this.httpOptions.headers });
  }

  Put(endpoint: string, entity) {
    if(entity.hasOwnProperty('ID')){
      return this.httpClient.put(`${this.url}/${endpoint}?id=${entity.ID}`, entity, { headers: this.httpOptions.headers });
    }else{
      return this.httpClient.put(`${this.url}/${endpoint}?id=${entity.Id}`, entity, { headers: this.httpOptions.headers });
    }
  }
}
