import { Injectable, OnInit } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class TenantService implements OnInit {
  decodedAccessToken: any;
  tenantName: any;
  tenantRegulatorName: any;
  constructor(public oidcSecurityService: OidcSecurityService) {}

  ngOnInit(): void {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    //get TenantName
    this.tenantName = this.decodedAccessToken.TenantName;
  }

  getTenantName(): string {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    //get TenantName
    this.tenantName = this.decodedAccessToken.TenantName;
    return this.tenantName;
  }

  getUserName(): string {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    return this.decodedAccessToken.name;
  }

  getTenantType(): string {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    //get TenantName
    let tenantType: string = this.decodedAccessToken.TenantType;
    return tenantType.toLowerCase();
  }

  getTenantRegulatorName(): string {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    //get TenantName
    this.tenantRegulatorName = this.decodedAccessToken.TenantRegulator;
    return this.tenantRegulatorName;
  }

  isRegulator(): any {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    //get TenantName

    let Isregulator = this.decodedAccessToken.Regulator;
    //if regulator return true, else false
    if (Isregulator == 1) {
      return true;
    } else {
      return false;
    }
  }

  isStaging(): any {
    //decode the access token
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());

    if (this.tenantName == "staging") {
      return true;
    } else {
      return false;
    }
  }
}

export enum Tenant {
  Tenant1 = "Capital Markets Authority",
  Tenant2 = "SEC Zambia",
  Tenant3 = "Other",
}

export enum Regulators {
  Regulator1 = "cma-kenya",
  Regulator2 = "zambia-sec",
}

export enum TenantType {
  Regulator = "Regulator",
  Custodian = "Custodian",
  FundManger = "Fund Manager",
}
