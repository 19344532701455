import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import notify from "devextreme/ui/notify";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { CashTransactionImporterServiceService } from "./cash-transaction-importer-service.service";

@Component({
  selector: "app-cash-transactions-importer",
  templateUrl: "./cash-transactions-importer.component.html",
  styleUrls: ["./cash-transactions-importer.component.scss"],
})
export class CashTransactionsImporterComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;

  //props
  files: any[] = [];
  currentVariableTab: string;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  cashTransactionsColumns: any;
  cashTransactionsPurchasesResponse: any;
  cashTransactionsSalesResponse: any;
  gridDataSource: any;
  importStatus: any;
  FileSuccess: boolean = true;
  FileNotSuccess: boolean;
  ImportErrors: Array<any> = [];
  EntityName = "Cash Transactions Data Importer";

  //subsciptions
  public _cashTransactionsPurchasesFilesImportSubscription: Subscription;
  public _cashTransactionsSalesFilesImportSubscription: Subscription;
  public _cashTransactionsPurchasesSaveParsedSubscription: Subscription;
  public _cashTransactionsSalesSaveParsedSubscription: Subscription;

  constructor(
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService,
    private _formBuilder: FormBuilder,
    private _cashTransactionsImporterService: CashTransactionImporterServiceService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);
  }

  ngOnInit() {
    // https://www.techiediaries.com/angular-formdata/
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }
  handleFileInput(files: FileList) {
    console.error(files);
  }

  goBack() {
    this.myStepper.previous();
  }

  goForward() {
    this.myStepper.next();
  }

  processFile() {
    //append the file
    const formData: FormData = new FormData();
    formData.append(
      "file",
      this.firstFormGroup.get("firstCtrl").value,
      this.firstFormGroup.get("firstCtrl").value.name
    );

    //for cashTransactions purchases
    if (
      this.currentVariableTab == "Cash Transactions" ||
      this.currentVariableTab == undefined
    ) {
      this._cashTransactionsPurchasesFilesImportSubscription =
        this._cashTransactionsImporterService
          .ImportcashTransactions(formData)
          .subscribe((res) => {
            // save the response
            this.cashTransactionsPurchasesResponse = res;

            //cashTransactions purchases columns
            this.cashTransactionsColumns =
              this._cashTransactionsImporterService.getcashTransactionsColumns();
            //cashTransactions purchases data grid
            this.gridDataSource = res["Data"];

            //Errors Data if success is false
            if (res["Success"] == false) {
              this.FileSuccess = false;
              this.FileNotSuccess = true;
              //get error
              this.ImportErrors = res["Errors"];
            } else {
              this.FileSuccess = true;
              this.FileNotSuccess = false;
            }
          });
    }
  }

  SaveFile() {
    //for cashTransactions purchases
    if (
      this.currentVariableTab == "Purchases" ||
      this.currentVariableTab == undefined
    ) {
      this._cashTransactionsPurchasesSaveParsedSubscription =
        this._cashTransactionsImporterService
          .SavecashTransactions(this.cashTransactionsPurchasesResponse)
          .subscribe((res) => {
            if (res["ImportStatus"] == "Success") {
              this.importStatus = "Successful";
              //notify({ message: "Import successful", width: 600 }, "info", 3000);
            } else {
              this.importStatus = "UnSuccessful";
              notify(
                { message: "Unsuccessful, try again later", width: 600 },
                "error",
                3000
              );
            }
          });
    }
  }

  reset() {
    this.myStepper.reset();
    this.files = [];
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    const file = $event.target.files[0];

    this.firstFormGroup.get("firstCtrl").setValue(file);

    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.firstFormGroup.get("firstCtrl").setValue(file);
      this.prepareFilesList($event.target.files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 30);
      }
    }, 500);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      //This to be removed incase you want to upload multiple files
      this.files = [];
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy(): void {
    if (this._cashTransactionsPurchasesFilesImportSubscription !== undefined)
      this._cashTransactionsPurchasesFilesImportSubscription.unsubscribe();
    if (this._cashTransactionsSalesFilesImportSubscription !== undefined)
      this._cashTransactionsSalesFilesImportSubscription.unsubscribe();
    if (this._cashTransactionsPurchasesSaveParsedSubscription !== undefined)
      this._cashTransactionsPurchasesSaveParsedSubscription.unsubscribe();
    if (this._cashTransactionsSalesSaveParsedSubscription !== undefined)
      this._cashTransactionsSalesSaveParsedSubscription.unsubscribe();
  }
}
