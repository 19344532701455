<div class="content-section">
  <div class="innova-app-routing-area-title">Holdings</div>
  <mat-divider></mat-divider>
  <div class="analytics-section-selection-dates" style="margin-top: 30px">
    <div>
      <span class="analytics-section-selection-title">Start Date</span>
      <dx-date-box displayFormat="dd-MMM-yyyy" #startDateBox></dx-date-box>
    </div>
    <div style="margin-left: 2%">
      <span class="analytics-section-selection-title">End Date</span>
      <dx-date-box displayFormat="dd-MMM-yyyy" #endDateBox></dx-date-box>
    </div>
    <div style="margin-left: 2%">
      <button
        mat-stroked-button
        matTooltip="Refresh Holdings"
        (click)="openHoldings(null, true)"
        color="primary"
      >
        <mat-icon>refresh</mat-icon> Refresh
      </button>
    </div>
  </div>

  <mat-divider style="margin-top: 1%; margin-bottom: 1%"></mat-divider>

  <mat-tab-group (selectedTabChange)="openHoldings($event, false)" #holdingTab>
    <!-- <mat-tab label="Commercial Papers">
		<dx-data-grid [dataSource]='fixedTBondsDataSource'></dx-data-grid>
	</mat-tab> -->
    <mat-tab label="Fixed Treasury Bonds">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="fixedTBondsDataSource"
        [columns]="holdingsGridColumns.fixedTBondColumns"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
      >
      </dx-data-grid>
    </mat-tab>
    <mat-tab label="Listed Equities">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="listedEquitiesDataSource"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
        [columns]="holdingsGridColumns.ListedEquiyHoldings"
      >
      </dx-data-grid>
    </mat-tab>
    <!-- <mat-tab label="Offshores">
		<dx-data-grid [dataSource]='tBillsDataSource'></dx-data-grid>
	</mat-tab> -->
    <mat-tab label="Treasury Bills">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="tBillsDataSource"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
        [columns]="holdingsGridColumns.TreasuryBillColumns"
      >
      </dx-data-grid>
    </mat-tab>
    <mat-tab label="Fixed Corporate Bonds">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="fixedCorporateBondsDataSource"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
        [columns]="holdingsGridColumns.FixedCorporateBondsColumns"
      ></dx-data-grid>
    </mat-tab>
    <!-- <mat-tab label="Unit Trusts">
		<dx-data-grid [dataSource]='fixedTBondsDataSource'></dx-data-grid>
	</mat-tab> -->
    <mat-tab label="Floating Treasury Bonds">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="floatingTBondDataSource"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
      ></dx-data-grid>
    </mat-tab>
    <mat-tab label="Floating Corporate Bonds">
      <dx-data-grid
        [rowAlternationEnabled]="true"
        [dataSource]="floatingCorporateBondHoldings"
        [wordWrapEnabled]="false"
        keyExpr="ID"
        [selection]="{ mode: 'single' }"
      ></dx-data-grid>
    </mat-tab>
  </mat-tab-group>
</div>
