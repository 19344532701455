import { Injectable } from "@angular/core";
import { Column } from "devextreme/ui/data_grid";

let RegulatorAssetClassReconColums: Column[] = [
  {
    caption: "FundManager",
    dataField: "FundManager",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    groupIndex: 0,
    allowEditing: false,
  },
  {
    caption: "Fund",
    dataField: "Fund",
    allowSorting: true,
    dataType: "string",
    groupIndex: 1,
    allowEditing: false,
  },
  {
    caption: "Asset Category",
    dataField: "AssetCategory",
    allowSorting: true,
    dataType: "string",
    cssClass: "performance-attr-grid-focus-2",
    sortOrder: "asc",
    allowEditing: false,
  },

  {
    caption: "Fund Manager Face Value",
    dataField: "FundManagerFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Custodian Face Value",
    dataField: "CustodianFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },

  {
    caption: "Difference",
    dataField: "FaceValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Variance (%)",
    dataField: "FaceValueVariance",
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Fund Manager's Comments",
    dataField: "LicenseeComments",
    width: "200",
    allowEditing: false,
    cssClass: "cell-highlighted",
  },
  {
    caption: "Custodian's Comments",
    dataField: "CustodianComments",
    width: "200",
    allowEditing: false,
    cssClass: "cell-highlighted",
  },
  {
    caption: "Regulator's Comments",
    dataField: "RegulatorComments",
    cssClass: "cell-highlighted-light",
    width: "200",
    allowEditing: true,
  },
];

let FundManagerAssetClassReconColums: Column[] = [
  {
    caption: "FundManager",
    dataField: "FundManager",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    groupIndex: 0,
    allowEditing: false,
  },
  {
    caption: "Fund",
    dataField: "Fund",
    allowSorting: true,
    dataType: "string",
    groupIndex: 1,
    allowEditing: false,
  },
  {
    caption: "Asset Category",
    dataField: "AssetCategory",
    allowSorting: true,
    dataType: "string",
    cssClass: "performance-attr-grid-focus-2",
    sortOrder: "asc",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Face Value",
    dataField: "FundManagerFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Custodian Face Value",
    dataField: "CustodianFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Difference",
    dataField: "FaceValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Variance (%)",
    dataField: "FaceValueVariance",
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Fund Manager's Comment",
    dataField: "LicenseeComments",
    width: "200",
    allowEditing: true,
    cssClass: "cell-highlighted-light",
  },
];
let CustodianAssetClassReconColums: Column[] = [
  {
    caption: "FundManager",
    dataField: "FundManager",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    groupIndex: 0,
    allowEditing: false,
  },
  {
    caption: "Fund",
    dataField: "Fund",
    allowSorting: true,
    dataType: "string",
    groupIndex: 1,
    allowEditing: false,
  },
  {
    caption: "Asset Category",
    dataField: "AssetCategory",
    allowSorting: true,
    dataType: "string",
    cssClass: "performance-attr-grid-focus-2",
    sortOrder: "asc",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Face Value",
    dataField: "FundManagerFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Custodian Face Value",
    dataField: "CustodianFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Difference",
    dataField: "FaceValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Variance (%)",
    dataField: "FaceValueVariance",
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Custodian Comment",
    dataField: "CustodianComments",
    width: "200",
    allowEditing: true,
    cssClass: "cell-highlighted-light",
  },
];

let securityReconColums: Column[] = [
  {
    caption: "FundManager",
    dataField: "FundManager",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    groupIndex: 0,
    allowEditing: false,
  },
  {
    caption: "Fund",
    dataField: "Fund",
    allowSorting: true,
    dataType: "string",
    groupIndex: 1,
    allowEditing: false,
  },
  {
    caption: "Asset Category",
    dataField: "AssetCategory",
    allowSorting: true,
    dataType: "string",
    cssClass: "performance-attr-grid-focus-2",
    sortOrder: "asc",
    allowEditing: false,
  },

  {
    caption: "Fund Manager Face Value",
    dataField: "FundManagerFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Custodian Face Value",
    dataField: "CustodianFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },

  {
    caption: "Difference",
    dataField: "Difference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Variance (%)",
    dataField: "Variance",
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-3",
    allowEditing: false,
  },
  {
    caption: "Fund Manager's Comments",
    dataField: "LicenseeComments",
    width: "200",
    allowEditing: false,
    cssClass: "cell-highlighted",
  },
  {
    caption: "Custodian's Comments",
    dataField: "CustodianComments",
    width: "200",
    allowEditing: false,
    cssClass: "cell-highlighted",
  },
  {
    caption: "Regulator's Comments",
    dataField: "RegulatorComments",
    cssClass: "cell-highlighted-light",
    width: "200",
    allowEditing: true,
  },
];

const securityNonEquitiesReconColumns = [
  {
    caption: "ISIN",
    dataField: "ISIN",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    allowEditing: false,
    cssClass: "performance-attr-grid-focus-3",
    alignment: "left",
  },
  {
    caption: "Security Name",
    dataField: "SecurityName",
    allowSorting: true,
    dataType: "string",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Face Value",
    dataField: "FundManagerFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Custodian Face Value",
    dataField: "CustodianFaceValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Face Value Difference",
    dataField: "FaceValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Face Value Variance (%)",
    dataField: "FaceValueVariance",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Market Value",
    dataField: "FundManagerMarketValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },

  {
    caption: "Custodian Market Value",
    dataField: "CustodianMarketValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },

  {
    caption: "Market Value Difference",
    dataField: "MarketValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
  {
    caption: "Market Value Variance (%)",
    dataField: "MarketValueVariance",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
];

const securityEquitiesReconColumns = [
  {
    caption: "ISIN",
    dataField: "ISIN",
    allowSorting: true,
    dataType: "string",
    sortOrder: "asc",
    allowEditing: false,
    cssClass: "performance-attr-grid-focus-3",
    alignment: "left",
  },
  {
    caption: "Security Name",
    dataField: "SecurityName",
    allowSorting: true,
    dataType: "string",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Share Volume",
    dataField: "FundManagerShareVolume",
    dataType: "number",
    cssClass: "performance-attr-grid-focus-1",
    sortOrder: "asc",
    allowEditing: false,
  },
  {
    caption: "Custodian Share Volume",
    dataField: "CustodianShareVolume",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Share Volume Difference",
    dataField: "ShareVolumeDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Share Volume Variance (%)",
    dataField: "ShareVolumeVariance",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "performance-attr-grid-focus-1",
    allowEditing: false,
  },
  {
    caption: "Fund Manager Market Value",
    dataField: "FundManagerMarketValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
  {
    caption: "Custodian Market Value",
    dataField: "CustodianMarketValue",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
  {
    caption: "Market Value Difference",
    dataField: "MarketValueDifference",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
  {
    caption: "Market Value Variance (%)",
    dataField: "MarketValueVariance",
    allowSorting: true,
    dataType: "number",
    format: { type: "fixedPoint", precision: 2 },
    cssClass: "non-portfolio-grid-color-4",
    allowEditing: false,
  },
];

@Injectable({
  providedIn: "root",
})
export class ReconService {
  constructor() {}

  getRegulatorAssetClassColumns(): any[] {
    return RegulatorAssetClassReconColums;
  }

  getFundManagerAssetClassColumns(): any[] {
    return FundManagerAssetClassReconColums;
  }

  getCustodianAssetClassColumns(): any[] {
    return CustodianAssetClassReconColums;
  }

  getSecuritiesReconColumns(): any[] {
    return securityReconColums;
  }

  getEquitiesSecurityReconColumns(): any[] {
    return securityEquitiesReconColumns;
  }

  getNonEquitiesSecurityReconColumns(): any[] {
    return securityNonEquitiesReconColumns;
  }
}
