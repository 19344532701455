import { NgModule, ModuleWithProviders } from "@angular/core";
// import { FormsModule } from '@angular/forms';
import { LayoutService } from "./services/layout.service";
import { MenudropdownDirective } from "./directives/menudropdown.directive";
import { CommonModule } from "@angular/common";
import { DraggableDirective } from "./directives/draggable.directive";

@NgModule({
  declarations: [MenudropdownDirective, DraggableDirective],
  imports: [
    // FormsModule,
    CommonModule,
  ],
  exports: [
    // FormsModule,
    MenudropdownDirective,
    DraggableDirective,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [LayoutService],
    };
  }
}
