import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class DepositImporterService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportDepositPurchases(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/DepositsFilesImport/DepositsPurchasesFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  ImportDepositSales(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/DepositsFilesImport/DepositsSalesFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  ImportDepositHoldings(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/DepositsFilesImport/DepositsHoldingsFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  SaveDepositPurchases(payload: any): Observable<any> {
    return this.apiService
      .Post("DepositsFilesImport/SaveParsedDepositPurchases", payload)
      .pipe(shareReplay());
  }

  SaveDepositSales(payload: any): Observable<any> {
    return this.apiService
      .Post("DepositsFilesImport/SaveParsedDepositSales", payload)
      .pipe(shareReplay());
  }

  SaveDepositHoldings(payload: any): Observable<any> {
    return this.apiService
      .Post("DepositsFilesImport/SaveParsedDepositsHoldings", payload)
      .pipe(shareReplay());
  }

  getDepositPurchasesColumns(): any {
    let DepositPurchasesColumns = [
      {
        caption: "Deposit Bank",
        dataField: "DepositBank",
        alignment: "left",
        cssClass: "performance-attr-grid-focus-3",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Cash Account", dataField: "InvestorCashAccount" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Interest Rate(%)", dataField: "InterestRate" },
      {
        caption: "Deposit Date",
        dataField: "DepositDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Days In Year", dataField: "DaysInYear" },
      { caption: "Is Call", dataField: "IsCall" },
      { caption: "Reference", dataField: "Reference" },
    ];
    return DepositPurchasesColumns;
  }

  getDepositSalesColumns(): any {
    let salesColumns = [
      {
        caption: "Deposit Bank",
        dataField: "DepositBank",
        alignment: "left",
        cssClass: "performance-attr-grid-focus-3",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "Cash Account", dataField: "InvestorCashAccount" },
      { caption: "Bank Swift Code", dataField: "BankSwiftCode" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Interest Rate(%)", dataField: "InterestRate" },
      {
        caption: "Sale Date",
        dataField: "SaleDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Is Call", dataField: "IsCall" },
      { caption: "Purchase Reference", dataField: "PurchaseReference" },
      { caption: "Sale Reference", dataField: "SaleReference" },
    ];

    return salesColumns;
  }

  getDepositHoldingsColumns(): any {
    let holdingsColumns = [
      {
        caption: "Deposit Bank",
        dataField: "DepositBankCode",
        alignment: "left",
        cssClass: "performance-attr-grid-focus-3",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      {
        caption: "Deposit Date",
        dataField: "DepositDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Holdings Date",
        dataField: "HoldingsDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Interest Rate(%)", dataField: "InterestRate" },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Nominal Value", dataField: "NominalValue" },
      { caption: "Cumulative Cost", dataField: "CumulativeCost" },
      { caption: "Accrued Interest(%)", dataField: "AccruedInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
      { caption: "Time Deposit Type", dataField: "TimeDepositType" },
      { caption: "Currency Symbol", dataField: "CurrencySymbol" },
      { caption: "Reference", dataField: "Reference" },
    ];

    return holdingsColumns;
  }
}
