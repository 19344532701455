import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApplicationSettingsService } from '../../app-services/app-settings/application-settings.service';
import { ApplicationDecimalPoints, ApplicationSettings } from 'src/app/app-services/app-settings/application-settings';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { timer, of, Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit {

	UpdateMessage$: Observable<string>

	fontStyles = [{
		text: 'small',
		cssClass: 'font-size-small'
	}, {
		text: 'normal',
		cssClass: 'font-size-normal'

	}, {
		text: 'large',
		cssClass: 'font-size-large'
	}, {
		text: 'larger',
		cssClass: 'font-size-larger'
	}];


	decimalPoints = [
		{
			text: '0',
			decimals: ApplicationDecimalPoints.Zero
		}
		, {
			text: '1',
			decimals: ApplicationDecimalPoints.One
		}
		, {
			text: '2',
			decimals: ApplicationDecimalPoints.Two
		}
		, {
			text: '3',
			decimals: ApplicationDecimalPoints.Three
		},
		{
			text: '4',
			decimals: ApplicationDecimalPoints.Four
		}
		, {
			text: '5',
			decimals: ApplicationDecimalPoints.Five
		}
		, {
			text: '6',
			decimals: ApplicationDecimalPoints.Six
		}
		, {
			text: '7',
			decimals: ApplicationDecimalPoints.Seven
		}
		, {
			text: '8',
			decimals: ApplicationDecimalPoints.Eight
		}
	];

	selectedFontStyleNames: string[] = ['font-size-normal'];
	selectedPercentageDecimalPoints: number[]
	selectedNonPercentageDecimalPoints: number[]
	percentageValueSample: string = ''
	nonPercentageValueSample: string = ''

	fontSize = 'font-size-normal';
	sampleLargeNumber = 67.1234567891

	constructor(private title: Title, private settingsService: ApplicationSettingsService, public dialogRef: MatDialogRef<SettingsComponent>, public snackBar: MatSnackBar) {
		this.logSelectionChanged = this.logSelectionChanged.bind(this);
	}

	ngOnInit() {
		this.settingsService.GetSettings().subscribe(settings => {
			const percentageDecimalPoints = settings.PercentageValuesDecimalPoints;
			const nonPercentageDecimalPoints = settings.NonPercentageValuesDecimalPoints

			this.selectedPercentageDecimalPoints = [percentageDecimalPoints]
			this.selectedNonPercentageDecimalPoints = [nonPercentageDecimalPoints]

			let testNumber = this.sampleLargeNumber

			this.percentageValueSample = testNumber.toFixed(percentageDecimalPoints).toString()
			this.nonPercentageValueSample = testNumber.toFixed(nonPercentageDecimalPoints).toString()
		})
	}

	ngAfterViewInit(): void {
	}

	logSelectionChanged() {

		if (this.selectedPercentageDecimalPoints.length > 0) {
			let testNumber = this.sampleLargeNumber
			const selection = this.selectedPercentageDecimalPoints[0];
			this.percentageValueSample = testNumber.toFixed(selection)
		}
		if (this.selectedNonPercentageDecimalPoints.length > 0) {
			let testNumber = this.sampleLargeNumber
			const selection = this.selectedNonPercentageDecimalPoints[0];
			this.nonPercentageValueSample = testNumber.toFixed(selection)


		}
	}

	updateSettings() {

		let localAppSettings: ApplicationSettings = new ApplicationSettings()
		if (this.selectedPercentageDecimalPoints.length > 0) {
			const selection = this.selectedPercentageDecimalPoints[0];
			localAppSettings.PercentageValuesDecimalPoints = <ApplicationDecimalPoints>selection

		}
		if (this.selectedNonPercentageDecimalPoints.length > 0) {

			const selection = this.selectedNonPercentageDecimalPoints[0];
			localAppSettings.NonPercentageValuesDecimalPoints = <ApplicationDecimalPoints>selection

		}

		this.settingsService.UpdateSettings(localAppSettings);

		this.UpdateMessage$ = of('Updated')

		let notificationsDelay = timer(1000)
		notificationsDelay.subscribe(_ => {
			this.UpdateMessage$ = of('')
		})
	}

	closeSettingsWindow() {
		this.dialogRef.close();
	}
}
