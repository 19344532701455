import { Component, OnInit } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { Observable } from "rxjs";
import {
  Tenant,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";
import { OidcGuardService } from "src/app/providers/oidc-guard/oidc-guard.service";
import { ReportsComponent } from "src/app/reports-module/reports/reports.component";
import { Router } from "@angular/router";
import { ZambiaSecReportComponent } from "src/app/reports-module/reports/ZambiaSecReports/zambia-sec-report/zambia-sec-report.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  currentServerTime$: Observable<any>;
  currentBrowserTime$: Observable<Date>;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private appSettingsService: ApplicationSettingsService,
    private tenantResolverService: TenantService,
    private router: Router
  ) {}

  ngOnInit() {
    // let tenantName = this.tenantResolverService.getTenantName();
    // //cma Tenant
    // if (tenantName == Tenant.Tenant1) {
    //   this.router.config.push({
    //     path: "reports",
    //     component: ReportsComponent,
    //     canActivate: [OidcGuardService],
    //   });
    // }
    // //zambia sec
    // else if (tenantName == Tenant.Tenant2) {
    //   this.router.config.push({
    //     path: "reports",
    //     component: ZambiaSecReportComponent,
    //     canActivate: [OidcGuardService],
    //   });
    // } else {
    //   this.router.config.push({
    //     path: "reports",
    //     component: ReportsComponent,
    //     canActivate: [OidcGuardService],
    //   });
    // }
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logoutPopup() {}

  private doCallbackLogicIfRequired() {
    // Will do a callback, if the url has a code and state parameter.
  }
}
