import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PagesCoreComponent } from "./theme-contents/pages-core/pages-core.component";

/////////////////////////////////// Innova Analytics Components To remove the above eventually ////////////////////////////////

import { PerformanceAttributionComponent } from "./app-components/performance-attribution/performance-attribution.component";
import { AnalyticsDisplayViewComponent } from "./app-components/performance-attribution/analytics-display-view/analytics-display-view.component";
import { MeanSampleComponent } from "./app-components/performance-attribution/mean-sample/mean-sample.component";
import { SettingsComponent } from "./app-components/settings/settings.component";
import { FundManagerSetupComponent } from "./app-components/setup/fund-manager-setup/fund-manager-setup.component";
import { FundsComponent } from "./app-components/funds/funds.component";
import { ListedEquitiesComponent } from "./app-components/master-data/listed-equities/listed-equities.component";
import { CommercialPaperComponent } from "./app-components/master-data/commercial-paper/commercial-paper.component";
import { FloatingTreasuryBondComponent } from "./app-components/master-data/floating-treasury-bond/floating-treasury-bond.component";
import { FixedTreasuryBondComponent } from "./app-components/master-data/fixed-treasury-bond/fixed-treasury-bond.component";
import { OffshoreComponent } from "./app-components/master-data/offshore/offshore.component";
import { TreasuryBillComponent } from "./app-components/master-data/treasury-bill/treasury-bill.component";
import { UnitTrustComponent } from "./app-components/master-data/unit-trust/unit-trust.component";
import { CorporateBondComponent } from "./app-components/master-data/corporate-bond/corporate-bond.component";
import { BenchMarksViewComponent } from "./app-components/bench-marks/bench-marks-view/bench-marks-view.component";
import { BenchmarkTypesComponent } from "./app-components/bench-marks/benchmark-types/benchmark-types.component";
import { InstrumentClassesComponent } from "./app-components/setup/instrument-classes/instrument-classes.component";
import { LimitsComponent } from "./app-components/setup/limits/limits.component";
import { LimitTypesComponent } from "./app-components/setup/limit-types/limit-types.component";
import { HoldingsComponent } from "./app-components/master-data/holdings/holdings/holdings.component";
import { InvestorsComponent } from "./app-components/setup/investors/investors.component";
import { CustodiansComponent } from "./app-components/master-data/custodians/custodians.component";
import { CountriesComponent } from "./app-components/master-data/countries/countries.component";
import { BanksComponent } from "./app-components/master-data/banks/banks.component";
import { ModelMappingComponent } from "./app-components/setup/model-mapping/model-mapping.component";
import { PurchasesSalesComponent } from "./app-components/master-data/purchases-sales/purchases-sales.component";
import { AssetRegistryComponent } from "./app-components/asset-registry/asset-registry.component";
import { OidcGuardService } from "./providers/oidc-guard/oidc-guard.service";
import { LoginComponent } from "./utilities/login/login.component";
import { DataExportComponent } from "./utilities/data-export/data-export.component";
import { NonPortfolioAnalyticsComponent } from "./app-components/non-portfolio/non-portfolio-analytics/non-portfolio-analytics.component";
import { PortfolioAnalyticsComponent } from "./app-components/portfolio-analytics/portfolio-analytics.component";
import { RegulatoryComplianceComponent } from "./app-components/reports/regulatory-compliance/regulatory-compliance.component";
import { PredictiveAnalyticsComponent } from "./app-components/non-portfolio/predictive-analytics/predictive-analytics.component";
import { UnauthorizedComponent } from "./utilities/unauthorized/unauthorized.component";
import { AnalyticsDashboardComponent } from "./app-components/analytics-dashboard/analytics-dashboard.component";

import { PortfolioRebalancingReportComponent } from "./app-components/reports/portfolio-rebalancing-report/portfolio-rebalancing-report.component";

import { ReportsComponent } from "./reports-module/reports/reports.component";
import { PortfolioBondRiskAnalysisComponent } from "./app-components/portfolio-bond-risk-analysis/portfolio-bond-risk-analysis.component";
import { NonPortfolioBondRiskAnalysisComponent } from "./non-portfolio-bond-risk-analysis/non-portfolio-bond-risk-analysis.component";
import { PortfolioCorrelationAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-correlation-analysis/portfolio-correlation-analysis.component";
import { PortfolioPeerGroupAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-peer-group-analysis/portfolio-peer-group-analysis.component";
import { PortfolioRegressionAnalysisComponent } from "./app-components/portfolio-analytics/portfolio-regression-analysis/portfolio-regression-analysis.component";
import { NonPortfolioRegressionAnalysisComponent } from "./app-components/non-portfolio/non-portfolio-regression-analysis/non-portfolio-regression-analysis.component";
import { NonPortfolioCorrelationAnalysisComponent } from "./app-components/non-portfolio/non-portfolio-correlation-analysis/non-portfolio-correlation-analysis.component";
import { PortfolioOptimizationComponent } from "./app-components/portfolio-analytics/portfolio-optimization/portfolio-optimization.component";
import { PortfolioRebalancingComponent } from "./app-components/portfolio-analytics/portfolio-rebalancing/portfolio-rebalancing.component";
import { InstrumentCategoriesComponent } from "./app-components/setup/instrument-categories/instrument-categories.component";
import { UserComponent } from "./app-components/master-data/user/user.component";
import { NonportfolioAssetRegistryComponent } from "./app-components/asset-registry/nonportfolio-asset-registry/nonportfolio-asset-registry.component";
import { EquitiesDataImporterComponent } from "./app-components/data-importer/equities-data-importer/equities-data-importer.component";
import { DepositDataImporterComponent } from "./app-components/data-importer/deposit-data-importer/deposit-data-importer.component";
import { TreasuryBondsImporterComponent } from "./app-components/data-importer/treasury-bonds-importer/treasury-bonds-importer.component";
import { CorporateBondsImporterComponent } from "./app-components/data-importer/corporate-bonds-importer/corporate-bonds-importer.component";
import { CommercialPaperImporterComponent } from "./app-components/data-importer/commercial-paper-importer/commercial-paper-importer.component";
import { TreasuryBillsImporterComponent } from "./app-components/data-importer/treasury-bills-importer/treasury-bills-importer.component";
import { DividendsImporterComponent } from "./app-components/data-importer/dividends-importer/dividends-importer.component";
import { CouponPaymentsImporterComponent } from "./app-components/data-importer/coupon-payments-importer/coupon-payments-importer.component";
import { CashTransactionsImporterComponent } from "./app-components/data-importer/cash-transactions-importer/cash-transactions-importer.component";
import { InvestorTypeComponent } from "./app-components/master-data/investor-type/investor-type.component";
import { InvestorSubTypeComponent } from "./app-components/master-data/investor-sub-type/investor-sub-type.component";
import { ZambiaSecReportComponent } from "./reports-module/reports/ZambiaSecReports/zambia-sec-report/zambia-sec-report.component";
import { ReconReportsComponent } from "./reports-module/reports/ReconReports/recon-reports/recon-reports.component";
import { CustodianPortfolioRegistryComponent } from "./app-components/asset-registry/custodian-portfolio-registry/custodian-portfolio-registry.component";
import { FundamentalAnalysisComponent } from "./app-components/fundamental-analysis/fundamental-analysis.component";
import { ChatBotComponent } from "./app-components/chat-bot/chat-bot.component";

const routes: Routes = [
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "",
    component: PagesCoreComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        component: AnalyticsDashboardComponent,
        canActivate: [OidcGuardService],
      },
      {
        path: "chatbot",
        component: ChatBotComponent,
        canActivate: [OidcGuardService],
      },
      {
        path: "performance-attribution",
        data: {
          breadcrumb: "Analytics",
        },
        // canActivate: [OidcGuardService],
        children: [
          {
            path: "",
            component: PerformanceAttributionComponent,
            data: {
              breadcrumb: null,
            },
            // canActivate: [OidcGuardService],
          },
          {
            path: "results",
            component: AnalyticsDisplayViewComponent,
            data: {
              breadcrumb: "Analytics Results",
            },
          },
          {
            path: "mean",
            component: MeanSampleComponent,
            data: {
              breadcrumb: "Mean",
            },
          },
        ],
      },
      {
        path: "portfolio-bond-risk-analysis",
        data: {
          breadcrumb: "Analytics",
        },
        // canActivate: [OidcGuardService],
        children: [
          {
            path: "",
            component: PortfolioBondRiskAnalysisComponent,
            data: {
              breadcrumb: null,
            },
          },
          {
            path: "results",
            component: AnalyticsDisplayViewComponent,
            data: {
              breadcrumb: "Analytics Results",
            },
          },
          {
            path: "mean",
            component: MeanSampleComponent,
            data: {
              breadcrumb: "Mean",
            },
          },
        ],
      },
      {
        path: "non-portfolio-bond-risk-analysis",
        data: {
          breadcrumb: "Analytics",
        },
        // // canActivate: [OidcGuardService],
        children: [
          {
            path: "",
            component: NonPortfolioBondRiskAnalysisComponent,
            data: {
              breadcrumb: null,
            },
          },
          {
            path: "results",
            component: AnalyticsDisplayViewComponent,
            data: {
              breadcrumb: "Analytics Results",
            },
          },
          {
            path: "mean",
            component: MeanSampleComponent,
            data: {
              breadcrumb: "Mean",
            },
          },
        ],
      },

      {
        path: "settings",
        component: SettingsComponent,
        data: {
          breadcrumb: "Settings",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "regulatory-compliance",
        component: RegulatoryComplianceComponent,
        data: {
          breadcrumb: "Settings",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "portfolio-analytics",
        component: PortfolioAnalyticsComponent,
        data: {
          breadcrumb: "Portfolio Analytics",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "portfolio-correlation-analysis",
        component: PortfolioCorrelationAnalysisComponent,
        data: {
          breadcrumb: "Correlation Analysis",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "portfolio-regression-analysis",
        component: PortfolioRegressionAnalysisComponent,
        data: {
          breadcrumb: "Regression Analysis",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "portfolio-peer-group-analysis",
        component: PortfolioPeerGroupAnalysisComponent,
        data: {
          breadcrumb: "Peer Group Analysis",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "portfolio-optimization",
        component: PortfolioOptimizationComponent,
        data: {
          breadcrumb: "Portfolio Optimization",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "predictive-analytics",
        component: PredictiveAnalyticsComponent,
        data: {
          breadcrumb: "Predictive Analytics",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "non-portfolio-regression-analysis",
        component: NonPortfolioRegressionAnalysisComponent,
        data: {
          breadcrumb: "Non Portfolio Regression Analysis",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "non-portfolio-correlation-analysis",
        component: NonPortfolioCorrelationAnalysisComponent,
        data: {
          breadcrumb: "Non Portfolio Correlation Analysis",
        },
        // canActivate: [OidcGuardService],
      },
      // {
      //   path: "fund-managers",
      //   component: FundManagerSetupComponent,
      //   data: {
      //     breadcrumb: "Fund Managers",
      //   },
      //   // canActivate: [OidcGuardService],
      // },
      {
        path: "funds",
        component: FundsComponent,
        data: {
          breadcrumb: "Funds",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "listed-equities",
        component: ListedEquitiesComponent,
        data: {
          breadcrumb: "Listed Equitites",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "commercial-papers",
        component: CommercialPaperComponent,
        data: {
          breadcrumb: "Commercial Papers",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "floating-treasury-bonds",
        component: FloatingTreasuryBondComponent,
        data: {
          breadcrumb: "floating treasury bonds",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "fixed-treasury-bonds",
        component: FixedTreasuryBondComponent,
        data: {
          breadcrumb: "fixed treasury bonds",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "offshores",
        component: OffshoreComponent,
        data: {
          breadcrumb: "offshores",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "treasury-bills",
        component: TreasuryBillComponent,
        data: {
          breadcrumb: "treasury bills",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "unit-trusts",
        component: UnitTrustComponent,
        data: {
          breadcrumb: "unit trusts",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "fixed-corporate-bonds",
        component: CorporateBondComponent,
        data: {
          breadcrumb: "fixed corporate bonds",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "benchmarks",
        component: BenchMarksViewComponent,
        data: {
          breadcrumb: "benchmarks",
        },
      },
      {
        path: "benchmark-types",
        component: BenchmarkTypesComponent,
        data: {
          breadcrumb: "benchmark types",
        },
      },
      {
        path: "asset-classes-setup",
        component: InstrumentClassesComponent,
        data: {
          breadcrumb: "Asset Classes",
        },
      },
      {
        path: "asset-categories",
        component: InstrumentCategoriesComponent,
        data: {
          breadcrumb: "instrument categories",
        },
      },
      {
        path: "limits",
        component: LimitsComponent,
        data: {
          breadcrumb: "Limits",
        },
      },
      {
        path: "limit-types",
        component: LimitTypesComponent,
        data: {
          breadcrumb: "Limit Types",
        },
      },
      {
        path: "holdings",
        component: HoldingsComponent,
        data: {
          breadcrumb: "Holdings",
        },
      },
      {
        path: "investors",
        component: InvestorsComponent,
        data: {
          breadcrumb: "Holdings",
        },
      },
      {
        path: "countries",
        component: CountriesComponent,
        data: {
          breadcrumb: "Countries",
        },
      },
      {
        path: "banks",
        component: BanksComponent,
        data: {
          breadcrumb: "Banks",
        },
      },
      {
        path: "investor-types",
        component: InvestorTypeComponent,
        data: {
          breadcrumb: "InvestorTypes",
        },
      },
      {
        path: "investor-sub-types",
        component: InvestorSubTypeComponent,
        data: {
          breadcrumb: "InvestorSubTypes",
        },
      },
      {
        path: "users",
        component: UserComponent,
        data: {
          breadcrumb: "Banks",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "analytics-setup/model-mapping",
        component: ModelMappingComponent,
        data: {
          breadcrumb: "Model Mapping",
        },
      },
      {
        path: "purchases-sales",
        component: PurchasesSalesComponent,
        data: {
          breadcrumb: "Purchases/ Sales",
        },
      },
      {
        path: "asset-registry",
        component: AssetRegistryComponent,
        data: {
          breadcrumb: "Asset Registry",
        },
      },
      {
        path: "custodian-asset-registry",
        component: CustodianPortfolioRegistryComponent,
        data: {
          breadcrumb: "Asset Registry",
        },
      },
      {
        path: "nonportfolio-asset-registry",
        component: NonportfolioAssetRegistryComponent,
        data: {
          breadcrumb: "Non Portfolio Asset Registry",
        },
      },
      {
        path: "sample-export-tool",
        component: DataExportComponent,
        data: {
          breadcrumb: "Data Export",
        },
        // canActivate: [OidcGuardService],
      },
      {
        path: "macro-security-analysis",
        component: NonPortfolioAnalyticsComponent,
        data: {
          breadcrumb: "Data Export",
        },
      },
      {
        path: "equities-data-importer",
        component: EquitiesDataImporterComponent,
        data: {
          breadcrumb: "Equities Data Importer",
        },
      },
      {
        path: "deposits-data-importer",
        component: DepositDataImporterComponent,
        data: {
          breadcrumb: "Fixed Deposits Importer",
        },
      },
      {
        path: "treasury-bonds-data-importer",
        component: TreasuryBondsImporterComponent,
        data: {
          breadcrumb: "Treasury Bonds Importer",
        },
      },
      {
        path: "corporate-bonds-data-importer",
        component: CorporateBondsImporterComponent,
        data: {
          breadcrumb: "Corporate Bonds Importer",
        },
      },
      {
        path: "treasury-bills-data-importer",
        component: TreasuryBillsImporterComponent,
        data: {
          breadcrumb: "Treasury Bills Importer",
        },
      },
      {
        path: "commercial-paper-data-importer",
        component: CommercialPaperImporterComponent,
        data: {
          breadcrumb: "Commercial Paper Importer",
        },
      },
      {
        path: "dividends-importer",
        component: DividendsImporterComponent,
        data: {
          breadcrumb: "Dividends Importer",
        },
      },
      {
        path: "cash-transactions-importer",
        component: CashTransactionsImporterComponent,
        data: {
          breadcrumb: "Cash Transactions Importer",
        },
      },
      {
        path: "coupon-payments-importer",
        component: CouponPaymentsImporterComponent,
        data: {
          breadcrumb: "Coupon Payments Importer",
        },
      },
      {
        path: "portfolio-rebalancing",
        component: PortfolioRebalancingComponent,
        data: {
          breadcrumb: "Data Import",
        },
      },
      {
        path: "reports",
        component: ReportsComponent,
        // canActivate: [OidcGuardService],
      },
      {
        path: "recon-report",
        component: ReconReportsComponent,
        // canActivate: [OidcGuardService],
      },
      {
        path: "fundamental-analysis",
        component: FundamentalAnalysisComponent,
        // canActivate: [OidcGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
