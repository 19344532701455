import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class CorporateBondsImporterService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportCorporateBondPurchases(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/BondsFileImport/FixedCorporateBondPurchasesFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  ImportCorporateBondSales(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/BondsFileImport/FixedCorporateBondSalesFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  ImportCorporateBondHoldings(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/BondsFileImport/FixedCorporateBondsHoldingsFileImport`,
        file
      )
      .pipe(shareReplay());
  }

  SaveCorporateBondsPurchases(payload: any): Observable<any> {
    return this.apiService
      .Post("BondsFileImport/SaveParsedFixedCorporateBondPurchases", payload)
      .pipe(shareReplay());
  }

  SaveCorporateBondSales(payload: any): Observable<any> {
    return this.apiService
      .Post("BondsFileImport/SaveParsedFixedCorporateBondSales", payload)
      .pipe(shareReplay());
  }

  SaveCorporateBondHoldings(payload: any): Observable<any> {
    return this.apiService
      .Post("BondsFileImport/SaveParsedFixedCorporateBondHoldings", payload)
      .pipe(shareReplay());
  }

  getCorporateBondPurchasesColumns(): any {
    let corporateBondPurchasesColumns = [
      {
        caption: "Code",
        dataField: "Code",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 180,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "ISIN", dataField: "ISIN" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Purchase Price", dataField: "PurchasePrice" },
      { caption: "Yield(%)", dataField: "Yield" },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Levies", dataField: "AggregatedLevies" },
      { caption: "Commissions", dataField: "Comissions" },
      // { caption: "Consideration", dataField: "Consideration" },
      { caption: "Purchase Reference", dataField: "Reference" },
    ];
    return corporateBondPurchasesColumns;
  }

  getCorporateBondSalesColumns(): any {
    let corporateBondSaleColumns = [
      {
        caption: "Code",
        dataField: "Code",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 180,
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "ISIN", dataField: "ISIN" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Sale Price", dataField: "SalePrice" },
      { caption: "Yield(%)", dataField: "Yield" },
      {
        caption: "Sale Date",
        dataField: "SaleDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Levies", dataField: "AggregatedLevies" },
      { caption: "Commissions", dataField: "Comissions" },
      // { caption: "Consideration", dataField: "Consideration" },
      { caption: "Purchase Reference", dataField: "PurchaseReference" },
      { caption: "Sale Reference", dataField: "SaleReference" },
    ];
    return corporateBondSaleColumns;
  }

  getCorporateBondHoldingsColumns(): any {
    let holdingsColumns = [
      {
        caption: "Security ISIN",
        dataField: "SecurityISIN",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Currency Symbol", dataField: "CurrencySymbol" },
      {
        caption: "Holdings Date",
        dataField: "HoldingsDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },

      {
        caption: "Purchase Date",
        dataField: "HPurchaseDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Maturity Date",
        dataField: "MaturityDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Interest Rate(%)", dataField: "InterestRate" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Face Value", dataField: "FaceValue" },
      { caption: "Cumulative Cost", dataField: "CumulativeCost" },
      { caption: "Accrued Interest", dataField: "AccruedInterest" },
      { caption: "Market Value", dataField: "MarketValue" },
    ];

    return holdingsColumns;
  }
}
