<div class="importer-content-section">
  <mat-tab-group color="primary" (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Purchases">
      <ng-container *ngTemplateOutlet="sampleStepper"></ng-container>
    </mat-tab>
    <mat-tab label="Sales">
      <ng-container *ngTemplateOutlet="sampleStepper"></ng-container>
    </mat-tab>
    <mat-tab label="Holdings">
      <ng-container *ngTemplateOutlet="sampleStepper"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <ng-template #sampleStepper>
    <mat-horizontal-stepper
      labelPosition="bottom"
      style="margin-top: 3%"
      #stepper
    >
      <mat-step
        [stepControl]="firstFormGroup"
        [editable]="false"
        [optional]="false"
      >
        <form [formGroup]="firstFormGroup" (ngSubmit)="processFile()">
          <ng-template matStepLabel matStepperIcon="edit">
            <i
              class="fas fa-file-import"
              style="margin-right: 0.5rem; color: Dodgerblue"
            ></i>
            <span class="stepper-label">Import File</span>
          </ng-template>
          <div class="container" (fileDropped)="onFileDropped($event)">
            <input
              type="file"
              name="file"
              #fileDropRef
              id="fileDropRef"
              (change)="fileBrowseHandler($event)"
              accept=".xls,.xlsx"
            />
            <h3 style="margin: 15px">Drag and drop</h3>
            <span style="color: #337ab7">
              <i class="fas fa-file-excel fa-5x"></i>
            </span>
            <h3 style="padding: 1%">or</h3>
            <dx-button
              text="Browse for file"
              stylingMode="contained"
              icon="far fa-folder"
              type="default"
              [width]="120"
              [height]="40"
            ></dx-button>
          </div>
          <div class="files-list">
            <div class="single-file" *ngFor="let file of files; let i = index">
              <div class="file-icon" style="width: 50px">
                <img src="assets/svg/ic-file.svg" width="45px" alt="file" />
              </div>
              <div class="info">
                <h4 class="name">
                  {{ file?.name }}
                </h4>
                <p class="size">
                  {{ formatBytes(file?.size) }}
                </p>
                <app-progress [progress]="file?.progress"></app-progress>
              </div>
              <div style="margin-left: 1%">
                <button type="submit" class="button-8" matStepperNext>
                  <i class="fas fa-arrow-right" style="margin-right: 0.5rem"></i
                  >Upload File
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <!-- process data stepper -->
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>
            <i
              class="fas fa-cloud-upload-alt"
              style="margin-right: 0.5rem; color: Dodgerblue"
            ></i>
            <span class="stepper-label">Upload File</span>
          </ng-template>
          <mat-tab-group color="primary">
            <mat-tab label="Data">
              <div style="margin-top: 0.5%">
                <dx-data-grid
                  #resultsDataGrid
                  [dataSource]="gridDataSource"
                  [columns]="stockColumns"
                  columnResizingMode="widget"
                  [allowColumnResizing]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [groupPanel]="{ visible: true }"
                  (onRowDblClick)="updateChart($event)"
                  [grouping]="{ autoExpandAll: true }"
                  [searchPanel]="{
                    visible: true,
                    placeholder: 'search for anything',
                    width: 500
                  }"
                  [allowColumnReordering]="false"
                  [rowAlternationEnabled]="false"
                  [export]="{
                    enabled: true,
                    fileName: 'Non-Portfolio-Analytics',
                    allowExportSelectedData: true
                  }"
                  [columnChooser]="{ enabled: true, mode: 'select' }"
                  [wordWrapEnabled]="false"
                  [columnFixing]="true"
                  [twoWayBindingEnabled]="true"
                  [repaintChangesOnly]="false"
                  [activeStateEnabled]="true"
                  [keyExpr]="gridKeyExpression"
                  [focusStateEnabled]="true"
                  [focusedRowEnabled]="false"
                  [scrolling]="{ mode: 'standard', preloadEnabled: true }"
                >
                  <dxo-selection
                    mode="single"
                    [deferred]="true"
                  ></dxo-selection>
                  <dxo-paging [enabled]="true"></dxo-paging>
                  <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
              </div>
            </mat-tab>
            <mat-tab label="Errors" *ngIf="FileSuccess == false">
              <div class="error-container" *ngIf="FileSuccess">
                <div style="padding: 1.5%">
                  <img src="assets/svg/tick-box.svg" width="45px" alt="file" />
                </div>
                <h3>Your File has no errors</h3>
                <h3>Click Save Button</h3>
                <h3>To upload to cloud</h3>
              </div>
              <div class="error-container">
                <div style="padding: 1.5%">
                  <img
                    src="assets/svg/error_icon.svg"
                    width="45px"
                    alt="file"
                  />
                </div>
                <h3>Kindly fix the following</h3>
                <h3>Errors to proceed:</h3>
                <li *ngFor="let error of ImportErrors" style="color: red">
                  {{ error }}
                </li>
              </div>
            </mat-tab>
          </mat-tab-group>
          <div class="wrapper">
            <!-- //should go forward if no errors are found -->
            <div class="goForward" (click)="SaveFile()">
              <div>
                <button
                  type="submit"
                  class="button-8"
                  [disabled]="FileNotSuccess"
                  matStepperNext
                >
                  <i class="fas fa-save" style="margin-right: 0.5rem"></i>Save
                </button>
              </div>
            </div>
            <div class="goBack" (click)="stepper.reset()">
              <button type="submit" class="button-8" matStepperNext>
                <i class="fas fa-arrow-left" style="margin-right: 0.5rem"></i
                >Import File
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <i
            class="fas fa-save"
            style="margin-right: 0.5rem; color: Dodgerblue"
          ></i>
          <span class="stepper-label">Save</span>
        </ng-template>

        <div class="success-container" *ngIf="importStatus == 'Successful'">
          <h3>Congratulations your file</h3>
          <h3>has been imported</h3>
          <div (click)="stepper.reset()" style="padding-top: 1%">
            <button type="submit" class="button-8">
              <i class="far fa-check-circle" style="margin-right: 0.5rem"></i
              >Complete
            </button>
          </div>
        </div>
        <div class="error-container" *ngIf="importStatus == 'UnSuccessful'">
          <div style="padding: 1.5%">
            <img src="assets/svg/error_icon.svg" width="45px" alt="file" />
          </div>
          <h3>An Error has Occured</h3>
          <h3>Kindly try again later</h3>
          <div (click)="stepper.reset()" style="padding-top: 1%">
            <dx-button
              text="Reset"
              icon="far fa-check-circle"
              stylingMode="contained"
              type="default"
              [width]="120"
              [height]="40"
            ></dx-button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-template>
</div>
