import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';


@Component({
	selector: 'app-listed-equities',
	templateUrl: './listed-equities.component.html',
	styleUrls: ['./listed-equities.component.scss']
})
export class ListedEquitiesComponent implements OnInit {

	public Columns: Array<any> = [
		{ caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Equity Class', dataField: 'EquityClass' },
		{ caption: 'Date Created', dataField: 'DateCreated', dataType: 'date' },
		{ caption: 'Security Type', dataField: 'SecurityType' },
		{ caption: 'System Code', dataField: 'SystemCode' },
		{ caption: 'Active', dataField: 'Active', dataType: 'boolean' },
		{ caption: 'Approved', dataField: 'Approved', dataType: 'boolean' },
	];


	listedEqitiesDataSource: any;
	EntityName = 'Listed Equities';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single' }

	constructor(private apiService: ApiService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Listed Equities')
	}

	ngOnInit() {
		this.apiService.Get('ListedEquities').subscribe(result => this.listedEqitiesDataSource = result);
	}

	rowDoubleClicked(event: any) {
	}
}
