import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
  Input,
  OnDestroy,
} from "@angular/core";
import { AnalyticsDisplayItem } from "../analytics-display-item";
import { ApiService } from "../../../app-services/api/api.service";
import { AssetLevel } from "../asset-class-type";
import { AnalyticsGridOption } from "./analytics-grid-options";

import { BehaviorSubject, Subscription, ReplaySubject } from "rxjs";
import DataSource from "devextreme/data/data_source";
import { AnalyticsDisplayViewGridColumnsService } from "./analytics-display-view-grid-columns-service";
import { AnalyticsModelClassCombination } from "../selected-category";
import { DocumentationService } from "src/app/app-services/documentation/documentation.service";
import { AnalyticsRequestObjectService } from "../analytics-request-object/analytics-request-object.service";
import { ConfigService } from "src/app/common/config.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";

@Component({
  selector: "app-analytics-display-view",
  templateUrl: "./analytics-display-view.component.html",
  styleUrls: ["./analytics-display-view.component.scss"],
})
export class AnalyticsDisplayViewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  assetClassModelObservable: BehaviorSubject<AnalyticsModelClassCombination>;
  @Input() startDateObservable: BehaviorSubject<Date>;
  @Input() endDateObservable: BehaviorSubject<Date>;
  @Input() investorIdObservable: BehaviorSubject<string>;
  @Input() includeCashObservable: BehaviorSubject<string>;
  // @Output() toogleModelsArea: EventEmitter<boolean> = new EventEmitter();

  performanceAttributionConfigSunscription: Subscription;
  apiServiceSubscription: Subscription;

  displayItems: Array<AnalyticsDisplayItem> = new Array<AnalyticsDisplayItem>();
  assetClassesDataSource: any;
  assetCategoriesDataSource: any;
  assetLevelDataSource: any;
  performanceAttributionDataSource: any;
  analyticsGridOptions: ReplaySubject<Array<AnalyticsGridOption>> =
    new ReplaySubject<Array<AnalyticsGridOption>>();
  @ViewChildren("someGroupThing") groupSummaryThingy: QueryList<any>;
  totalFundSummarizedPerformanceAttribution = null;
  apiUrl: string;
  PerformanceAttributionSummary: Array<any> = [];

  totalFundGroupSummary = {};
  IncludeCash: boolean = false;
  TenantId: string;
  systemSettings: ApplicationSettings;
  nonPercentageDecimalPlaces: any = 2;

  private _performanceAttributionSubscription: Subscription;
  private _summarizedPerformanceSubscription: Subscription;
  private _totalFundSubscription: Subscription;
  private _requestObjectServiceSubscription: Subscription;
  totalFundGridKey = ["Date", "AssetCategory", "InstrumentId"];

  constructor(
    public documentationService: DocumentationService,
    public appSettingsService: ApplicationSettingsService,
    private apiService: ApiService,
    public analyticsDisplayViewGridColumnsService: AnalyticsDisplayViewGridColumnsService,
    private requestObjService: AnalyticsRequestObjectService,
    private configService: ConfigService,
    private oidcSecurityService: OidcSecurityService,
    private http: HttpClient
  ) {
    this.apiUrl = this.configService.config.APIURL;

    this.assetClassesDataSource = [];
    this.assetCategoriesDataSource = [];
    this.assetLevelDataSource = [];
    this.performanceAttributionDataSource = [];

    this.totalFundGroupSummary = {
      groupItems: [
        {
          showInColumn: "OpeningMarketValue",
          name: "_openingMarketValue",
          summaryType: "custom",
          alignByColumn: false,
          showInGroupFooter: true,
          displayFormat: "{0}",
        },
        // { column: 'ClosingMarketValue', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'AssetCategoryWeight', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'WeightedAssetCategoryReturn', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'BenchmarkWeight', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'WeightedBenchmarkReturn', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'ActiveReturn', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'SelectionEffect', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'InteractionEffect', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'ActiveManagementEffect', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
        // { column: 'AllocationEffect', summaryType: 'sum', alignByColumn: true, showInGroupFooter: true, displayFormat: '{0}' },
      ],
      calculateCustomSummary: function name(options) {
        if (options.name == "_openingMarketValue") {
          switch (options.summaryProcess) {
            case "start":
              // options.totalValue = 0;
              break;
            case "calculate":
              // this.PerformanceAttributionSummary.forEach(element => {
              //   var date = element.Date;
              //   if (date === dateInQuestion) { }
              //   // this is the ordinal case
              //   alert('yeeah')
              // });

              // this.PerformanceAttributionSummary.filter()
              // get the corresponding items fgrom the saved summpary process
              // Modifying 'totalValue' here

              // options.totalValue = 'theDateinQuestion';

              break;
            case "finalize":
              break;
          }
        }
      },
    };
  }
  ngOnDestroy(): void {
    if (this._performanceAttributionSubscription !== undefined)
      this._performanceAttributionSubscription.unsubscribe();
    if (this._summarizedPerformanceSubscription !== undefined)
      this._summarizedPerformanceSubscription.unsubscribe();
    if (this._totalFundSubscription !== undefined)
      this._totalFundSubscription.unsubscribe();

    if (this._requestObjectServiceSubscription !== undefined)
      this._requestObjectServiceSubscription.unsubscribe();
  }

  IsTotalFundRowASummary(element) {
    return element.AssetCategory === "Summary";
  }

  ngOnInit() {
    //get settings
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
      this.nonPercentageDecimalPlaces =
        settings.NonPercentageValuesDecimalPoints;
    });
    this._requestObjectServiceSubscription =
      this.requestObjService.requestObjectObservable$.subscribe(
        (requestObject) => {
          //Destructure the incoming value
          let investorId = requestObject.investorId;
          let startDate = requestObject.startDate;
          let endDate = requestObject.endDate;
          let modelClass = requestObject.modelDescption;
          let includeCash = requestObject.includeCash;
          let tenantId = requestObject.TenantId;

          //set global includeCash value, quick fix
          this.IncludeCash = includeCash;
          this.TenantId = tenantId;

          if (
            investorId !== null &&
            startDate !== null &&
            endDate !== null &&
            modelClass !== null &&
            includeCash !== null &&
            tenantId !== null
          ) {
            let assetClassTypeName = modelClass.selectedCategory.propertyName;
            let assetClassTypeId = modelClass.selectedCategory.propertyId;

            const startDateString = startDate;
            const endDateString = endDate;

            let arrayResult: Array<AnalyticsGridOption> =
              new Array<AnalyticsGridOption>();
            if (assetClassTypeName === "Total Fund") {
              this.totalFundSummarizedPerformanceAttribution = null;
              this._totalFundSubscription = this.apiService
                .Get(
                  `analytics/FindTotalFundPerformance?investorId=${investorId}&startdate=${startDateString}&endDate=${endDateString}&includeCash=${includeCash}&TenantId=${tenantId}`
                )
                .subscribe(
                  (result: Array<any>) => {
                    /** Display all the data from the API other than the summary row - from the API the Asset Category Name for the summary row is 'Summary */
                    let performanceAttributionData = new DataSource({
                      store: result,
                      filter: ["AssetCategory", "<>", "Summary"],
                      group: "Date",
                    });
                    function isSummary(element) {
                      return element.AssetCategory === "Summary";
                    }
                    this.PerformanceAttributionSummary =
                      result.filter(isSummary);

                    let itemGridOptions = new AnalyticsGridOption(
                      performanceAttributionData,
                      assetClassTypeName,
                      "Performance Attribution",
                      AssetLevel.TotalFund,
                      this.analyticsDisplayViewGridColumnsService.totalFundColumns
                    );
                    arrayResult.push(itemGridOptions);
                    this.analyticsGridOptions.next(arrayResult);

                    //toogle models area
                  },
                  () => {}
                );
            } else if (
              assetClassTypeName === "summary-performance-attribution"
            ) {
              this.analyticsGridOptions.next(null);
              this._summarizedPerformanceSubscription = this.apiService
                .Get(
                  `analytics/GetSummarizedPerformanceAttribution?investorId=${investorId}&startDate=${startDateString}&endDate=${endDateString}&includeCash=${includeCash}&TenantId=${tenantId}`
                )
                .subscribe(
                  (result: any) => {
                    this.totalFundSummarizedPerformanceAttribution = result;
                    //toogle models area
                  },
                  () => {}
                );
            } else {
              this.totalFundSummarizedPerformanceAttribution = null;
              this._performanceAttributionSubscription = this.apiService
                .Get(
                  `analytics/PerformanceAttribution?investorId=${investorId}&startdate=${startDateString}&endDate=${endDateString}&assetLevel=1&instrumentId=${assetClassTypeId}&includeCash=${includeCash}&TenantId=${tenantId}`
                )
                .subscribe(
                  (result) => {
                    let itemGridOptions = new AnalyticsGridOption(
                      result,
                      assetClassTypeName,
                      "Performance Attribution",
                      AssetLevel.AssetCategory,
                      null
                    );
                    arrayResult.push(itemGridOptions);
                    this.analyticsGridOptions.next(arrayResult);
                    //toogle models area
                  },
                  () => {}
                );
            }
          } else {
            this.analyticsGridOptions.next(null);
          }
        }
      );
  }

  ngAfterViewInit(): void {}

  calculatePercentageValue(rowData: number) {
    return rowData.toFixed(8);
  }
  /**
   *  Use this method to add specific css for specific cells in grids.
   */

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "date") {
      columnDataEntry.format = "dd-MMM-yyyy";
    }

    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined) {
        if (value > 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.systemSettings.NonPercentageValuesDecimalPoints
          );
          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");

          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let floatNumber = parseFloat(textDisplayed).toFixed(
            this.systemSettings.NonPercentageValuesDecimalPoints
          );

          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value === -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }

        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.systemSettings.NonPercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
      }
    }

    //asset category goes here
    if ((event.rowType = "data")) {
      let text: string = event.text;
      if (text !== undefined) {
        if (text.search("/*")) {
          console.error(text);
        }
      }
    }

    if (event.rowType == "header") {
      if (event.columnIndex == 0) {
        if (event.column.dataField === "AssetCategory") {
          event.cellElement.classList.add(
            "regulatory-limit-grid-columns-categories"
          );
        } else {
          event.cellElement.classList.add("regulatory-limit-grid-columns");
        }
      } else {
        event.cellElement.classList.add("regulatory-limit-grid-columns");
      }
    }
  }

  numberWithCommas(numberToModify: any) {
    var parts = numberToModify.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  calculateGridHeight() {
    return 0.65 * window.screen.height + "px";
  }

  getAssetCategory(assetClass) {
    let key = assetClass.key;
    let item = this.assetClassesDataSource.find(
      (i) => i.key === assetClass.key
    );
    if (!item) {
      const holdingsDate = assetClass.data.HoldingsDate;
      const startDate = holdingsDate;
      const endDate = holdingsDate;
      const investorId = assetClass.data.InvestorId;
      const instrumentId = assetClass.data.InstrumentId;
      const includeCash = this.IncludeCash;
      const tenantId = this.TenantId;
      let token = this.oidcSecurityService.getToken();

      item = {
        key: key,
        dataSourceInstance: new DataSource({
          load: (loadOptions) => {
            return this.http
              .get(
                this.apiUrl +
                  `/analytics/GetAssociatedPerformanceAttribution?instrumentType=2` +
                  "&startDate=" +
                  startDate +
                  "&endDate=" +
                  endDate +
                  "&investorId=" +
                  investorId +
                  "&instrumentId=" +
                  instrumentId +
                  "&includeCash=" +
                  includeCash +
                  "&TenantId=" +
                  tenantId,
                { headers: { Authorization: "Bearer " + token } }
              )
              .toPromise()
              .then((response) => {
                return {
                  data: response,
                };
              })
              .catch(() => {
                throw "Data loading error";
              });
          },
        }),
      };
      this.assetClassesDataSource.push(item);
    }
    return item.dataSourceInstance;
  }
  getAssetLevel(assetCategory) {
    let key = assetCategory.data.Id;
    let item = this.assetLevelDataSource.find(
      (i) => i.key === assetCategory.data.Id
    );
    if (!item) {
      const holdingsDate = assetCategory.data.HoldingsDate;
      const startDate = holdingsDate;
      const endDate = holdingsDate;
      const investorId = assetCategory.data.InvestorId;
      const instrumentId = assetCategory.data.InstrumentId;
      const instrumentType = assetCategory.data.InstrumentType;
      const includeCash = this.IncludeCash;
      const tenantId = this.TenantId;
      const token = this.oidcSecurityService.getToken();

      item = {
        key: key,
        dataSourceInstance: new DataSource({
          load: (loadOptions) => {
            return this.http
              .get(
                this.apiUrl +
                  "/analytics/GetAssociatedPerformanceAttribution?instrumentType=" +
                  instrumentType +
                  "&startDate=" +
                  startDate +
                  "&endDate=" +
                  endDate +
                  "&investorId=" +
                  investorId +
                  "&instrumentId=" +
                  instrumentId +
                  "&IsAssetLevel=" +
                  true +
                  "&includeCash=" +
                  includeCash +
                  "&TenantId=" +
                  tenantId,
                { headers: { Authorization: "Bearer " + token } }
              )
              .toPromise()
              .then((response) => {
                return {
                  data: response,
                };
              })
              .catch(() => {
                throw "Data loading error";
              });
          },
        }),
      };
      this.assetLevelDataSource.push(item);
    }
    return item.dataSourceInstance;
  }
  /**
   * Bad coding Hacks*
   * @param assetCategory AssetCategory
   */
  getAssetLevelTotalFund(assetCategory) {
    let key = assetCategory.data.InstrumentId + ":" + assetCategory.data.Date;
    let item = this.performanceAttributionDataSource.find(
      (i) =>
        i.key ===
        assetCategory.data.InstrumentId + ":" + assetCategory.data.Date
    );
    if (!item) {
      const holdingsDate = assetCategory.data.Date;
      const startDate = holdingsDate;
      const endDate = holdingsDate;
      const investorId = assetCategory.data.InvestorId;
      const instrumentId = assetCategory.data.InstrumentId;
      const includeCash = this.IncludeCash;
      const tenantId = this.TenantId;
      const token = this.oidcSecurityService.getToken();

      item = {
        key: key,
        dataSourceInstance: new DataSource({
          load: (loadOptions) => {
            return this.http
              .get(
                this.apiUrl +
                  "/analytics/GetAssociatedPerformanceAttribution?instrumentType=" +
                  2 +
                  "&startDate=" +
                  startDate +
                  "&endDate=" +
                  endDate +
                  "&investorId=" +
                  investorId +
                  "&instrumentId=" +
                  instrumentId +
                  "&IsAssetLevel=" +
                  true +
                  "&includeCash=" +
                  includeCash +
                  "&TenantId=" +
                  tenantId,
                { headers: { Authorization: "Bearer " + token } }
              )
              .toPromise()
              .then((response) => {
                return {
                  data: response,
                };
              })
              .catch(() => {
                throw "Data loading error";
              });
          },
        }),
      };
      this.performanceAttributionDataSource.push(item);
    }
    return item.dataSourceInstance;
  }
  PerformanceAttributionOnRowPrepared(info) {
    if (info.rowType === "data") {
      if (info.data.AssetCategory === "Summary") {
      }
    }
  }

  /**
   * OBSOLETE
   * @param e the grid
   */
  totalFundGridselectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  getTemplateNoDataText() {
    return "Results will show here";
  }

  customizeLabel(arg) {
    let valueText = arg.originalValue;
    if (valueText !== 0) {
      return arg.argumentText + " (" + valueText.toFixed(2) + "%)";
    } else {
      return "";
    }
  }
  summaryPieChartTitle() {
    return this.investorIdObservable;
  }

  onContentReady(e) {
    var visibleRowsCount = e.component.totalCount();
    var pageSize = e.component.pageSize();
    if (visibleRowsCount > pageSize) visibleRowsCount = pageSize;
    var totalCount = e.component.option("dataSource").length;
    e.component.option(
      "pager.infoText",
      "Displaying " + visibleRowsCount + " of " + totalCount + " records"
    );
  }
}
