<div class="non-portfolio-asset-categories-models">
    <div *ngFor="let modelGroup of ModelGroupItems" class="model-group-item">
        <dx-tree-list [dataSource]="modelGroup.ModelItems" keyExpr="ID" parentIdExpr="Head_ID"
            [allowColumnResizing]="true" [allowColumnReordering]="true" [showBorders]="true" [width]="200"
            (onSelectionChanged)="ModelGroupsItemsSelected($event, modelGroup.ModelGroupName)"
            noDataText="no data to display..." [selectedRowKeys]="SelectedModelkeys" class="tree-list"
            [highlightChanges]="true" [repaintChangesOnly]="true" [focusedRowEnabled]="true" [showBorders]="true"
            (onNodesInitialized)="clearChildrenSelection()">

            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple">
                <!-- or "multiple" | "none" -->
            </dxo-sorting>
            <!-- For uniqueness, The storage key comprises of the selected asset category id -->
            <dxo-state-storing [enabled]="true" type="sessionStorage" [storageKey]="AssetCategory.id + 'storage'">
            </dxo-state-storing>
            <dxi-column dataField="ModelName" sortOrder="asc" caption={{modelGroup.ModelGroupName}}>
            </dxi-column>
        </dx-tree-list>
    </div>
</div>