import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dates-selector',
  templateUrl: './dates-selector.component.html',
  styleUrls: ['./dates-selector.component.scss']
})
export class DatesSelectorComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public formOptions: any,
    public dialogRef: MatDialogRef<DatesSelectorComponent>, ) { }

  ngOnInit() {
  }

}
