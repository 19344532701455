import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
	selector: 'app-models-container-directive',
	templateUrl: './models-container-directive.component.html',
	styleUrls: ['./models-container-directive.component.scss']
})
export class ModelsContainerDirectiveComponent implements OnInit, AfterViewInit {

	@Input() GridKeyExpression: string = undefined;

	constructor() { }

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		throw new Error('Method not implemented.');
	}


}
