import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from "@angular/core";
import { ToolbarStrings } from "../../app-services/strings/strings.service";
import {
  DxLookupComponent,
  DxDateBoxComponent,
  DxDataGridComponent,
  DxTabPanelComponent,
  DxSwitchComponent,
  DxTreeListComponent,
  DxDropDownBoxComponent,
} from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { ApiService } from "src/app/app-services/api/api.service";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Subscription } from "rxjs";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { AssetRegistryService } from "./asset-registry.service";
import { TenantService } from "src/app/app-services/TenantService/tenant.service";
import { NonInnovaClientsAssetRegistryService } from "./non-innova-clients-asset-registry.service";
import dxDropDownBox from "devextreme/ui/drop_down_box";

export class AssetRegistryConfig {
  assetCategories: Array<any>;
}

@Component({
  selector: "app-asset-registry",
  templateUrl: "./asset-registry.component.html",
  styleUrls: ["./asset-registry.component.scss"],
})
export class AssetRegistryComponent
  implements OnInit, AfterViewInit, OnChanges
{
  /**
   * Tool bar specific UI names
   */
  toolBarStrings: ToolbarStrings;
  SideMenuCollapsedReference = false;
  UpdateTitleForArabic = false;
  GridKeyExpression: any;
  gridDataSource: any;
  cashGridDataSource: any;
  cashColumns: any;
  stockHoldingGridDataSource: any;
  purchasesHoldingGridDataSource: any;
  salesHoldingGridDataSource: any;
  stockHoldingColumns: any;
  purchasesHoldingColumns: any;
  salesHoldingColumns: any;
  billsHoldingColumns: any;
  billsPurchasesHoldingColumns: any;
  billsSalesHoldingColumns: any;
  tBondssHoldingColumns: any;
  tBondsPurchasesColumns: any;
  tBondsSalesColumns: any;
  tBondsHoldingGridDataSource: any;
  tBondsPurchasesHoldingDataSource: any;
  tBondsSalesHoldingGridDataSource: any;

  corporateBondssHoldingColumns: any;
  corporateBondsPurchasesColumns: any;
  corporateBondsSalesColumns: any;
  corporateBondsHoldingDataSource: any;
  corporateBondsPurchasesHoldingDataSource: any;
  corporateBondsSalesHoldingDataSource: any;

  floatingCorporateBondssHoldingColumns: any;
  floatingCorporateBondsPurchasesColumns: any;
  floatingCorporateBondsSalesColumns: any;
  floatingCorporateBondsHoldingDataSource: any;
  floatingCorporateBondsPurchasesHoldingDataSource: any;
  floatingCorporateBondsSalesHoldingDataSource: any;

  commercialPaperHoldingsTitle: any;
  commercialPaperPurchasesTitle: any;
  commercialPaperSalesTitle: any;
  cpHoldingGridDataSource: any;
  cpHoldingColumns: any;
  cpPurchasesHoldingGridDataSource: any;
  cpPurchasesHoldingColumns: any;
  cpSalesHoldingGridDataSource: any;
  cpSalesHoldingColumns: any;
  equityHoldingsTitle: string;
  equityPurchasesTitle: string;
  billsHoldingsTitle: string;
  billsPurchasesTitle: string;
  billsSalesTitle: string;
  tBondsHoldingsTitle: string;
  tBondsPurchasesTitle: string;
  tBondsSalesTitle: string;
  corporateBondsHoldingsTitle: string;
  corporateBondsPurchasesTitle: string;
  corporateBondsSalesTitle: string;
  floatingCorporateBondsHoldingsTitle: string;
  floatingCorporateBondsPurchasesTitle: string;
  floatingCorporateBondsSalesTitle: string;
  formattedStartDate: any;
  formattedEndDate: any;
  equitySalesTitle: any;
  fixedDepositsHoldingsTitle: any;
  callDepositHoldingsTitle: any;
  investorsDataSource: any;
  callDepositDataSource: any;
  callDepositColumns: any;
  callDepositPurchasesDataSource: any;
  callDepositPurchasesColumns: any;
  callDepositSalesDataSource: any;
  callDepositSalesColumns: any;
  investorDataSource: any;
  fixedDepositHoldingDataSource: any;
  fixedDepositPurchasesHoldingDataSource: any;
  fixedDepositSalesHoldingDataSource: any;
  fixedDepositHoldingColumns: any;
  fixedDepositPurchasesHoldingColumns: any;
  fixedDepositSalesHoldingColumns: any;
  billsHoldingGridDataSource: any;
  billsPurchasesHoldingGridDataSource: any;
  billsSalesHoldingGridDataSource: any;
  investorAccountDataSource: any;
  InvestorNotSelected: boolean = true;
  InvestorAccountNotSelected: boolean = true;
  showSampleGrid: boolean = true;
  fetchedAssetClasses: any;
  tabResult: any;
  activeTenantName: string;
  activeTenantRegulator: string;
  FundsFundManagerDataSource: any;
  treeBoxValue: string;
  selectedFund: string = null;
  selectedInvestorId: string = null;
  selectedTenantId: string = null;
  investorAccountLookupSubscription: Subscription = undefined;

  @ViewChild("InvestorLookup", { static: false })
  investorLookupInstance: DxLookupComponent;
  @ViewChild("InvestorAccountLookup", { static: false })
  investorAccountLookupInstance: DxLookupComponent;
  @ViewChild("startDateBox", { static: true })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("endDateBox", { static: true })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("assetRegistryDisplayDataGrid", { static: false })
  displayGridInstance: DxDataGridComponent;
  @ViewChild("assetRegistryMenuItemsPanel", { static: false })
  assetRegistryMenuItemsPanel: DxTabPanelComponent;
  @ViewChild("assetCategoriesPanel", { static: false })
  assetCategoriesPanel: DxTabPanelComponent;
  @ViewChild("callDepositPanel", { static: false })
  callDepositPanel: DxTabPanelComponent;
  @ViewChild("fixedDepositPanel", { static: false })
  fixedDepositPanel: DxTabPanelComponent;
  @ViewChild("timeDepositPanel", { static: false })
  timeDepositPanel: DxTabPanelComponent;
  @ViewChild("equitiesPanel", { static: false })
  equitiesPanel: DxTabPanelComponent;
  @ViewChild("HideTraversalSwitch", { static: false })
  HideTraversalSwitch: DxSwitchComponent;
  @ViewChild("fundManagerTreeList", { static: false })
  fundManagerTree: DxTreeListComponent;
  @ViewChild("fundDropdown", { static: false })
  funddropdownInstance: DxDropDownBoxComponent;

  public _apiFetchSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    public appSettingsService: ApplicationSettingsService,
    public matSnackBar: MatSnackBar,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public notificationService: SnackNotificationsService,
    private assetRegistryService: AssetRegistryService,
    private tenantResolverService: TenantService,
    private nonInnovaClientsAssetRegistryService: NonInnovaClientsAssetRegistryService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Fund Manager Portfolio Asset Registry"
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.EntityDataArray;
    if (currentItem.currentValue) {
      this.displayGridInstance.dataSource = [...currentItem.currentValue];
    }
  }

  ngOnInit() {
    //resolve the current tenant
    this.activeTenantName = this.tenantResolverService.getTenantName();
    this.activeTenantRegulator =
      this.tenantResolverService.getTenantRegulatorName();

    //Initialize default dates
    this.appSettingsService.GetSettings().subscribe((settings) => {     
      this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    });

    //InvestorLookup initialize datasource
    //InvestorLookup initialize datasource
    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      //for other reports
      //added MasterFund Property as a quickfix, to solve the recurive issue, master fund was being taken as a fund when selected
      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
          Masterfund: true,
          FundManagerID: fundManager.Id,
        });

        //for the case where master fund not is null
        fundManager.MasterFunds.forEach((masterFund) => {
          if (masterFund.Id != null) {
            nodes.push({
              Name: masterFund.Name,
              ID: masterFund.Id,
              Head_ID: fundManager.Id,
              TenantId: fundManager.TenantId,
              Masterfund: true,
              FundManagerID: fundManager.Id,
            });
          }

          if (masterFund.Id != null) {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: masterFund.Id,
                TenantId: fundManager.TenantId,
                Masterfund: false,
                FundManagerID: fundManager.Id,
              });
            });
          } else {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: fundManager.Id,
                TenantId: fundManager.TenantId,
                masterFund: false,
                FundManagerID: fundManager.Id,
              });
            });
          }
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });

    //Initialize Tab Names
    this.updateTabNames();
  }

  ngAfterViewInit(): void {}

  InvestorSelectionChanged(event) {
    if (event.selectedRowsData.length !== 0) {
      if (event.selectedRowsData[0].Masterfund == true) {
      } else {
        this.selectedInvestorId = event.selectedRowsData[0].ID;
        this.selectedTenantId = event.selectedRowsData[0].TenantId;
        const investorName = event.selectedRowsData[0].Name;
        //get Investor Account Data and populate the DxLookup
        this.investorAccountLookupSubscription = this.assetRegistryService
          .getInvestorAccountsById(
            this.selectedInvestorId,
            this.selectedTenantId
          )
          .subscribe((result) => {
            this.investorAccountDataSource = result;
            //select 1st by default
            if (result.length != 0) {
              this.investorAccountLookupInstance.value = result[0].ID;
            }
            this.funddropdownInstance.value = investorName;
            this.funddropdownInstance.instance.close();
          });
      }
    }
  }

  fetchResults() {
    //update tab Names
    this.updateTabNames();

    //get data
    this.getCashHoldingsData();
    this.getStockHoldingsData();
    this.getFixedDepositHoldingData();
    this.getCallDepositHoldingData();
    this.getTreasuryBondsHoldingData();
    this.getCorporateBondsHoldingData();
    this.getFloatingCorporateBondsHoldingData();
    this.getTreasuryBillstHoldingData();
    this.getCommercialPapertHoldingData();
  }

  getTreasuryBillstHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;
      this.billsHoldingGridDataSource = [];
      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
        instrumentCategoryCode: "treasury bills",
      };

      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getTreasuryBillsHoldings(requestPayload)
          .subscribe((result) => {
            this.billsHoldingGridDataSource = result[0].TabData;
          });

        //populate the columns
        this.billsHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getBillsHoldingsColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getTreasuryBillsHoldings(requestPayload)
          .subscribe((result) => {
            this.billsHoldingGridDataSource = result[0].TabData;
            this.billsPurchasesHoldingGridDataSource = result[1].TabData;
            this.billsSalesHoldingGridDataSource = result[2].TabData;
          });

        //populate the columns
        this.billsHoldingColumns =
          this.assetRegistryService.getBillsHoldingsColumns();
        this.billsPurchasesHoldingColumns =
          this.assetRegistryService.getBillsPurchasesHoldingsColumns();
        this.billsSalesHoldingColumns =
          this.assetRegistryService.getBillsSaleHoldingsColumns();
      }
    }
  }

  getTreasuryBondsHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;
      this.tBondsHoldingGridDataSource = [];
      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
        instrumentCategoryCode: "fixed treasury bonds",
      };

      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getTreasuryBondsHoldings(requestPayload)
          .subscribe((result) => {
            this.tBondsHoldingGridDataSource = result[0].TabData;
          });

        //populate the columns
        this.tBondssHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getTbondsHoldingsColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getTreasuryBondsHoldings(requestPayload)
          .subscribe((result) => {
            this.tBondsHoldingGridDataSource = result[0].TabData;
            this.tBondsPurchasesHoldingDataSource = result[1].TabData;
            this.tBondsSalesHoldingGridDataSource = result[2].TabData;
          });

        //populate the columns
        this.tBondssHoldingColumns =
          this.assetRegistryService.getTbondsHoldingsColumns();
        this.tBondsPurchasesColumns =
          this.assetRegistryService.getTbondsSaleHoldingsColumns();
        this.tBondsSalesColumns =
          this.assetRegistryService.getTbondsSaleHoldingsColumns();
      }
    }
  }

  getCorporateBondsHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
        instrumentCategoryCode: "fixed corporate bonds",
      };
      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getCorporateBondsHoldings(requestPayload)
          .subscribe((result) => {
            this.corporateBondsHoldingDataSource = result[0].TabData;
          });

        //populate the columns
        this.corporateBondssHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getCorporateBondsHoldingsColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getCorporateBondsHoldings(requestPayload)
          .subscribe((result) => {
            this.corporateBondsHoldingDataSource = result[0].TabData;
            this.corporateBondsPurchasesHoldingDataSource = result[1].TabData;
            this.corporateBondsSalesHoldingDataSource = result[2].TabData;
          });

        //populate the columns
        this.corporateBondssHoldingColumns =
          this.assetRegistryService.getCorporateBondsHoldingsColumns();
        this.corporateBondsPurchasesColumns =
          this.assetRegistryService.getCorporateBondsPurchasesColumns();
        this.corporateBondsSalesColumns =
          this.assetRegistryService.getcorporateBondsSaleColumns();
      }
    }
  }

  getFloatingCorporateBondsHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
        instrumentCategoryCode: "floating corporate bonds",
      };
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        this.assetRegistryService
          .getFloatingCorporateBondsHoldingsData(requestPayload)
          .subscribe((result) => {
            this.floatingCorporateBondsHoldingDataSource = result[0].TabData;
          });

        //populate the columns
        this.floatingCorporateBondssHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getFloatingCorporateBondsHoldingsColumns();
      } else {
        this.assetRegistryService
          .getFloatingCorporateBondsHoldingsData(requestPayload)
          .subscribe((result) => {
            this.floatingCorporateBondsHoldingDataSource = result[0].TabData;
            this.floatingCorporateBondsPurchasesHoldingDataSource =
              result[1].TabData;
            this.floatingCorporateBondsSalesHoldingDataSource =
              result[2].TabData;
          });

        //populate the columns
        this.floatingCorporateBondssHoldingColumns =
          this.assetRegistryService.getFloatingCorporateBondsHoldingsColumns();
        this.floatingCorporateBondsPurchasesColumns =
          this.assetRegistryService.getFloatingCorporateBondsPurchasesColumns();
        this.floatingCorporateBondsSalesColumns =
          this.assetRegistryService.getFloatingCorporateBondsSaleColumns();
      }
      //populate the datasources
    }
  }

  getCommercialPapertHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
      };

      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getCommercialPaperHoldings(requestPayload)
          .subscribe((result) => {
            this.cpHoldingGridDataSource = result.CommercialPapersHoldings;
          });

        //populate the columns
        this.cpHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getCpHoldingsColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getCommercialPaperHoldings(requestPayload)
          .subscribe((result) => {
            this.cpHoldingGridDataSource = result.CommercialPapersHoldings;
            this.cpPurchasesHoldingGridDataSource =
              result.CommercialPapersPurchases;
            this.cpSalesHoldingGridDataSource = result.CommercialPapersSales;
          });

        //populate the columns
        this.cpHoldingColumns =
          this.assetRegistryService.getCpHoldingsColumns();
        this.cpPurchasesHoldingColumns =
          this.assetRegistryService.getCPHoldingsPurchasesColumns();
        this.cpSalesHoldingColumns =
          this.assetRegistryService.getCPHoldingSalesColumns();
      }
    }
  }

  getCashHoldingsData() {
    if (
      this.selectedInvestorId == null ||
      this.investorAccountLookupInstance.value == null
    ) {
      this.notificationService.ShowWarningNotification(
        "Select an Investor and an Investor Account"
      );
      return;
    } else {
      this.cashGridDataSource = [];
      this.InvestorAccountNotSelected = false;
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;
      let cashAccountId = this.investorAccountLookupInstance.value;
      let showReversals = this.HideTraversalSwitch.value;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorid: investorId,
        tenantId: this.selectedTenantId,
        showReversals: showReversals,
        cashAccountId: cashAccountId,
      };

      this.assetRegistryService
        .getCashTransactions(requestPayload)
        .subscribe((res) => {
          this.cashGridDataSource = res;
        });

      this.cashColumns = this.assetRegistryService.getCashColumns();
    }
  }

  updateTabNames() {
    //Initialize Tab Names
    this.formattedStartDate = this.formatDateToString(
      this.startDateBoxInstance.value
    );
    this.formattedEndDate = this.formatDateToString(
      this.endDateBoxInstance.value
    );
    this.equityHoldingsTitle = "Equity Holdings as at " + this.formattedEndDate;
    this.equityPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.equitySalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.fixedDepositsHoldingsTitle =
      "Fixed Deposits Holdings as at " + this.formattedEndDate;
    this.callDepositHoldingsTitle =
      "Call Deposits Holdings as at " + this.formattedEndDate;
    this.billsHoldingsTitle =
      "Treasury Bill Holdings as at " + this.formattedEndDate;
    this.billsPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.billsSalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.tBondsHoldingsTitle =
      "Treasury Bonds Holdings as at " + this.formattedEndDate;
    this.tBondsPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.tBondsSalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.corporateBondsHoldingsTitle =
      "Corporate Bonds Holdings as at " + this.formattedEndDate;
    this.corporateBondsPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.corporateBondsSalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    //
    this.floatingCorporateBondsHoldingsTitle =
      "Floating Corporate Bonds Holdings as at " + this.formattedEndDate;
    this.floatingCorporateBondsPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.floatingCorporateBondsSalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    //
    this.commercialPaperHoldingsTitle =
      "Commercial Paper Holdings as at " + this.formattedEndDate;
    this.commercialPaperPurchasesTitle =
      "Purchases Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
    this.commercialPaperSalesTitle =
      "Sales Between " +
      this.formattedStartDate +
      " and " +
      this.formattedEndDate;
  }

  getStockHoldingsData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorId: investorId,
        tenantId: this.selectedTenantId,
      };
      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        this.stockHoldingGridDataSource = [];
        //innova clients
        this.assetRegistryService
          .getStocksHoldings(requestPayload)
          .subscribe((result) => {
            this.stockHoldingGridDataSource = result.StocksHoldings;
          });

        //populate the columns
        this.stockHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getEquityHoldingsColumns();
      } else {
        this.assetRegistryService
          .getStocksHoldings(requestPayload)
          .subscribe((result) => {
            this.stockHoldingGridDataSource = result.StocksHoldings;
            this.purchasesHoldingGridDataSource = result.StocksPurchases;
            this.salesHoldingGridDataSource = result.StocksSales;
          });

        //populate the columns
        this.stockHoldingColumns =
          this.assetRegistryService.getEquityHoldingsColumns();
        this.purchasesHoldingColumns =
          this.assetRegistryService.getPurchasesHoldingsColumns();
        this.salesHoldingColumns =
          this.assetRegistryService.getSaleHoldingsColumns();
      }
    }
  }

  getFixedDepositHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      this.fixedDepositHoldingDataSource = [];

      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorId: investorId,
        tenantId: this.selectedTenantId,
      };

      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getFixedDepositHoldings(requestPayload)
          .subscribe((result) => {
            this.fixedDepositHoldingDataSource = result.FixedDepositsHoldings;
            this.fixedDepositPurchasesHoldingDataSource =
              result.FixedDepositsPurchases;
            this.fixedDepositSalesHoldingDataSource = result.FixedDepositsSales;
          });

        //populate the columns
        this.fixedDepositHoldingColumns =
          this.nonInnovaClientsAssetRegistryService.getFixedDepositHoldingColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getFixedDepositHoldings(requestPayload)
          .subscribe((result) => {
            this.fixedDepositHoldingDataSource = result.FixedDepositsHoldings;
          });

        //populate the columns
        this.fixedDepositHoldingColumns =
          this.assetRegistryService.getFixedDepositHoldingColumns();
        this.fixedDepositPurchasesHoldingColumns =
          this.assetRegistryService.getFixedDepositPurchasesHoldingColumns();
        this.fixedDepositSalesHoldingColumns =
          this.assetRegistryService.getFixedDepositSalesHoldingColumns();
      }
    }
  }

  getCallDepositHoldingData() {
    if (this.selectedInvestorId == null) {
      this.notificationService.ShowWarningNotification("Select an Investor");
      return;
    } else {
      let startDate = this.startDateBoxInstance.value;
      let endDate = this.endDateBoxInstance.value;
      let investorId = this.selectedInvestorId;
      this.callDepositDataSource = [];

      let requestPayload = {
        startDate: startDate,
        endDate: endDate,
        investorId: investorId,
        tenantId: this.selectedTenantId,
      };
      // non innova clients
      if (
        this.activeTenantName == "SEC Zambia" ||
        this.activeTenantRegulator == "zambia-sec"
      ) {
        //populate the datasources
        this.assetRegistryService
          .getCallDepositHoldings(requestPayload)
          .subscribe((result) => {
            this.callDepositDataSource = result.CallDepositsHoldings;
          });

        //populate the columns
        this.callDepositColumns =
          this.nonInnovaClientsAssetRegistryService.getCallDepositHoldingColumns();
      } else {
        //populate the datasources
        this.assetRegistryService
          .getCallDepositHoldings(requestPayload)
          .subscribe((result) => {
            this.callDepositDataSource = result.CallDepositsHoldings;
            this.callDepositPurchasesDataSource = result.CallDepositsPurchases;
            this.callDepositSalesDataSource = result.CallDepositsSales;
          });

        //populate the columns
        this.callDepositColumns =
          this.assetRegistryService.getCallDepositHoldingColumns();
        this.callDepositPurchasesColumns =
          this.assetRegistryService.getCallDepositPurchasesColumns();
        this.callDepositSalesColumns =
          this.assetRegistryService.getCallDepositSalesColumns();
      }
    }
  }

  calculatePaneldHeight() {
    return 0.55 * window.screen.height + "px";
  }

  //format the Date to string
  formatDateToString(date: any): any {
    let dateInstance = new Date(date);

    let formatedDate = dateInstance
      .toDateString()
      .split(" ")
      .slice(1)
      .join(" ");

    return formatedDate;
  }

  treeList_itemSelectionChanged(e) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }
}
