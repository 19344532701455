import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
	selector: 'app-fixed-treasury-bond',
	templateUrl: './fixed-treasury-bond.component.html',
	styleUrls: ['./fixed-treasury-bond.component.scss']
})
export class FixedTreasuryBondComponent implements OnInit {

	gridDataSource: any;
	EntityName = 'Fixed Treasury Bonds';
	GridKeyExpression = 'ID';
	Selection = { mode: 'single'}
	Columns = [
		{ caption: 'Code', dataField: 'Code', width: 200, cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'asc' },
		{ caption: 'Payments Per Year', dataField: 'PaymentsPerYear' },
		{ caption: 'Fixed Bond Pricing Mode', dataField: 'FixedBondPricingMode' },
		{ caption: 'Account Number', width: 200, dataField: 'AccountNumber'},
		{ caption: 'Issue Date', dataField: 'IssueDate', dataType: 'date'},
		{ caption: 'MaturityDate', dataField: 'MaturityDate', dataType: 'date'},
		{ caption: 'Actual Maturity Date', dataField: 'ActualMaturityDate', dataType: 'date'},
		{ caption: 'SecurityType', dataField: 'SecurityType' },
		{ caption: 'Issue Price', dataField: 'IssuePrice'},
		{ caption: 'Issue Yield', dataField: 'IssueYield'},
		{ caption: 'Issue Value', dataField: 'IssueValue'},
		{ caption: 'Coupon Value', dataField: 'CouponValue'}
	];

	constructor(private apiService: ApiService, private title: Title,private navBarSettingsService: NavbarHeaderSettingsService) {
		this.navBarSettingsService.ChangeActiveComponentName('Fixed Treasury Bonds')
	 }

	ngOnInit() {
		this.apiService.Get('FixedTreasuryBonds').subscribe(result => this.gridDataSource = result);
	}

	rowDoubleClicked(event: any) {

	}


}
