import { ViewChild } from "@angular/core";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  DxLookupComponent,
  DxSwitchComponent,
  DxTabPanelComponent,
} from "devextreme-angular";
import { DxTreeListComponent } from "devextreme-angular/ui/tree-list";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { ReportService } from "../report.service";
import {
  ReportEndUserSpecification,
  ReportGroupItem,
  ReportGroupItemsGenerator,
  ReportItem,
} from "./helper-classes";

@Component({
  selector: "app-report-groups-view",
  templateUrl: "./report-groups-view.component.html",
  styleUrls: ["./report-groups-view.component.scss"],
})
export class ReportGroupsViewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() ReportGroupItems: Array<ReportGroupItem>;
  @Input() PerfomanceMeasurementReports: Array<ReportGroupItem>;
  @ViewChildren(DxTreeListComponent)
  ReportCategoriesChildren: QueryList<DxTreeListComponent>;
  @ViewChild(DxTreeListComponent, { static: false })
  reportGroupData: DxTreeListComponent;
  @ViewChild("reportCategoriesPanel", { static: false })
  reportCategoriesPanelInstance: DxTabPanelComponent;
  @ViewChild("assetClassLookupInstance", { static: false })
  assetClassLookupInstance: DxLookupComponent;
  @ViewChild("summarySwitch", { static: false })
  summarySwitch: DxSwitchComponent;
  @ViewChild("includeCashSwitch", { static: false })
  IncludeCashSwitch: DxSwitchComponent;
  selectedReportCategoryName: any;

  //subsciptions
  public _assetClassSubscription: Subscription;
  public _modelsSubscription: Subscription = undefined;

  @Output() ReportItemSelected: EventEmitter<ReportItem> = new EventEmitter();
  @Output() ReportCategorySelected: EventEmitter<string> = new EventEmitter();
  @Output() AssetClassSelected: EventEmitter<string> = new EventEmitter();
  @Output() SummarySwitchValue: EventEmitter<boolean> = new EventEmitter();
  @Output() IncludeCashPortfolioValutionValue: EventEmitter<boolean> =
    new EventEmitter();
  @Input() SelectedFundId: any = null;
  @Input() SelectedTenantId: any = null;

  constructor(
    public notificationService: SnackNotificationsService,
    private apiService: ApiService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    let reportGroupItems = new ReportGroupItemsGenerator().generate();
    let perfomanceMeasurementReports =
      new ReportGroupItemsGenerator().generatePerfomanceMeasurementReports();
    this.ReportGroupItems = reportGroupItems;
    this.PerfomanceMeasurementReports = perfomanceMeasurementReports;
  }

  ngOnDestroy(): void {
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.reportCategoriesPanelInstance.onSelectionChanged.subscribe((res) => {
      //get the selected report category
      this.selectedReportCategoryName =
        this.reportCategoriesPanelInstance.selectedItem.title;
      this.ReportCategorySelected.emit(this.selectedReportCategoryName);
    });

    this.summarySwitch.onValueChanged.subscribe((res) => {
      let summarySwitchValue = this.summarySwitch.value;
      this.SummarySwitchValue.emit(summarySwitchValue);
    });

    this.IncludeCashSwitch.onValueChanged.subscribe((res) => {
      let includeCashSwitchValue = this.IncludeCashSwitch.value;
      this.IncludeCashPortfolioValutionValue.emit(includeCashSwitchValue);
    });

    this.assetClassLookupInstance.onSelectionChanged.subscribe((res) => {
      let selectedAssetClass = this.assetClassLookupInstance.selectedItem;
      this.AssetClassSelected.emit(selectedAssetClass);
    });
  }

  ngOnChanges(): void {
    //Only in Perfomance Attribution Reports
    if (this.selectedReportCategoryName == "Perfomance Attribution Reports") {
      if (this.SelectedFundId != null && this.SelectedTenantId != null) {
        //get Instrument classes
        this._assetClassSubscription = this.reportService
          .getAssetClassesByInvestorId(
            this.SelectedFundId,
            this.SelectedTenantId
          )
          .subscribe((res) => {
            this.assetClassLookupInstance.dataSource = res;
          });
      }
    }
  }

  gOnDestroy(): void {
    if (this._assetClassSubscription !== undefined)
      this._assetClassSubscription.unsubscribe();
  }

  showMoreInfoOnHover(event) {
    //todo find something to do when a model is hovered upon
    // if (event.data !== undefined) {
    //   console.log(event.data);
    // }
  }

  onCellPrepared(e) {
    if (e.rowType === "data") {
    }
  }

  ModelGroupsItemsSelected(event, reportGroupName) {
    if (event.data) {
      this.ReportItemSelected.emit(event.data);
      this.ReportCategoriesChildren.toArray().forEach((x) => {
        x.instance.deselectAll();
      });
    }
  }

  updateCaption(modelGroupName, modelItemsCount) {
    return modelGroupName + ` (${modelItemsCount})`;
  }
}
