import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app-services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DataFormComponent, DataFormOptions } from '../../../app-directives/data-form/data-form.component';
import CustomStore from 'devextreme/data/custom_store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';
import { forkJoin, from, Observable } from 'rxjs';
import { concatMap, map, switchMap, toArray } from 'rxjs/operators'; 
import { BenchmarksService } from './benchmarks.service';

@Component({
  selector: 'app-bench-marks-view',
  templateUrl: './bench-marks-view.component.html',
  styleUrls: ['./bench-marks-view.component.scss']
})

export class BenchMarksViewComponent implements OnInit {
  gridDataSource: any;
  EntityName = 'Benchmarks';
  GridKeyExpression = 'Name';
  public Columns: any = [
    { caption: 'Name', dataField: 'Name', cssClass: 'grid-focus-column', alignment: 'left', sortOrder: 'acs' },
    { caption: 'Benchmark Type', dataField: 'BenchmarkTypeData.Name' },
    { caption: 'Cycle', dataField: 'Cycle' },
    { caption: 'Annual Percentage Value', dataField: 'AnnualPercentageValue', dataType: 'boolean' },
    { caption: 'Active', dataField: 'Active', dataType: 'boolean' },
    { caption: 'Approved', dataField: 'Approved', dataType: 'boolean' },
  ];

  CyclesEnum: any;
  formItems: Array<any>;
  loadedCharacter: any;

  constructor(private apiService: ApiService, public matDialog: MatDialog, private title: Title,private navBarSettingsService: NavbarHeaderSettingsService, private benchmarksService: BenchmarksService) {
    this.navBarSettingsService.ChangeActiveComponentName('Benchmarks')
    this.CyclesEnum = {
      Anually: 1,
      BiAnnually: 2,
      Daily: 3,
      Monthly: 4,
      Quaterly: 5,
      Weekly: 6,
      properties: [
        { id: 0, description: 'Zero' },
        { id: 1, description: 'Anually' },
        { id: 2, description: 'BiAnnually' },
        { id: 3, description: 'Daily' },
        { id: 4, description: 'Monthly' },
        { id: 5, description: 'Quaterly' },
        { id: 6, description: 'Weekly' },
      ]
    };


    this.formItems = [
      {
        label: { text: 'Name' },
        dataField: 'Name',
        validationRules: [{
          type: 'required',
          message: 'Name is required'
        }],
        cssClass: 'required-form-item',
        editorType: 'dxTextBox',
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true
        },

      },
      //todo: remove this when the API is updated not to accept the same.
      {
        dataField: 'BenchMarkTypeId',
        cssClass: 'required-form-item',
        validationRules: [{
          type: 'required',
          message: 'Benchmark Type is required'
        }],
        editorType: 'dxLookup',
        editorOptions: {
          dataSource: new CustomStore({
            key: 'ID',
            load: (loadOption) => {
              if (loadOption.searchExpr === 'Name') {
                if (loadOption.searchValue !== null) {
                  return this.apiService.Get('BenchmarkTypes/Get/FindByName?name=' + loadOption.searchValue).toPromise();
                }
              }
              return this.apiService.Get('BenchmarkTypes/Get').toPromise();
            },
            byKey: (key) => {
              return this.apiService.Get('BenchmarkTypes/GetById?id=' + key).toPromise();
            }
          }),
          valueExpr: 'ID',
          displayExpr: 'Name'
        },
      
        label: {
          text: 'Benchmark Type'
        }
      },
      {
        dataField: 'Cycle',
        editorType: 'dxSelectBox',
        editorOptions: {
          dataSource: this.CyclesEnum.properties,
          valueExpr: 'id',
          displayExpr: 'description'
        },
        validationRules: [{
          type: 'required',
          message: 'Cycle is required',
        }],
        cssClass: 'required-form-item',
        label: {
          text: 'Cycle'
        }
      },
      {
        colSpan: 2,
        dataField: 'Notes',
        editorType: 'dxTextArea',
        editorOptions: {
          height: 90
        }
      },
      {
        dataField: 'AnnualPercentageValue',
        editorType: 'dxCheckBox',
      },
      {
        dataField: 'Active',
        editorType: 'dxCheckBox',
      },
      {
        dataField: 'Approved',
        editorType: 'dxCheckBox',
      }];
  }

  ngOnInit() {
    //get enchMarks Data
    let benchMarks$ = this.benchmarksService.loadBenchMarks();
    let benchMarkType$ = this.benchmarksService.loadBenchMarkTypes();

    benchMarks$.pipe(
      switchMap(benchMarks => {
          //const benchMarksTypeIds = benchMarks.map(a => a.BenchMarkTypeId);
  
          return benchMarkType$.pipe(
              map(benchmarkTypes => {
                benchMarks.forEach(a => {
                      a.BenchmarkTypeData = benchmarkTypes.find(u => u.ID === a.BenchMarkTypeId)
                      delete benchMarks.BenchMarkTypeId;
                  });
  
                  return benchMarks;
              })
          );
      })
  ).subscribe(benchMarkData => {
      this.gridDataSource = benchMarkData;
  });
}


  rowDoubleClicked(rowData: any) {

    let benchmarkId = rowData.ID;

    this.apiService.Get(`Benchmarks/GetById?id=${benchmarkId}`).subscribe(benchmark => {

      let dataFormOptions = new DataFormOptions();
      dataFormOptions.formData = benchmark;
      dataFormOptions.formItems = this.formItems;
      dataFormOptions.formTitle = 'Edit Benchmark';
      dataFormOptions.postEndPoint = 'Benchmarks';
      let dialogRef = this.matDialog.open(DataFormComponent,
        { width: '800px', data: dataFormOptions });
    });

  }

  AddNew(event: any) {

    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = 'New Benchmark';
    dataFormOptions.postEndPoint = 'Benchmarks';
    let dialogRef = this.matDialog.open(DataFormComponent,
      { width: '800px', data: dataFormOptions });
    
      /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });

  }
}
