<div class="document-dialog">
	<div mat-dialog-content>
		<app-markdown text="{{data}}"></app-markdown>
	</div>
	<div class="documentation-actions-section">
		<div mat-dialog-actions>
			<button mat-flat-button cdkFocusInitial (click)="closeDialog()">Close</button>
		</div>
		<div>
			<div>
				<span>Found this information helpful?</span>
			</div>
			<div>
				<app-star-rating [rating]="rating" [starCount]="starCount" [color]="starColor"
					(ratingUpdated)="onRatingChanged($event)"></app-star-rating>
			</div>
		</div>
	</div>
</div>