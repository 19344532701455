import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class EquitiesImporterService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportStockPurchases(file: any): Observable<any> {
    return this.http
      .post<any>(`${this.url}/StocksFilesImport/StocksPurchasesImport`, file)
      .pipe(
        // map((res) => res["Data"]),
        shareReplay()
      );
  }

  ImportStockSales(file: any): Observable<any> {
    return this.http
      .post<any>(`${this.url}/StocksFilesImport/StocksSalesImport`, file)
      .pipe(shareReplay());
  }

  ImportStockHoldings(file: any): Observable<any> {
    return this.http
      .post<any>(`${this.url}/StocksFilesImport/StocksHoldingsImport`, file)
      .pipe(shareReplay());
  }

  SaveStockPurchases(payload: any): Observable<any> {
    return this.apiService
      .Post("StocksFilesImport/SaveParsedStockPurchases", payload)
      .pipe(shareReplay());
  }

  SaveStockSales(payload: any): Observable<any> {
    return this.apiService
      .Post("StocksFilesImport/SaveParsedStockSales", payload)
      .pipe(shareReplay());
  }

  SaveStockHoldings(payload: any): Observable<any> {
    return this.apiService
      .Post("StocksFilesImport/SaveParsedStocksHoldings", payload)
      .pipe(shareReplay());
  }

  getStockPurchasesColumns(): any {
    let stockPurchasesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "ISIN", dataField: "StockISIN" },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "SharesVolume" },
      { caption: "Cash Account", dataField: "InvestorCashAccountNumber" },
      {
        caption: "Purchase Date",
        dataField: "PurchaseDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Levies", dataField: "Levies" },
      { caption: "Commissions", dataField: "Commissions" },
      { caption: "Consideration", dataField: "Consideration" },
      { caption: "Reference", dataField: "Reference" },
    ];
    return stockPurchasesColumns;
  }

  getStockSalesColumns(): any {
    let salesColumns = [
      {
        caption: "Stock Name",
        dataField: "StockName",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Investor Name", dataField: "InvestorName" },
      {
        caption: "ISIN",
        dataField: "StockISIN",
        alignment: "left",
        width: 120,
      },
      { caption: "Trade Price", dataField: "TradePrice", width: 120 },
      { caption: "Share Volume", dataField: "SharesVolume" },
      { caption: "Cash Account", dataField: "InvestorCashAccountNumber" },
      {
        caption: "Sale Date",
        dataField: "SaleDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      {
        caption: "Settlement Date",
        dataField: "SettlementDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Currency", dataField: "Currency" },
      { caption: "Sale Value", dataField: "SaleValue" },
      { caption: "Levies", dataField: "Levies" },
      { caption: "Commissions", dataField: "Commissions" },
      { caption: "Consideration", dataField: "Consideration" },
      { caption: "Sale Ticket", dataField: "Reference" },
    ];

    return salesColumns;
  }

  getStockHoldingsColumns(): any {
    let holdingsColumns = [
      {
        caption: "Security ISIN",
        dataField: "SecurityISIN",
        cssClass: "performance-attr-grid-focus-3",
        alignment: "left",
        sortOrder: "asc",
        width: 200,
      },
      { caption: "Currency Symbol", dataField: "CurrencySymbol" },
      {
        caption: "Holdings Date",
        dataField: "HoldingsDate",
        dataType: "date",
        format: "dd MMM yyyy",
      },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Market Price", dataField: "MarketPrice" },
      { caption: "Share Volume", dataField: "ShareVolume" },
      { caption: "Purchase Value", dataField: "PurchaseValue" },
      { caption: "Cumulative Cost", dataField: "CumulativeCost" },
      { caption: "Market Value", dataField: "MarketValue" },
    ];

    return holdingsColumns;
  }
}
