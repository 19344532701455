import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-release-info-view',
  templateUrl: './release-info-view.component.html',
  styleUrls: ['./release-info-view.component.scss']
})
export class ReleaseInfoViewComponent implements OnInit {

  notes: string[]
  constructor(public dialogRef: MatDialogRef<ReleaseInfoViewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.notes = this.data.notes;
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close()
  }


}
