import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('rating') rating: number;
  // tslint:disable-next-line: no-input-rename
  @Input('starCount') starCount: number;
  // tslint:disable-next-line: no-input-rename
  @Input('color') color: string;
  @Output() ratingUpdated = new EventEmitter();

  snackBarDuration = 2000;
  ratingArr = [];

  constructor(private snackBar: MatSnackBar) {
  }


  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating: number) {
    let ratingSnacRef = this.snackBar.open('Thank you. Your feedback will be submitted when you close this dialog.', 'Clear rating?', { duration: this.snackBarDuration });

    ratingSnacRef.afterDismissed().subscribe(matSnackBarDismiss => {
      if (matSnackBarDismiss.dismissedByAction === true) {
        this.ratingUpdated.emit(0);
      }
    });

    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}
export enum StarRatingColor {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn'
}
