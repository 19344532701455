import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChildren,
  QueryList,
  OnDestroy,
} from "@angular/core";
import {
  ModelGroupItem,
  TestInstrumentCategory,
  AssetCategorySecurities,
  ModelItem,
  ModelVariable,
  NonPortfolioAnalysisInputModel,
  ModelClassification,
  RenderButtonConfig,
} from "../non-portfolio-analytics/helper-classes";
import { DxTreeListComponent } from "devextreme-angular";
import { Subject, Subscription, Observable } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import * as _ from "underscore";
import { ApiService } from "src/app/app-services/api/api.service";
import { ModelOutput } from "./model-output";
import {
  NonPortolioUpdaterService,
  AssetCategoryModelsOutput,
} from "../updater/non-portfilio-updater.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectedModelGroupItems } from "../models-request-description/selected-model-group-items";
import { LocalStorageService } from "src/app/app-services/services/local-storage.service";

@Component({
  selector: "app-models-view",
  templateUrl: "./models-view.component.html",
  styleUrls: ["./models-view.component.scss"],
})
export class ModelsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ModelGroupItems: Array<ModelGroupItem>;
  @Input() AssetCategory: TestInstrumentCategory;
  @Input() AssetCategorySelectionChanged: boolean;
  @Input() Securities: Array<AssetCategorySecurities>;
  @Input() StartDate: Date;
  @Input() EndDate: Date;
  @Input() ModelParameters: Array<ModelVariable>;
  @Input() SelectedModelkeys: Array<string>;
  @Input() RenderButton: Observable<any> = new Observable<any>();

  @ViewChildren(DxTreeListComponent)
  modelGroupsChildren: QueryList<DxTreeListComponent>;
  ModelsSubject: Subject<Array<ModelItem>> = new Subject<Array<ModelItem>>();
  ModelsObservables = this.ModelsSubject.asObservable();
  SelectedBondRiskIds: Array<any> = [];
  SelectedModelAnalyticsIds: Array<any> = [];
  SelectedVariables: Array<any> = [];

  _renderButtonSubscription: Subscription = undefined;
  modelsSelectionSubscription: Subscription = undefined;
  _apiServiceSubscriton: Subscription = undefined;
  _clicEventSubscription: Subscription = undefined;
  _MacrosAPISubscription: Subscription = undefined;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    public updaterService: NonPortolioUpdaterService,
    public notificationService: SnackNotificationsService,
    public dialiog: MatDialog
  ) {
    this.updaterService.changeRenderButtonName("Refresh");

    //Update the render button display value
    this.updaterService.renderButtonEventObservable$.subscribe((config) => {
      if (config.cancelRequest === true) {
        if (this._apiServiceSubscriton !== undefined) {
          this._apiServiceSubscriton.unsubscribe();
          this.updaterService.changeRenderButtonName("Refresh");
        }
      }
    });
  }

  ngAfterViewInit(): void {
    /**------------------------------- (a) Render Buttom click event ---------------------------------------- */
    let combinedItems = this.updaterService.renderButtonEventObservable$.pipe(
      withLatestFrom(this.ModelsObservables)
    );
    this._clicEventSubscription = combinedItems.subscribe((value) => {
      let [buttonConfig, models] = value;
      let catstedButtonConfig: RenderButtonConfig = buttonConfig;
      let castedModels: Array<ModelItem> = models;

      if (
        this.AssetCategory.id === catstedButtonConfig.SelectedAssetCategoryId
      ) {
        if (catstedButtonConfig.cancelRequest === true) {
          return;
        } else {
          // TODO: Check the securities list is added properly
          let securitiesList = this.Securities.map((item) => item.Id);
          /**---------------------------------------- (b) if models and securities are not selected  --------------------------------- */
          if (securitiesList.length === 0 && castedModels.length == 0) {
            this.notificationService.ShowErrorNotification(
              `Select at least one security and model(s) to show results`
            );
            this.updaterService.focusOnSecurities(true);
          } else {
            /**---------------------------------------- (c) if either models and securities is not selected  --------------------------------- */
            if (securitiesList.length === 0) {
              this.notificationService.ShowErrorNotification(
                `Select at least one security to continue...`
              );
              this.updaterService.focusOnSecurities(true);
            } else {
              this.updaterService.focusOnSecurities(false);

              if (castedModels.length !== 0) {
                //there is more than one item, filter
                let assetCategoryModelGroups =
                  this.modelGroupsChildren.toArray();
                let allSelectedModels = assetCategoryModelGroups.map(
                  (element) => {
                    let data: Array<ModelItem> =
                      element.instance.getSelectedRowsData();
                    return data;
                  }
                );
                let flattenedModelsArray = [].concat.apply(
                  [],
                  allSelectedModels
                );
                if (flattenedModelsArray.length === 0)
                  this.notificationService.ShowErrorNotification(
                    "Select model(s) first"
                  );
                else {
                  //Fetch the model results here
                  this.fetchModelsResults(
                    flattenedModelsArray,
                    catstedButtonConfig.weightOption
                  );
                }
              } else {
                this.notificationService.ShowErrorNotification(
                  `Select at least one model in ${this.AssetCategory.text} to continue...`
                );
              }
            }
          }
        }
      }
    });
    this.ModelsSubject.next([]);
    /**-------------------------------Render Buttom click event ---------------------------------------- */
  }

  ngOnInit() {}

  fetchModelsResults(
    selectedModels: Array<ModelItem>,
    weightingOption: string
  ) {
    if (selectedModels.length !== 0) {
      //Selected Model Analytics ID's
      //filter bond risk id's by modelgroupname
      let selectedModelAnalytics = selectedModels.filter((x) => {
        if (x.ModelGroupName !== "Bond Risk Analysis") {
          return x;
        }
      });

      //map the selected Model Analytics if the array is not empty
      if (selectedModelAnalytics.length > 0) {
        //map the selected bond Risk Ids
        let selectedModelAnaltyticIds = _.uniq(
          selectedModelAnalytics.map((x) => x.UniqueModelId)
        );
        this.SelectedModelAnalyticsIds = selectedModelAnaltyticIds;
      } else {
        this.SelectedModelAnalyticsIds = [];
      }

      //Selected Bond Risk ID's
      //filter bond risk id's by modelgroupname
      let selectedBondRisk = selectedModels.filter((x) => {
        if (x.ModelGroupName == "Bond Risk Analysis") {
          return x;
        }
      });

      //map the selected Bond risks if the array is not empty
      if (selectedBondRisk.length > 0) {
        //map the selected bond Risk Ids
        let selectedBondRiskId = _.uniq(
          selectedBondRisk.map((x) => x.UniqueModelId)
        );
        this.SelectedBondRiskIds = selectedBondRiskId;
        //create a unique array in the case where both market price and Yield rate are selected(_.uniq)
      } else {
        this.SelectedBondRiskIds = [];
      }

      const groupedSelectedModelsInput = new Array<any>();
      let parentModels = _.chain(selectedModelAnalytics)
        .filter(
          (model) =>
            model.ModelClassification === ModelClassification.ParentModel
        )
        .map((value) => value)
        .value();
      let parameterModels = _.chain(selectedModelAnalytics)
        .filter(
          (model) => model.ModelClassification === ModelClassification.Parameter
        )
        .map((value) => value)
        .value();
      if (parentModels.length !== 0) {
        parentModels.forEach((model) => {
          // * the model could have one or more pameters
          _.each(model.ModelParameters, (parameter) => {
            //deep copy
            let ModelClone = Object.assign({}, model);
            ModelClone.ModelValue = parameter;
            groupedSelectedModelsInput.push(ModelClone);
          });
        });
      }

      if (parameterModels.length !== 0) {
        groupedSelectedModelsInput.push(...parameterModels);
      }

      /*************************************************** Model request decription modal ******************************************/
      let modelsRequestDecription: Array<SelectedModelGroupItems> = _.chain(
        groupedSelectedModelsInput
      )
        .groupBy((model: ModelItem) => model.ModelGroupName)
        .map(function (value, key) {
          return {
            ModelGroupName: key.toString(),
            Models: _.map(value, (model) => {
              if (model.ModelClassification === ModelClassification.Parameter)
                return model.ParentModelName;
              else return model.ModelName;
            }),
          };
        })
        .value();

      let groupedSelectedModels: Array<any> = _.chain(
        groupedSelectedModelsInput
      )
        .groupBy((model) => model.ModelValue)
        .map(function (value, key) {
          return {
            Name: key,
            Models: _.map(value, (model) => model.UniqueModelId),
          };
        })
        .value();

      let assetCategoryId = this.AssetCategory.id;
      let assetCategoryName = this.AssetCategory.text.toLowerCase();

      //* dates transform
      let requestStartDate = this.StartDate;
      let requestEndDate = this.EndDate;
      //* dates transform

      let selectedSecurities = this.Securities.map((item) => item.Id);
      let selectedSecuritiesNames = this.Securities.map((x) => x.SecurityName);

      //The response data of Macros is different
      if (assetCategoryName === "macro-economic variables") {
        //Models variables
        let selectedParameters = selectedModelAnalytics.map(
          (x) => x.ModelParameters
        );
        if (selectedParameters[0] == null) {
          let selectedVariables = selectedModels.map((x) => x.ModelValue);
          let selectedUniqueVariables = _.uniq(selectedVariables);
          this.SelectedVariables = selectedUniqueVariables;
        } else {
          this.SelectedVariables = selectedParameters[0];
        }

        let macrosRequestPayload = {
          StartDate: requestStartDate,
          EndDate: requestEndDate,
          Variables: this.SelectedVariables,
          Countries: [...selectedSecurities],
          Models: this.SelectedModelAnalyticsIds,
        };

        this._MacrosAPISubscription = this.apiService
          .Post("analytics/macroanalysis", macrosRequestPayload)
          .subscribe((macrosResponseOutput: Array<any>) => {
            let macrosOutput =
              this.ProcessMacrosRequestAPI(macrosResponseOutput);
            let assetCatModelOutputs: AssetCategoryModelsOutput = {
              AssetCategoryId: this.AssetCategory.id,
              ModelOutputs: macrosOutput,
              weightingOption: weightingOption,
            };
            this.updaterService.pushGridChanges(assetCatModelOutputs);
          });
      } else {
        let requestPayload: NonPortfolioAnalysisInputModel = {
          StartDate: requestStartDate,
          EndingDate: requestEndDate,
          Securities: selectedSecurities,
          InstrumentCategory: assetCategoryId,
          ModelParameters: [...groupedSelectedModels],
          ModelDependency: "geometric mean",
          RiskFreeRateReturn: 0.1,
          investorId: null,
          Weighted: weightingOption,
          RiskFreeRateIsAnnualized: 1,
          DaysInYear: 365,
        };

        let bondRiskPayload = {
          StartDate: requestEndDate,
          EndDate: requestEndDate,
          InstrumentCategoryCode: assetCategoryName,
          Funds: [],
          Models: this.SelectedBondRiskIds,
          YieldChange: 0.02,
          Securities: selectedSecuritiesNames,
        };

        this.updaterService.changeRenderButtonName("Cancel");

        this._apiServiceSubscriton = this.apiService
          .Post("analytics/nonportfolioanalysis", requestPayload)
          .subscribe(
            (modelOutputs: Array<ModelOutput>) => {
              this.updaterService.changeRenderButtonName("Done");
              //cache data for AI
              this.localStorageService.setItem(
                "macro-security-analysis",
                modelOutputs
              );
              console.log(modelOutputs);

              let thisAssetCategoryId = this.AssetCategory.id;

              if (
                this.SelectedModelAnalyticsIds.length > 0 &&
                this.SelectedBondRiskIds.length == 0
              ) {
                let assetCatModelOutputs: AssetCategoryModelsOutput = {
                  AssetCategoryId: thisAssetCategoryId,
                  ModelOutputs: modelOutputs,
                  weightingOption: weightingOption,
                };
                this.updaterService.pushGridChanges(assetCatModelOutputs);
              } else if (
                this.SelectedModelAnalyticsIds.length > 0 &&
                this.SelectedBondRiskIds.length > 0
              ) {
                //make api request
                this.apiService
                  .Post("analytics/bondriskanalysis", bondRiskPayload)
                  .subscribe((portfolioBondRiskAnalysisResponse: any) => {
                    let bondRiskModelOutput =
                      this.ProcessBondRiskAnalysisRequestAPI(
                        portfolioBondRiskAnalysisResponse
                      );
                    //loop through the ModelOutput where the parameter Name is Returns
                    //Search the security of Keys, if matches insert it's value in modelCategory array

                    modelOutputs.forEach((element) => {
                      let parameterName = element.ParameterName;
                      if (parameterName == "Returns") {
                        let securitiesData = element.Securities;
                        securitiesData.forEach((element) => {
                          let triggered = false; //to solve the bug of bond risk being inserted more than once
                          let securityName = element.Name.toUpperCase();
                          let modelCategoriesData = element.ModelCategories;
                          modelCategoriesData.forEach((element) => {
                            for (const key in bondRiskModelOutput) {
                              if (key == securityName && triggered == false) {
                                modelCategoriesData.push(
                                  bondRiskModelOutput[key][0]
                                );
                                triggered = true;
                              }
                            }
                          });
                        });
                      }
                    });
                    let assetCatModelOutputs: AssetCategoryModelsOutput = {
                      AssetCategoryId: thisAssetCategoryId,
                      ModelOutputs: modelOutputs,
                      weightingOption: weightingOption,
                    };
                    this.updaterService.pushGridChanges(assetCatModelOutputs);
                  });
              } else if (
                this.SelectedModelAnalyticsIds.length == 0 &&
                this.SelectedBondRiskIds.length > 0
              ) {
                this.apiService
                  .Post("analytics/bondriskanalysis", bondRiskPayload)
                  .subscribe((portfolioBondRiskAnalysisResponse: any) => {
                    let bondRiskModelResponse = this.ProcessBondRiskAnalysis(
                      portfolioBondRiskAnalysisResponse
                    );
                    let assetCatModelOutputs: AssetCategoryModelsOutput = {
                      AssetCategoryId: thisAssetCategoryId,
                      ModelOutputs: bondRiskModelResponse,
                      weightingOption: weightingOption,
                    };
                    this.updaterService.pushGridChanges(assetCatModelOutputs);
                  });
              }
            },
            (error) => {
              this.updaterService.changeRenderButtonName("Refresh");
            },
            () => {
              this.updaterService.changeRenderButtonName("Refresh");
            }
          );
      }
    }
  }

  ProcessBondRiskAnalysisRequestAPI(bondRiskData): any {
    if (bondRiskData.Successful == false) {
      this.notificationService.ShowErrorNotification(bondRiskData.ErrorMessage);
    } else {
      let resultsData = [];
      //check if contains Data
      let containsData: boolean =
        this.ContainsSecurityBondRiskData(bondRiskData);
      if (containsData) {
        let securityBondRiskData = bondRiskData[0].SecuritiesBondRiskAnalysis;
        let result: Array<any> = [];

        securityBondRiskData.forEach((element) => {
          let Results = [];
          let securityName = element.Security;
          let modelCategorizations = element.ModelCategorizations;

          modelCategorizations.forEach((element) => {
            let modelName = element.Model;
            let modelData: Array<any> = element.ModelData;

            modelData.forEach((element) => {
              let modelValue = element.Value;
              Results.push({
                Model: modelName,
                Value: modelValue,
              });
            });
          });
          //format the end Date
          let endDateFormatted = new Date(this.EndDate);
          let lastDate = endDateFormatted
            .toDateString()
            .split(" ")
            .slice(1)
            .join(" ");

          resultsData.push({
            Name: `Bond Risk Analysis (as at ${lastDate})`,
            Security: securityName,
            Results,
          });
        });
      }

      //group by security Name
      let groupedModelBySecurity = _.mapObject(
        _.groupBy(resultsData, "Security"),
        (clist) => clist.map((resultsData) => _.omit(resultsData, "Security"))
      );
      return groupedModelBySecurity;
    }
  }
  ProcessBondRiskAnalysis(bondRiskData): any {
    if (bondRiskData.Successful == false) {
      this.notificationService.ShowErrorNotification(bondRiskData.ErrorMessage);
    } else {
      //check if contains Data
      let containsData: boolean =
        this.ContainsSecurityBondRiskData(bondRiskData);
      if (containsData) {
        let securityBondRiskData = bondRiskData[0].SecuritiesBondRiskAnalysis;
        let modelOutput: Array<any> = [];
        let bondRiskModelOutput: Array<any> = [];

        securityBondRiskData.forEach((element) => {
          let Results = [];
          let resultsData = [];
          let tenorRange = "";
          let timeToMaturity = NaN;
          let securityName = element.Security;
          let modelCategorizations = element.ModelCategorizations;
          let dataSourceObject = {};

          modelCategorizations.forEach((element) => {
            let modelName = element.Model;
            let modelData: Array<any> = element.ModelData;

            modelData.forEach((element) => {
              tenorRange = element.TenorRange;
              timeToMaturity = element.TimeToMaturity;
              let modelValue = element.Value;

              Results.push({
                Model: modelName,
                Value: modelValue,
              });
            });
          });
          //format the end Date
          let endDateFormatted = new Date(this.EndDate);
          let lastDate = endDateFormatted
            .toDateString()
            .split(" ")
            .slice(1)
            .join(" ");

          resultsData.push({
            Name: `Bond Risk Analysis (as at ${lastDate})`,
            Results,
          });

          dataSourceObject = {
            Name: securityName,
            Sector: tenorRange,
            ModelCategories: resultsData,
            Grouping: tenorRange,
            GroupingDescription: "Tenor",
            TimeToMaturity: timeToMaturity,
          };

          modelOutput.push(dataSourceObject);
        });
        let finalDataSource = {
          ParameterName: "Returns",
          Securities: modelOutput,
        };
        bondRiskModelOutput.push(finalDataSource);
        //console.log(finalDataSource);
        return bondRiskModelOutput;
      }
    }
  }
  ContainsSecurityBondRiskData(data: any): boolean {
    if (typeof data === "object") {
      let values = data[0].SecuritiesBondRiskAnalysis;
      if (values.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  ProcessMacrosRequestAPI(macrosResponseOutput): any {
    if (macrosResponseOutput.Successful == false) {
      this.notificationService.ShowErrorNotification(
        macrosResponseOutput.ErrorMessage
      );
    } else {
      let modelOutputsData: Array<any> = [];
      let finalModelOutputData: Array<any> = [];
      macrosResponseOutput.forEach((element) => {
        let country = element.Country;
        let macroVariableResults = element.MacroVariableResults;
        macroVariableResults.forEach((element) => {
          let SecurityDataOutput: Array<any> = [];
          let variable = element.Variable;
          let modelGroupResults = element.ModelGroupResults;
          modelGroupResults.forEach((element) => {
            let modelResultsOutput: Array<any> = [];
            let modelGroup = element.ModelGroup;
            let modelResults = element.ModelResults;
            modelResults.forEach((element) => {
              let model = element.Model;
              let value = element.Value;
              modelResultsOutput.push({
                Model: model,
                Value: value,
              });
            });
            SecurityDataOutput.push({
              Name: modelGroup,
              Results: modelResultsOutput,
            });
          });

          modelOutputsData.push({
            ParameterName: variable,
            Securities: [
              {
                Name: country,
                ModelCategories: SecurityDataOutput,
              },
            ],
          });
        });
      });

      //group by parameterName
      let groupedModelByParameter = _.mapObject(
        _.groupBy(modelOutputsData, "ParameterName"),
        (clist) =>
          clist.map((resultsData) => _.omit(resultsData, "ParameterName"))
      );

      //console.log(groupedModelByParameter);

      //merge the securities array
      for (const [key, value] of Object.entries(groupedModelByParameter)) {
        //loop through the value
        let securityArray = [];
        value.forEach((element) => {
          let security = element.Securities[0];
          securityArray.push(security);
        });

        finalModelOutputData.push({
          ParameterName: key,
          Securities: securityArray,
        });
      }

      return finalModelOutputData;
    }
  }

  resetSelectedModels() {
    this.SelectedBondRiskIds = [];
    this.SelectedModelAnalyticsIds = [];
  }

  ngOnDestroy(): void {
    if (this._renderButtonSubscription !== undefined)
      this._renderButtonSubscription.unsubscribe();
    if (this.modelsSelectionSubscription !== undefined)
      this.modelsSelectionSubscription.unsubscribe();
    if (this._apiServiceSubscriton !== undefined)
      this._apiServiceSubscriton.unsubscribe();
    if (this._clicEventSubscription !== undefined)
      this._clicEventSubscription.unsubscribe();
    if (this._MacrosAPISubscription !== undefined)
      this._MacrosAPISubscription.unsubscribe();
  }

  ModelGroupsItemsSelected() {
    let assetCategoryModelGroups = this.modelGroupsChildren.toArray();

    let allSelectedModels = assetCategoryModelGroups.map((element) => {
      let data: Array<ModelItem> = element.instance.getSelectedRowsData();
      return data;
    });
    let flattenedModelsArray = [].concat.apply([], allSelectedModels);
    this.ModelsSubject.next(flattenedModelsArray);
  }

  clearChildrenSelection() {
    this.modelGroupsChildren.toArray().forEach((x) => {
      x.instance.clearSelection();
    });
  }

  calculateDisplayValueInColumn(event: ModelItem) {}
}
