import { Component, OnInit } from "@angular/core";

import { ApiService } from "src/app/app-services/api/api.service";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { FundManager, Investor } from "./investors";
import { Observable, of } from "rxjs";
import { InvestorService } from "src/app/app-services/services/investor.service";
import CustomStore from "devextreme/data/custom_store";
import {
  DataFormComponent,
  DataFormOptions,
} from "src/app/app-directives/data-form/data-form.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-investors",
  templateUrl: "./investors.component.html",
  styleUrls: ["./investors.component.scss"],
})
export class InvestorsComponent implements OnInit {
  gridDataSource$: Observable<any>;
  columns: any[];
  EntityName = "Investors";

  Columns: Array<any> = [
    { dataField: "FundManager", caption: "FundManager", groupIndex: 0 },
    { dataField: "Name", caption: "Investor" },
    { dataField: "InvestorType", caption: "Investor Type" },
    { dataField: "InvestorSubType", caption: "Investor Sub Type" },
    { dataField: "NumberOfMembers", caption: "Number of Members" },
    { dataField: "Country", caption: "Country" },
    { dataField: "ValuationCurrency", caption: "Valuation Currency" },
    { dataField: "PhoneNumber", caption: "Phone Number" },
    { dataField: "PhysicalAddress", caption: "Physical Address" },
    { dataField: "Active", caption: "Active", dataType: "boolean" },
    { dataField: "Approved", caption: "Approved", dataType: "boolean" },
  ];

  formItems = [
    {
      label: { text: "Name" },
      dataField: "Name",
      validationRules: [
        {
          type: "required",
          message: "Name is required",
        },
      ],
      cssClass: "required-form-item",
      editorType: "dxTextBox",
      editorOptions: {
        activeStateEnabled: true,
        showClearButton: true,
      },
    },
    {
      dataField: "InvestorSubTypeId",
      cssClass: "required-form-item",
      validationRules: [
        {
          type: "required",
          message: "investor Sub Type is required",
        },
      ],
      editorType: "dxLookup",
      editorOptions: {
        dataSource: new CustomStore({
          key: "ID",
          load: (loadOption) => {
            return this.apiService.Get("InvestorSubTypes").toPromise();
          },
          byKey: (key) => {
            return this.apiService
              .Get("InvestorSubTypes/GetById?id=" + key)
              .toPromise();
          },
        }),
        valueExpr: "Id",
        displayExpr: "Name",
      },
      label: {
        text: "Investor Sub Type",
      },
    },
    {
      dataField: "CountryId",
      cssClass: "required-form-item",
      validationRules: [
        {
          type: "required",
          message: "Country is required",
        },
      ],
      editorType: "dxLookup",
      editorOptions: {
        dataSource: new CustomStore({
          key: "ID",
          load: (loadOption) => {
            if (loadOption.searchExpr === "Name") {
              if (loadOption.searchValue !== null) {
                return this.apiService
                  .Get("Countries/FindByName?name=" + loadOption.searchValue)
                  .toPromise();
              }
            }
            return this.apiService.Get("Countries/Get").toPromise();
          },
          byKey: (key) => {
            return this.apiService
              .Get("Countries/GetById?id=" + key)
              .toPromise();
          },
        }),
        valueExpr: "ID",
        displayExpr: "Name",
      },
      label: {
        text: "Country",
      },
    },
    {
      label: { text: "Number of Members" },
      dataField: "NumberOfMembers",
      editorType: "dxNumberBox",
      editorOptions: {
        activeStateEnabled: true,
        showClearButton: true,
      },
    },
    {
      dataField: "ValuationCurrencyId",
      cssClass: "required-form-item",
      validationRules: [
        {
          type: "required",
          message: "Valuation Currency is required",
        },
      ],
      editorType: "dxLookup",
      editorOptions: {
        dataSource: new CustomStore({
          key: "ID",
          load: (loadOption) => {
            if (loadOption.searchExpr === "Name") {
              if (loadOption.searchValue !== null) {
                return this.apiService
                  .Get("Currencies/FindByName?name=" + loadOption.searchValue)
                  .toPromise();
              }
            }
            return this.apiService.Get("Currencies").toPromise();
          },
          byKey: (key) => {
            return this.apiService
              .Get("Currencies/GetById?id=" + key)
              .toPromise();
          },
        }),
        valueExpr: "ID",
        displayExpr: "Name",
      },
      label: {
        text: "Currency",
      },
    },
    {
      label: { text: "Phone Number" },
      dataField: "PhoneNumber",
      editorType: "dxTextBox",
      editorOptions: {
        activeStateEnabled: true,
        showClearButton: true,
      },
    },
    {
      label: { text: "Email Address" },
      dataField: "EmailAddress",
      editorType: "dxTextBox",
      editorOptions: {
        activeStateEnabled: true,
        showClearButton: true,
      },
    },
    {
      label: { text: "Physical Address" },
      dataField: "PhysicalAddress",
      editorType: "dxTextBox",
      editorOptions: {
        activeStateEnabled: true,
        showClearButton: true,
      },
    },
    {
      dataField: "BankId",
      editorType: "dxLookup",
      editorOptions: {
        dataSource: new CustomStore({
          key: "ID",
          load: (loadOption) => {
            if (loadOption.searchExpr === "Name") {
              if (loadOption.searchValue !== null) {
                return this.apiService
                  .Get("Banks/FindByName?name=" + loadOption.searchValue)
                  .toPromise();
              }
            }
            return this.apiService.Get("Banks").toPromise();
          },
          byKey: (key) => {
            return this.apiService.Get("Banks/GetById?id=" + key).toPromise();
          },
        }),
        valueExpr: "ID",
        displayExpr: "Name",
      },
      label: {
        text: "Bank",
      },
    },
    {
      dataField: "Notes",
      editorType: "dxTextArea",
      editorOptions: {
        height: 70,
        placeholder: "Notes...",
      },
    },
    {
      dataField: "Benchmarks",
      editorType: "dxTagBox",
      showClearButton: true,
      editorOptions: {
        dataSource: new CustomStore({
          key: "ID",
          load: () => {
            return this.apiService.Get("Benchmarks/Get").toPromise();
          },
          byKey: (key) => {
            let params = new HttpParams();

            params = params.append("id", key);
            return this.apiService
              .Get("Benchmarks/GetById?id=" + key)
              .toPromise();
          },
        }),
        //valueExpr: "ID",
        displayExpr: "Name",
        placeholder: "Select one or more Benchmarks...",
        height: 70,
      },
    },
    {
      dataField: "Active",
      editorType: "dxCheckBox",
    },
    {
      dataField: "Approved",
      editorType: "dxCheckBox",
    },
  ];

  constructor(
    private investorService: InvestorService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    private apiService: ApiService,
    public matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.navBarSettingsService.ChangeActiveComponentName("Investors");

    const investorsFetch$ = this.investorService.loadInvestors();

    investorsFetch$.subscribe((investorsData) => {
      this.gridDataSource$ = of(investorsData);
    });
  }
  AddNew(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `New Investor`;
    dataFormOptions.postEndPoint = "Investors";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = rowData;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `Edit Investor - ${rowData.Name}`;
    dataFormOptions.postEndPoint = "Investors";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((res) => {
      this.ngOnInit();
    });
  }
}
