<dx-tab-panel [showNavButtons]="true" style="background-color: rgba(255, 255, 255, 0.336);" [activeStateEnabled]="true">
    <dxi-item class="tabpanel-item" title="Regulatory Compliance">
        <div style="margin-top: 4px;">
            <app-regulatory-compliance></app-regulatory-compliance>
        </div>
    </dxi-item>
    <dxi-item class="tabpanel-item" title="Portfolio Rebalancing">
        <div style="margin-top: 4px;">
            <app-portfolio-rebalancing-report></app-portfolio-rebalancing-report>
        </div>
    </dxi-item>
</dx-tab-panel>