import {
  Component,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges,
  SimpleChange,
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";

/**
 * @description Provides a global DataGrid Component to use in your components to load grid data.
 */
@Component({
  selector: "app-data-grid",
  templateUrl: "./data-grid.component.html",
  styleUrls: ["./data-grid.component.scss"],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class DataGridComponent implements AfterViewInit, OnChanges {
  /**@description The entity name whose data is being rendered on the UI */
  @Input() EntityIdentifier: string = undefined;
  @Input() EntityDataArray: Array<any>;
  @Input() EntityColumns: any;
  @Input() GridKeyExpression: string = undefined;
  @Input() GridColumnSummaries: any = undefined;
  @Input() selection: any = undefined;
  @Input() scrollingMode: any = undefined;
  @Input() noDataText: string = undefined;
  @Input() PageSize15 = false;
  @Input() GridHeight: number = undefined;

  @Input() ColumnResizingMode: string = undefined;
  @Output() EditEvent: EventEmitter<any> = new EventEmitter();
  @Output() CellPrepared: EventEmitter<any> = new EventEmitter();
  @ViewChild("listGridView", { static: true })
  GridInstance: DxDataGridComponent;

  systemSettings: ApplicationSettings;
  constructor(public appSettingsService: ApplicationSettingsService) {
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.EntityColumns) {
      const currentItem: SimpleChange = changes.EntityColumns;
      if (currentItem.currentValue) {
        this.GridInstance.columns = [...currentItem.currentValue];
      }
    }
    if (changes.EntityDataArray) {
      const currentItem = changes.EntityDataArray;
      if (currentItem.currentValue) {
        this.GridInstance.dataSource = [...currentItem.currentValue];
      }
    }
  }

  ngAfterViewInit(): void {
    this.ColumnResizingMode = this.ColumnResizingMode;

    if (this.selection !== undefined)
      this.GridInstance.selection = this.selection;

    if (this.EntityColumns !== undefined)
      this.GridInstance.columns = this.EntityColumns;
    // if (this.EntityDataArray !== undefined)
    this.GridInstance.dataSource = this.EntityDataArray;
    this.GridInstance.export = {
      allowExportSelectedData: true,
      enabled: true,
      fileName: this.EntityIdentifier,
    };

    if (this.noDataText !== undefined) {
      this.GridInstance.noDataText = this.noDataText;
    }
    if (this.GridColumnSummaries !== undefined) {
      this.GridInstance.summary = this.GridColumnSummaries;
    }
    if (this.CellPrepared !== undefined) {
      this.GridInstance.onCellPrepared = this.CellPrepared;
    }
    this.GridInstance.showRowLines = true;
    this.GridInstance.showBorders = true;
    this.GridInstance.grouping = { contextMenuEnabled: true };
    this.GridInstance.groupPanel = {
      visible: true,
    };
    if (this.scrollingMode !== undefined) {
      this.GridInstance.scrolling = this.scrollingMode;
    } else {
      this.GridInstance.scrolling = {
        mode: "standard",
        showScrollbar: "always",
        scrollByContent: true,
      };
      this.GridInstance.pager = {
        allowedPageSizes: [5, 10, 15, 20, 30, 50, 80, 100, 150, 200, 300],
        showPageSizeSelector: true,
        showInfo: true,
        visible: true,
      };
    }

    if (this.PageSize15 === true) {
      this.GridInstance.paging = {
        pageSize: 15,
        enabled: true,
      };
    } else {
      this.GridInstance.paging = {
        pageSize: 10,
        enabled: true,
      };
    }

    this.GridInstance.rowAlternationEnabled = true;
    if (false) {
      this.GridInstance.filterRow = {
        visible: true,
      };
    }

    this.GridInstance.filterPanel = {
      filterEnabled: true,
      visible: true,
    };

    if (false) {
      this.GridInstance.selection = {
        allowSelectAll: false,
        showCheckBoxesMode: "true",
      };
    }
    this.GridInstance.repaintChangesOnly = true;
    this.GridInstance.allowColumnResizing = true;
    this.GridInstance.columnResizingMode = "widget";
    this.GridInstance.allowColumnReordering = true;
    this.GridInstance.cellHintEnabled = true;
    this.GridInstance.columnAutoWidth = false;

    if (this.GridKeyExpression !== undefined) {
      this.GridInstance.focusedRowEnabled = true;
      this.GridInstance.keyExpr = this.GridKeyExpression;
    }
    this.GridInstance.focusStateEnabled = true;
    this.GridInstance.wordWrapEnabled = true;
    this.GridInstance.columnChooser = {
      allowSearch: true,
      enabled: true,
    };
    // this.GridInstance.headerFilter = {
    //   allowSearch: true,
    //   visible: true
    // }

    this.GridInstance.searchPanel = {
      visible: true,
      placeholder: "search...",
      width: 200,
    };

    this.GridInstance.showColumnHeaders = true;

    if (this.GridHeight !== undefined) {
      this.GridInstance.height = this.GridHeight;
    }
  }
  /**
   *  Use this method to add specific css for specific cells in grids.
   */
  gridInstanceCellPrepared(event) {
    let cellValue = event.value;

    let columnDataEntry: any = event.column;
    if (columnDataEntry.dataType === "date") {
      columnDataEntry.format = "dd-MMM-yyyy";
    }
    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          let floatNumber = parseFloat(value).toFixed(2);
          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.systemSettings.PercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
      }
    }
  }

  numberWithCommas(numberToModify: any) {
    var parts = numberToModify.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  /**
   * Edit event fired
   */
  rowDoubleClicked(event) {
    let boundData = event.data;
    this.EditEvent.emit(boundData);
  }

  onContentReady(e) {
    var visibleRowsCount = e.component.totalCount();
    var pageSize = e.component.pageSize();
    if (visibleRowsCount > pageSize) visibleRowsCount = pageSize;
    var totalCount = e.component.option("dataSource").length;
    e.component.option(
      "pager.infoText",
      "Displaying " + visibleRowsCount + " of " + totalCount + " records"
    );
  }
}
