<div class="analytics-display-setion">
	<mat-tab-group color="accent" animationDuration="1ms" *ngIf="analyticsGridOptions | async">
		<mat-tab *ngFor="let itemGrid of analyticsGridOptions | async" [label]="itemGrid.title"
			style="width: 100%; height: 100%;">
			<div *ngIf="itemGrid.title !== 'Total Fund'">

				<dx-data-grid [focusedRowEnabled]="true" [focusStateEnabled]="true" columnResizingMode="widget"
					[allowColumnResizing]="true" [dataSource]=itemGrid.dataSource
					[masterDetail]="{ enabled: true, template: 'assetClass' }" [showBorders]="true"
					[showRowLines]="true" [groupPanel]="{visible: true}"
					(onCellPrepared)="gridInstanceCellPrepared($event)" [searchPanel]="{
					visible: true,
					placeholder: 'search for anything',
					width: 500
				}" [allowColumnReordering]="true" [pager]="{
					allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
					showPageSizeSelector: true,
					showInfo: true,
					visible: true
				}" [paging]="{
					pageSize: 20,
					enabled: true
				}" [rowAlternationEnabled]="true" [export]='{
					enabled: true,
          fileName: "Asset Class -" + itemGrid.title,
           allowExportSelectedData:true }' [columns]="analyticsDisplayViewGridColumnsService.assetClassGridColumns"
					[columnChooser]="{
					enabled: true,
					mode: 'select'
				}" [selection]='{ mode: "multiple"}' keyExpr="Id" [wordWrapEnabled]="true" [columnAutoWidth]="true"
					[columnFixing]="{enabled: true}" [activeStateEnabled]="true" [repaintChangesOnly]="false"
					(onContentReady)="onContentReady($event)">
					<div *dxTemplate="let class of 'assetClass'">
						<div>
							<div class="master-detail-caption">
								Asset Categories</div>
							<dx-data-grid columnResizingMode="widget" [focusStateEnabled]="true"
								[allowColumnResizing]="true" [dataSource]="getAssetCategory(class)" [showBorders]="true"
								(onCellPrepared)="gridInstanceCellPrepared($event)"
								[masterDetail]="{ enabled: true, template: 'assetCategory' }"
								[rowAlternationEnabled]="true" [paging]="{
								pageSize: 20,
								enabled: true
							}" [pager]="{
								allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
								showPageSizeSelector: true,
								showInfo: true,
								visible: true
							}" [export]='{
								enabled: true,
								fileName: "AssetCategories-"+ class.data.AssetClass,
								allowExportSelectedData: true
							}' [columns]="analyticsDisplayViewGridColumnsService.gridColumnsAssetCategory" [columnChooser]="{
								enabled: true,
								mode: 'select'
							}" [selection]='{ mode: "single"}' [wordWrapEnabled]="true" [columnAutoWidth]="true" [activeStateEnabled]="true"
								[columnFixing]="true" (onContentReady)="onContentReady($event)">
								<!-- Illustration purposes -->
								<div *dxTemplate="let category of 'assetCategory'">
									<div>
										<div class="master-detail-caption">
											Assets : {{category.data.AssetCategory}}</div>
										<dx-data-grid columnResizingMode="widget" [focusStateEnabled]="true"
											[allowColumnResizing]="true" [dataSource]="getAssetLevel(category)"
											[showBorders]="true" (onCellPrepared)="gridInstanceCellPrepared($event)"
											[pager]="{
											allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
											showPageSizeSelector: true,
											showInfo: true,
											visible: true
										}" [paging]="{
											pageSize: 20,
											enabled: true
										}" [rowAlternationEnabled]="true" [export]='{
											enabled: true,
											fileName: "AssetLevel-"+category.data.AssetCategory,
											allowExportSelectedData: true
										}' [columns]="analyticsDisplayViewGridColumnsService.gridColumnsAssetLevel" [columnChooser]="{
											enabled: true,
											mode: 'select'
										}" [selection]='{ mode: "single"}' keyExpr="Asset" [wordWrapEnabled]="true" [columnAutoWidth]="true"
											[columnFixing]="true" (onContentReady)="onContentReady($event)">
										</dx-data-grid>
									</div>
								</div>
							</dx-data-grid>
						</div>
					</div>
					<!-- (mouseover)="documentationService.showHelpContent(info.column.name)" -->
					<div *dxTemplate="let info of 'titleHeaderTemplate'"
						(mouseover)="documentationService.showHelpContent(info.column.name)">
						<span>{{info.column.caption}}</span>

					</div>
				</dx-data-grid>
			</div>
			<div *ngIf="itemGrid.title === 'Total Fund'" class="total-fund-grid">
				<dx-data-grid columnResizingMode="widget" [focusStateEnabled]="true" [allowColumnResizing]="true"
					#totalFundGrid [dataSource]=itemGrid.dataSource [showBorders]="true" [showRowLines]="true"
					[groupPanel]="{visible: true}" (onCellPrepared)="gridInstanceCellPrepared($event)" [paging]="{
						pageSize: 20,
						enabled: true
					}" [pager]="{
						allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
						showPageSizeSelector: true,
						showInfo: true,
						visible: true
					}"
				[searchPanel]="{
				visible: true,
				placeholder: 'search for anything',
				width: 500
			}" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [export]='{
				enabled: true,
				fileName: "TotalFund",
				allowExportSelectedData: true
			}' [columns]="analyticsDisplayViewGridColumnsService.totalFundColumns" [selection]='{ mode: "single"}'
					[columnChooser]="{
					enabled: true,
					mode: 'select'
				}" (onRowPrepared)="PerformanceAttributionOnRowPrepared($event)"
					class="analytics-display-performance-attribution-grid"
					[masterDetail]="{ enabled: true, template: 'tFundAssetCategories' }" [(keyExpr)]="totalFundGridKey"
					[scrolling]='{mode: "infinite"}' [wordWrapEnabled]="true" [columnAutoWidth]="true"
					[columnFixing]="true" [summary]="totalFundGroupSummary" (onContentReady)="onContentReady($event)">
					<!-- Illustration purposes -->
					<div *dxTemplate="let tFundAssetCategory of 'tFundAssetCategories'">
						<div>
							<div class="master-detail-caption">
								Assets : {{tFundAssetCategory.data.AssetCategory}}</div>
							<dx-data-grid columnResizingMode="widget" [focusStateEnabled]="true"							
								[allowColumnResizing]="true" [dataSource]="getAssetLevelTotalFund(tFundAssetCategory)"
								[showBorders]="true" (onCellPrepared)="gridInstanceCellPrepared($event)" [pager]="{
									allowedPageSizes: [5, 10, 20, 30, 50, 80, 100, 150, 200, 300],
									showPageSizeSelector: true,
									showInfo: true,
									visible: true
								}" [paging]="{
									pageSize: 20,
									enabled: true
								}" [rowAlternationEnabled]="true" [export]='{
									enabled: true,
									fileName: "AssetLevelTotalFund" + tFundAssetCategory.data.AssetCategory,
									allowExportSelectedData: true
								}' [columns]="analyticsDisplayViewGridColumnsService.gridColumnsAssetLevel" [columnChooser]="{
									enabled: true,
									mode: 'select'
								}" keyExpr="AssetName" [selection]='{ mode: "single"}' [wordWrapEnabled]="true" [columnAutoWidth]="true"
								[columnFixing]="true" (onContentReady)="onContentReady($event)">
							</dx-data-grid>
						</div>
					</div>
				</dx-data-grid>
			</div>


		</mat-tab>
	</mat-tab-group>
	<div class="analytics-display-setion" *ngIf="totalFundSummarizedPerformanceAttribution !== null">
		<mat-tab-group color="accent">
			<mat-tab label="Performance Attribution Summary">
				<div>
					<app-data-grid [EntityDataArray]="totalFundSummarizedPerformanceAttribution"
						[EntityColumns]="analyticsDisplayViewGridColumnsService.totalFundSummaryGridColumns"
						[GridColumnSummaries]="analyticsDisplayViewGridColumnsService.totalFundSummaryGridSummary"
						GridKeyExpression="AssetCategory">
					</app-data-grid>
				</div>
				<mat-divider></mat-divider>
				<div class="portfolio-summary-charts">

					<div>
						<dx-pie-chart #pie title="Asset Category Weight" palette="bright"
							[dataSource]="totalFundSummarizedPerformanceAttribution" resolveLabelOverlapping="shift">
							<dxo-margin [bottom]="20"></dxo-margin>
							<dxo-legend [visible]="false"></dxo-legend>
							<dxo-export [enabled]="true"></dxo-export>
							<dxi-series argumentField="AssetCategory" valueField="CategoryWeight">
								<dxo-label [visible]="true" [customizeText]="customizeLabel"
									[showForZeroValues]="false">
								</dxo-label>
							</dxi-series>
						</dx-pie-chart>
					</div>
					<div>

						<dx-chart [dataSource]="totalFundSummarizedPerformanceAttribution" palette="soft">
							<dxo-title text="Weighted Performance">
								<!-- <dxo-title text="Benchmark Return" subtitle="as of January 2017"> -->
							</dxo-title>
							<dxo-common-series-settings argumentField="AssetCategory" type="bar"
								valueField="WeightedPerformance" [ignoreEmptyPoints]="true">
								<dxo-label [visible]="true" [customizeText]="customizeLabel">
								</dxo-label>
							</dxo-common-series-settings>
							<dxo-series-template nameField="AssetCategory"></dxo-series-template>
						</dx-chart>
					</div>
				</div>

			</mat-tab>

		</mat-tab-group>
	</div>
</div>
<div class="analytics-after-display-view-section"></div>

<!-- NOTE: FOLLOW THIS LINK TO SEE HOW TO PULL DATA SAY BASED ON THE ID OF THE SELECTED ASSET CLASS ITEM ID, USE THE SAME FOR ASSET CATEGORY ID AND ACTUAL ASSET -->
<!-- https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MasterDetailView/Angular/Light/ -->