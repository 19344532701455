<div class="modal-header">
  <div class="modal-title">
    <h2 class="form-title">{{title}}</h2>
  </div>
  <div class="close-button">
    <div style="display: flex; flex-direction: row;">
      <div>
        <button mat-icon-button color="primary" [disabled]="isEditAction" matTooltip="Clear all inputs"
          (click)="ClearFormContents($event)">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
      <div>
        <button mat-icon-button color="warn" matTooltip="Close this form" (click)="closeForm($event)">
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </div>
  </div>
</div>
<div class="form-actions">
  <div *ngIf="isEditAction;else editTemplate">
    <button mat-flat-button (click)="SaveAction($event)" matTooltip="Update item" color="primary">Update
      <mat-icon>update</mat-icon>
    </button>
  </div>
  <ng-template #editTemplate>
    <button mat-flat-button (click)="SaveAction($event)" matTooltip="Save new item" color="primary">Save
      <mat-icon>save</mat-icon>
    </button>

  </ng-template>

  <div class="delete-button">
    <button mat-button [disabled]="true" mat-stroked-button color="warn">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </div>
</div>
<mat-divider color="accent"></mat-divider>
<mat-dialog-content>
  <dx-form #dataForm>


    <dxi-item dataField="Name" editorType="dxTextBox" [validationRules]="nameValidationRule" required="true"
      cssClass="required-form-item">
    </dxi-item>

    <dxi-item dataField="FundId" editorType='dxLookup' [editorOptions]="fundIdEditorOptions"
      [validationRules]="fundValidationRule" cssClass="required-form-item">
      <dxo-label text="Fund / Investor"></dxo-label>
    </dxi-item>

    <dxi-item dataField="Description" editorType="dxTextArea" [editorOptions]="descriptionOptions" required="true"
      cssClass="required-form-item" [showChevron]=true>
    </dxi-item>

    <!-- <dxi-item #assetClassLookUp dataField="AssetCategoryClassId" editorType='dxLookup'
			[editorOptions]="assetClassOptions" [validationRules]="assetClassValidationRules"
			cssClass="required-form-item">
			<dxo-label text="Asset Class"></dxo-label>
		</dxi-item> -->
    <!-- <dx-lookup #assetClassLookUp></dx-lookup> -->

    <dxi-item itemType="group" caption="Asset Categories" [colCount]=5 [tabPanelOptions]="{ deferRendering: false }">
      <dxi-item *ngFor="let category of assetCategories" [dataField]="category.Name" editorType="dxCheckBox"
        [selected]="false" value="false" >
      </dxi-item>
    </dxi-item>


  </dx-form>

</mat-dialog-content>
