import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
  SimpleChanges,
  SimpleChange,
  OnChanges,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  NonPortolioUpdaterService,
  AssetCategoryModelsOutput,
} from "../updater/non-portfilio-updater.service";
import * as _ from "underscore";
import {
  ModelVariable,
  TestInstrumentCategory,
} from "../non-portfolio-analytics/helper-classes";
import { ModelOutput, Result } from "../models-view/model-output";
import { ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { MatTabGroup } from "@angular/material/tabs";
import { Column } from "devextreme/ui/data_grid";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { DxDataGridComponent } from "devextreme-angular";
import { IfStmt } from "@angular/compiler";
import { parameterName } from "devexpress-dashboard/model/index.metadata";
import { LocalStorageService } from "src/app/app-services/services/local-storage.service";

export interface ModelParameterResult {
  parameterName: string;
  parameterId: string;
  parameterCode: string;
  columns: Array<any>;
  dataSource: any;
  showSummary: string;
  summaryColumns: Array<string>;
}
@Component({
  selector: "app-asset-category-results",
  templateUrl: "./asset-category-results.component.html",
  styleUrls: ["./asset-category-results.component.scss"],
})
export class AssetCategoryResultsComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() Title: any;
  @Input() ModelParameters: Array<ModelVariable>;

  @ViewChildren("resultsDataGrid")
  resultsDataGrid: QueryList<DxDataGridComponent>;

  SelectedAssetCategory: TestInstrumentCategory;
  systemSettings: ApplicationSettings;
  ModelParameterResults: ReplaySubject<Array<ModelParameterResult>> =
    new ReplaySubject<Array<ModelParameterResult>>(0);
  @ViewChild("resultsTab", { static: false }) resultsTabInstance: MatTabGroup;

  constructor(
    public updaterService: NonPortolioUpdaterService,
    public appSettingsService: ApplicationSettingsService
  ) {
    this.appSettingsService.GetSettings().subscribe((settings) => {
      this.systemSettings = settings;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ModelParameters) {
      const currentItem: SimpleChange = changes.ModelParameters;
      if (currentItem.currentValue) {
        let currentVal = currentItem.currentValue;
        if (currentVal.length !== 0) {
          let modelParametersMap = this.ModelParameters.map((modelParam) => {
            let item: ModelParameterResult = {
              columns: new Array<any>(),
              parameterId: modelParam.Id,
              dataSource: null,
              parameterName: modelParam.Name,
              parameterCode: modelParam.Code,
              showSummary: "False",
              summaryColumns: new Array<string>(),
            };
            return item;
          });
          this.ModelParameterResults.next(modelParametersMap);
        }
      }
    }
  }

  ngOnInit() {
    this.updaterService.selectedAssetCategory$.subscribe((assetCat) => {
      this.SelectedAssetCategory = assetCat;
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    //after view has been initialized. push items here via a service
    this.updaterService.gridDataConfigObservable$.subscribe(
      (assetCategoryModelOutput: AssetCategoryModelsOutput) => {
        if (
          this.SelectedAssetCategory.id ===
          assetCategoryModelOutput.AssetCategoryId
        ) {
          // check if the asset category id used to create this grid exists in the replay subject

          //weignting option changes
          let weightingOptionIncoming =
            assetCategoryModelOutput.weightingOption;

          let modelOutputs = assetCategoryModelOutput.ModelOutputs;
          let parameters: Array<string> = modelOutputs.map(
            (item) => item.ParameterName
          );

          parameters.forEach((parameter) => {
            let matchingIncomingModels: ModelOutput = _.find(
              modelOutputs,
              (x) => x.ParameterName == parameter
            );
            //there is a list of securities
            let finalModelGroups: Array<any> = new Array<any>();
            let finalModelCategories: Array<any> = new Array<any>();
            let dataSource: Array<any> = new Array<any>();

            matchingIncomingModels.Securities.forEach((security) => {
              //each security is unique
              let securityName = security.Name;
              let dataSourceObject = {};
              Object.defineProperty(dataSourceObject, "Security", {
                value: securityName,
                writable: true,
              });

              let sectorName = security.Sector;

              Object.defineProperty(dataSourceObject, "Sector", {
                value: sectorName,
                writable: true,
              });
              let timeToMaturity = security.TimeToMaturity;
              let tenor = security.Tenor;

              Object.defineProperty(dataSourceObject, "TimeToMaturity", {
                value: timeToMaturity,
                writable: true,
              });
              Object.defineProperty(dataSourceObject, "Tenor", {
                value: tenor,
                writable: true,
              });

              finalModelCategories = new Array<any>();
              let modelGroupings: Array<any> = _.chain(security.ModelCategories)
                .groupBy((x) => x.Name)
                .map(function (value, key) {
                  return {
                    Name: key,
                    Results: value.map((item) => item.Results),
                    dataSourceObject: dataSourceObject,
                  };
                })
                .value();
              finalModelCategories.push(modelGroupings);

              security.ModelCategories.forEach((category) => {
                finalModelGroups = new Array<any>();
                let groupedModels: Array<any> = _.chain(category.Results)
                  .groupBy((x) => x.Model)
                  .map(function (value, key) {
                    return {
                      Name: key,
                    };
                  })
                  .value();
                finalModelGroups.push(groupedModels);
                // ---------------------------------------------------------------------------------------------------------------------------------------

                let groupedModelData: Array<any> = _.chain(category.Results)
                  .groupBy((x) => x.Model)
                  .map(function (value, key) {
                    return {
                      Model: key,
                      ModelValue: value,
                    };
                  })
                  .value();

                // ---------------------------------------------------------------------------------------------------------------------------------------
                _.each(groupedModelData, (modelData) => {
                  modelData.ModelValue.forEach((element) => {
                    let modelNameWithoutSpaces = element.Model.replace(
                      /\s/g,
                      ""
                    );

                    Object.defineProperty(
                      dataSourceObject,
                      `${category.Name + modelNameWithoutSpaces}`,
                      {
                        value: element.Value,
                        writable: true,
                      }
                    );
                  });
                });
                // ---------------------------------------------------------------------------------------------------------------------------------------
              }); //each seurity
              dataSource.push(dataSourceObject);
            });

            let columns: Array<Column> = new Array<Column>();
            let selectedAssetCategoryName =
              this.SelectedAssetCategory.text.toLowerCase();
            if (selectedAssetCategoryName !== "macro-economic variables") {
              columns.push({
                caption: "Security",
                dataField: "Security",
                dataType: "string",
                sortOrder: "asc",
                allowSorting: true,
                fixed: true,
                fixedPosition: "left",
                width: "300px",
                minWidth: 300,
                allowResizing: true,
                cssClass: "non-portfolio-security-column",
              });
            } else {
              columns.push({
                caption: "Country",
                dataField: "Security",
                dataType: "string",
                sortOrder: "asc",
                allowSorting: true,
                fixedPosition: "left",
                width: "300px",
                minWidth: 300,
                allowResizing: true,
                cssClass: "non-portfolio-security-column",
              });
            }

            if (
              selectedAssetCategoryName == "commercial paper" ||
              selectedAssetCategoryName == "fixed treasury bonds" ||
              selectedAssetCategoryName == "fixed corporate bonds" ||
              selectedAssetCategoryName == "time deposits" ||
              selectedAssetCategoryName == "treasury bills"
            ) {
              columns.push({
                caption: "Time To Maturity",
                dataField: "TimeToMaturity",
                dataType: "number",
                width: "100px",
                minWidth: 100,
                cssClass: "non-portfolio-security-column",
                calculateCellValue: (rowData) =>
                  this.calculateNonPercentageValue(rowData["TimeToMaturity"]),
              });
            }
            if (
              selectedAssetCategoryName == "commercial paper" ||
              selectedAssetCategoryName == "fixed treasury bonds" ||
              selectedAssetCategoryName == "fixed corporate bonds" ||
              selectedAssetCategoryName == "time deposits" ||
              selectedAssetCategoryName == "treasury bills"
            ) {
              columns.push({
                caption: "Tenor",
                dataField: "Tenor",
                dataType: "string",
                groupIndex: 0,
              });
            } else if (selectedAssetCategoryName == "listed equities") {
              columns.push({
                caption: "Sector",
                dataField: "Sector",
                dataType: "string",
                minWidth: 200,
                groupIndex: 0,
              });
            }

            let summaryColumns: Array<string> = new Array<string>();

            //----------------------------------------------------------------------------------------------------------------------------------------------
            finalModelCategories.forEach((modelCategory) => {
              modelCategory.forEach((element) => {
                let modelgroupName = element.Name;
                let elementIndex: number = NaN;

                //associate models to colors-quick fix
                switch (modelgroupName) {
                  case "Statistical Analysis":
                    elementIndex = 0;
                    break;
                  case "Risk Adjusted Performance Ratios":
                    elementIndex = 1;
                    break;
                  case "Relative Return Measures":
                    elementIndex = 2;
                    break;
                  case "Bond Risk Analysis":
                    elementIndex = 6;
                    break;
                  case "Fat Tail Analysis":
                    elementIndex = 8;
                    break;
                  default:
                    elementIndex = 5;
                }

                //let elementIndex = _.indexOf(modelCategory, element); //use this to populate already made colors

                let cssClassWithColorIndex = `non-portfolio-grid-color-${elementIndex}`;

                let modelGroupColumn: any = {
                  caption: `${element.Name}`,
                  cssClass: cssClassWithColorIndex,
                  columns: [],
                };
                let resultsArray: Array<Result> = _.flatten(element.Results);

                resultsArray.forEach((result) => {
                  let resultModel = result.Model;
                  let modelNameWithoutSpaces = resultModel.replace(/\s/g, "");
                  //model group + modelName
                  let fullyQualifiedModelName =
                    modelgroupName + modelNameWithoutSpaces;
                  let modelValue = result.Value;

                  //each model group column
                  let modelColumn: Column = {
                    caption: `${resultModel}`,
                    dataField: `${fullyQualifiedModelName}`,
                    cssClass: cssClassWithColorIndex,
                    dataType: "number",
                    calculateCellValue: (rowData) =>
                      this.calculateNonPercentageValue(
                        rowData[fullyQualifiedModelName]
                      ),
                    minWidth: 60,
                  };

                  //update the summary columns for each unique model group
                  summaryColumns.push(fullyQualifiedModelName);

                  modelGroupColumn.columns.push(modelColumn);
                });
                columns.push(modelGroupColumn);
              });
            });

            //------------------------------------------------ Results Update----------------------------------------------------------------------------------------------
            this.ModelParameterResults.pipe(
              map((y) => {
                if (
                  this.SelectedAssetCategory.id ===
                  assetCategoryModelOutput.AssetCategoryId
                ) {
                  //for the case of Macro-economic Variables - remove the parentheses
                  let openParentheses = "(";
                  if (parameter.includes(openParentheses)) {
                    let fields = parameter.split(" (");
                    let cleanParameter = fields[0];
                    parameter = cleanParameter;
                  }

                  let matchingParamGridItem = _.find(
                    y,
                    (x) =>
                      x.parameterCode.toLowerCase() == parameter.toLowerCase()
                  );

                  if (matchingParamGridItem) {
                    matchingParamGridItem.columns = columns;
                    matchingParamGridItem.dataSource = dataSource;
                    matchingParamGridItem.showSummary = weightingOptionIncoming;
                    matchingParamGridItem.summaryColumns = summaryColumns;
                    return matchingParamGridItem;
                  }
                  return y;
                }
              })
            ).subscribe();
          }); //for each parameter
        }
      }
    ); //subscribe to model output
  }
  calculateNonPercentageValue(rowData: any) {
    let nonpercentageDecimalPlaces =
      this.systemSettings.NonPercentageValuesDecimalPoints;

    return Number.parseFloat(rowData).toFixed(nonpercentageDecimalPlaces);
  }

  generateNoDataText = (): string =>
    `Do something interesting with ${this.Title}. \n Your results will show here.`;

  gridInstanceCellPrepared(event) {
    let value = event.value;
    if (value !== undefined) {
      if (value < 0) {
        event.cellElement.classList.add("data-grid-negative-number-cell");
        event.value = Math.abs(value);
        let textDisplayed: string = event.text;
        textDisplayed = textDisplayed.replace("-", "");
        event.cellElement.innerHTML = `(${textDisplayed})`;
      }
    }
  }

  assetCategoryResultInformation(assetCatName) {
    return `${assetCatName} Asset Category results`;
  }

  customizeParameterName(item: ModelParameterResult) {
    let parameterName = item.parameterName;
    let itemDataSource = item.dataSource;
    let dataSourceLength = 0;
    if (itemDataSource !== null) {
      if (itemDataSource.length !== 0) {
        dataSourceLength = item.dataSource.length;
        return parameterName + ` (${dataSourceLength})`;
      } else return parameterName;
    } else return parameterName;
  }

  customizeSummaryText(data) {
    let value = data.value;
    let normalInString = localStorage.getItem("nrml-decPoints");
    let NonPercentageValuesDecimalPoints = Number.parseInt(normalInString);
    if (value < 0) {
      let absoluteValue = Math.abs(value);
      let numberWithDecimalPoints = absoluteValue.toFixed(
        NonPercentageValuesDecimalPoints
      );

      return `(${numberWithDecimalPoints})`;
    } else {
      let numberWithDecimalPoints = value.toFixed(
        NonPercentageValuesDecimalPoints
      );

      return numberWithDecimalPoints;
    }
  }

  //This is to fix the migAlignment bug
  //On tab selection change refresh the grids

  refreshGrid(event) {
    this.resultsDataGrid.toArray().forEach((x) => {
      x.instance.refresh();
    });
  }
}
