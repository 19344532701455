import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-portfolio-multi-variate-analysis',
  templateUrl: './non-portfolio-multi-variate-analysis.component.html',
  styleUrls: ['./non-portfolio-multi-variate-analysis.component.scss']
})
export class NonPortfolioMultiVariateAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
