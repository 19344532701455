import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-information',
  templateUrl: './settings-information.component.html',
  styleUrls: ['./settings-information.component.css']
})
export class SettingsInformationComponent implements OnInit {

  settingsInformationPercentage: string = ''
  settingsInformationNonPercentage: string = ''
  settingsInformationFont: string = ''

  constructor(@Inject(MAT_DIALOG_DATA) public settingsInformationInput: any,
    public dialogRef: MatDialogRef<SettingsInformationComponent>) {
    const settingInputArray = settingsInformationInput.settingsInformation

    this.settingsInformationPercentage = settingInputArray[0]
    this.settingsInformationNonPercentage = settingInputArray[1]
    this.settingsInformationFont = settingInputArray[2]

    
  }

  ngOnInit() {

  }

  closeThisDialog() {
    this.dialogRef.close()
  }

}
