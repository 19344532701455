import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import {
  DxDataGridComponent,
  DxDateBoxComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { Column } from "devextreme/ui/data_grid";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { NotificationService } from "src/app/app-services/notification/notification.service";
import {
  Regulators,
  Tenant,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";
import { ReconService } from "./recon.service";
import DxDataGrid from "devextreme/ui/data_grid";
import { HttpClient } from "@angular/common/http";

export class ReconReportsRequestObject {
  public StartDate: any;
  public EndDate: any;
  public FundManagers: Array<any> = undefined;
  public Funds: Array<any> = undefined;
  public TenantId: string;
}

export interface ReconReportData {
  Fund: string;
  FundManager: string;
  Custodian: string;
  AssetCategories: AssetCategory[];
}

export interface AssetCategory {
  Id: string;
  AssetCategory: string;
  FundManagerMarketValue: number;
  FundManagerFaceValue: number;
  CustodianMarketValue: number;
  CustodianFaceValue: number;
  Difference: number;
  FaceValueDifference: number;
  FaceValueVariance: number;
  Variance: number;
  CustodianComments: string;
  FundManagerComments: string;
  LicenseeComments: string;
  RegulatorComments: string;
  SecuritiesReconciliation: any[];
}

@Component({
  selector: "app-recon-reports",
  templateUrl: "./recon-reports.component.html",
  styleUrls: ["./recon-reports.component.scss"],
})
export class ReconReportsComponent implements OnInit {
  @ViewChild("EndDateBox", { static: true })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("startDateBox", { static: true })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("fundManagerTreeList", { static: false })
  fundManagerTree: DxTreeListComponent;
  // @ViewChild("resultsDataGrid", { static: true })
  // resultsDataGrid: DxDataGridComponent;
  @ViewChildren("resultsDataGrid") dataGrids: QueryList<DxDataGridComponent>;

  FundsFundManagerDataSource: any;
  treeBoxValue: string;
  selectedFund: string = null;
  selectedTenant: string = null;
  activeTenantName: string;
  activeTenantType: string;
  activeTenantRegulator: string;
  applicationSettings: ApplicationSettings;
  gridDataSource: any;
  reconColumns: any;
  securitiesReconColumns: any;
  equitySecuritiesReconColumns: any;
  nonEquitySecuritiesReconColumns: any;
  authToken: any;
  isRegulator: boolean = true;
  //allowupdating: boolean = false;
  events: Array<string> = [];
  public _fetchFundsSubscription: Subscription;
  responseData: ReconReportData[];

  ReportsRequestObject: ReconReportsRequestObject =
    new ReconReportsRequestObject();
  constructor(
    private apiService: ApiService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public appSettingsService: ApplicationSettingsService,
    private notificationService: NotificationService,
    private tenantResolverService: TenantService,
    private reconService: ReconService,
    private http: HttpClient
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Reconciliation Reports"
    );
  }

  ngOnInit() {
    //Resolve the currenct tenant
    this.activeTenantName = this.tenantResolverService.getTenantName();
    this.activeTenantType = this.tenantResolverService.getTenantType();
    this.activeTenantRegulator =
      this.tenantResolverService.getTenantRegulatorName();

    this.isRegulator = this.tenantResolverService.isRegulator();

    //Initialize default dates
    this.appSettingsService.GetSettings().subscribe((settings) => {
      //console.log(settings.DefaultDateRange.EndDate);
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    });

    //InvestorLookup initialize datasource
    let fundManagersFetch$ = this.apiService.Get(
      "fundmanagers/getfundmanagers"
    );

    fundManagersFetch$.subscribe((fundManagers) => {
      //for other reports
      //added MasterFund Property as a quickfix, to solve the recurive issue, master fund was being taken as a fund when selected
      let nodes: Array<any> = [];

      fundManagers.forEach((fundManager) => {
        nodes.push({
          Name: fundManager.Name,
          ID: fundManager.Id,
          Head_ID: 0,
          TenantId: fundManager.TenantId,
          Masterfund: true,
          FundManagerID: fundManager.Id,
        });

        //for the case where master fund not is null
        fundManager.MasterFunds.forEach((masterFund) => {
          if (masterFund.Id != null) {
            nodes.push({
              Name: masterFund.Name,
              ID: masterFund.Id,
              Head_ID: fundManager.Id,
              TenantId: fundManager.TenantId,
              Masterfund: true,
              FundManagerID: fundManager.Id,
            });
          }

          if (masterFund.Id != null) {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: masterFund.Id,
                TenantId: fundManager.TenantId,
                Masterfund: false,
                FundManagerID: fundManager.Id,
              });
            });
          } else {
            masterFund.Investors.forEach((investor) => {
              nodes.push({
                Name: investor.Name,
                ID: investor.Id,
                Head_ID: fundManager.Id,
                TenantId: fundManager.TenantId,
                masterFund: false,
                FundManagerID: fundManager.Id,
              });
            });
          }
        });
      });

      this.FundsFundManagerDataSource = nodes;
    });

    //Initialize the Asset columns
    if (
      this.activeTenantName == "SEC Zambia" ||
      this.activeTenantName == "Capital Markets Authority"
    ) {
      this.reconColumns = this.reconService.getRegulatorAssetClassColumns();
    } else if (this.activeTenantType == "Fund Manager") {
      this.reconColumns = this.reconService.getFundManagerAssetClassColumns();
    } else if (this.activeTenantType == "Custodian") {
      this.reconColumns = this.reconService.getCustodianAssetClassColumns();
    }
  }

  fetchResults() {
    if (
      this.ReportsRequestObject.FundManagers == null ||
      this.ReportsRequestObject.FundManagers.length == 0
    ) {
      this.notificationService.showError("Select Fund Manager/Funds", "");
      return;
    }

    this.ReportsRequestObject.StartDate = this.endDateBoxInstance.value;
    this.ReportsRequestObject.EndDate = this.endDateBoxInstance.value;
    let reportRequestFinal = {
      FundManagers: this.ReportsRequestObject.FundManagers,
      EndDate: this.ReportsRequestObject.EndDate,
      StartDate: this.ReportsRequestObject.StartDate,
    };

    this.apiService
      .Post("SECZambiaReports/FundsReconciliation", reportRequestFinal)
      .subscribe((res) => {
        this.responseData = res["Data"];
        this.formatGridResponse(this.responseData);
      });
    //Security Recon Columns
    this.equitySecuritiesReconColumns =
      this.reconService.getEquitiesSecurityReconColumns();
    this.nonEquitySecuritiesReconColumns =
      this.reconService.getNonEquitiesSecurityReconColumns();
  }

  formatGridResponse(responseData: ReconReportData[]) {
    let finalData: any[] = [];
    responseData.forEach((data) => {
      var fund = data.Fund;
      var fundManager = data.FundManager;
      var custodian = data.Custodian;
      data.AssetCategories.forEach((assetcategory) => {
        var assetCategoryName = assetcategory.AssetCategory;
        var fundManagerMarketValue = assetcategory.FundManagerMarketValue;
        var fundManagerFaceValue = assetcategory.FundManagerFaceValue;
        var custodianMarketValue = assetcategory.CustodianMarketValue;
        var custodianFaceValue = assetcategory.CustodianFaceValue;
        var difference = assetcategory.Difference;
        var faceValueDifference = assetcategory.FaceValueDifference;
        var faceValueVariance = assetcategory.FaceValueVariance;
        var fundManagercomments = assetcategory.LicenseeComments;
        var custodianComments = assetcategory.CustodianComments;
        var regulatorcomments = assetcategory.RegulatorComments;
        var variance = assetcategory.Variance;
        var assetCategoryID = assetcategory.Id;
        var securitiesReconciliation = assetcategory.SecuritiesReconciliation;
        var securityColumns;

        if (assetCategoryName == "Listed Equities") {
          securityColumns = this.equitySecuritiesReconColumns;
          fundManagerFaceValue = assetcategory.FundManagerMarketValue;
          custodianFaceValue = assetcategory.CustodianMarketValue;
          faceValueDifference = assetcategory.Difference;
          faceValueVariance = variance;
        } else {
          securityColumns = this.nonEquitySecuritiesReconColumns;
        }

        finalData.push({
          Fund: fund,
          FundManager: fundManager,
          Custodian: custodian,
          AssetCategory: assetCategoryName,
          AssetCategoryID: assetCategoryID,
          FundManagerMarketValue: fundManagerMarketValue,
          FundManagerFaceValue: fundManagerFaceValue,
          CustodianMarketValue: custodianMarketValue,
          CustodianFaceValue: custodianFaceValue,
          Difference: difference,
          FaceValueDifference: faceValueDifference,
          FaceValueVariance: faceValueVariance,
          LicenseeComments: fundManagercomments,
          CustodianComments: custodianComments,
          Variance: variance,
          RegulatorComments: regulatorcomments,
          SecuritiesReconciliation: securitiesReconciliation,
          SecurityColumns: securityColumns,
        });
      });

      this.gridDataSource = finalData;
    });
  }

  InvestorSelectionChanged(event) {
    this.ReportsRequestObject.Funds = [];
    this.ReportsRequestObject.FundManagers = [];

    if (event.selectedRowsData.length !== 0) {
      this.fundManagerTree.instance
        .getSelectedRowsData("includeRecursive")
        .forEach((selectedRowsData) => {
          if (selectedRowsData.Masterfund == true) {
          } else {
            const fundId = selectedRowsData.ID;
            //send selectedFund to child component
            this.selectedFund = fundId;
            const fundManagerId = selectedRowsData.FundManagerID;
            const selectedTenantId = selectedRowsData.TenantId;
            //send selectedFund to child component
            this.selectedTenant = selectedTenantId;

            let results = this.ReportsRequestObject.FundManagers.filter((x) => {
              if (x.Id === fundManagerId) {
                return x;
              }
            });
            let selectedFundManager: any;
            if (results.length > 0) {
              selectedFundManager = results[0];
            }

            if (!selectedFundManager) {
              selectedFundManager = {
                Id: fundManagerId,
                TenantId: selectedTenantId,
                Funds: [fundId],
              };
              this.ReportsRequestObject.FundManagers.push(selectedFundManager);
            } else {
              selectedFundManager.Funds.push(fundId);
            }
          }
        });
    }
  }

  UpdateComment(eventName: any) {
    let updatingRowData = eventName.key;
    let newComment = eventName.newData.Comments;
    let assetCategoryId = updatingRowData.AssetCategoryID;

    //Loop through the response use AssetCategoryId to update changed comment to create a new post request
    this.responseData.forEach((data) => {
      data.AssetCategories.forEach((assetCategory) => {
        if (assetCategory.Id == assetCategoryId) {
          //regular can only edit regulator comments
          if (this.isRegulator == true) {
            assetCategory.RegulatorComments =
              eventName.newData.RegulatorComments;
          } else if (this.activeTenantType == "Fund Manager") {
            //FM can only edit fm comments
            assetCategory.LicenseeComments = eventName.newData.LicenseeComments;
          } else if (this.activeTenantType == "Custodian") {
            //custodian can only edit licencee comments
            assetCategory.CustodianComments =
              eventName.newData.CustodianComments;
          }
        }
      });
    });

    let requestObject = { Data: this.responseData };

    this.apiService
      .Post("SECZambiaReports/SaveFundsReconciliation", requestObject)
      .subscribe((res) => {
        this.notificationService.showSuccess(res["Data"], "Success");
      });
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;

    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined && value) {
        if (value > 0) {
          let floatNumber = parseFloat(value).toFixed(2);
          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `${formattedNumber}`;
        }
        if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        }
        if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(
            this.applicationSettings.NonPercentageValuesDecimalPoints
          );
          event.cellElement.innerHTML = `${floatNumber}`;
        }
        //if variance > 5% color red
        if (columnDataEntry.dataField === "Variance" && value > 5) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
        }
      }
    }
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  calculateGridHeight() {
    return 0.6 * window.screen.height + "px";
  }

  treeList_itemSelectionChanged(e) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  //This is to fix the migAlignment bug
  //On tab selection change refresh the grids

  refreshGrid(event) {
    this.dataGrids.toArray().forEach((x) => {
      x.instance.refresh();
    });
  }

  getAssetCategorySecurities(assetCategory) {
    let key = assetCategory.key;
    console.log(key);
  }

  onContentReady(e) {
    var visibleRowsCount = e.component.totalCount();
    var pageSize = e.component.pageSize();
    if (visibleRowsCount > pageSize) visibleRowsCount = pageSize;
    var totalCount = e.component.option("dataSource").length;
    e.component.option(
      "pager.infoText",
      "Displaying " + visibleRowsCount + " of " + totalCount + " records"
    );
  }
}
