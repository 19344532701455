import { ViewChild } from "@angular/core";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  DxLookupComponent,
  DxSwitchComponent,
  DxTabPanelComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/app-services/api/api.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import {
  ReportGroupItem,
  ReportGroupItemsGenerator,
  ReportItem,
} from "../../../CMAReports/helper-classes";
import { ReportService } from "../../../report.service";

@Component({
  selector: "zambia-sec-custodian-reports",
  templateUrl: "./zambia-sec-custodian-reports.component.html",
  styleUrls: ["./zambia-sec-custodian-reports.component.scss"],
})
export class ZambiaSecCustodianReportsComponent implements OnInit {
  @Input() ReportGroupItems: Array<ReportGroupItem>;
  @Input() PerfomanceMeasurementReports: Array<ReportGroupItem>;
  @ViewChildren(DxTreeListComponent)
  ReportCategoriesChildren: QueryList<DxTreeListComponent>;
  @ViewChild(DxTreeListComponent, { static: false })
  reportGroupData: DxTreeListComponent;
  @ViewChild("reportCategoriesPanel", { static: false })
  reportCategoriesPanelInstance: DxTabPanelComponent;
  @ViewChild("assetClassLookupInstance", { static: false })
  assetClassLookupInstance: DxLookupComponent;
  @ViewChild("summarySwitch", { static: false })
  summarySwitch: DxSwitchComponent;
  @ViewChild("includeCashSwitch", { static: false })
  IncludeCashSwitch: DxSwitchComponent;
  selectedReportCategoryName: any;

  //subsciptions
  public _assetClassSubscription: Subscription;
  public _modelsSubscription: Subscription = undefined;

  @Output() custodianReportItemSelected: EventEmitter<any> = new EventEmitter();

  @Input() SelectedFundId: any = null;
  @Input() SelectedTenantId: any = null;

  constructor(
    public notificationService: SnackNotificationsService,
  ) {}

  ngOnInit() {
    let regulatoryComplianceReports =
      new ReportGroupItemsGenerator().generateRegulatoryComplianceCustodianReports();

    this.ReportGroupItems = regulatoryComplianceReports;
  }

  ngOnDestroy(): void {
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    // this.reportCategoriesPanelInstance.onSelectionChanged.subscribe((res) => {
    //   //get the selected report category
    //   this.selectedReportCategoryName =
    //     this.reportCategoriesPanelInstance.selectedItem.title;
    //   this.ReportCategorySelected.emit(this.selectedReportCategoryName);
    // });
  }

  // ngOnChanges(): void {
  //   //Only in Perfomance Attribution Reports
  //   if (this.selectedReportCategoryName == "Perfomance Attribution Reports") {
  //     if (this.SelectedFundId != null && this.SelectedTenantId != null) {
  //       //get Instrument classes
  //       this._assetClassSubscription = this.reportService
  //         .getAssetClassesByInvestorId(
  //           this.SelectedFundId,
  //           this.SelectedTenantId
  //         )
  //         .subscribe((res) => {
  //           this.assetClassLookupInstance.dataSource = res;
  //         });
  //     }
  //   }
  // }

  gOnDestroy(): void {
    if (this._assetClassSubscription !== undefined)
      this._assetClassSubscription.unsubscribe();
  }

  showMoreInfoOnHover(event) {
    //todo find something to do when a model is hovered upon
    // if (event.data !== undefined) {
    //   console.log(event.data);
    // }
  }

  onCellPrepared(e) {
    if (e.rowType === "data") {
    }
  }

  ModelGroupsItemsSelected(event, reportGroupName) {
    if (event.data) {
      console.log(event.data);
      this.custodianReportItemSelected.emit(event.data);
      this.ReportCategoriesChildren.toArray().forEach((x) => {
        x.instance.deselectAll();
      });
    }
  }

  updateCaption(modelGroupName, modelItemsCount) {
    return modelGroupName + ` (${modelItemsCount})`;
  }
}
