import { AssetLevel } from '../asset-class-type';

export class AnalyticsGridOption {
  dataSource: any;
  title: string;
  analysisName: string;
  category: AssetLevel;
  gridColumns: any;


  constructor(_dataSource: any, _title: string, _analysisName: string, _category: AssetLevel, gridColumns) {
    this.dataSource = _dataSource;
    this.title = _title;
    this.analysisName = _analysisName;
    this.category = _category;
    this.gridColumns = gridColumns;
  }
}
