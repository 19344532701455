<div class="regulator-compliance-main-container">
  <div class="regulator-compliance-main-area">
    <!-- main config section -->
    <div class="portfolio-analytics-config-section">
      <div>
        <span id="analytics-fund-caption" class="component-inputs-label"
          >Fund</span
        >
        <!-- <dx-lookup id="analytics-fund-input" #investorLookupInstance itemTemplate="listItem"
                    matTooltip="The fund to show compliance on">
                    <div *dxTemplate="let item of 'listItem'">
                        <span style="font-weight: 500">{{ item.Name }}</span>
                    </div>
                </dx-lookup> -->
        <dx-drop-down-box
          #fundDropdown
          valueExpr="ID"
          displayExpr="name"
          placeholder="Select a Fund..."
          [dataSource]="FundsFundManagerDataSource"
          width="300"
        >
          <dx-tree-list
            height="800"
            width="500"
            #fundManagerTreeList
            [dataSource]="FundsFundManagerDataSource"
            keyExpr="ID"
            parentIdExpr="Head_ID"
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [showBorders]="true"
            (onSelectionChanged)="ModelGroupsItemsSelected($event)"
            noDataText="no data to display..."
            class="tree-list"
            [highlightChanges]="true"
            [repaintChangesOnly]="true"
            [focusedRowEnabled]="false"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [focusStateEnabled]="true"
            (onItemSelectionChanged)="treeList_itemSelectionChanged($event)"
            [autoExpandAll]="true"
          >
            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"> </dxo-sorting>
            <dxi-column
              dataField="Name"
              caption="Select Funds under a Fund Manager"
              sortOrder="asc"
              style="font-size: 200%; font-weight: 700"
            >
            </dxi-column>
            <dxo-selection mode="single"> </dxo-selection>
          </dx-tree-list>
        </dx-drop-down-box>
      </div>

      <div>
        <span
          id="analytics-start-date-caption"
          class="analytics-section-selection-title component-inputs-label"
          >Start Date</span
        >
        <dx-date-box
          id="analytics-start-date-input"
          displayFormat="dd MMM yyyy"
          #startDateBox
          matTooltip="Period start date"
        >
        </dx-date-box>
      </div>
      <div>
        <span
          id="analytics-end-date-caption"
          class="analytics-section-selection-title component-inputs-label"
          >End Date</span
        >
        <dx-date-box
          id="analytics-end-date-input"
          displayFormat="dd MMM yyyy"
          #endDateBox
          matTooltip="Period end date"
        >
        </dx-date-box>
      </div>
    </div>
    <div>
      <div
        *ngIf="_reportSubscription === undefined; else fetchingInProgressTemp"
      >
        <button
          #refreshButton
          mat-flat-button
          color="primary"
          (click)="fetchReport()"
        >
          <mat-icon> update </mat-icon>
          Refresh
        </button>
      </div>

      <ng-template #fetchingInProgressTemp>
        <button
          mat-raised-button
          color="warn"
          (click)="closeRequest()"
          matTooltip="Cancel this request"
        >
          <mat-icon>cancel_presentation</mat-icon>
          Cancel
        </button>
      </ng-template>
    </div>
  </div>

  <div style="margin-top: 1%"></div>
  <div
    *ngIf="regulatoryComplianceGridData"
    class="regulator-compliance-grid-main-container"
  >
    <dx-data-grid
      #complianceGridInstance
      [dataSource]="regulatoryComplianceGridData"
      [columns]="regulatoryComplianceGridColumns"
      columnResizingMode="widget"
      [allowColumnResizing]="true"
      [showBorders]="true"
      [showRowLines]="true"
      [groupPanel]="{ visible: true }"
      [grouping]="{ autoExpandAll: true }"
      [searchPanel]="{
        visible: true,
        placeholder: 'search for anything',
        width: 500
      }"
      [allowColumnReordering]="false"
      [rowAlternationEnabled]="false"
      [export]="{
        enabled: true,
        fileName: 'Non-Portfolio-Analytics',
        allowExportSelectedData: true
      }"
      [selection]="{
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      }"
      [columnChooser]="{ enabled: true, mode: 'select' }"
      [wordWrapEnabled]="false"
      [columnFixing]="true"
      [twoWayBindingEnabled]="true"
      [repaintChangesOnly]="false"
      [activeStateEnabled]="true"
      (onCellPrepared)="gridInstanceCellPrepared($event)"
      height="400px"
      [keyExpr]="gridKeyExpression"
      [focusStateEnabled]="true"
      [focusedRowEnabled]="false"
      [scrolling]="{ mode: 'standard', preloadEnabled: true }"
    >
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-sorting mode="single"> </dxo-sorting>
      <dxo-summary>
        <dxi-total-item
          column="ActualHolding"
          summaryType="sum"
          [customizeText]="customizeSummaryText"
        >
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>

  <div id="chart-area">
    <div *ngIf="cmaRegulatoryComplianceData" id="cma-chart-area">
      <dx-chart
        id="cma-regulatory-compliance-chart"
        title="{{ firstRegulatorName }} Aggregate Regulatory Compliance - {{
          selectedFund
        }}"
        [dataSource]="cmaRegulatoryComplianceData"
      >
        <dxi-series
          valueField="maximum"
          name="maximum"
          color="#f6cd61"
        ></dxi-series>
        <dxi-series
          valueField="actual"
          name="actual"
          color="#0e9aa7"
        ></dxi-series>
        <dxi-series
          valueField="minimum"
          name="minimum"
          color="#fe8a71"
        ></dxi-series>
        <dxo-common-series-settings
          argumentField="state"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <dxo-label
            [visible]="true"
            [format]="{
              type: 'fixedPoint',
              precision: '2'
            }"
          >
          </dxo-label>
        </dxo-common-series-settings>
        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
        </dxo-legend>
        <dxo-export [enabled]="true"></dxo-export>
      </dx-chart>
    </div>
    <div *ngIf="rbaRegulatoryComplianceData" id="rba-chart-area">
      <dx-chart
        id="rba-regulatory-compliance-chart"
        title="{{ secondRegulatorName }} Regulatory Compliance - {{
          selectedFund
        }}"
        [dataSource]="rbaRegulatoryComplianceData"
      >
        <dxi-series
          valueField="maximum"
          name="maximum"
          color="#f6cd61"
        ></dxi-series>
        <dxi-series
          valueField="actual"
          name="actual"
          color="#0e9aa7"
        ></dxi-series>
        <dxi-series
          valueField="minimum"
          name="minimum"
          color="#fe8a71"
        ></dxi-series>
        <dxo-common-series-settings
          argumentField="state"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <dxo-label
            [visible]="true"
            [format]="{
              type: 'fixedPoint',
              precision: '2'
            }"
          >
          </dxo-label>
        </dxo-common-series-settings>
        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
        </dxo-legend>
        <dxo-export [enabled]="true"></dxo-export>
      </dx-chart>
    </div>
  </div>
</div>
