import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { ModelOutput } from "../models-view/model-output";
import {
  TestInstrumentCategory,
  RenderButtonConfig,
} from "../non-portfolio-analytics/helper-classes";

export interface AssetCategoryModelsOutput {
  AssetCategoryId: string;
  ModelOutputs: Array<ModelOutput>;
  weightingOption: string;
}
@Injectable({
  providedIn: "root",
})
//https://stackoverflow.com/questions/40313770/how-to-trigger-function-from-one-component-to-another-in-angular2
export class NonPortolioUpdaterService {
  /**Asset category result component output grid */
  private gridDataConfigSubject = new Subject<AssetCategoryModelsOutput>();
  gridDataConfigObservable$ = this.gridDataConfigSubject.asObservable();

  /**Projects selected asset category from non-portolio component to asset category result component */
  private nonPortfolioSelectedAssetCatSub = new Subject<TestInstrumentCategory>();
  selectedAssetCategory$ = this.nonPortfolioSelectedAssetCatSub.asObservable();

  /**Request to render results in non-portfolio */
  private renderButtonEventSubject = new Subject<RenderButtonConfig>();
  renderButtonEventObservable$ = this.renderButtonEventSubject.asObservable();

  /**Request to render results in non-portfolio */
  private renderButtonDisableEnableConfig = new Subject<boolean>();
  renderButtonDisableEnableConfig$ = this.renderButtonDisableEnableConfig.asObservable();

  /**Request to render results in non-portfolio */
  private focusOnSecuritiesConfig = new BehaviorSubject<boolean>(false);
  focusOnSecurities$ = this.focusOnSecuritiesConfig.asObservable();

  /**Request to render results in non-portfolio */
  private updateButtonNameSubject = new BehaviorSubject<string>("Refresh");
  UpdateButtonName$ = this.updateButtonNameSubject.asObservable();

  pushGridChanges = (modelOutPut: AssetCategoryModelsOutput) => {
    this.gridDataConfigSubject.next(modelOutPut);
  };

  updateSelectedAssetCategory = (assetCategory: TestInstrumentCategory) => {
    this.nonPortfolioSelectedAssetCatSub.next(assetCategory);
  };

  processRenderRequest(config: RenderButtonConfig) {
    this.renderButtonEventSubject.next(config);
  }

  changeRenderButtonEnabledState(input: boolean) {
    this.renderButtonDisableEnableConfig.next(input);
  }

  changeRenderButtonName(input: string) {
    this.updateButtonNameSubject.next(input);
  }

  focusOnSecurities(focus: boolean) {
    this.focusOnSecuritiesConfig.next(focus);
    // if (focus === true) {
    //   let source = interval(1000);
    //   //after 5 seconds, emit value
    //   let timer$ = timer(4000);
    //   //when timer emits after 5s, complete source
    //   const example = source.pipe(takeUntil(timer$));
    //   example.subscribe(val => {
    //     this.focusOnSecuritiesConfig.next(false)
    //   });
    // }
  }
}
