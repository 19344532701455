<div class="data-form-container">
	<div class="modal-header">
		<div class="modal-title">
			<h2 class="form-title">{{title}}</h2>
		</div>
		<div class="close-button">
			<div style="display: flex; flex-direction: row;">
				<div>
					<button mat-icon-button color="primary" [disabled]="isEditAction" matTooltip="Clear all inputs"
						(click)="ClearFormContents($event)">
						<mat-icon>clear_all</mat-icon>
					</button>
				</div>
				<div>
					<button mat-icon-button color="warn" matTooltip="Close this form" (click)="closeForm($event)">
						<mat-icon>close</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
	<!-- <mat-divider></mat-divider> -->
	<div class="form-actions">
		<div *ngIf="isEditAction;else editTemplate">
			<button mat-flat-button (click)="SaveAction($event)" [disabled]="disableActionControl" matTooltip="Update item"
				color="primary">Update
				<mat-icon>update</mat-icon>
			</button>
		</div>
		<ng-template #editTemplate>
			<button mat-flat-button (click)="SaveAction($event)" [disabled]="disableActionControl"
				matTooltip="Save new item" color="primary">Save
				<mat-icon>save</mat-icon>
			</button>
		</ng-template>
		<div class="delete-button">
			<button mat-button [disabled]="true" mat-stroked-button color="warn" (click)="DeleteAction($event)">
				<mat-icon>delete</mat-icon>
				Delete
			</button>
		</div>
	</div>
	<mat-divider color="accent"></mat-divider>
	<mat-dialog-content>
		<dx-form #dataForm>
		</dx-form>
	</mat-dialog-content>
	<mat-divider color="accent" class="form-bottom-divider"></mat-divider>

</div>

