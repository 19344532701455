import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'non-portfolio-regression-analysis',
  templateUrl: './non-portfolio-regression-analysis.component.html',
  styleUrls: ['./non-portfolio-regression-analysis.component.scss']
})
export class NonPortfolioRegressionAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
