import marked, { Renderer } from 'marked';
import DOMPurify from 'dompurify';

import {
	Component, Input, OnChanges, SimpleChange, ViewEncapsulation
} from '@angular/core';
import {
	DomSanitizer, SafeHtml
} from '@angular/platform-browser';

@Component({
	selector: 'app-markdown',
	template: '<pre [innerHTML]="data"></pre>',
	encapsulation: ViewEncapsulation.None
})
export class MarkdownComponent implements OnChanges {

	@Input() text: string;
	data: SafeHtml;
	md: any;


	constructor(private sanitizer: DomSanitizer) {
		const renderer = new Renderer();
		this.md = marked.setOptions({ renderer });
	}

	markdownToSafeHtml(value: string): SafeHtml {
		const html = this.md(value);
		const safeHtml = DOMPurify.sanitize(html);
		return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
	}

	ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
		for (const propName in changes) {
			if (propName === 'text') {
				const value = changes[propName].currentValue;
				if (value) {
					this.data = this.markdownToSafeHtml(value);
				}
			}
		}
	}
}