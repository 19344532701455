import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { OidcSecurityService, AuthorizationResult, EventTypes, OidcClientNotification } from 'angular-auth-oidc-client';
import { Observable, BehaviorSubject, Subscription, of, from, timer } from 'rxjs';
import { filter, delay } from 'rxjs/operators';
import { PublicEventsService } from 'angular-auth-oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationSettings, ApplicationDecimalPoints } from './app-services/app-settings/application-settings';
import { ApplicationSettingsService } from './app-services/app-settings/application-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsInformationComponent } from './utilities/settings-information/settings-information.component';
import { ReleaseInformationService } from './utilities/release-information/release-information.service';


import { Angulartics2Piwik } from 'angulartics2/piwik';
import { ConfigService } from './common/config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Innova Analytics';
  skipAuthentication: boolean;

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  userData: any;

  showAuthenticationErrorMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showAuthenticationErrorMessage$: Observable<boolean> = this.showAuthenticationErrorMessage.asObservable();

  private onAuthorizationResultSubscription: Subscription;
  private isAuthenticatedCheck1: Subscription;
  private isAuthenticatedCheck2: Subscription;
  private isAuthenticatedCheck3: Subscription;
  private oidcModuleSetupSubscription: Subscription;

  public currentBrowserTime$: Observable<Date>
  public currentServerTime$: Observable<Date>

  notificationDelaySubscription: Subscription
  httpServiceSubscription: Subscription
  currentCopyrightYear: any;

  constructor(public oidcSecurityService: OidcSecurityService, private eventService: PublicEventsService, private httpClient: HttpClient,
    private appSettingsService: ApplicationSettingsService, public dialog: MatDialog, public releaseInformationService: ReleaseInformationService, angulartics2Piwik: Angulartics2Piwik, private configService: ConfigService) {

    // analytics
    angulartics2Piwik.startTracking();
    // analytics

    this.currentCopyrightYear = new Date().getFullYear();

    this.onAuthorizationResultSubscription = this.eventService.registerForEvents().pipe(
      filter((notification) => notification.type === EventTypes.NewAuthorizationResult)
    ).subscribe((value: OidcClientNotification<AuthorizationResult>) => {

      if (value.value.validationResult === 'MaxOffsetExpired') {
        // get the current date here
        //get the current server time here too
        const serverTimeURL = this.configService.config.STSURL + '/TimeSettings/ReturnCurrentServerTime'
        // https://sts.analytics.innova.co.ke/TimeSettings/ReturnCurrentServerTime
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json').set('Accept', 'application/json');
        this.httpServiceSubscription = this.httpClient.get(serverTimeURL, { headers: headers }).subscribe((serverTime) => {
          //@ts-ignore
          let currentServerTime = serverTime.currentServerTime;
          this.currentServerTime$ = new Observable(sub => sub.next(new Date(currentServerTime)))
          this.currentBrowserTime$ = new Observable(sub => sub.next(new Date()))
        });
        this.showAuthenticationErrorMessage.next(true)
      }
    });

    if (this.configService.config.SkipAuthentication === true) {
      /**Skip authentication for test purposes */
      this.isAuthenticated.next(true);
    }
  }

  ngOnInit() {

    if (this.configService.config.SkipAuthentication !== true) {
      this.isAuthenticatedCheck1 = this.oidcSecurityService.isAuthenticated$.subscribe(auth => {
        this.isAuthenticated.next(auth);
        this.isAuthenticatedCheck2 = this.isAuthenticated$.subscribe((authentication: boolean) => {
          if (authentication === true) {
            this.isAuthenticatedCheck3 = this.oidcSecurityService.userData$.subscribe(userData => {
              this.userData = userData;
            });
            let notificationsDelay = timer(6000)
            this.notificationDelaySubscription = notificationsDelay.subscribe(_ => {
              //When the user has logged in successfully, show ther changes in the version 
              this.showRelevantInformation()
              this.releaseInformationService.checkVersionChanges()
            })
          }
        })
      });
    }
    else {
      this.isAuthenticated.next(true);
    }
    ////////////////////////////// Application Settings Section //////////////////////////////////////////////
  }

  showRelevantInformation() {

    let setupMessages: String[] = [];
    let percentageDecimalPoints = localStorage.getItem('pcntg-decPoints')
    if (percentageDecimalPoints === null) {
      let settings = new ApplicationSettings();
      settings.PercentageValuesDecimalPoints = ApplicationDecimalPoints.Two;
      this.appSettingsService.UpdateSettings(settings)
      let messageOne = 'Percentage values default decimal places set to 2'
      setupMessages.push(messageOne)
    }

    let normalFieldsDecimalPoints = localStorage.getItem('nrml-decPoints')
    if (normalFieldsDecimalPoints === null) {
      let settings = new ApplicationSettings();
      settings.NonPercentageValuesDecimalPoints = ApplicationDecimalPoints.Four;
      this.appSettingsService.UpdateSettings(settings)
      let messageOne = 'Non-percentage values decimal places set to 4'
      setupMessages.push(messageOne)
    }

    if (setupMessages.length !== 0) {
      this.dialog.open(SettingsInformationComponent, { data: { settingsInformation: setupMessages }, width: '450px', position: { bottom: '100px', right: '100px' }, disableClose: true })
    }
    ////////////////////////////// Application Settings Section //////////////////////////////////////////////

  }

  ngOnDestroy(): void {
    if (this.onAuthorizationResultSubscription !== undefined)
      this.onAuthorizationResultSubscription.unsubscribe()
    if (this.isAuthenticatedCheck1 !== undefined)
      this.isAuthenticatedCheck1.unsubscribe()
    if (this.isAuthenticatedCheck2 !== undefined)
      this.isAuthenticatedCheck2.unsubscribe()
    if (this.isAuthenticatedCheck3 !== undefined)
      this.isAuthenticatedCheck3.unsubscribe()
    if (this.oidcModuleSetupSubscription !== undefined)
      this.oidcModuleSetupSubscription.unsubscribe()
    if (this.notificationDelaySubscription !== undefined)
      this.notificationDelaySubscription.unsubscribe()
    if (this.httpServiceSubscription !== undefined)
      this.httpServiceSubscription.unsubscribe()
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

}
