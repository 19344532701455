<div>
  <dx-data-grid
    #resultsDataGrid
    [dataSource]="EntityDataArray"
    [columns]="EntityColumns"
    columnResizingMode="widget"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [showRowLines]="true"
    [groupPanel]="{ visible: true }"
    [grouping]="{ autoExpandAll: true }"
    [searchPanel]="{
      visible: true,
      placeholder: 'search for anything',
      width: 500
    }"
    [allowColumnReordering]="false"
    [rowAlternationEnabled]="true"
    [export]="{
      enabled: true,
      fileName: 'Non-Portfolio-Analytics',
      allowExportSelectedData: true
    }"
    [columnChooser]="{ enabled: true, mode: 'select' }"
    [wordWrapEnabled]="false"
    [columnFixing]="true"
    [twoWayBindingEnabled]="true"
    [repaintChangesOnly]="false"
    [activeStateEnabled]="true"
    (onCellPrepared)="gridInstanceCellPrepared($event)"
    [focusStateEnabled]="true"
    [focusedRowEnabled]="false"
    [scrolling]="{ mode: 'standard', preloadEnabled: true }"
    height="{{ calculateGridHeight() }}"
    (onCellPrepared)="gridInstanceCellPrepared($event)"
    Width="95%"
    [pager]="{
      allowedPageSizes: [20, 30, 50, 80, 100, 150, 200, 300],
      showPageSizeSelector: true,
      showInfo: true,
      visible: true
    }"
  >
    <dxo-selection mode="single" [deferred]="true"></dxo-selection>
    <dxo-sorting mode="single"> </dxo-sorting>
    <dxo-summary>
      <dxi-total-item
        *ngFor="let colDef of summaryColumns"
        [column]="colDef"
        summaryType="sum"
        [customizeText]="customizeSummaryText"
      >
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
