<div style="text-align: center; font-size: 200%; font-weight: 300;margin-bottom: 10px;">
    Whats
    new
    in
    Analytics
    UI
    version
    <span style="font-weight: 500;">{{data.version}}</span>
</div>

<ul>
    <li *ngFor="let item of notes"
        style="font-weight: 100; color: blue;">{{item}}</li>
</ul>

<div>
    application
    features
</div>


<div style="font-weight: 100; font-style: italic;">
    You
    can
    view
    this
    information
    later
    by
    clicking
    on
    <span> <button
            disabled
            color="accent">
            <i class="fa fa-envelope"
               aria-hidden="true"></i>
        </button></span>
    on
    the
    header
    section.
</div>



<div style="display: flex; flex-direction: row-reverse;">
    <div>
        <button mat-raised-button
                color="warn"
                matTooltip="Close this dialog"
                mat-button
                (click)="closeDialog()">
            <mat-icon>
                close
            </mat-icon>
            Close
        </button>
    </div>
</div>