import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/app-services/api/api.service";
import { ConfigService } from "src/app/common/config.service";

@Injectable({
  providedIn: "root",
})
export class CashTransactionImporterServiceService {
  private url = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private configService: ConfigService
  ) {
    this.url = this.configService.config.APIURL;
  }

  ImportcashTransactions(file: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/CashTransactionsImport/cashTransactionsImport`,
        file
      )
      .pipe(shareReplay());
  }

  SavecashTransactions(payload: any): Observable<any> {
    return this.apiService
      .Post("CashTransactionsImport/SaveParsedCashTransactions", payload)
      .pipe(shareReplay());
  }

  getcashTransactionsColumns(): any {
    let cashTransactionsColumns = [
      { caption: "Investor Code", dataField: "InvestorCode" },
      { caption: "Investor Name", dataField: "InvestorName" },
      { caption: "Investor", dataField: "Investor", groupIndex: 0 },
      { caption: "Transaction Type", dataField: "TransactionType" },
      { caption: "Amount", dataField: "Amount" },
      { caption: "Date", dataField: "Date" },
      { caption: "Notes", dataField: "Notes" },
    ];
    return cashTransactionsColumns;
  }
}
