<div class="portfolio-analytics-config-main">
  <!-- main config section -->
  <div class="portfolio-analytics-config-section">
    <div>
      <span
        id="analytics-start-date-caption"
        class="analytics-section-selection-title component-inputs-label"
        >Start Date</span
      >
      <dx-date-box
        id="analytics-start-date-input"
        displayFormat="dd-MMM-yyyy"
        #startDateBox
        matTooltip="Period start date"
        type="date"
      >
      </dx-date-box>
    </div>
    <div>
      <span
        id="analytics-end-date-caption"
        class="analytics-section-selection-title component-inputs-label"
        >End Date</span
      >
      <dx-date-box
        id="analytics-end-date-input"
        displayFormat="dd-MMM-yyyy"
        #endDateBox
        matTooltip="Period end date"
        type="date"
      >
      </dx-date-box>
    </div>
  </div>
  <div>
    <!--        <div *ngIf="_reportSubscription === undefined; else fetchingInProgressTemp">-->
    <button
      #refreshButton
      mat-flat-button
      color="primary"
      (click)="fetchReport()"
    >
      <mat-icon> update </mat-icon>
      Refresh
    </button>
    <!--        </div>-->

    <!--        <ng-template #fetchingInProgressTemp>-->
    <!--            <button mat-raised-button color="warn" (click)="closeRequest()" matTooltip="Cancel this request">-->
    <!--                <mat-icon>cancel_presentation</mat-icon>-->
    <!--                Cancel-->
    <!--            </button>-->
    <!--        </ng-template>-->
  </div>
</div>

<div style="margin-bottom: 5px"></div>
