import { Component, OnInit, OnDestroy } from "@angular/core";

import { ApiService } from "../../../app-services/api/api.service";
import { MatDialog } from "@angular/material/dialog";
import {
  DataFormOptions,
  DataFormComponent,
} from "src/app/app-directives/data-form/data-form.component";
import { Title } from "@angular/platform-browser";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-benchmark-types",
  templateUrl: "./benchmark-types.component.html",
  styleUrls: ["./benchmark-types.component.scss"],
})
export class BenchmarkTypesComponent implements OnInit, OnDestroy {
  gridDataSource: any;
  EntityName = "Benchmarks Types";
  GridKeyExpression = "ID";

  formItems: Array<any>;
  columns: any[];
  ApiSubscription1: Subscription = undefined;

  constructor(
    private apiService: ApiService,
    public matDialog: MatDialog,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName("Benchmark Types");

    this.columns = [
      {
        caption: "Name",
        dataField: "Name",
        cssClass: "grid-focus-column",
        alignment: "left",
        sortOrder: "asc",
      },
      { caption: "Notes", dataField: "Notes", alignment: "left", width: 500 },
      { caption: "Approved", dataField: "Approved", dataType: "boolean" },
      { caption: "Active", dataField: "Active", dataType: "boolean" },
    ];

    this.formItems = [
      {
        label: { text: "Name" },
        dataField: "Name",
        validationRules: [
          {
            type: "required",
            message: "Name is required",
          },
        ],
        cssClass: "required-form-item",
        editorType: "dxTextBox",
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true,
        },
      },
      {
        dataField: "Active",
        editorType: "dxCheckBox",
      },
      {
        colSpan: 2,
        dataField: "Notes",
        editorType: "dxTextArea",
        editorOptions: {
          height: 90,
        },
      },

      {
        dataField: "Approved",
        editorType: "dxCheckBox",
      },
    ];
  }

  ngOnInit() {
    this.ApiSubscription1 = this.apiService
      .Get("BenchmarkTypes/Get")
      .subscribe((result) => {
        this.gridDataSource = result;
      });
  }

  ngOnDestroy(): void {
    if (this.ApiSubscription1 !== undefined) {
      this.ApiSubscription1.unsubscribe();
    }
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = rowData;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "Edit Benchmark Types";
    dataFormOptions.postEndPoint = "BenchmarkTypes";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });
    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  AddBenchmark(event: any) {
    let dataFormOptions = new DataFormOptions();
    dataFormOptions.formData = null;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = "New Benchmark Type";
    dataFormOptions.postEndPoint = "BenchmarkTypes";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });

    /** A cheap but expensive way to refresh */
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}
