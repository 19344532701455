import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/app-services/api/api.service';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ApplicationSettingsService } from 'src/app/app-services/app-settings/application-settings.service';
import { HttpParams } from '@angular/common/http';
import { MatTabGroup } from '@angular/material/tabs';
import { HodingsGridColumnDefinitionsService } from './hodings-grid-column-definitions-service';
import { ReplaySubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NavbarHeaderSettingsService } from 'src/app/app-services/navbar-header-settings/navbar-header-settings.service';

@Component({
  selector: 'app-holdings',
  templateUrl: './holdings.component.html',
  styleUrls: ['./holdings.component.scss']
})
export class HoldingsComponent implements OnInit, AfterViewInit {

  @ViewChild('startDateBox', { static: true }) startDateBoxInstance: DxDateBoxComponent;
  @ViewChild('endDateBox', { static: true }) endDateBoxInstance: DxDateBoxComponent;
  @ViewChild('holdingTab', { static: true }) holdingsTabInstance: MatTabGroup

  fixedTBondsDataSource: any;
  floatingTBondDataSource: any;
  fixedCorporateBondsDataSource: any;
  floatingCorporateBondHoldings: any;
  tBillsDataSource: any;
  listedEquitiesDataSource: any;
  SelectedCategoryReplaySubject: ReplaySubject<any> = new ReplaySubject<string>(1);
  selectedCategory = '';



  constructor(private apiService: ApiService, private appSetttingsService: ApplicationSettingsService,
    public holdingsGridColumns: HodingsGridColumnDefinitionsService, private title: Title, private navBarSettingsService: NavbarHeaderSettingsService) {
    this.navBarSettingsService.ChangeActiveComponentName('Holdings')
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

    this.appSetttingsService.GetSettings().subscribe(settings => {
      this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
    });


    let selectedIndex = this.holdingsTabInstance.selectedIndex;
    if (selectedIndex === 0) {

      this.SelectedCategoryReplaySubject.next('Fixed Treasury Bonds');

      let params = new HttpParams();
      //@ts-ignore
      params = params.append('StartDate', this.startDateBoxInstance.value.toDateString());
      //@ts-ignore
      params = params.append('EndDate', this.startDateBoxInstance.value.toDateString());

      this.apiService.Get(`FixedTreasuryBondHoldings`, { params: params }).subscribe(res => {
        this.fixedTBondsDataSource = res;
      });
    }
  }


  openHoldings(tabChangeEvent, isRefreshAction: boolean) {

    let label = '';

    if (isRefreshAction === true) {

      // get the name of the category

      this.SelectedCategoryReplaySubject.subscribe(previouslySelectedCategory => {
        label = previouslySelectedCategory;
      });

    }
    else {
      label = tabChangeEvent.tab.textLabel;
      this.SelectedCategoryReplaySubject.next(label);
    }



    let params = new HttpParams();
    //@ts-ignore
    params = params.append('StartDate', this.startDateBoxInstance.value.toDateString());
    //@ts-ignore
    params = params.append('EndDate', this.startDateBoxInstance.value.toDateString());

    if (label === 'Fixed Treasury Bonds') {
      this.apiService.Get(`FixedTreasuryBondHoldings`, { params: params }).subscribe(res => {
        this.fixedTBondsDataSource = res
      });
    }
    if (label === 'Floating Treasury Bonds') {
      this.apiService.Get(`FloatingTreasuryBondHoldings`, { params: params }).subscribe(res => {
        this.floatingTBondDataSource = res;
      });
    }
    if (label === 'Fixed Corporate Bonds') {
      this.apiService.Get(`FixedCorporateBondHoldings`, { params: params }).subscribe(res => {
        this.fixedCorporateBondsDataSource = res;
      });
    }
    if (label === 'Floating Corporate Bonds') {
      this.apiService.Get(`FloatingCorporateBondHoldings`, { params: params }).subscribe(res => {
        this.floatingCorporateBondHoldings = res;
      });
    }
    if (label === 'Treasury Bills') {
      this.apiService.Get(`TreasuryBillHoldings`, { params: params }).subscribe(res => {
        this.tBillsDataSource = res;
      });
    }
    if (label === 'Listed Equities') {
      this.apiService.Get(`ListedEquityHoldings`, { params: params }).subscribe(res => {
        this.listedEquitiesDataSource = res;
      });
    }
    else {

    }
  }

}
