import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import CustomStore from "devextreme/data/custom_store";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
  DataFormOptions,
  DataFormComponent,
} from "src/app/app-directives/data-form/data-form.component";
import { ApiService } from "src/app/app-services/api/api.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { InstrumentCategoriesService } from "src/app/app-services/services/instrument-categories.service";
import { InvestorService } from "src/app/app-services/services/investor.service";
import { map } from "underscore";
import { BenchmarksService } from "../../bench-marks/bench-marks-view/benchmarks.service";

@Component({
  selector: "app-instrument-categories",
  templateUrl: "./instrument-categories.component.html",
  styleUrls: ["./instrument-categories.component.scss"],
})
export class InstrumentCategoriesComponent implements OnInit {
  InstrumentCategories$: Observable<any>;
  gridDataSource: any;
  EntityName = "Asset Categories";
  GridKeyExpression = "Name";

  public Columns: any = [
    {
      caption: "Name",
      dataField: "Name",
      cssClass: "grid-focus-column",
      alignment: "left",
      sortOrder: "acs",
    },
    { caption: "Asset Class", dataField: "InstrumentClass" },
    { caption: "Order", dataField: "Order" },
    { caption: "Investor", dataField: "Investor" },
    { caption: "Display Name", dataField: "DisplayName" },
  ];

  formItems: Array<any>;

  constructor(
    private instrumentCategoriesService: InstrumentCategoriesService,
    private apiService: ApiService,
    public matDialog: MatDialog,
    private title: Title,
    private navBarSettingsService: NavbarHeaderSettingsService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(this.EntityName);

    this.formItems = [
      {
        label: { text: "Display Name" },
        dataField: "DisplayName",
        cssClass: "required-form-item",
        editorType: "dxTextBox",
        editorOptions: {
          activeStateEnabled: true,
          showClearButton: true,
        },
      },
    ];
  }

  ngOnInit() {
    this.InstrumentCategories$ =
      this.instrumentCategoriesService.loadInstrumentCategories();
  }

  rowDoubleClicked(rowData: any) {
    let dataFormOptions = new DataFormOptions();
    let assetCategoryName = rowData.Name;
    dataFormOptions.formData = rowData;
    dataFormOptions.formItems = this.formItems;
    dataFormOptions.formTitle = `Edit ${assetCategoryName} Display Name`;
    dataFormOptions.postEndPoint = "InstrumentCategories";
    let dialogRef = this.matDialog.open(DataFormComponent, {
      width: "800px",
      data: dataFormOptions,
    });
  }
}
