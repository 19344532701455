import { Component, OnInit, Input, OnDestroy, Inject } from "@angular/core";
import { LayoutService } from "../../../shared-module/services/layout.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { MatDialog } from "@angular/material/dialog";
import { AboutPageComponent } from "src/app/utilities/about-page/about-page.component";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { SettingsComponent } from "src/app/app-components/settings/settings.component";
import { ReleaseInfoViewComponent } from "src/app/utilities/release-info-view/release-info-view.component";
import { APP_CONFIG, AppConfig } from "src/app/app.config";
import { ConfigService } from "src/app/common/config.service";
import { browser } from "protractor";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import {
  Tenant,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";
import { ReportsComponent } from "src/app/reports-module/reports/reports.component";
import { OidcGuardService } from "src/app/providers/oidc-guard/oidc-guard.service";
import { ZambiaSecReportComponent } from "src/app/reports-module/reports/ZambiaSecReports/zambia-sec-report/zambia-sec-report.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;

  isDemoMode: boolean;
  isRegulator: boolean;
  isStagingMode: boolean;
  SkipAuthentication: boolean;

  constructor(
    private layoutService: LayoutService,
    public oidcSecurityService: OidcSecurityService,
    public dialog: MatDialog,
    public navBarSettingsService: NavbarHeaderSettingsService,
    private router: Router,
    private tenantResolverService: TenantService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private configService: ConfigService
  ) {
    /** If is demo, mode, show the  DEMO-VERSION flag */
    this.isDemoMode = this.configService.config.ShowDemoModeTagOnHeader;
    this.SkipAuthentication = this.configService.config.SkipAuthentication;
  }

  isAuthenticated: boolean;
  decodedAccessToken: any;
  tenantName: string;

  ngOnInit() {
    this.oidcSecurityService.isAuthenticated$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    this.isRegulator = this.tenantResolverService.isRegulator();
    this.isStagingMode = this.tenantResolverService.isStaging();
    this.tenantName = this.tenantResolverService.getUserName().toUpperCase();
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => {
      window.location.href = `${this.configService.config.STSURL}/account/logout?redirectUri=${this.configService.config.MatrixUIURL}`;
    });
  }

  logoffAndRevokeTokens() {
    this.oidcSecurityService
      .logoffAndRevokeTokens()
      .subscribe((result) => console.log(result));
  }

  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }

  showAboutInformation(event: any) {
    this.dialog.open(AboutPageComponent, {
      width: "300px",
    });
  }

  updateSettings(eventData: any) {
    this.dialog.open(SettingsComponent, {
      width: "800px",
    });
  }

  showReleaseInfo() {
    let releaseNotes = this.config.ReleaseNotes;
    let version = this.config.AppVersion;
    this.dialog.open(ReleaseInfoViewComponent, {
      data: { notes: releaseNotes, version: version },
      maxWidth: "700px",
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
