<div *ngIf="httpErrorResponse !== undefined">
	<mat-toolbar color="warn" class="error-toolbar">

		<div class="autoMargin">
			<div>Error</div>
		</div>
	</mat-toolbar>


	<mat-dialog-content>
		<div class="error-content-wrapper">

			<div class="http-error-message"><span> {{httpErrorResponse.message}}</span></div>
			<div style="margin-bottom: 5px;">----------------------------</div>
			<div class="http-error-message"><span> {{httpErrorResponse.error.ExceptionMessage}}</span></div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="close-button">
		<button color="warn" mat-button (click)="Close()">
			<mat-icon>close</mat-icon>Close
		</button>
	</mat-dialog-actions>
</div>