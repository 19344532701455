<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Select Securities and Models </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
    <div class="portfolio-analytics-dates-section">
      <div class="dates-container">
        <div>
          <span id="analytics-start-date-caption" class="component-inputs-label"
            >Start Date</span
          >
          <dx-date-box
            id="analytics-start-date-input"
            displayFormat="dd MMM yyyy"
            #startDateBox
            matTooltip="Period start date"
          >
          </dx-date-box>
        </div>
        <div>
          <span id="analytics-end-date-caption" class="component-inputs-label"
            >End Date</span
          >
          <dx-date-box
            id="analytics-end-date-input"
            displayFormat="dd MMM yyyy"
            #endDateBox
            matTooltip="Period end date"
          >
          </dx-date-box>
        </div>
      </div>

      <div class="render-button">
        <div>
          <div
            *ngIf="
              _apiFetchSubscription === undefined;
              else fetchingInProgressTemp
            "
          >
            <button
              #refreshButton
              mat-flat-button
              color="primary"
              (click)="fetchResults()"
            >
              <mat-icon> update </mat-icon>
              Refresh
            </button>
          </div>

          <ng-template #fetchingInProgressTemp>
            <button
              mat-raised-button
              color="warn"
              (click)="closeAnalyticsFetchRequest()"
              matTooltip="Cancel this request"
            >
              <mat-icon> cancel_presentation </mat-icon>
              Cancel
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- Fund and asset category and model groups ----------------------------------- -->
    <div class="funds-asset-cats-container">
      <div class="funds-section">
        <!-- <div class="fund-title">
                Fund Manager / Funds
            </div> -->
        <mat-divider></mat-divider>
        <dx-tree-list
          height="400"
          #fundManagerTreeList
          [dataSource]="FundsFundManagerDataSource"
          keyExpr="ID"
          parentIdExpr="Head_ID"
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [showBorders]="true"
          (onSelectionChanged)="ModelGroupsItemsSelected($event)"
          noDataText="no data to display..."
          class="tree-list"
          [highlightChanges]="true"
          [repaintChangesOnly]="true"
          [focusedRowEnabled]="false"
          [showBorders]="true"
          [autoExpandAll]="false"
          [hoverStateEnabled]="true"
          [focusStateEnabled]="true"
        >
          <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"> </dxo-sorting>
          <dxi-column
            dataField="Name"
            caption="Select Funds under a Fund Manager"
            sortOrder="asc"
            style="font-size: 200%; font-weight: 700; color: black"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
      <div class="asset-cats-section">
        <!-- !test on [selectedIndex]="2" return to [selectedIndex]="0"-->
        <dx-tab-panel
          #assetCategoriesPanel
          [dataSource]="assetCategoryTabsObservable | async"
          [showNavButtons]="true"
          [selectedIndex]="0"
          [loop]="false"
          [animationEnabled]="true"
          [swipeEnabled]="true"
          height="400"
          [deferRendering]="false"
        >
          <div *dxTemplate="let item of 'title'">
            <div [ngStyle]="item.iconStyle" matTooltip="{{ item.content }}">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div>
                  <span>
                    <mat-icon>{{ item.icon }}</mat-icon>
                  </span>
                </div>
                <div>
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>

          <div
            *dxTemplate="let selectedAsstCat of 'item'"
            style="overflow-y: scroll"
          >
            <app-portfolio-models-view
              [ModelGroupItems]="ModelGroupItems"
              (ModelsSelectionUpdateEvent)="modelsSelectionUpdate($event)"
              [SelectedAssetCategory]="selectedAsstCat.text"
            >
            </app-portfolio-models-view>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> View Output Data </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="results-section">
      <div>
        <div style="margin-left: 20px; font-weight: 300">
          Modify the output here. Changes to this inputs trigger an automatic
          reload with the updated data displayed on the grid.
        </div>
        <div class="results-controls">
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 110%;
              "
            >
              <div style="font-weight: 400">Return Types?</div>
              <div>
                <dx-radio-group
                  #returnTypeGroup
                  [items]="returnTypesOptions"
                  layout="horizontal"
                  color="accent"
                >
                  <div *dxTemplate="let returnItem of 'item'">
                    <div [matTooltip]="returnItem.helpInformation">
                      {{ returnItem.text }}
                    </div>
                  </div>
                </dx-radio-group>
              </div>
            </div>
          </div>
          <div>
            <mat-divider [vertical]="true" style="height: 40px"></mat-divider>
          </div>
          <div>
            <!-- <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;"> -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 110%;
              "
            >
              <div style="font-weight: 500">Weighted?</div>
              <div>
                <dx-radio-group
                  #weightRadioGroup
                  [items]="weightingOptions"
                  layout="horizontal"
                >
                </dx-radio-group>
              </div>
            </div>
          </div>
          <div>
            <mat-divider
              [vertical]="true"
              style="height: 40px; width: 50px"
              color="accent"
            ></mat-divider>
          </div>
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 30%;
              "
            >
              <div style="font-weight: 500; margin-right: 10px">Benchmark</div>
              <div>
                <dx-lookup
                  [width]="200"
                  placeholder="Benchmark"
                  #benchmarksLookup
                >
                  <dxo-drop-down-options
                    [showTitle]="false"
                  ></dxo-drop-down-options>
                </dx-lookup>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-tab-group
        #resultsTab
        [disableRipple]="true"
        [disablePagination]="true"
        (selectedTabChange)="refreshGrid($event)"
      >
        <mat-tab
          *ngFor="
            let item of DisplayModelCategoryResultsObservable | async;
            let i = index
          "
        >
          <ng-template mat-tab-label>
            {{ item.modelCategory }}
          </ng-template>

          <div style="width: 100%" id="{{ i }}">
            <dx-data-grid
              #resultsDataGrid
              columnResizingMode="widget"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [showBorders]="true"
              [showRowLines]="true"
              [groupPanel]="{ visible: true }"
              [searchPanel]="{
                visible: true,
                placeholder: 'search for anything',
                width: 500
              }"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="false"
              [export]="{
                enabled: true,
                fileName: 'Non-Portfolio-Analytics',
                allowExportSelectedData: true
              }"
              [selection]="{ mode: 'single' }"
              [columnChooser]="{ enabled: true, mode: 'select' }"
              [wordWrapEnabled]="true"
              [columnFixing]="true"
              [twoWayBindingEnabled]="true"
              [repaintChangesOnly]="false"
              [dataSource]="item.dataSource"
              [columns]="item.columns"
              (onCellPrepared)="gridInstanceCellPrepared($event)"
              [scrolling]="{ mode: 'standard', preloadEnabled: true }"
              [height]="400"
              [keyExpr]="gridKeyExpression"
              [focusStateEnabled]="true"
              [focusedRowEnabled]="true"
            >
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-sorting mode="multiple">
                <!-- or "multiple" | "none" -->
              </dxo-sorting>

              <div *ngIf="weightRadioGroup.value == 'True'">
                <dxo-summary>
                  <dxi-group-item
                    *ngFor="let colDef of item.summaryColumnNames"
                    [column]="colDef"
                    summaryType="sum"
                    [customizeText]="customizeSummaryText"
                    [showInGroupFooter]="true"
                    [alignByColumn]="true"
                  >
                  </dxi-group-item>
                </dxo-summary>
              </div>
            </dx-data-grid>
          </div>
        </mat-tab>
        <ng-template #placeHolderTemplate> no data guys </ng-template>
      </mat-tab-group>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<app-chat-bot currentParentId="portfolioanalysis"></app-chat-bot>
