import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'src/app/app-directives/loading-dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {

  dialogOpenend: MatDialogRef<LoadingDialogComponent, any>;

  constructor(public dialog: MatDialog) {

  }

  openDialog(): void {
    this.dialogOpenend = this.dialog.open(LoadingDialogComponent, {
      width: '250px',
      closeOnNavigation: true,
      disableClose: true

    });

    this.dialogOpenend.afterClosed().subscribe(result => {
      console.error('The dialog was closed');
    });
  }

  closeDialog(): void {
    this.dialogOpenend.close()
  }
}
