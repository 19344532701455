import { Component, OnInit, Input, HostListener } from "@angular/core";
import {
  Regulators,
  TenantService,
} from "src/app/app-services/TenantService/tenant.service";
import { LayoutService } from "../../../shared-module/services/layout.service";

@Component({
  selector: "app-left-panel",
  templateUrl: "./left-panel.component.html",
  styleUrls: ["./left-panel.component.scss"],
})
export class LeftPanelComponent implements OnInit {
  asidebarHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  title: any;
  menuList: MenuList[] = [];
  selected: any;
  activeTenantType: any;
  constructor(
    private layoutService: LayoutService,
    private tenantResolverService: TenantService
  ) {}

  isActive(item) {
    return this.selected === item;
  }
  onItemSelect(item) {
    this.selected = this.selected === item ? item : item;
  }
  onSubItemSelect(item) {
    this.selected = this.selected === item ? item : item;
  }

  @HostListener("window:resize", ["$event"])
  onResizeHeight(event: any) {
    this.asidebarHeight = window.innerHeight;
  }

  ngOnInit() {
    this.layoutService.setAsidebarHeightCast.subscribe(
      (setSidebarHeight) => (this.asidebarHeight = setSidebarHeight)
    );

    this.activeTenantType = this.tenantResolverService.getTenantType();

    //Route Hiding
    //1. hide non - display routes for all tenants i.e Regulators, custodian, fund manager
    //2. hide FM - display for Regulators and fund manager
    //1. hide Custodian - display for Regulators and fund manager

    this.title = "Navigation";
    this.menuList = [
      {
        name: "Dashboard",
        icon: "fas fa-tachometer-alt",
        url: "/dashboard",
        hide: "non",
      },
      {
        name: "Non-Portfolio Analytics",
        icon: "fas fa-chart-bar",
        badge: "",
        badgeBg: "bg-success",
        hide: "non",
        subMenu: [
          {
            name: "Macro & Security Analysis",
            icon: "far fa-circle",
            url: "/macro-security-analysis",
            hide: "non",
          },
          {
            name: "Fundamental Analysis",
            icon: "far fa-circle",
            url: "/fundamental-analysis",
            hide: "non",
          },
          {
            name: "Correlation Analysis",
            icon: "far fa-circle",
            url: "/non-portfolio-correlation-analysis",
            hide: "non",
          },
          {
            name: "Regression Analysis",
            icon: "far fa-circle",
            url: "/non-portfolio-regression-analysis",
            hide: "non",
          },
          {
            name: "Multivariate Analysis",
            icon: "far fa-circle",
            url: "/non-portfolio-multivariate-analysis",
            hide: "non",
          },
          {
            name: "Predictive Analysis",
            icon: "far fa-circle",
            url: "/predictive-analysis",
            hide: "non",
          },
        ],
      },
      {
        name: "Portfolio Analytics",
        icon: "fas fa-chart-line",
        // badge: "updated",
        badgeBg: "bg-success",
        hide: "non",
        subMenu: [
          {
            name: "Portfolio Models",
            icon: "far fa-circle",
            url: "/portfolio-analytics",
            hide: "non",
          },
          {
            name: "Performance Attribution",
            icon: "far fa-circle",
            url: "/performance-attribution",
            hide: "non",
          },
          {
            name: "Correlation Analysis",
            icon: "far fa-circle",
            url: "/portfolio-correlation-analysis",
            hide: "non",
          },
          {
            name: "Regression Analysis",
            icon: "far fa-circle",
            url: "/portfolio-regression-analysis",
            hide: "non",
          },
          {
            name: "Portfolio Optimization",
            icon: "far fa-circle",
            url: "/portfolio-optimization",
            hide: "non",
          },
          {
            name: "Portfolio Rebalancing",
            icon: "far fa-circle",
            url: "/portfolio-rebalancing",
            hide: "non",
          },
          {
            name: "Peer Group Analysis",
            icon: "far fa-circle",
            url: "/portfolio-peer-group-analysis",
            hide: "non",
          },
          {
            name: "Regulatory Compliance",
            icon: "far fa-circle",
            url: "/regulatory-compliance",
            hide: "non",
          },
        ],
      },
      {
        name: "Asset Registry",
        badge: "updated",
        badgeBg: "bg-success",
        icon: "fas fa-shopping-basket",
        hide: "non",
        subMenu: [
          {
            name: "Non-Portfolio Registry",
            icon: "far fa-circle",
            url: "/nonportfolio-asset-registry",
            hide: "non",
          },
          {
            name: "Fund Manager Registry",
            icon: "far fa-circle",
            url: "/asset-registry",
            hide: "custodian",
          },
          {
            name: "Custodian Registry",
            icon: "far fa-circle",
            url: "/custodian-asset-registry",
            hide: "fundManager",
          },
        ],
      },
      {
        name: "Funds",
        icon: "far fa-user-circle",
        url: "/investors",
        badgeBg: "bg-danger",
        hide: "non",
      },

      {
        name: "Securities",
        icon: "far fa-paper-plane",
        hide: "non",
        subMenu: [
          {
            name: "Listed Equities",
            icon: "far fa-circle",
            url: "/listed-equities",
            hide: "non",
          },
          {
            name: "Commercial Papers",
            icon: "far fa-circle",
            url: "/commercial-papers",
            hide: "non",
          },
          {
            name: "Fixed Treasury Bonds",
            icon: "far fa-circle",
            url: "/fixed-treasury-bonds",
            hide: "non",
          },
          // {
          //   name: "Floating Treasury Bonds",
          //   icon: "far fa-circle",
          //   url: "/floating-treasury-bonds",
          // },
          {
            name: "Fixed Corporate Bonds",
            icon: "far fa-circle",
            url: "/fixed-corporate-bonds",
            hide: "non",
          },
          {
            name: "Treasury Bills",
            icon: "far fa-circle",
            url: "/treasury-bills",
            hide: "non",
          },
          {
            name: "Offshores",
            icon: "far fa-circle",
            url: "/offshores",
            hide: "non",
          },
          {
            name: "Unit Trusts",
            icon: "far fa-circle",
            url: "/unit-trusts",
            hide: "non",
          },
        ],
      },
      {
        name: "Asset Classes",
        icon: "far fa-gem",
        url: "/asset-classes-setup",
        hide: "non",
      },
      {
        name: "Asset Categories",
        icon: "far fa-edit",
        url: "/asset-categories",
        badgeBg: "bg-danger",
        hide: "non",
      },
      {
        name: "Benchmarks Setup",
        icon: "fas fa-balance-scale",
        hide: "non",
        subMenu: [
          {
            name: "Benchmark Types",
            icon: "far fa-circle",
            url: "/benchmark-types",
            hide: "non",
          },
          {
            name: "Benchmarks",
            icon: "far fa-circle",
            url: "/benchmarks",
            hide: "non",
          },
        ],
      },
      {
        name: "Compliance Setup",
        icon: "far fa-bookmark",
        hide: "non",
        subMenu: [
          {
            name: "Limit Types",
            icon: "far fa-circle",
            url: "/limit-types",
            hide: "non",
          },
          {
            name: "Limits",
            icon: "far fa-circle",
            url: "/limits",
            hide: "non",
          },
        ],
      },
      {
        name: "General Setup",
        icon: "fas fa-dice-d20",
        badgeBg: "bg-success",
        hide: "non",
        subMenu: [
          {
            name: "Users",
            icon: "far fa-circle",
            url: "/users",
            hide: "non",
          },
          {
            name: "Investor Types",
            icon: "far fa-circle",
            url: "/investor-types",
            hide: "non",
          },
          {
            name: "Investor Sub Types",
            icon: "far fa-circle",
            url: "/investor-sub-types",
            hide: "non",
          },
          {
            name: "Banks",
            icon: "far fa-circle",
            url: "/banks",
            hide: "non",
          },
          {
            name: "Countries",
            icon: "far fa-circle",
            url: "/countries",
            hide: "non",
          },
        ],
      },
      {
        name: "Data Importer",
        icon: "fas fa-file-import",
        badge: "updated",
        badgeBg: "bg-success",
        hide: "non",
        subMenu: [
          {
            name: "Listed Equities",
            icon: "far fa-circle",
            url: "/equities-data-importer",
            hide: "non",
          },
          {
            name: "Fixed Deposits",
            icon: "far fa-circle",
            url: "/deposits-data-importer",
            hide: "non",
          },
          {
            name: "Treasury Bonds",
            icon: "far fa-circle",
            url: "/treasury-bonds-data-importer",
            hide: "non",
          },
          {
            name: "Corporate Bonds",
            icon: "far fa-circle",
            url: "/corporate-bonds-data-importer",
            hide: "non",
          },
          {
            name: "Treasury Bills",
            icon: "far fa-circle",
            url: "/treasury-bills-data-importer",
            hide: "non",
          },
          {
            name: "Commercial Paper",
            icon: "far fa-circle",
            url: "/commercial-paper-data-importer",
            hide: "non",
          },
          {
            name: "Coupon Payments",
            icon: "far fa-circle",
            url: "/coupon-payments-importer",
            hide: "non",
          },
          {
            name: "Dividends",
            icon: "far fa-circle",
            url: "/dividends-importer",
            hide: "non",
          },
          {
            name: "Cash Transactions",
            icon: "far fa-circle",
            url: "/cash-transactions-importer",
            hide: "non",
          },
        ],
      },

      {
        name: "Reports",
        icon: "fa fa-table",
        hide: "non",
        subMenu: [
          {
            name: "Regulatory Compliance Reports",
            icon: "far fa-circle",
            url: "/reports",
            hide: "non",
          },
          {
            name: "Reconciliation Reports",
            icon: "far fa-circle",
            url: "/recon-report",
            hide: "non",
          },
        ],
      },
    ];
  }
}

export class MenuList {}
