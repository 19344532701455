import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxDateBoxComponent, DxLookupComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/app-services/api/api.service';
import { ApplicationSettingsService } from 'src/app/app-services/app-settings/application-settings.service';
import { SelectedFundConfigService } from 'src/app/app-services/selected-fund-config/selected-fund-config.service';
import { SelectedFundItemsConfig } from '../../models/selected-fund-items-config';

@Component({
  selector: 'app-fund-config-form',
  templateUrl: './fund-config-form.component.html',
  styleUrls: ['./fund-config-form.component.scss']
})
export class FundConfigFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('startDateBox', { static: false }) startDateBoxInstance: DxDateBoxComponent;
  @ViewChild('endDateBox', { static: false }) endDateBoxInstance: DxDateBoxComponent;


  SelectedStartDate: string;
  SelectedEndDate: string;



  private _appSettingsServiceSubscription: Subscription
  private _investorsSubscription: Subscription
  private _startDateChangeSubscription: Subscription;
  private _reportSubscription: Subscription;
  private _apiServiceSubscription: Subscription;

  /**
   * Refresh the report
   */
  @Output() RefreshReport = new EventEmitter<any>()

  constructor(private settingsService: ApplicationSettingsService, public apiService: ApiService, public selectedFundConfigService: SelectedFundConfigService) { }
  ngOnDestroy(): void {
    if (this._appSettingsServiceSubscription !== undefined)
      this._appSettingsServiceSubscription.unsubscribe()
    if (this._investorsSubscription !== undefined)
      this._investorsSubscription.unsubscribe()
    if (this._startDateChangeSubscription !== undefined)
      this._startDateChangeSubscription.unsubscribe()
    if (this._reportSubscription !== undefined)
      this._reportSubscription.unsubscribe()
    if (this._apiServiceSubscription !== undefined)
      this._apiServiceSubscription.unsubscribe()
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.startDateBoxInstance.type ='date'
    this.endDateBoxInstance.type ='date'

    this._appSettingsServiceSubscription = this.settingsService.GetSettings().subscribe((settings) => {
      this.startDateBoxInstance.value = settings.DefaultDateRange.StartDate;
      this.endDateBoxInstance.value = settings.DefaultDateRange.EndDate;
      this.endDateBoxInstance.min = this.startDateBoxInstance.value
    });

    this._startDateChangeSubscription = this.startDateBoxInstance.onValueChanged.subscribe(newDateChange => {
      this.endDateBoxInstance.min = newDateChange.value;
    })
    

  }

  fetchReport() {
    // this.selectedFundConfigService.updateSelectedFundConfig(selectedFundConfig);
    //@ts-ignore
    this.RefreshReport.next({startDate: this.startDateBoxInstance.value, endDate: this.endDateBoxInstance.value});

  }

  closeRequest() {
    if (this._reportSubscription !== undefined) {
      this._reportSubscription.unsubscribe()
      this._reportSubscription = undefined;
    }
  }

}
