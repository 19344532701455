import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  DxDataGridComponent,
  DxDateBoxComponent,
  DxLookupComponent,
  DxSwitchComponent,
  DxTreeListComponent,
} from "devextreme-angular";
import { Subject } from "rxjs";
import { ApplicationSettings } from "src/app/app-services/app-settings/application-settings";
import { ApplicationSettingsService } from "src/app/app-services/app-settings/application-settings.service";
import { NavbarHeaderSettingsService } from "src/app/app-services/navbar-header-settings/navbar-header-settings.service";
import { NotificationService } from "src/app/app-services/notification/notification.service";
import _, { first, result } from "underscore";
import {
  FundamentalAnalysisService,
  FundamentalModels,
} from "./fundamental-analysis.service";
import { v4 as uuidv4 } from "uuid";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: "app-fundamental-analysis",
  templateUrl: "./fundamental-analysis.component.html",
  styleUrls: ["./fundamental-analysis.component.scss"],
})
export class FundamentalAnalysisComponent implements OnInit {
  @ViewChild("endDateBox", { static: true })
  endDateBoxInstance: DxDateBoxComponent;
  @ViewChild("startDateBox", { static: true })
  startDateBoxInstance: DxDateBoxComponent;
  @ViewChild("StatementLookup", { static: false })
  statementLookupInstance: DxLookupComponent;
  @ViewChild("securityTreeList", { static: false })
  securityTreeListInstance: DxTreeListComponent;
  @ViewChildren("modelTreeList")
  modelGroupsChildren: QueryList<DxTreeListComponent>;
  @ViewChild("showInputDataSwitch", { static: false })
  showInputSwitch: DxSwitchComponent;
  @ViewChildren("outputDataGrid")
  outputDataGrids: QueryList<DxDataGridComponent>;

  models: FundamentalModels[];
  listedSecurities: any[];
  listedSecuritiesFinal: any[];
  InputData: any[];
  firstAccountData: any[];
  selectedStatement: any;
  applicationSettings: ApplicationSettings;
  sectorsBuilder: any[];
  finalModelsSelection: any[] = [];
  StatementDataSource: any[] = [
    "balance sheet",
    "income statement",
    "cashflow statement",
  ];
  tabData: any[] = [{ Name: "Fundamental Analysis", Icon: "reorder" }];
  panelOpenState = false;
  hideInputDataAccordion: boolean = false;

  //observables

  DisplayAccountDataInput: Subject<any> = new Subject<any>();
  DisplayAccountDataInputObservable =
    this.DisplayAccountDataInput.asObservable();

  DisplayModelOutputData: Subject<any> = new Subject<any>();
  DisplayModelOutputDataObservable = this.DisplayModelOutputData.asObservable();

  constructor(
    private fundamentalAnalysisService: FundamentalAnalysisService,
    private navBarSettingsService: NavbarHeaderSettingsService,
    public appSettingsService: ApplicationSettingsService,
    private notificationService: NotificationService
  ) {
    this.navBarSettingsService.ChangeActiveComponentName(
      "Fundamental Analysis"
    );
  }

  ngOnInit(): void {
    //fetch Models
    this.models = this.fundamentalAnalysisService.getAllModels();

    //fetch Listed Equities
    this.fundamentalAnalysisService.getAllListedSecurities().subscribe(
      (res) => {
        //filter country exchanges where companies != null
        const countryExchangesNotNull = res.filter(
          (item) => item.Companies.length > 0
        );

        let nodes: any[] = [];
        countryExchangesNotNull.forEach((data) => {
          //Root - country
          nodes.push({
            Name: data.Country,
            ID: data.CountryId,
            Head_ID: 0,
          });

          data.Companies.forEach((security) => {
            //Parent - Sector
            nodes.push({
              Name: security.Sector,
              ID: security.SectorId,
              Head_ID: data.CountryId,
            });

            //child - Security
            nodes.push({
              Name: security.Name,
              ID: security.Id,
              Head_ID: security.SectorId,
            });
          });
        });

        // //get unique parent, remove redundancy
        nodes = [...new Map(nodes.map((item) => [item["ID"], item])).values()];
        this.listedSecuritiesFinal = nodes;
      },
      (err) => {}
    );
  }

  //Fetch Data
  FetchData() {
    //reset data to null
    this.DisplayAccountDataInput.next(null);
    this.DisplayModelOutputData.next(null);
    let selectedSecurities =
      this.securityTreeListInstance.instance.getSelectedRowsData(
        "includeRecursive"
      );

    if (selectedSecurities.length == 0) {
      this.notificationService.showError(
        "Select at least one security",
        "Error"
      );
      return;
    }
    if (this.finalModelsSelection.length == 0) {
      this.notificationService.showError("Select at least one Model", "Error");
      return;
    } else {
      //flatten securities
      let securities = selectedSecurities.filter((item) => item.Head_ID !== 0);
      let securitiesIDs = securities.map((sec) => sec.ID);

      //Make 2 parallel request - one for input data if InputDataRequest: true, and the other one for output data

      if (this.showInputSwitch.value == true) {
        let payload = {
          Securities: securitiesIDs,
          StartDate: this.startDateBoxInstance.value,
          EndDate: this.endDateBoxInstance.value,
          ModelGroups: this.finalModelsSelection,
          InputDataRequest: this.showInputSwitch.value,
          Weighted: false,
        };

        this.fundamentalAnalysisService
          .getAccountData(payload)
          .subscribe((res) => {
            //Input Data
            let inputData = res["Data"]["InputData"];
            let firstData = inputData[0].FinancialStatementSecuritiesData[0];
            this.BindInputData(inputData, firstData);
          });
      }

      //output data request
      let payload = {
        Securities: securitiesIDs,
        StartDate: this.startDateBoxInstance.value,
        EndDate: this.endDateBoxInstance.value,
        ModelGroups: this.finalModelsSelection,
        InputDataRequest: false,
        Weighted: false,
      };

      this.fundamentalAnalysisService
        .getAccountData(payload)
        .subscribe((res) => {
          //Output Data
          let modelOutputData = res["Data"]["ModelGroupsOutput"];
          this.BindOutputData(modelOutputData);
        });
    }
  }

  BindInputData(inputData: any[], firstData: any[]) {
    if (firstData.length != 0) {
      //The parent account AccountData sometimes null, use traverse down get the columns
      if (firstData[0].AccountData.length != 0) {
        this.firstAccountData = firstData[0].AccountData;
      } else {
        firstData.forEach((financialData) => {
          let accountData = financialData.AccountData;
          if (accountData.length != 0) {
            this.firstAccountData = accountData;
            return;
          } else {
            financialData.ChildAccounts.forEach((childAccounts) => {
              let accountData = childAccounts.AccountData;
              if (accountData.length != 0) {
                this.firstAccountData = accountData;
                return;
              }
            });
          }
        });
      }
      //customize columns for tree at runtime
      this.customizeColumns = this.customizeColumns.bind(this);

      //sort input data in this order Income -> Balance -> Cahsflow statement
      inputData.sort((a, b) => {
        if (a.Statement === "income statement") return -1; // "income statement" comes first
        if (b.Statement === "income statement") return 1; // "income statement" comes first
        return a.Statement.localeCompare(b.Statement); // Sort the rest in ascending order
      });

      //flatten the res
      let dataInputSource: any[] = [];

      inputData.forEach((statements) => {
        let accountsInputSource: any[] = [];

        let statement = statements.Statement;
        statements.FinancialStatementSecuritiesData.forEach((accountGroup) => {
          let accountGroupData = this.processAccounts(accountGroup);
          accountGroupData.forEach((obj) => {
            accountsInputSource.push(obj);
          });
        });

        //sort the roots with Order
        accountsInputSource.sort((a, b) => a.Order - b.Order);

        // //group the data by securities
        let accountsGroupedbySecurity = accountsInputSource.reduce(
          (result, item) => {
            const key = item.Security;
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(item);
            return result;
          },
          {}
        );

        let accountsGroupedbySecurityArray: any[] = [];
        //push the accountsGroupedbySecurity object into an array
        Object.keys(accountsGroupedbySecurity).forEach((key) => {
          accountsGroupedbySecurityArray.push({
            Security: key,
            AccountData: accountsGroupedbySecurity[key],
          });
        });

        dataInputSource.push({
          Statement: this.capitalizeStatementName(statement),
          Accounts: accountsGroupedbySecurityArray,
        });
      });

      this.DisplayAccountDataInput.next(dataInputSource);
    } else {
      this.notificationService.showWarning(
        "There is no data for the selected security",
        "No data"
      );
    }
  }

  processAccounts(accountGroup: any[]): any[] {
    const processedAccounts: any[] = [];

    accountGroup.forEach((account) => {
      let rootAccountObj: any = {
        AccountId: account.AccountId,
        AccountName: account.AccountName,
        AccountCode: account.AccountCode,
        ParentAccountId: account.ParentAccountId,
        Sector: account.Sector,
        Security: account.Security,
        Order: account.Order,
        ChildAccounts: [],
      };

      account.AccountData.forEach((accountData) => {
        let accountDate = accountData.Date;
        let formattedAccountDate = this.formatDate(accountDate);
        rootAccountObj[formattedAccountDate] = this.convertTo8DP(
          accountData.Value
        );
      });

      if (account.ChildAccounts && account.ChildAccounts.length > 0) {
        let childAccounts = this.processAccounts(account.ChildAccounts);
        rootAccountObj.ChildAccounts = childAccounts;
      }

      processedAccounts.push(rootAccountObj);
    });

    return processedAccounts;
  }

  BindOutputData(modelOutputData: any[]) {
    if (modelOutputData.length > 0) {
      let outputDataSource: any[] = [];
      modelOutputData.forEach((data) => {
        const dataSource: any[] = [];
        //columns seed
        let modelGroupColumnsFinal: any[] = [
          {
            caption: "Security",
            dataField: "Security",
            cssClass: "performance-attr-grid-focus-3",
            width: 180,
            fixed: "true",
            fixedPosition: "left",
          },
          {
            caption: "Sector",
            dataField: "Sector",
            width: 150,
            cssClass: "performance-attr-grid-focus-2",
            fixed: "true",
            fixedPosition: "left",
          },
        ];

        const modelGroup = data.ModelGroup;
        const securitiesOutput = data.SecuritiesOutput;
        securitiesOutput.forEach((securities) => {
          const securityName = securities.Security;
          const sectorName = securities.Sector;
          const financialYearsReturns = securities.FinancialYearsReturns;
          const modelDataObj = { Security: securityName, Sector: sectorName };

          financialYearsReturns.forEach((modelsPerYear) => {
            let modelGroupColumns: any[] = [];
            const year = modelsPerYear.Year;
            const models = modelsPerYear.ModelsOutput;
            models.forEach((modelsOutput) => {
              const modelID = modelsOutput.Model;
              const modelName = this.GetModelName(modelGroup, modelID);
              const newModelName = modelsOutput.Model + year;
              const modelNameSpaceRemoved = newModelName.replace(/\s/g, "");
              const value = modelsOutput.Value;

              modelDataObj[modelNameSpaceRemoved] = value;

              //push to columns, each model group willl be unique
              modelGroupColumns.push({
                caption: modelName,
                dataField: modelNameSpaceRemoved,
                // width: 150,
              });
            });
            modelGroupColumnsFinal.push({
              caption: year,
              columns: modelGroupColumns,
            });
          });
          dataSource.push(modelDataObj);
        });
        // console.log(modelGroupColumnsFinal);
        // console.log(dataSource);
        outputDataSource.push({
          ModelGroup: modelGroup,
          DataSource: dataSource,
          Columns: modelGroupColumnsFinal,
        });
        this.DisplayModelOutputData.next(outputDataSource);
      });
    } else {
      this.notificationService.showError(
        "No Model output data present",
        "Error"
      );
      return;
    }
  }

  GetModelName(modelGroup: string, modelID: string): string {
    let modelGroupData = this.models.filter(
      (item) => item.ModelGroup.toLowerCase() == modelGroup.toLowerCase()
    );
    let modelsData = modelGroupData[0].Models;

    let model = modelsData.filter((item) => item.ID == modelID);
    let modelName = model[0].Name;
    return modelName;
  }

  GetSelectedSecurities(): string[] {
    let selectedSecurities: string[] = [];
    this.securityTreeListInstance.instance
      .getSelectedRowsData("includeRecursive")
      .forEach((selectedSecurity) => {
        if (selectedSecurity.Head_ID != 0) {
          selectedSecurities.push(selectedSecurity.ID);
        }
      });
    return selectedSecurities;
  }

  customizeColumns(columns) {
    //get the first account Data array to set the dynamic columns
    this.firstAccountData.forEach((accountData) => {
      let formattedDate = this.formatDate(accountData.Date);
      let accountDataColumn = {
        caption: formattedDate,
        dataField: formattedDate,
        dataType: "number",
        // width: 100,
      };
      columns.push(accountDataColumn);
    });
  }

  ShowInputDataSwitchChanged() {
    if (this.showInputSwitch.value == true) {
      this.hideInputDataAccordion = false;
    } else {
      this.hideInputDataAccordion = true;
    }
  }

  //format date to dd-MMM-yyyy
  formatDate(myDate: string): any {
    const date = new Date(myDate); // current date
    const day = date.getDate().toString().padStart(2, "0"); // get day with leading zero if needed
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth(); // get month as index (0-11)
    const month = monthNames[monthIndex]; // get month name from array
    const year = date.getFullYear(); // get full year

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  }

  capitalizeStatementName(statementName: string): string {
    const capitalizedSentence = statementName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedSentence;
  }

  SecurityItemsSelected(e) {}
  onCellPrepared(e) {}

  ModelGroupsItemsSelected() {
    let modelGroupItems: any[] = [];
    let selectedModelGroups = this.modelGroupsChildren.toArray();
    let allSelectedModels = selectedModelGroups.map((element) => {
      let data: any[] = element.instance.getSelectedRowsData();
      return data;
    });

    //remove empty tree - they are 3
    let flattenedModelsArray = [].concat.apply([], allSelectedModels);
    //remove Name and Head_Id prop not needed
    let modelsWithoutNameandHeadId = flattenedModelsArray.map(
      ({ Name, Head_ID, ...rest }) => rest
    );
    //group array by Parent Name
    const ModelsGroupedbyModelGroupName = modelsWithoutNameandHeadId.reduce(
      (acc, model) => {
        const { ParentName, ...rest } = model;
        if (!acc[ParentName]) {
          acc[ParentName] = [];
        }
        acc[ParentName].push(rest.ID);
        return acc;
      },
      {}
    );
    //models structure
    for (const key in ModelsGroupedbyModelGroupName) {
      if (ModelsGroupedbyModelGroupName.hasOwnProperty(key)) {
        modelGroupItems.push({
          Name: key,
          Models: ModelsGroupedbyModelGroupName[key],
        });
      }
    }
    this.finalModelsSelection = modelGroupItems;
  }

  updateCaption(modelGroupName, modelItemsCount) {
    return modelGroupName + ` (${modelItemsCount})`;
  }

  gridInstanceCellPrepared(event) {
    let columnDataEntry: any = event.column;

    if (columnDataEntry.dataType === "number") {
      let value = event.value;
      if (value !== undefined) {
        if (value == 0) {
          let floatNumber = parseFloat(value).toFixed(2);
          event.cellElement.innerHTML = `${floatNumber}`;
        } else if (value > 0) {
          let floatNumber = parseFloat(value).toFixed(2);
          let formattedNumber = this.numberWithCommas(floatNumber);
          event.cellElement.innerHTML = `${formattedNumber}`;
        } else if (value < 0) {
          event.cellElement.classList.add("data-grid-negative-number-cell");
          event.value = Math.abs(value);

          let textDisplayed: string = event.text;
          textDisplayed = textDisplayed.replace("-", "");

          let formattedNumber = this.numberWithCommas(textDisplayed);
          event.cellElement.innerHTML = `(${formattedNumber})`;
        } else if (value == -0) {
          event.value = Math.abs(value);
          let cellabosultevalue = Math.abs(value);
          event.cellElement.innerHTML = `${cellabosultevalue}`;
        }
      }
    }
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  convertTo8DP(number): number {
    let num = number.toFixed(8);
    return num;
  }

  calculateGridHeight() {
    return 0.6 * window.screen.height + "px";
  }

  calculateModelOutputGridHeight() {
    return 0.45 * window.screen.height + "px";
  }

  //This is to fix the migAlignment bug
  //On tab selection change refresh the grids

  refreshGrid(event) {
    this.outputDataGrids.toArray().forEach((x) => {
      x.instance.refresh();
    });
  }

  showMoreInfoOnHover(event) {}
}
