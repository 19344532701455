import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChildren,
  QueryList,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  ModelGroupItem,
  ModelItem,
  ModelVariable,
} from "../../non-portfolio/non-portfolio-analytics/helper-classes";
import { DxTreeListComponent } from "devextreme-angular";
import { Subscription } from "rxjs";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";

@Component({
  selector: "app-portfolio-models-view",
  templateUrl: "./portfolio-models-view.component.html",
  styleUrls: ["./portfolio-models-view.component.scss"],
})
export class PortfolioModelsViewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() ModelGroupItems: Array<ModelGroupItem>;
  @Input() ModelParameters: Array<ModelVariable>;
  @ViewChildren(DxTreeListComponent)
  modelGroupsChildren: QueryList<DxTreeListComponent>;
  _modelsSubscription: Subscription = undefined;
  @Output() ModelsSelectionUpdateEvent = new EventEmitter();
  @Input() SelectedAssetCategory: string;

  constructor(public notificationService: SnackNotificationsService) {}
  ngAfterViewInit(): void {}
  ngOnInit() {}
  ngOnDestroy(): void {
    if (this._modelsSubscription !== undefined)
      this._modelsSubscription.unsubscribe();
  }

  fetchModelsResults(selectedModels: Array<ModelItem>) {
    if (selectedModels.length !== 0) {
      //dont map here
      //let selectedModelsIds = selectedModels.map((x) => x.ID);
      this.ModelsSelectionUpdateEvent.next(selectedModels);
    } else {
      this.notificationService.ShowErrorNotification(
        "there are no models selected"
      );
    }
  }

  ModelGroupsItemsSelected() {
    let assetCategoryModelGroups = this.modelGroupsChildren.toArray();
    let allSelectedModels = assetCategoryModelGroups.map((element) => {
      let data: Array<ModelItem> = element.instance.getSelectedRowsData();
      return data;
    });
    let flattenedModelsArray = [].concat.apply([], allSelectedModels);
    this.updateSelectedModels(flattenedModelsArray);
  }

  private updateSelectedModels(models) {
    let castedModels: Array<ModelItem> = models;
    if (castedModels.length !== 0) {
      //there is more than one item, filter
      let assetCategoryModelGroups = this.modelGroupsChildren.toArray();
      let allSelectedModels = assetCategoryModelGroups.map((element) => {
        let data: Array<ModelItem> = element.instance.getSelectedRowsData();
        return data;
      });
      let flattenedModelsArray = [].concat.apply([], allSelectedModels);
      if (flattenedModelsArray.length === 0)
        this.notificationService.ShowErrorNotification("Select model(s) first");
      else {
        this.fetchModelsResults(flattenedModelsArray);
      }
    } else {
      this.ModelsSelectionUpdateEvent.next([]);
      this.notificationService.ShowErrorNotification(
        "there are no selected models/groups currently"
      );
    }
    //If there are no selected models - update to the service/ output parameter attached to the parent component notifying them that there is no models,
    // The parent component can then show the approriate error messages
  }

  updateCaption(modelGroupName, modelItemsCount) {
    return modelGroupName + ` (${modelItemsCount})`;
  }
}
