<div class="model-mapping-page-container content-section">
	<div class="model-mapping-page-items">

		<div class="model-mapping-asset-categories">
			<div class="innova-app-routing-area-title ">Asset Categories</div>

			<div style="margin-bottom: 1%;"></div>
			<mat-divider></mat-divider>
			<div style="margin-bottom: 3%;"></div>


			<dx-list #list [showSelectionControls]="true" selectionMode="single" [selectedItemKeys]="[]"
				pageLoadMode="scrollBottom"></dx-list>
		</div>
	</div>
	<div class="model-mapping-page">
		<div style="margin-bottom: 1%;"></div>
		<mat-divider></mat-divider>
		<div style="margin-bottom: 1%;"></div>
		<div class="model-mapping-title-refresh-section">
			<div class="innova-app-routing-area-title">
				<div>{{EntityName}}</div>
			</div>
			<div>
				<button mat-stroked-button matTooltip="Refresh {{EntityName}}" (click)="ngOnInit()" color="primary">
					<mat-icon>refresh</mat-icon> Refresh
				</button>
			</div>
		</div>
		<div style="margin-bottom: 1%;"></div>
		<mat-divider></mat-divider>
		<div style="margin-bottom: 1%;"></div>

		<div class="model-mapping-content-area">
			<dx-list #modelGroupings [showSelectionControls]="true" selectionMode="single" [selectedItemKeys]="[]"
				pageLoadMode="scrollBottom"></dx-list>

			<!-- <div>
				<div id="list-api-demo">
					<div class="widget-container">
						<dx-list [items]="tasks" [height]="400" [allowItemDeleting]="allowDeleting"
							[itemDeleteMode]="deleteType" [showSelectionControls]="true" selectionMode="single"
							[(selectedItems)]="selectedItems">
						</dx-list>
						<div class="selected-data">
							<span class="caption">Selected Tasks:</span>
							<span>{{selectedItems.join(", ")}}</span>
						</div>
					</div>
					<div class="options">
						<div class="caption">Options</div>
						<div class="option">
							<dx-check-box text="Allow deleting" [(value)]="allowDeleting">
							</dx-check-box>
						</div>
						<div class="option">
							<span>Deleting type</span>
							<dx-select-box
								[items]="['static', 'toggle', 'slideButton', 'slideItem', 'swipe', 'context']"
								[(value)]="deleteType">
							</dx-select-box>
						</div>
					</div>
				</div>
			</div> -->

			<mat-divider [vertical]="true"></mat-divider>

			<!-- <div>
				<div class="form">
					<dx-tree-view id="simple-treeview" [items]="products" [width]="300"
						(onItemClick)="selectItem($event)" selectionMode="multiple" showCheckBoxesMode="normal">
					</dx-tree-view>
					<div id="product-details" *ngIf="currentItem.price">
						<img [src]="currentItem.image" />
						<div class="name">{{currentItem.text}}</div>
						<div class="price">{{"$" + currentItem.price}}</div>
					</div>
				</div>
			</div> -->

		</div>
	</div>
</div>