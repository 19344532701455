<div class="parent-container">
  <mat-accordion multi>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Select Securities and Models</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="date-section label">
        <div style="display: flex; justify-content: flex-end">
          <div style="flex: 1">
            <div class="">
              <div class="date-rating-agency">
                <span
                  id="analytics-start-date-caption"
                  class="component-inputs-label label"
                  >Start Date</span
                >
                <dx-date-box
                  id="analytics-start-date-input"
                  displayFormat="dd-MMM-yyyy"
                  #startDateBox
                  matTooltip="Period start date"
                  width="200"
                  value="31 Dec 2015"
                >
                </dx-date-box>
              </div>
              <div class="date-rating-agency">
                <span
                  id="analytics-rating-agency-caption"
                  class="component-inputs-label label"
                  >End Date</span
                >
                <dx-date-box
                  id="analytics-start-date-input"
                  displayFormat="dd-MMM-yyyy"
                  #endDateBox
                  matTooltip="Period start date"
                  width="200"
                  value="31 Dec 2020"
                >
                </dx-date-box>
              </div>
              <div class="date-rating-agency">
                <span
                  id="analytics-rating-agency-caption"
                  class="component-inputs-label label"
                  >Show Input Data
                </span>
                <dx-switch
                  #showInputDataSwitch
                  [value]="true"
                  matTooltip="Opt to show Input data"
                  (onValueChanged)="ShowInputDataSwitchChanged()"
                >
                </dx-switch>
              </div>
            </div>
          </div>

          <div class="refresh-button">
            <dx-button
              text="Refresh"
              stylingMode="contained"
              icon="fa fa-sync"
              type="default"
              [width]="120"
              [height]="40"
              (onClick)="FetchData()"
            >
            </dx-button>
          </div>
        </div>
      </div>
      <div class="model-section label">
        <div style="display: flex; justify-content: space-between">
          <div class="securities">
            Securities
            <hr class="hr" />
            <dx-tree-list
              height="320"
              #securityTreeList
              [dataSource]="listedSecuritiesFinal"
              keyExpr="ID"
              parentIdExpr="Head_ID"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [showBorders]="true"
              (onSelectionChanged)="SecurityItemsSelected($event)"
              noDataText="no data to display..."
              class="tree-list"
              [highlightChanges]="true"
              [repaintChangesOnly]="true"
              [focusedRowEnabled]="false"
              [showBorders]="true"
              [autoExpandAll]="false"
              [hoverStateEnabled]="true"
              [focusStateEnabled]="true"
              (onCellPrepared)="onCellPrepared($event)"
            >
              <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-sorting mode="multiple"> </dxo-sorting>
              <dxi-column
                dataField="Name"
                caption="Select Securities under Sectors"
                sortOrder="asc"
                style="font-size: 200%"
              >
              </dxi-column>
            </dx-tree-list>
          </div>

          <div class="models">
            <dx-tab-panel
              #assetCategoriesPanel
              [dataSource]="tabData"
              [selectedIndex]="0"
              [loop]="false"
              [animationEnabled]="true"
              [swipeEnabled]="true"
              [showNavButtons]="true"
              [scrollingEnabled]="true"
              width="100%"
              height="370px"
            >
              <div *dxTemplate="let item of 'title'">
                <div [ngStyle]="item.iconStyle" matTooltip="{{ item.content }}">
                  <div class="tabpanel-item">
                    <div>
                      <span class="icon">
                        <mat-icon [inline]="true">{{ item.Icon }}</mat-icon>
                      </span>
                    </div>
                    <div>
                      {{ item.Name }}
                    </div>
                  </div>
                </div>
              </div>
              <div *dxTemplate="let selectedAsstCat of 'item'">
                <div
                  class="model-group-contents"
                  style="height: 50vh !important; overflow-y: scroll"
                >
                  <div *ngFor="let model of models" style="margin-top: 2%">
                    <dx-tree-list
                      #modelTreeList
                      [id]="model.ModelGroup"
                      [dataSource]="model.Models"
                      keyExpr="ID"
                      parentIdExpr="Head_ID"
                      [allowColumnResizing]="true"
                      [allowColumnReordering]="true"
                      [showBorders]="true"
                      [width]="230"
                      (onCellClick)="
                        ModelGroupsItemsSelected($event, model.ModelGroup)
                      "
                      class="tree-list"
                      noDataText="no data to display..."
                      class="tree-list"
                      [highlightChanges]="true"
                      [repaintChangesOnly]="true"
                      [focusedRowEnabled]="false"
                      [showBorders]="true"
                      [autoExpandAll]="false"
                      [hoverStateEnabled]="true"
                      [focusStateEnabled]="true"
                      (onCellHoverChanged)="showMoreInfoOnHover($event)"
                      (onCellPrepared)="onCellPrepared($event)"
                    >
                      <dxo-selection
                        mode="multiple"
                        [recursive]="true"
                      ></dxo-selection>
                      <dxo-filter-row [visible]="true"></dxo-filter-row>
                      <dxo-sorting mode="multiple"> </dxo-sorting>
                      <dxi-column
                        dataField="Name"
                        sortOrder="asc"
                        [caption]="
                          updateCaption(model.ModelGroup, model.Models.length)
                        "
                        style="font-size: 200%"
                      >
                      </dxi-column>
                    </dx-tree-list>
                  </div>
                </div>
              </div>
            </dx-tab-panel>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="false"
      [hidden]="hideInputDataAccordion"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>View Input Data </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        class="output-section label"
        *ngIf="DisplayAccountDataInputObservable"
      >
        <mat-tab-group #tabGroup [disableRipple]="true" [animationDuration]="0">
          <mat-tab
            *ngFor="
              let item of DisplayAccountDataInputObservable | async;
              let i = index
            "
            [label]="item.Statement"
          >
            <mat-tab-group
              #tabGroup2
              [disableRipple]="true"
              [animationDuration]="0"
            >
              <mat-tab
                *ngFor="let account of item.Accounts"
                [label]="account.Security"
              >
                <dx-tree-list
                  id="InputDataTree"
                  [dataSource]="account.AccountData"
                  itemsExpr="ChildAccounts"
                  dataStructure="tree"
                  [autoExpandAll]="false"
                  itemsExpr="ChildAccounts"
                  dataStructure="tree"
                  [columnAutoWidth]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [customizeColumns]="customizeColumns"
                  (onCellPrepared)="gridInstanceCellPrepared($event)"
                  [pager]="{
                    allowedPageSizes: [100, 200, 300, 400, 500],
                    showPageSizeSelector: true,
                    showInfo: true,
                    visible: true
                  }"
                  [paging]="{
                    pageSize: 100,
                    enabled: true
                  }"
                  [rowAlternationEnabled]="true"
                  [height]="500"
                >
                  <dxo-selection mode="single"></dxo-selection>
                  <dxo-search-panel
                    [visible]="true"
                    width="250"
                  ></dxo-search-panel>
                  <dxi-column
                    dataField="AccountName"
                    caption="Ledger/SubLedger"
                    [width]="300"
                    fixed="true"
                    fixedPosition="left"
                    cssClass="performance-attr-grid-focus-3"
                  ></dxi-column>
                </dx-tree-list>
                <!-- <dx-tree-list
                  id="InputDataTree"
                  [dataSource]="account.AccountData"
                  [autoExpandAll]="false"
                  keyExpr="AccountId"
                  parentIdExpr="ParentAccountId"
                  [columnAutoWidth]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [customizeColumns]="customizeColumns"
                  (onCellPrepared)="gridInstanceCellPrepared($event)"
                  [pager]="{
                    allowedPageSizes: [100, 200, 300, 400, 500],
                    showPageSizeSelector: true,
                    showInfo: true,
                    visible: true
                  }"
                  [paging]="{
                    pageSize: 100,
                    enabled: true
                  }"
                  [rowAlternationEnabled]="true"
                  [height]="500"
                >
                  <dxo-selection mode="single"></dxo-selection>
                  <dxo-search-panel
                    [visible]="true"
                    width="250"
                  ></dxo-search-panel>
                  <dxi-column
                    dataField="AccountName"
                    caption="Ledger/SubLedger"
                    [width]="300"
                    fixed="true"
                    fixedPosition="left"
                    cssClass="performance-attr-grid-focus-3"
                  ></dxi-column>
                 
                </dx-tree-list> -->
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
          <ng-template #placeHolderTemplate> no data guys </ng-template>
        </mat-tab-group>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> View Output Data </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        class="output-section label"
        *ngIf="DisplayModelOutputDataObservable"
      >
        <mat-tab-group
          #tabGroup
          [disableRipple]="true"
          [animationDuration]="0"
          (selectedTabChange)="refreshGrid($event)"
        >
          <mat-tab
            *ngFor="
              let item of DisplayModelOutputDataObservable | async;
              let i = index
            "
            [label]="item.ModelGroup"
          >
            <dx-data-grid
              #outputDataGrid
              [dataSource]="item.DataSource"
              [columns]="item.Columns"
              columnResizingMode="widget"
              [allowColumnResizing]="true"
              [showBorders]="true"
              [showRowLines]="true"
              [groupPanel]="{ visible: true }"
              [grouping]="{ autoExpandAll: true }"
              [searchPanel]="{
                visible: true,
                placeholder: 'search for anything',
                width: 500
              }"
              [allowColumnReordering]="false"
              [columnChooser]="{ enabled: true, mode: 'select' }"
              [wordWrapEnabled]="false"
              [columnFixing]="true"
              [twoWayBindingEnabled]="true"
              [repaintChangesOnly]="false"
              [activeStateEnabled]="true"
              (onCellPrepared)="gridInstanceCellPrepared($event)"
              (OnContentReady)="gridInstanceContentReady($event)"
              [keyExpr]="gridKeyExpression"
              [focusStateEnabled]="true"
              [focusedRowEnabled]="false"
              [scrolling]="{ mode: 'standard', preloadEnabled: true }"
              height="{{ calculateModelOutputGridHeight() }}"
              [rowAlternationEnabled]="true"
              [export]="{
                enabled: true,
                fileName: 'Model Outputs',
                allowExportSelectedData: true
              }"
              [columnAutoWidth]="true"
              [pager]="{
                allowedPageSizes: [10, 20, 50, 100],
                showPageSizeSelector: true,
                showInfo: true,
                visible: true
              }"
              [paging]="{
                pageSize: 20,
                enabled: true
              }"
              [rowAlternationEnabled]="true"
            >
              <dxo-selection mode="single" [deferred]="true"></dxo-selection>
              <dxo-paging [enabled]="true"></dxo-paging>
              <dxo-sorting mode="single"> </dxo-sorting>
            </dx-data-grid>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
