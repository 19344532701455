import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
	selector: 'app-analytics-asset-level-settings',
	templateUrl: './analytics-asset-level-settings.component.html',
	styleUrls: ['./analytics-asset-level-settings.component.scss']
})
export class AnalyticsAssetLevelSettingsComponent implements OnInit {


	selectAllAssetCategoriesInAssetClass: boolean;

	constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: boolean, private _bottomSheetRef: MatBottomSheetRef<AnalyticsAssetLevelSettingsComponent>) {
		this.selectAllAssetCategoriesInAssetClass = data;

		this._bottomSheetRef.disableClose = true;
	}

	ngOnInit() {
		/**
		 * On initialized get the settings. Probably from local storage on the browser.
		 */
	}
	dismissBottomNavBarSettings(event: any) {
		/**
		 * Before dismissing, check the value of the form and save the changes.
		 */
		this._bottomSheetRef.dismiss(this.selectAllAssetCategoriesInAssetClass);
	}

}
