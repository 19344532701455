import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { DxFormComponent } from "devextreme-angular";
import { ApiService } from "src/app/app-services/api/api.service";
import { SnackNotificationsService } from "src/app/app-services/snack-notifications/snack-notifications.service";
import jwt_decode from "jwt-decode";
import { UserService } from "src/app/app-components/master-data/user/user.service";
import notify from "devextreme/ui/notify";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "src/app/app-services/notification/notification.service";

@Component({
  selector: "app-data-form",
  templateUrl: "./data-form.component.html",
  styleUrls: ["./data-form.component.scss"],
})
export class DataFormComponent implements OnInit, AfterViewInit {
  title: string;
  formData: any;
  formItems: any;
  formID: any;
  postEndPoint: string;
  isEditAction = false;
  disableActionControl: boolean;
  initialFormData: any;
  isAuthenticated: boolean;
  userData: any;
  decodedAccessToken: any;
  currentUserID: any;

  @ViewChild("dataForm", { static: true }) dataFormInstance: DxFormComponent;
  formValidationGroupName: "data-form-validation-group";

  /**
   * Dialog to show configured forms. Populate the form with data and send the form from here.
   * @param formOptions Incoming Form Options, Includes FormData, FormItems,
   * FormName(TO be displayed as the form title), EndPointUrl(the url to send the data to)
   * @param dialogRef This dialog reference
   * @param apiService The api service to use to post the data that is created.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public formOptions: any,
    public dialogRef: MatDialogRef<DataFormComponent>,
    private apiService: ApiService,
    private notificationService: NotificationService,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private toastr: ToastrService,
    private notifyService: NotificationService
  ) {
    this.formData = formOptions.formData;
    this.formItems = formOptions.formItems;
    this.formID = formOptions.formID;
    this.title = formOptions.formTitle;
    this.postEndPoint = formOptions.postEndPoint;
  }

  ngOnInit() {
    this.oidcSecurityService.isAuthenticated$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    //Get current User ID
    this.decodedAccessToken = jwt_decode(this.oidcSecurityService.getToken());
    this.userData = this.decodedAccessToken;
    this.currentUserID = this.decodedAccessToken.sub;

    if (this.formData !== null) {
      this.isEditAction = true;
    }

    this.disableActionControl = true;

    // tslint:disable-next-line: variable-name
    const _intitialFormData = this.formData;

    this.initialFormData = _intitialFormData;
  }

  ngAfterViewInit(): void {
    this.dataFormInstance.showOptionalMark = true;
    this.dataFormInstance.formData = this.formData;
    this.dataFormInstance.items = this.formItems;
    this.dataFormInstance.showValidationSummary = true;
    this.dataFormInstance.validationGroup = this.formValidationGroupName;
    this.dataFormInstance.colCount = 2;
    this.dataFormInstance.labelLocation = "top";
    this.dataFormInstance.scrollingEnabled = true;
    this.dataFormInstance.focusStateEnabled = true;
    this.dataFormInstance.showValidationSummary = true;
    // this.dataFormInstance.onEditorEnterKey.subscribe(event => {
    //   this.SaveAction(null);
    // });

    this.dataFormInstance.onFieldDataChanged.subscribe((change) => {
      if (change.value !== undefined) this.disableActionControl = false;
    });
  }

  changeTitle() {
    this.title = "Another form directive";
  }

  toogleDetailsMode() {
    if (this.dataFormInstance.disabled === false) {
      this.dataFormInstance.disabled = true;

      return;
    }

    this.dataFormInstance.disabled = false;
  }

  closeForm(event: any) {
    this.dialogRef.close();
  }

  DeleteAction(event: any) {
    this.apiService.Delete(this.postEndPoint, this.formID).subscribe(
      (result) => {
        this.notificationService.showInfo("result", "Success");
        this.dialogRef.close();
        // this.dataFormInstance.disabled = false;
      },
      (error) => {
        this.notificationService.showError(error, "Error");
        this.dialogRef.close();
      },
      () => {
        this.dataFormInstance.disabled = false;
        this.disableActionControl = false;
      }
    );
  }

  SaveAction(event: any) {
    let validationRes = this.dataFormInstance.instance.validate();
    if (validationRes.isValid) {
      this.dataFormInstance.disabled = true;
      this.disableActionControl = true;
      //for add user component
      if (this.title == "New User") {
        //post endpoint is different
        this.postEndPoint =
          "https://auth.innova.co.ke/api/accounts/RegisterUser";
        let formData = this.dataFormInstance.formData;
        //append the current User ID
        this.dataFormInstance.formData.AddedById = this.currentUserID;
        this.userService.Post(this.postEndPoint, formData).subscribe(
          (result: Response) => {
            let response = result.json();
            this.notificationService.showSuccess(
              "User successfully added",
              "Success"
            );
            this.dataFormInstance.disabled = false;
          },
          (error) => {
            console.log(error);
            this.notificationService.showError(error, "Error");
            this.dialogRef.close();
          },
          () => {
            this.dataFormInstance.disabled = false;
            this.disableActionControl = false;
          }
        );
      } else {
        if (this.isEditAction === true) {
          let formData = this.dataFormInstance.formData;

          this.apiService.Put(this.postEndPoint, formData).subscribe(
            () => {
              this.notificationService.showInfo("Entity Updated", "Success");
              this.dialogRef.close();
            },
            (error) => {
              this.dataFormInstance.disabled = false;
              this.disableActionControl = false;
            }
          );
        } else {
          //is a save action
          let formData = this.dataFormInstance.formData;
          this.apiService.Post(this.postEndPoint, formData).subscribe(
            (result) => {
              this.notificationService.showSuccess(
                "New entity created",
                "Success"
              );
              this.dataFormInstance.disabled = false;
            },
            (error) => {
              this.notificationService.showError(error, "Error");
              this.dialogRef.close();
            },
            () => {
              this.dataFormInstance.disabled = false;
              this.disableActionControl = false;
            }
          );
        }
      }
    }
  }

  ClearFormContents(event: any) {
    this.dataFormInstance.instance.resetValues();
  }
}

/**
 * Holds Form Configuration options and the actual form data if any
 */
export class DataFormOptions {
  /**
   * The data array to be bound to the form
   */
  formData: any;
  /**
   * Form fields Configuration
   */
  formItems: any;

  formID: any;
  /**
   * This is the title that will be rendered on the form.
   * Make is a maximum of two words for simplicity and clarity
   */
  formTitle: string;
  postEndPoint: string;
}
