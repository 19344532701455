<div class="data-grid-container">
  <dx-data-grid
    #listGridView
    class="grid-container"
    (onCellPrepared)="gridInstanceCellPrepared($event)"
    (onRowDblClick)="rowDoubleClicked($event)"
    (onContentReady)="onContentReady($event)"
  >
  </dx-data-grid>
</div>
