import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseInfoViewComponent } from '../release-info-view/release-info-view.component';

/**
 * @type Service
 * Checks if the version of the application has changed so as to furnish the user of the system with
 * information on the relevant changes.
 *
 * 
 * @description checks if there is an existing app version information on the local storage of th user, 
 * if none, Add it and let them know of the release information
 * 
 * If there is an app version already in the users local storage, check if it the current one, if not, update it and show the user
 * the release information
 */
@Injectable({
  providedIn: 'root'
})
export class ReleaseInformationService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig, public dialog: MatDialog) { }

  checkVersionChanges() {
    let appVersion = localStorage.getItem('appverCurr')

    if (appVersion === null) {
      //Show the release notes here
      let releaseNotes = this.config.ReleaseNotes;
      let version = this.config.AppVersion;

      let initialShowRef = this.dialog.open(ReleaseInfoViewComponent, { data: { notes: releaseNotes, version: version }, maxWidth: '700px', disableClose: true })
        localStorage.setItem('appverCurr', this.config.AppVersion)
    }
    else {
      if (this.config.AppVersion != appVersion) {

        let releaseNotes = this.config.ReleaseNotes;
        let version = this.config.AppVersion;
        //Show the release notes here
        this.dialog.open(ReleaseInfoViewComponent, { data: { notes: releaseNotes, version: version }, maxWidth: '700px', disableClose: true })
        localStorage.setItem('appverCurr', this.config.AppVersion)
      }
    }


  }
}
